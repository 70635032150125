import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditLoadInput from '../../uicomponents/EditLoadInput';
import EditLoadDropdown from '../../uicomponents/EditLoadDropdown';
import { DateTimePicker } from 'react-widgets';
import $ from 'jquery';
import 'jquery-validation';
import { formData } from '../../../helpers/formData';
import { getTimeZones } from '../../../services/commonService';
import moment from 'moment';
import { getcitybystateService, getEldByCariierService, getTrucksByELDSService, getzipbycityService } from '../../../services/loadService';
import { updateLoadAct } from '../../../actions/loadActions';
class UniversalLoadEditcopy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeZones: '',
            editableData: '',
            OriginCity: [],
            OriginZip: [],
            DestinationCity: [],
            DestinationZip: [],
            shipperName: [],
            Lanedetails: [],
            stateData: [],
            loadPoints: [],
            sequence: 0,
            loadDate: '',
            pickearliesttime: '',
            picklatesttime: '',
            dropearliesttime: '',
            droplatesttime: '',
            tenderTime: '',
            loggedUser: {
                FirstName: localStorage.getItem('userName')
            }
        }

    }
    componentDidMount() {
        this.loadTimeZone()
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.editLoadValues !== prevProps.editLoadValues) {
            this.setState({ editableData: this.props.editLoadValues })
            this.loadOriginCity(this.props.editLoadValues?.loadPoints[0]?.stateId, 2);
            this.loadOriginZip(this.props.editLoadValues?.loadPoints[0]?.cityId, 2);
            this.loadDestinationCity(this.props.editLoadValues?.loadPoints[1]?.stateId, 2);
            this.loadDestinationZip(this.props.editLoadValues?.loadPoints[1]?.cityId, 2);
            if (this.props.editLoadValues.vehicleInfo?.eldId) {
                this.truckNos(this.props.editLoadValues.vehicleInfo.eldId, 4);
            }
            if (this.props.editLoadValues.carrierInfo?.id) {
                this.loadEldList(this.props.editLoadValues.carrierInfo.id, 3);
            }
            this.setState({
                loadDate: this.props.editLoadValues.loadDate,
                pickearliesttime: this.props.editLoadValues?.loadPoints[0].earliestTime,
                picklatesttime: this.props.editLoadValues?.loadPoints[0].latestTime,
                dropearliesttime: this.props.editLoadValues?.loadPoints[1].earliestTime,
                droplatesttime: this.props.editLoadValues?.loadPoints[1].latestTime,
                tenderTime: this.props.editLoadValues?.tenderInfo?.tenderTime
            })
        }
    }

    loadEldList = (e, type) => {
        let carrierId;
        if (type === 3) {
            carrierId = e;
            // this.setState({
            //     ...this.state,
            //     editableData:{...this.state.editableData,
            //         instruction:$(e.target).val()
            //     }
            // });
            
        }
        else {
            carrierId = e.target.value;
            this.setState({
                ...this.state,
                editableData:{...this.state.editableData,
                vehicleInfo:{...this.state.editableData.vehicleInfo,
                eldVendor:'',
                assetNumber:'',
                name:'',
                eldId:''
                }
                }
            });
        }
        this.setState({ eldlist: [], trucksNoList: [] })
        let param = {
            payload: carrierId
        }
        getEldByCariierService(param).then((response) => {
            if (response) {
                this.setState({
                    ...this.state,
                    eldlist: [...response.data],
                    selectedCompany: param.payload
                });
            }
        });
    }
    truckNos = (e, type) => {
        let eldId,selectedIdx;
        if (type === 4) {
            eldId = e;
            selectedIdx=this.props.editLoadValues.carrierInfo.id;
        }
        else {
            eldId = e.target.value;
            selectedIdx= this.state.selectedCompany;
        }
        let param = {
            eldProviderId: eldId,
            companyId: selectedIdx
        }
        getTrucksByELDSService(param).then((response) => {
            if (response) {
                this.setState({
                    ...this.state,
                    trucksNoList: [...response]
                });
            }
        });
    }
    loadTimeZone() {
        getTimeZones().then((response) => {
            let timeZones = []
            for (let index = 0; index < Object.values(response).length; index++) {
                const element = Object.values(response)[index];
                timeZones.push({ id: index + 1, value: element })
            }
            if (response) {
                this.setState({
                    ...this.state,
                    timeZones: timeZones
                });
            }
        });
    }
    loadOriginCity = (e, type) => {
        let stateId;
        if (type === 1) {
            let stateArr = $(e.target).val().split(',')
            stateId = stateArr[0];
        }
        else {
            stateId = e;
        }
        let param = {
            payload: stateId
        }
        getcitybystateService(param).then((response) => {
            if (response) {
                this.setState({
                    ...this.state,
                    OriginCity: [...response]
                });
            }
        });
    }
    loadOriginZip = (e, type) => {
        let cityId;
        if (type === 1) {
            let cityArr = $(e.target).val().split(',')
            cityId = cityArr[0];
        }
        else {
            cityId = e;
        }
        let param = {
            payload: cityId
        }
        getzipbycityService(param).then((response) => {
            if (response) {
                this.setState({
                    ...this.state,
                    OriginZip: response
                });
            }
        });
    }
    loadDestinationCity = (e, type) => {
        let stateId;
        if (type === 1) {
            let stateArr = $(e.target).val().split(',')
            stateId = stateArr[0];
        }
        else {
            stateId = e;
        }
        let param = {
            payload: stateId
        }
        getcitybystateService(param).then((response) => {
            if (response) {
                this.setState({
                    ...this.state,
                    DestinationCity: [...response]
                });
            }
        });
    }
    loadDestinationZip = (e, type) => {
        let cityId;
        if (type === 1) {
            let cityArr = $(e.target).val().split(',')
            cityId = cityArr[0];
        }
        else {
            cityId = e;
        }
        let param = {
            payload: cityId
        }
        getzipbycityService(param).then((response) => {
            if (response) {
                this.setState({
                    ...this.state,
                    DestinationZip: response
                });
            }
        });
    }
    laneDetails = (event) => {
        var currentTarget = event.currentTarget;
        var indexFinalval = currentTarget.options[currentTarget.selectedIndex].dataset.lanedetailsindex;
        if (typeof indexFinalval === 'undefined') {
            this.setState({
                Lanedetails: []
            })
        }
        else {
            this.setState({
                Lanedetails: this.props.shippernameAndLane[indexFinalval]
            })
        }
    }
    onChangeDate = (type, event) => {
        switch (type) {
            case 'loadDate':
                this.setState({
                    loadDate: event
                })
            break;
            case 'pickearliesttime':
                this.setState({
                    pickearliesttime: event
                })
            break;
            case 'picklatesttime':
                this.setState({
                    picklatesttime: event
                })
            break;
            case 'dropearliesttime':
                this.setState({
                    dropearliesttime: event
                })
            break;
            case 'droplatesttime':
                this.setState({
                    droplatesttime: event
                })
            break;
            case 'tenderTime':
                this.setState({
                    tenderTime: event
                })
                break;
            default:;
        }
    }
    submitEditForm = (e) => {
        $('#myeditform').validate({
            rules: {
                loadno: {
                    required: true
                },
                noofpickup: {
                    required: true
                },
                noofdropoff: {
                    required: true
                },
                customername: {
                    required: true
                },
                pickaddress1: {
                    required: true
                },
                pickaddress2: {
                    required: true
                },
                originstate: {
                    required: true
                },
                origincity: {
                    required: true
                },
                originzip: {
                    required: true
                },
                pickearliesttime: {
                    required: true
                },
                picklatesttime: {
                    required: true
                },
                picktimezone: {
                    required: true
                },
                picksequence: {
                    required: true
                },
                dropaddress1: {
                    required: true
                },
                dropaddress2: {
                    required: true
                },
                destinationcity: {
                    required: true
                },
                destinationstate: {
                    required: true
                },
                destinationzip: {
                    required: true
                },
                dropearliesttime: {
                    required: true
                },
                droplatesttime: {
                    required: true
                },
                droptimezone: {
                    required: true
                },
                dropsequence: {
                    required: true
                }
            },
            messages: {
                loadno: {
                    required: 'Load Number can not be left blank.!'
                },
                noofpickup: {
                    required: 'No of Pickup can not be left blank.!'
                },
                noofdropoff: {
                    required: 'No of Dropoff can not be left blank.!'
                },
                customername: {
                    required: 'Customer Name can not be left blank.!'
                },
                pickaddress1: {
                    required: 'Address1 can not be left blank.!'
                },
                pickaddress2: {
                    required: 'Address2 can not be left blank.!'
                },
                originstate: {
                    required: 'State can not be left blank.!'
                },
                origincity: {
                    required: 'City Number can not be left blank.!'
                },
                originzip: {
                    required: 'Zip code can not be left blank.!'
                },
                pickearliesttime: {
                    required: 'Earliest time can not be left blank.!'
                },
                picklatesttime: {
                    required: 'Latest time can not be left blank.!'
                },
                picktimezone: {
                    required: 'Time zone can not be left blank.!'
                },
                picksequence: {
                    required: 'Sequence can not be left blank.!'
                },
                dropaddress1: {
                    required: 'Address1 can not be left blank.!'
                },
                dropaddress2: {
                    required: 'Address2 can not be left blank.!'
                },
                destinationcity: {
                    required: 'City can not be left blank.!'
                },
                destinationstate: {
                    required: 'State can not be left blank.!'
                },
                destinationzip: {
                    required: 'Zip code can not be left blank.!'
                },
                dropearliesttime: {
                    required: 'Earliest time can not be left blank.!'
                },
                droplatesttime: {
                    required: 'Latest time can not be left blank.!'
                },
                droptimezone: {
                    required: 'Time zone can not be left blank.!'
                },
                dropsequence: {
                    required: 'Sequence can not be left blank.!'
                }
            }, errorPlacement: function (error, element) {
                if (element.attr('name') === 'pickearliesttime') {
                    error.insertAfter($(element).parents('div.master').find($('.error_place')));
                }
                else if (element.attr('name') === 'picklatesttime') {
                    error.insertAfter($(element).parents('div.master').find($('.error_place')));
                }
                else if (element.attr('name') === 'dropearliesttime') {
                    error.insertAfter($(element).parents('div.master').find($('.error_place')));
                }
                else if (element.attr('name') === 'droplatesttime') {
                    error.insertAfter($(element).parents('div.master').find($('.error_place')));
                }
                else {
                    error.insertAfter(element);
                }
            }
        });
        let form = document.getElementById('myeditform');
        let loadData = formData(form);
        if ($('#myeditform').valid()) {
            //Origin
            var originstate = $('#originstate option:selected').text();
            var origincity = $('#origincity option:selected').text();
            var originzip = $('#originzip option:selected').text();
            //Destination
            var destinationstate = $('#destinationstate option:selected').text();
            var destinationcity = $('#destinationcity option:selected').text();
            var destinationzip = $('#destinationzip option:selected').text();
            const level1 = {};
            //Load Info
            level1.loadNumber = (loadData.loadno) ? (loadData.loadno) : ('');
            level1.bolNumber = (loadData.bolno) ? (loadData.bolno) : ('');
            level1.orederno = (loadData.orederno) ? (loadData.orederno) : ('');
            let loadDate = (this.state.loadDate) ? moment(this.state.loadDate).utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]') : ('');
            level1.loadDate = (loadDate) ? (loadDate) : ('');
            level1.rate = (loadData.rate) ? (loadData.rate) : ('');
            level1.weight = (loadData.weight) ? (loadData.weight) : ('');
            level1.distance = (loadData.distance) ? (loadData.distance) : ('');
            level1.noofpickup = (loadData.noofpickup) ? (loadData.noofpickup) : ('');
            let shipperInfo = {};
            shipperInfo.id = (loadData.shipper) ? (loadData.shipper) : ('');
            level1.shipperInfo = shipperInfo;
            level1.noofdropoff = (loadData.noofdropoff) ? (loadData.noofdropoff) : ('');
            level1.customername = (loadData.customername) ? (loadData.customername) : ('');
            let carrierInfo = {};
            carrierInfo.id = (loadData.carrierCompanyId) ? (loadData.carrierCompanyId) : '';
            level1.carrierInfo = carrierInfo;
            level1.eldlist = (loadData.eldlist) ? (loadData.eldlist) : ('');
            let vehicleInfo = {};
            vehicleInfo.id = (loadData.truckno) ? (loadData.truckno) : '';
            vehicleInfo.truckTypeId = (loadData.trucktype) ? (loadData.trucktype) : '';
            vehicleInfo.eldVendor = (loadData.eldlist) ? (loadData.eldlist) : '';
            level1.vehicleInfo = vehicleInfo;
            level1.instruction = (loadData.instruction) ? (loadData.instruction) : ('');
            //pickup info 
            level1.pickaddress1 = (loadData.pickaddress1) ? (loadData.pickaddress1) : ('');
            level1.pickaddress2 = (loadData.pickaddress2) ? (loadData.pickaddress2) : ('');
            level1.orginStateName = (originstate !== 'State') ? (originstate) : ('');
            level1.orginCityName = (origincity !== 'City') ? (origincity) : ('');
            level1.orginZipValue = (originzip !== 'Zip Code') ? (originzip) : ('');
            let pickearliesttime = (loadData.pickearliesttime) ? (moment(loadData.pickearliesttime).utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]')) : ('');
            level1.pickearliesttime = (pickearliesttime) ? (pickearliesttime) : ('');
            let picklatesttime = (loadData.picklatesttime) ? (moment(loadData.picklatesttime).utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]')) : ('');
            level1.picklatesttime = (picklatesttime) ? (picklatesttime) : ('');
            level1.picktimezone = (loadData.picktimezone !== 'Time Zone') ? (loadData.picktimezone) : ('');
            level1.picksequence = (loadData.picksequence) ? (loadData.picksequence) : ('');
            level1.pickMobileNo = (loadData.pickMobileNo) ? (loadData.pickMobileNo) : ('');
            level1.picklattitude = (loadData.picklattitude) ? (loadData.picklattitude) : ('');
            level1.picklongitude = (loadData.picklongitude) ? (loadData.picklongitude) : ('');
            //dropoff info 
            level1.dropaddress1 = (loadData.dropaddress1) ? (loadData.dropaddress1) : ('');
            level1.dropaddress2 = (loadData.dropaddress2) ? (loadData.dropaddress2) : ('');
            level1.destinationStateName = (destinationstate !== 'State') ? (destinationstate) : ('');
            level1.destinationCityName = (destinationcity !== 'City') ? (destinationcity) : ('');
            level1.destinationZipValue = (destinationzip !== 'Zip Code') ? (destinationzip) : ('');
            let dropearliesttime = (this.state.dropearliesttime) ? (moment(this.state.dropearliesttime).utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]')) : ('');
            level1.dropearliesttime = (dropearliesttime) ? (dropearliesttime) : ('');
            let droplatesttime = (this.state.droplatesttime) ? (moment(this.state.droplatesttime).utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]')) : ('');
            level1.droplatesttime = (droplatesttime) ? (droplatesttime) : ('');
            level1.droptimezone = (loadData.droptimezone !== 'Time Zone') ? (loadData.droptimezone) : ('');
            level1.dropsequence = (loadData.dropsequence) ? (loadData.dropsequence) : ('');
            level1.dropMobileNo = (loadData.dropMobileNo) ? (loadData.dropMobileNo) : ('');
            level1.droplattitude = (loadData.droplattitude) ? (loadData.droplattitude) : ('');
            level1.droplongitude = (loadData.droplongitude) ? (loadData.droplongitude) : ('');
            //Tender Info
            level1.tamount = (loadData.tamount) ? (loadData.tamount) : ('');
            level1.loadtenderamount = (loadData.loadtenderamount) ? (loadData.tamount) : ('');
            let tenderInfo = {};
            tenderInfo.amount = (loadData.tamount) ? (loadData.tamount) : '';
            tenderInfo.totalBrokerNetAmount = (loadData.loadtenderamount) ? (loadData.loadtenderamount) : '';
            tenderInfo.tenderTime = (loadData.tenderTime) ? (moment(loadData.tenderTime).utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]')) : '';
            level1.tenderInfo = tenderInfo;
            //loadPoints  array
            let originCItyPrep = loadData.origincity.split(',');
            let destinationCItyPrep = loadData.destinationcity.split(',');
            let originStatePrep = loadData.originstate.split(',');
            let destinationStatePrep = loadData.destinationstate.split(',');
            let originZipPrep = loadData.originzip.split(',');
            let destinationZipPrep = loadData.destinationzip.split(',');
            level1.loadPoints = []
            //prepare pick up  object
            let pickupObj = {
                typeId: 2,
                id: this.props.editLoadValues?.loadPoints[0]?.id,
                address1: level1.pickaddress1,
                address2: level1.pickaddress2,
                city: originCItyPrep[1],
                cityId: originCItyPrep[0],
                state: originStatePrep[1],
                stateId: originStatePrep[0],
                zipId: originZipPrep[0],
                zipCode: originZipPrep[1],
                contactNumber: level1.pickMobileNo,
                earliestTime: level1.pickearliesttime,
                latestTime: level1.picklatesttime,
                sequence: level1.picksequence,
                latitude: level1.picklattitude,
                longitude: level1.picklongitude
            }
            //prepare drop off  object
            let dropoffObj = {
                typeId: 3,
                id: this.props.editLoadValues?.loadPoints[1]?.id,
                address1: level1.dropaddress1,
                address2: level1.dropaddress2,
                city: destinationCItyPrep[1],
                cityId: destinationCItyPrep[0],
                state: destinationStatePrep[1],
                stateId: destinationStatePrep[0],
                zipId: destinationZipPrep[0],
                zipCode: destinationZipPrep[1],
                contactNumber: level1.dropMobileNo,
                earliestTime: level1.dropearliesttime,
                latestTime: level1.droplatesttime,
                sequence: level1.dropsequence,
                latitude: level1.droplattitude,
                longitude: level1.droplongitude
            }
            level1.loadPoints.push(pickupObj)
            level1.loadPoints.push(dropoffObj)
            //remove unnecessary keys and preparing final post Body
            let finalPostBody = {
                loadId: this.props.editLoadValues.loadId,
                loadNumber: level1.loadNumber,
                orderNumber: level1.orederno,
                loadDate: level1.loadDate,
                bolNumber: level1.bolNumber,
                rate: level1.rate,
                weight: level1.weight,
                distance: level1.distance,
                eldlist: level1.eldlist,
                numberOfPickup: level1.noofpickup,
                numberOfDropoff: level1.noofdropoff,
                instruction: level1.instruction,
                shipperInfo: level1.shipperInfo,
                carrierInfo: level1.carrierInfo,
                vehicleInfo: level1.vehicleInfo,
                loadPoints: level1.loadPoints,
                tenderInfo: level1.tenderInfo
            }
            this.props.dispatch(updateLoadAct(finalPostBody));
        }
    }
    
    instructionChange = (e, index) => {
        this.setState({
            ...this.state,
            editableData:{...this.state.editableData,
                instruction:$(e.target).val()
            }
        });
    }
    handleClick = () => {
        $('label.error').hide();
        $('.error').removeClass('error');
        $('input[name="loadDate"]').html();
    }
    render() {
        return (
            <div className='modal fade editLoadModal' data-backdrop='static' data-keyboard='false' tabIndex='-1' role='dialog' aria-labelledby='myLargeModalLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title doc-color' id='exampleModalLabel'>Edit Load</h5>
                            <button style={{ display: 'block' }} type='button' className='close' id='editmodalpopup' data-dismiss='modal' onClick={this.handleClick} aria-label='Close'>
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>
                        <div className='modal-body table-data'>
                            <div className='tab-content' id='addloadshippers'>
                                <div className='tab-pane fade show active' id='addloadshipper' role='tabpanel' aria-labelledby='addloadshipper-tab'>
                                    <form id='myeditform' >
                                        <h5 className='highlight doc-color'>Load Information</h5>
                                        <div className='row'>
                                            <EditLoadInput
                                                name={'loadno'}
                                                id='loadno'
                                                placeHolder={'Load Number'}
                                                type={'text'}
                                                className={'form-control json-col requiredfield'}
                                                defaultValue={this.state.editableData.loadNumber}
                                                disabled={true}
                                            />
                                            <EditLoadInput
                                                name={'orederno'}
                                                id='orederno'
                                                placeHolder={'Order Number'}
                                                type={'text'}
                                                className={'form-control json-col'}
                                                defaultValue={this.state.editableData.orderNumber}
                                            />
                                            <div className='col'>
                                                <DateTimePicker
                                                    className='json-col'
                                                    id='LoadDateVal'
                                                    name='loadDate'
                                                    disabled
                                                    placeholder='Load Date'
                                                    value={this.state.loadDate ? new Date(this.state.loadDate) : null}
                                                    onChange={(e) => this.onChangeDate('loadDate', e)}
                                                    min={new Date()}
                                                    dropDown
                                                />
                                            </div>
                                            <EditLoadInput
                                                name={'bolno'}
                                                id='bolno'
                                                placeHolder={'BOL #'}
                                                type={'text'}
                                                className={'form-control json-col'}
                                                defaultValue={this.state.editableData.bolNumber}
                                            />
                                        </div>
                                        <div className='row'>
                                            <EditLoadInput
                                                name={'rate'}
                                                id='rate'
                                                placeHolder={'Rate($)'}
                                                type={'number'}
                                                className={'form-control json-col'}
                                                defaultValue={this.state.editableData.rate}
                                            />
                                            <EditLoadInput
                                                name={'weight'}
                                                id='weight'
                                                placeHolder={'Weight(lbs)'}
                                                type={'number'}
                                                defaultValue={this.state.editableData.weight}
                                                className={'form-control json-col'}
                                            />
                                            <EditLoadInput
                                                name={'distance'}
                                                id={'distance'}
                                                placeHolder={'Distance'}
                                                type={'text'}
                                                defaultValue={this.state.editableData.distance}
                                                className={'form-control json-col'}
                                            />
                                            <EditLoadInput
                                                name={'noofpickup'}
                                                id={'noofpickup'}
                                                placeHolder={'No Of Pickup'}
                                                type={'number'}
                                                className={'form-control json-col requiredfield'}
                                                defaultValue={this.state.editableData.numberOfPickup}
                                                disabled
                                            />
                                        </div>
                                        <div className='row'>
                                            <EditLoadInput
                                                name={'noofdropoff'}
                                                id={'noofdropoff'}
                                                placeHolder={'No Of Drop Off'}
                                                type={'number'}
                                                className={'form-control json-col requiredfield'}
                                                defaultValue={this.state.editableData.numberOfDropoff}
                                                // value={1}
                                                disabled
                                            />
                                            <EditLoadDropdown
                                                data={this.props.ShipperNameAndLaneDetails}
                                                placeHolder={'Shipper Name'}
                                                name={'shipper'}
                                                value={'cid'}
                                                id={'shipper'}
                                                onChange={this.laneDetails}
                                                defaultValueSelected={(this.state.editableData?.shipperInfo) ? this.state.editableData?.shipperInfo?.id : ''}
                                                className={'form-control json-col'}
                                            />
                                            <EditLoadInput
                                                name={'customername'}
                                                id={'customername'}
                                                placeHolder={'Customer Name'}
                                                type={'text'}
                                                className={'form-control json-col requiredfield'}
                                                defaultValue={this.state.loggedUser.FirstName}
                                                disabled={true}
                                            />
                                            <EditLoadDropdown
                                                data={this.props.companyNameListValue !== undefined ? this.props.companyNameListValue.companyList : this.props.companyNameListValue?.companyList}
                                                placeHolder={'Carrier Name'}
                                                name={'carrierCompanyId'}
                                                id={'carrierCompanyId'}
                                                value={'c_id'}
                                                defaultValueSelected={(this.state.editableData?.carrierInfo) ? this.state.editableData?.carrierInfo?.id : ''}
                                                onChange={(e) => { this.loadEldList(e) }}
                                                className={'form-control json-col'}
                                            />
                                        </div>
                                        <div className='row'>
                                            <EditLoadDropdown
                                                data={this.state.eldlist}
                                                placeHolder={'ELD List'}
                                                name={'eldlist'}
                                                id={'eldlist'}
                                                value={'c_eldlist'}
                                                defaultValueSelected={(this.state.editableData?.vehicleInfo) ? this.state.editableData?.vehicleInfo?.eldVendor : ''}
                                                onChange={(e) => { this.truckNos(e) }}
                                                className={'form-control json-col'}
                                            />
                                            <EditLoadDropdown
                                                data={this.state.trucksNoList}
                                                placeHolder={'Truck No'}
                                                name={'truckno'}
                                                id={'truckno'}
                                                value={'c_truckno'}
                                                defaultValueSelected={(this.state.editableData?.vehicleInfo) ? this.state.editableData?.vehicleInfo?.assetNumber : ''}
                                                className={'form-control json-col'}
                                            />
                                            <EditLoadDropdown
                                                data={this.props.trucktypelist.data}
                                                placeHolder={'Truck Type'}
                                                name={'trucktype'}
                                                id={'trucktype'}
                                                value={'ctrucktype'}
                                                defaultValueSelected={(this.state.editableData?.vehicleInfo) ? this.state.editableData?.vehicleInfo?.truckTypeId : ''}
                                                className={'form-control json-col'}
                                            />
                                            <div className='col'>
                                                <textarea name='instruction' placeholder='instruction' onChange={(e) => { this.instructionChange(e, 1) }} defaultValue={this.state.editableData.instruction} className='form-control json-col' ></textarea>
                                            </div>
                                        </div>
                                        <h5 className='highlight doc-color'>Pickup Information</h5>
                                        <div className='row'>
                                            <EditLoadInput
                                                name={'pickaddress1'}
                                                placeHolder={'Address 1'}
                                                type={'text'}
                                                defaultValue={this.state.editableData.loadPoints?.[0].address1}
                                                className={'form-control json-col requiredfield'}
                                            />
                                            <EditLoadInput
                                                name={'pickaddress2'}
                                                placeHolder={'Address 2'}
                                                defaultValue={this.state.editableData.loadPoints?.[0].address2}
                                                type={'text'}
                                                className={'form-control json-col requiredfield'}
                                            />
                                            <EditLoadDropdown
                                                data={this.props.state.data}
                                                placeHolder={'State'}
                                                name={'originstate'}
                                                id={'originstate'}
                                                type={1}
                                                value={'pickstate'} //here we pass cstate coz cState has same return type as above
                                                onChange={(e) => { this.loadOriginCity(e, 1) }}
                                                defaultValueSelected={this.state.editableData.loadPoints?.[0].state}
                                                className={'form-control json-col requiredfield'}
                                            />
                                            <EditLoadDropdown
                                                data={this.state.OriginCity}
                                                placeHolder={'City'}
                                                name={'origincity'}
                                                id={'origincity'}
                                                value={'cCity'}
                                                type={1}
                                                onChange={(e) => { this.loadOriginZip(e, 1) }}
                                                defaultValueSelected={this.state.editableData.loadPoints?.[0].city}
                                                className={'form-control json-col requiredfield'}
                                            />
                                        </div>
                                        <div className='row'>
                                            <EditLoadDropdown
                                                data={this.state.OriginZip}
                                                placeHolder={'Zip Code'}
                                                name={'originzip'}
                                                id={'originzip'}
                                                value={'cZip'}
                                                type={1}
                                                defaultValueSelected={this.state.editableData.loadPoints?.[0].zipCode}
                                                className={'form-control json-col requiredfield'}
                                            />
                                            <div className='col master'>
                                                <DateTimePicker
                                                    className='json-col requiredfield'
                                                    id='pickearliesttime'
                                                    name='pickearliesttime'
                                                    placeholder='Earliest Time'
                                                    min={new Date()}
                                                    onChange={(e) => this.onChangeDate('pickearliesttime', e)}
                                                    value={new Date(this.state.pickearliesttime)}
                                                    dropDown
                                                />
                                                <input hidden className='error_place' defaultValue='' />
                                            </div>
                                            <div className='col master'>
                                                <DateTimePicker
                                                    className='json-col requiredfield'
                                                    id='picklatesttime'
                                                    name='picklatesttime'
                                                    placeholder='Latest Time'
                                                    onChange={(e) => this.onChangeDate('picklatesttime', e)}
                                                    value={new Date(this.state.picklatesttime)}
                                                    min={new Date()}
                                                    dropDown
                                                />
                                                <input hidden className='error_place' defaultValue='' />
                                            </div>
                                            <EditLoadDropdown
                                                data={this.state.timeZones}
                                                placeHolder={'Time Zone'}
                                                name={'picktimezone'}
                                                id={'picktimezone'}
                                                value={'c_timezone'}
                                                className={'form-control json-col requiredfield'}
                                                defaultValueSelected={this.state.editableData.loadPoints?.[0].timezone}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className='row'>
                                            <EditLoadInput
                                                name={'picksequence'}
                                                placeHolder={'Sequence'}
                                                type={'number'}
                                                className={'form-control json-col requiredfield'}
                                                defaultValue={'0'}
                                                disabled={true}
                                            />
                                            <EditLoadInput
                                                name={'pickMobileNo'}
                                                placeHolder={'Mobile No'}
                                                type={'text'}
                                                defaultValue={this.state.editableData.loadPoints?.[0].contactNumber}
                                                className={'form-control json-col'}
                                            />
                                            <EditLoadInput
                                                name={'picklattitude'}
                                                placeHolder={'Lattitude'}
                                                type={'text'}
                                                defaultValue={this.state.editableData.loadPoints?.[0].latitude}
                                                className={'form-control json-col'}
                                            />
                                            <EditLoadInput
                                                name={'picklongitude'}
                                                placeHolder={'Longitude'}
                                                type={'text'}
                                                defaultValue={this.state.editableData.loadPoints?.[0].longitude}
                                                className={'form-control json-col'}
                                            />
                                        </div>
                                        <h5 className='highlight doc-color'>Drop Off Information</h5>
                                        <div className='row'>
                                            <EditLoadInput
                                                name={'dropaddress1'}
                                                placeHolder={'Address 1'}
                                                type={'text'}
                                                defaultValue={this.state.editableData.loadPoints?.[1].address1}
                                                className={'form-control json-col requiredfield'}
                                            />
                                            <EditLoadInput
                                                name={'dropaddress2'}
                                                placeHolder={'Address 2'}
                                                type={'text'}
                                                defaultValue={this.state.editableData.loadPoints?.[1].address2}
                                                className={'form-control json-col requiredfield'}
                                            />
                                            <EditLoadDropdown
                                                data={this.props.state.data}
                                                placeHolder={'State'}
                                                name={'destinationstate'}
                                                id={'destinationstate'}
                                                value={'dropState'} //here we pass dstate coz dState has same return type as above
                                                type={1}
                                                onChange={(e) => { this.loadDestinationCity(e, 1) }}
                                                defaultValueSelected={this.state.editableData.loadPoints?.[1].state}
                                                className={'form-control json-col requiredfield'}
                                            />
                                            <EditLoadDropdown
                                                data={this.state.DestinationCity}
                                                placeHolder={'City'}
                                                name={'destinationcity'}
                                                id={'destinationcity'}
                                                value={'cCity'}
                                                type={1}
                                                onChange={(e) => { this.loadDestinationZip(e, 1) }}
                                                defaultValueSelected={this.state.editableData.loadPoints?.[1].city}
                                                className={'form-control json-col requiredfield'}
                                            />
                                        </div>
                                        <div className='row'>
                                            <EditLoadDropdown
                                                data={this.state.DestinationZip}
                                                placeHolder={'Zip code'}
                                                name={'destinationzip'}
                                                id={'destinationzip'}
                                                value={'cZip'}
                                                type={1}
                                                defaultValueSelected={this.state.editableData.loadPoints?.[1].zipCode}
                                                className={'form-control json-col requiredfield'}
                                            />
                                            <div className='col master'>
                                                <DateTimePicker
                                                    className='json-col requiredfield'
                                                    id='dropearliesttime'
                                                    name='dropearliesttime'
                                                    placeholder='Earliest Time'
                                                    min={new Date()}
                                                    onChange={(e) => this.onChangeDate('dropearliesttime', e)}
                                                    value={new Date(this.state.dropearliesttime)}
                                                    dropDown
                                                />
                                                <input hidden className='error_place' defaultValue='' />
                                            </div>
                                            <div className='col master'>
                                                <DateTimePicker
                                                    className='json-col requiredfield'
                                                    id='droplatesttime'
                                                    name='droplatesttime'
                                                    onChange={(e) => this.onChangeDate('droplatesttime', e)}
                                                    value={new Date(this.state.droplatesttime)}
                                                    placeholder='Latest Time'
                                                    min={new Date()}
                                                    dropDown
                                                />
                                                <input hidden className='error_place' defaultValue='' />
                                            </div>
                                            <EditLoadDropdown
                                                data={this.state.timeZones}
                                                placeHolder={'Time Zone'}
                                                name={'droptimezone'}
                                                id={'droptimezone'}
                                                value={'c_timezone'}
                                                defaultValueSelected={this.state.editableData.loadPoints?.[1].timezone}
                                                className={'form-control json-col requiredfield'}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className='row'>
                                            <EditLoadInput
                                                name={'dropsequence'}
                                                placeHolder={'Sequence'}
                                                type={'number'}
                                                className={'form-control json-col requiredfield'}
                                                defaultValue={'0'}
                                                disabled={true}
                                            />
                                            <EditLoadInput
                                                name={'dropMobileNo'}
                                                placeHolder={'Mobile No'}
                                                type={'text'}
                                                defaultValue={this.state.editableData.loadPoints?.[1].contactNumber}
                                                className={'form-control json-col'}
                                            />
                                            <EditLoadInput
                                                name={'droplattitude'}
                                                placeHolder={'Lattitude'}
                                                type={'text'}
                                                defaultValue={this.state.editableData.loadPoints?.[1].latitude}
                                                className={'form-control json-col'}
                                            />
                                            <EditLoadInput
                                                name={'droplongitude'}
                                                placeHolder={'Longitude'}
                                                type={'text'}
                                                defaultValue={this.state.editableData.loadPoints?.[1].longitude}
                                                className={'form-control json-col'}
                                            />
                                        </div>
                                        <h5 className='highlight doc-color'>Tender Information</h5>
                                        <div className='row'>
                                            <EditLoadInput
                                                name={'tamount'}
                                                placeHolder={'Total Amount'}
                                                type={'number'}
                                                defaultValue={this.state.editableData.tenderInfo?.amount}
                                                className={'form-control json-col'}
                                            />
                                            <EditLoadInput
                                                name={'loadtenderamount'}
                                                placeHolder={'Load Tender Amount'}
                                                type={'number'}
                                                defaultValue={this.state.editableData.tenderInfo?.totalBrokerNetAmount}
                                                className={'form-control json-col'}
                                            />
                                            <div className='col'>
                                                <DateTimePicker
                                                    className='json-col'
                                                    id='tenderTime'
                                                    name='tenderTime'
                                                    onChange={(e) => this.onChangeDate('tenderTime', e)}
                                                    value={this.state.tenderTime ? new Date(this.state.tenderTime):null}
                                                    placeholder='Tender Date'
                                                    min={new Date()}
                                                    dropDown
                                                />
                                            </div>
                                            <div className='col master'></div>
                                        </div>
                                        <div className='modal-footer'>
                                            <button type='button' className='btn btn-primary' onClick={(e) => { this.submitEditForm(e) }}>Save Load</button>
                                            <button type='button' className='btn btn-primary' data-dismiss='modal'>Cancel</button>
                                        </div>
                                    </form>
                                </div>
                                <div className='tab-pane fade' id='bulkuploadshipper' role='tabpanel' aria-labelledby='bulkuploadshipper-tab'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyNameListValue: state.companyReducer.companyNamelist,
        ShipperNameAndLaneDetails: state.loadReducer.Shipperandlanedetails.companyTypeList,

    }
}

export default connect(mapStateToProps)(UniversalLoadEditcopy);
