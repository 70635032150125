import React from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
class MaterialUITabs extends React.Component {
  constructor(props) {
    super(props)
  }
  setTabIndex = (event,newTabIndex) => {
    this.props.tabsData[newTabIndex].methodName(newTabIndex)
  }
  render() {
    return (
      <Tabs value={this.props.state.tabIndex}
        onChange={this.setTabIndex} 
        TabIndicatorProps={{ style: { background: 'var(--main-color)', color: 'var(--main-color)' } }}
        sx={{
          '& button': { borderRadius: 2, color: '#696969', fontFamily: 'Poppins', fontWeight: '700', letterSpacing: '2px',height: '32px' },
          '& button:hover': { color: 'var(--main-color)' },
          '& button:focus': { color: 'var(--main-color)' },
          '& button:active': { color: 'var(--main-color)' },
          '&.Mui-selected': {
            backgroundColor: 'var(--main-color)',
            color: 'var(--main-color)'
          },
          '& .MuiTab-root.Mui-selected': {
            color: 'var(--main-color)'
          }
        }}
      >
        {this.props.tabsData.map((tab, index) => (
          <Tab key={tab.tabID} label={tab.component?tab.component: tab.eldVendorDisplayName} />
        ))}
      </Tabs>
    )
  }
}

export default MaterialUITabs;