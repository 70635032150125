const components = {
	dashboard: {
		component: 'FactorDashboard',
		url: '/dashboard',
		title: 'Dashboard',
		icon: 'menu',
		module: 1
	},	
	brokerprofileview: {
		component: 'brokerprofileview',
		url: '/profileView',
		title: 'profileview',
		icon: 'menu',
		module: 1
	},
	CarrierOnBoard: {
		component: 'CarrierOnBoard',
		url: '/carriers',
		title: 'Carriers',
		icon: 'menu',
		module: 2
	},
	Users: {
		component: 'Users',
		url: '/users',
		title: 'Users',
		icon: 'menu',
		module: 2
	},
	Branding: {
		component: 'Branding',
		url: '/branding',
		title: 'Branding',
		icon: 'menu',
		module: 2
	},
	CompanyProfile: {
		component: 'CompanyProfile',
		url: '/company_profile',
		title: 'Company Profile',
		icon: 'menu',
		module: 2
	},
	editemailtemplate: {
		component: 'editemailtemplate',
		url: '/editemailtemplate',
		title: 'Edit Template',
		icon: 'menu',
		module: 1
	},
	emailtemplatelist: {
		component: 'EmailTemplateList',
		url: '/email_templates',
		title: 'Email Templates',
		icon: 'menu',
		module: 1
	},
	emailaccountlist: {
		component: 'EmailAccountList',
		url: '/email_accounts',
		title: 'Email Accounts',
		icon: 'menu',
		module: 1
	},
	RoleAccess: {
		component: 'RoleAccess',
		url: '/role_access',
		title: 'Role Access',
		icon: 'menu',
		module: 1
	},
	Trucks: {
		component: 'Trucks',
		url: '/trucks',
		title: 'Trucks',
		icon: 'menu',
		module: 1
	},
	loads: {
		component: 'Loads',
		url: '/loads',
		title: 'Loads',
		icon: 'menu',
		module: 1
	},
	cancelledloads: {
		component: 'cancelloads',
        url: '/cancelledLoads',
        title: 'View Trailer',
        icon: 'menu',
        module: 1
	},  
};

const TechUserRoutes = {
	techpartner: {
		routes: [
			components.dashboard,
			components.CarrierOnBoard,
			components.brokerprofileview,
			components.Users,
			components.CompanyProfile,
			components.editemailtemplate,
			components.emailtemplatelist,
			components.emailaccountlist,
			components.RoleAccess,
			components.Trucks,
			components.loads,
			components.cancelledloads,
			components.Branding
		]
	}
};

export { TechUserRoutes };