import { DeleteCookie, GetCookie } from "../Cookies";
import RegexValidators from "../RegexValidators";
import { getUrlParams } from "../appUtil";

const defaultURL = process.env.REACT_APP_REDIRECT_URL;
const companies = [
    'canal'
];

const CompanyPostOnboarding = (invitingCompanyName, operationOK, statusCode) => {
    let response = {};
    let message = '';
    let origin = GetCookie('origin', true);
    if (origin !== 'widget') {
        message = 'Not from widget'
        console.log(message);
        response = {
            status: false,
            message: message,
            url: defaultURL
        };
        return response;
    }

    if (!companies.includes(invitingCompanyName.toLowerCase())) {
        message = 'Inviting company is not a valid company: ' + invitingCompanyName
        console.log(message)
        response = {
            status: false,
            message: message,
            url: defaultURL
        };
        return response;
    }

    let urlparam = getUrlParams(window.location.href);
    let companyState = urlparam.state ?? GetCookie('companyState', true);
    let successURL = urlparam.success ?? GetCookie('successURL', true);
    // let failureURL = urlparam.failure ?? GetCookie('failureURL', true) ?? "";
    if (companyState === null) {
        message = 'No state present, no redirect'
        console.log(message)
        response = {
            status: false,
            message: message,
            url: defaultURL
        };
        return response;
    }
    else {
        console.log('state exists')
    }
    if (successURL === null) {
        message = 'No success URL present, no redirect'
        console.log(message)
        response = {
            status: false,
            message: message,
            url: defaultURL
        };
        return response;
    }
    else {
        console.log('success URL exists')
    }

    if (operationOK === true) {
        if (successURL && RegexValidators('url', successURL)) {
            DeleteCookie('companyState', true);
            DeleteCookie('origin', true);

            message = 'All good. Will redirect to: ' + successURL + '?state=' + companyState
            console.log(message);
            response = {
                status: true,
                message: message,
                url: successURL + '?code=' + statusCode + '&state=' + companyState
            };
        }
        else {
            message = 'No state present, or success URL is not valid: ' + successURL
            console.log(message)
            response = {
                status: false,
                message: message,
                url: defaultURL
            };
        }
    }
    else {
        if (successURL && RegexValidators('url', successURL)) {
            DeleteCookie('companyState', true);
            DeleteCookie('origin', true);

            message = 'Signup failed. Will redirect to: ' + successURL + '?state=' + companyState
            console.log(message);

            let statusMessage;
            switch (statusCode) {
                case '400':
                    statusMessage = 'The request was invalid or cannot be otherwise served.';
                    break;
                case '401':
                    statusMessage = 'Authentication credentials were missing or incorrect.';
                    break;
                case '403':
                    statusMessage = 'The request has been refused or access is not allowed.';
                    break;
                case '404':
                    statusMessage = 'The URI requested is invalid or the resource requested does not exists.';
                    break;
                case '429':
                    statusMessage = 'Too many requests were done to the endpoint.';
                    break;
                case '500':
                default:
                    statusMessage = 'Internal server error.';
                    break;
            }

            response = {
                status: false,
                message: message,
                url: successURL + '?code=' + statusCode + '&message=' + statusMessage + '&state=' + companyState
            };
        }
        else {
            message = 'No state present, or failure URL is not valid: ' + successURL
            console.log(message)
            response = {
                status: false,
                message: message,
                url: defaultURL
            };
        }
    }
    return response;
}

export default CompanyPostOnboarding;