const regex = /^[^#?%\\\^]+$/
const dotregex = /^[0-9]+$/
const mcregex = /^[0-9]+$/
export const checkSplChar = {

    regex,
    dotregex,
    mcregex
    }


