import React from "react";
const NewELDList = props => {
  return props.eldCredentials.map((val, idx) => {
    let credType = `credType-${idx}`,
        credValue = `credValue-${idx}`;
    return (
      <div className="form-row" key={val.index}>
        <div className="col in-col">
          <input
            type="text"
            className={'form-control json-col'}
            placeholder="Credential Type"
            name="credType"
            data-id={idx}
            id={credType}
          />
          {/* {props.isSubmit && val.credType===''?<span className={'requiredfield-key'}>Required</span>:null} */}
        </div>
        <div className="col in-col">
          <input
            type="text"
            className={'form-control json-col'}
            placeholder="Credential Value"
            name="credValue"
            id={credValue}
            data-id={idx}
          />
          {/* {props.isSubmit && val.credValue===''?<span className={'requiredfield-key'}>Required</span>:null} */}
        </div>
        <div className="col-2 in-col">
          {idx === 0 ? (
            <button
              onClick={() => props.add()}
              type="button"
              className="btn btn-primary text-center"
            >
              <i className="fa fa-plus-circle" aria-hidden="true" />
            </button>
          ) : (
            <button
              className="btn btn-danger"
              onClick={() => props.delete(val)}
            >
              <i className="fa fa-minus" aria-hidden="true" />
            </button>
          )}
        </div>
      </div>
    );
  });
};
export default NewELDList;
