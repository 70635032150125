
import React from 'react';
import { inviteNewCarrierService } from '../services/companyService';
import { toastr } from 'react-redux-toastr';

// var MC_NO, DOT_NO,UU_ID;
class CustomModal extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      disableBtn:false
    }
  }
  reInviteCarrier = () => {
    this.setState({disableBtn:true})
    let MC_NO = '',
    DOT_NO = '',
    UU_ID=''
    let carrierCodes = this.props.data.carrierInfo.carrierCodes;
    for (let index = 0; index < carrierCodes.length; index++) {
      if (carrierCodes[index].codeType === 'MC') {
        MC_NO = carrierCodes[index].codeValue;
      }
      else if (carrierCodes[index].codeType === 'DOT') {
        DOT_NO = carrierCodes[index].codeValue;
      }
      else if (carrierCodes[index].codeType === 'UUID') {
        UU_ID = carrierCodes[index].codeValue;
      }
    }
    let params = {
      'contactInfo': {
        email: this.props.data.contactInfo.email,
        firstName: this.props.data.contactInfo.firstName,
        lastName: this.props.data.contactInfo.lastName
      }
    }
    console.log(MC_NO,DOT_NO,UU_ID)
    let passCodeType;
        if (UU_ID ==null) {
          passCodeType = { codeType: 'DOT', codeValue: DOT_NO}
      }
      else {
        passCodeType = { codeType: 'UUID', codeValue: UU_ID }
      }
    console.log(params, passCodeType)
    inviteNewCarrierService(params,passCodeType).then((response) => {
      if (response.status) {
        if (response.status === 'OK') {
          toastr.success('Success', response.message);
          this.setState({disableBtn:false})
        } else {
          toastr.error('Oops!', response.message);
          this.setState({disableBtn:false})
        }
      }
    });
  }
  render() {
    return (
      <div className='modal' id='customModal'>
        <div className='modal-dialog modal-dialog-centered modal-lz'>
      
          <div className='modal-content'>
          <div className="modal-header">
                            <h5 className="modal-title"> Re-invite Carrier</h5>
                            <button type="button" className="close" data-dismiss='modal'>
                                <span aria-hidden="true">&times;</span>
                            </button>
        </div>
            <div className='modal-body'>
              Do you want to re-invite <span className='contentCss' >{this.props.data ? this.props.data.carrierInfo.carrierName : null} ?</span>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-primary btn-sm' data-dismiss='modal' onClick={this.reInviteCarrier} disabled={this.state.disableBtn}>Yes</button>
              <button type='button' className='btn btn-primary btn-sm' data-dismiss='modal'>No</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomModal;