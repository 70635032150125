import React from 'react';
class EditLoadInput  extends React.Component {
    render() {
        return (
            <div className='col form-group'>
                <input name={this.props.name} type={this.props.type} className={this.props.class} placeholder={this.props.placeHolder} disabled={this.props.disabled} defaultValue={this.props.defaultValue ?? this.props.value ?? ''} onChange={this.props.onChangeValue}/>
            </div>
        )
    }
}
export default EditLoadInput;
