import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import verified from '../../splash_verified.svg';
import verifying from '../../verifying.png';
import { verifyCredentialsAndSignLOA, completeSignUPCarriers } from '../../actions/companyActions';
import { toastr } from 'react-redux-toastr';
import wave from '../../wave.svg'
import notVerified from '../../splash_warning.svg';
import ProgressBar from '../../helpers/progreeBar';
import Delayed from '../../helpers/Delayed';
import Icofont from 'react-icofont';
import * as ReactBootstrap from 'react-bootstrap';
import noPhoto from '../../no-photo-available.png'
// let path = 'https://carrierapi.truckercloud.com/api/v1/elds/logo/'
let path = process.env.REACT_APP_CARRIER_API_URL + 'elds/logo/'
let verifiedNoOfEld = 0;

class AuthorizeCarrierCopy extends Component {
    constructor(props) {
        super(props);
        console.log(this.props.location.state)
        this.state = {
            disableVerifyBtn: true,
            isClickedSignUP: false,
            isClickedVerify: false,
            isShowVerifyImg: false,
            isSignedUp: false,
            tabData: this.prepareTabsData(JSON.parse(this.props.location.state.checkData)),
            count: 0,
            indexcount: JSON.parse(sessionStorage.getItem('indexcount')) || 0,
            index: 0,
            flag: true,
            checkedeldData: true,
            dummyData: this.props.location.state.tcData,
            isCompeleteSignup: (JSON.parse(sessionStorage.getItem('isCompeleteSignup')) === false ? false : true),
            screenCount: JSON.parse(sessionStorage.getItem('screenCount')) || 1,
            verifiedCount: 0,
            progressCompleted: 0,
            fieldDisable: JSON.parse(sessionStorage.getItem('fieldDisable')) || [],
            priviousPassing: this.props.location.state.checkedData,
            showExpirymessage: '',
            requiredDataError: '',
            enableError: false,
            disableInput: [],
            tabIndex: 0,
            invitationkey: this.props.location.state.tcData.invitationkey,
            verifiedNoOfEldProgress: 0,
            showModal: false,
            isOkayButton:false
        };
        this.handleInput.bind(this);
        this.handleExitingEld.bind(this);
    }
    prepareTabsData(dataToBePrepared) {
        var preparedTabData = []
        // this.setState({ tabIndex: dataToBePrepared.dataShareAndLOAArr[0].eldIndex })
        dataToBePrepared.tabData.forEach((element, index) => {
            if (element.overallStatus === 'VERIFIED' || element.eldVendor === 'TruckX') {
                preparedTabData.push({ ...element, verifyingImage: '', eldIndex: index, overallStatus: 'VERIFIED', imageStatus: '', disableELDFormFields: false, ShowInputField: false, isUtilizeCredentials: false, isTermsNConditions: false, isInvalidCredentials: false, methodName: (index) => { this.setIndex(index) } })
            }
            else {
                preparedTabData.push({ ...element, verifyingImage: '', eldIndex: index, overallStatus: 'NOT_VERIFIED', imageStatus: '', attemptsLeft: 3, disableELDFormFields: false, ShowInputField: true, isUtilizeCredentials: true, isTermsNConditions: false, isInvalidCredentials: false, methodName: (index) => { this.setIndex(index) } })
            }
        });
        console.log('preparedTabData', preparedTabData)
        return preparedTabData;
    }
    componentWillUnmount() {
        sessionStorage.setItem('fieldDisable', JSON.stringify(this.state.fieldDisable));
        sessionStorage.setItem('progressCompleted', this.state.progressCompleted);
        sessionStorage.setItem('screenCount', this.state.screenCount);
        sessionStorage.setItem('indexcount', this.state.indexcount);
        sessionStorage.setItem('isCompeleteSignup', this.state.isCompeleteSignup);
        verifiedNoOfEld = 0;
    }
    componentWillReceiveProps(prevProps) {
        if (prevProps.verifiedLOAData !== this.props.verifiedLOAData) {
            if (prevProps.verifiedLOAData && prevProps.verifiedLOAData.status === 'OK') {
                if (verifiedNoOfEld < this.state.tabData.length) {
                    verifiedNoOfEld++;
                }
                this.setState({ verifiedNoOfEldProgress: Math.ceil(verifiedNoOfEld / this.state.tabData.length * 100) })
                for (let index = 0; index < this.state.tabData.length; index++) {
                    this.setState(state => {
                        const list = state.tabData.map((item) => {
                            if (item.eldProviderId === prevProps.verifiedLOAData.eldVerifications[0].id) {
                                item.overallStatus = prevProps.verifiedLOAData.eldVerifications[0].overallStatus;
                                item.disableELDFormFields = true;
                                item.imageStatus = 'IMAGE_VERIFIED';
                                item.showModal = true;
                            }
                            else {
                                return item;
                            }
                        });
                        return {
                            list,
                        };
                    });
                }
                this.sleep(20000);
                if (this.state.verifiedNoOfEldProgress >= 100) {
                    this.setState({ showVerificationStatusText: 'Credentials Verified', verifyingImage: verified, showNext: false, isSignedUp: false })
                }
                else {
                    this.setState({ showVerificationStatusText: 'Credentials Verified', verifyingImage: verified, showNext: true })
                }
            }
            else if (prevProps.verifiedLOAData && prevProps.verifiedLOAData.status === 'TOO_MANY_REQUESTS') {
                this.setState({
                    verifyingImage: notVerified,
                    isOkayButton: true,
                    showVerificationStatusText: 'You have reached the maximum number of failed attempts of “ELD Verification”. Please click the invitation email to restart the verification.',
                });
                for (let index = 0; index < this.state.tabData.length; index++) {
                    this.setState(state => {
                        const list = state.tabData.map((item) => {
                            if (item.eldProviderId === prevProps.verifiedLOAData.eldVerifications[0].id) {
                                item.overallStatus = prevProps.verifiedLOAData.eldVerifications[0].overallStatus;
                                item.attemptsLeft = prevProps.verifiedLOAData.invitationInfo.pendingAttempts;
                                item.imageStatus = 'IMAGE_NOT_VERIFIED';
                                item.disableELDFormFields = true;
                            } else {
                                return item;
                            }
                        });
                        return {
                            list,
                        };
                    });
                }
            }
            else if (prevProps.verifiedLOAData && prevProps.verifiedLOAData.status === 'BAD_REQUEST') {
                for (let index = 0; index < this.state.tabData.length; index++) {
                    this.setState(state => {
                        const list = state.tabData.map((item) => {
                            if (item.eldProviderId === prevProps.verifiedLOAData.eldVerifications[0].id) {
                                item.overallStatus = prevProps.verifiedLOAData.eldVerifications[0].overallStatus;
                                item.imageStatus = 'IMAGE_NOT_VERIFIED'
                            }
                            if (item.eldVendor === prevProps.verifiedLOAData.invitationInfo.latestAttempt) {
                                item.attemptsLeft = prevProps.verifiedLOAData.invitationInfo.pendingAttempts;
                                item.isInvalidCredentials = true;
                                item.imageStatus = 'IMAGE_NOT_VERIFIED'
                            }
                            else {
                                return item;
                            }
                        });
                        return {
                            list,
                        };
                    });
                }
                this.setState({ showVerificationStatusText: 'Credentials Verification Failed', verifyingImage: notVerified, showNext: false })
            }
            else {
                this.setState({ showVerificationStatusText: 'Credentials Verification Failed', verifyingImage: notVerified, showNext: false })
            }
            console.log(this.state.tabData)
        }
        if (prevProps && prevProps.signedUPLOAData !== this.props.signedUPLOAData) {
            // if (prevProps.signedUPLOAData.status === 'SignedUP') {
            if (prevProps.signedUPLOAData.status === 'OK') {
                this.setState({ isSignedUp: true })
                toastr.success('Success!', prevProps.signedUPLOAData.message);
                this.sleep(10000);
                window.location.href = process.env.REACT_APP_REDIRECT_URL;
            }
            else {
                toastr.error('Oops!', prevProps.signedUPLOAData.message);
            }
        }
    }
    close = (e) => {
        this.setState({
            showModal: false,
            showReirectionModal: false
        });
    }
    switchToNextELD() {
        if (this.state.currentELDData.eldIndex < this.state.tabData.length - 1) {
            this.setState({
                showModal: false
            });
            this.setIndex(this.state.currentELDData.eldIndex + 1)
        }
        else {
            this.setState({
                showModal: false
            });
            this.setIndex(this.state.currentELDData.eldIndex)
        }
    }
    switchToPreviousELD() {
        if (this.state.currentELDData.eldIndex < this.state.tabData.length - 1) {
            this.setState({
                showModal: false
            });
            this.setIndex(this.state.currentELDData.eldIndex - 1)
        }
        else {
            this.setState({
                showModal: false
            });
            this.setIndex(this.state.currentELDData.eldIndex)
        }
    }

    handleInput(e, item, i) {
        item.isInvalidCredentials = false;
        item.eldMetadataParams.forEach((element, index) => {
            if (element.paramName === e.target.id) {
                element.paramValue = e.target.value.trim()
            }
        });
        if (this.state.isTCChecked) {
            this.setState({ isClickedSignUP: false, isClickedVerify: true, disableVerifyBtn: true })
        } else {
            this.setState({ isClickedSignUP: false, isClickedVerify: true, disableVerifyBtn: false })
        }
    }
    handleExitingEld(index) {
        this.setState(state => {
            const list = state.tabData.map((item, j) => {
                if (item.eldVendorId === index) {
                    item.isUtilizeCredentials = !item.isUtilizeCredentials;
                } else {
                    return item;
                }
            });
            return {
                list,
            };
        });
    }
    preventCheck(e) {
        if (e.target.checked === true) {
            e.target.checked = false
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            return false;
        }
        e.target.checked = true
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        return false;
    }
    clickAction() {
        window.location.href = process.env.REACT_APP_REDIRECT_URL;
    }
    finalSUbmit() {
        this.props.dispatch(completeSignUPCarriers(this.state.dummyData.invitationData, this.state.invitationkey));
    }
    verifyCredentials(i, itemData) {
        console.log('itemData', itemData)
        let eldData = { carrierInfo: { carrierId: this.state.dummyData.invitationData.carrierInfo.carrierId }, carrierCodes: this.state.dummyData.invitationData.carrierInfo.carrierCodes, eldInfo: [itemData], invitationkey: this.state.invitationkey }
        if (itemData.overallStatus === 'VERIFIED' || itemData.eldVendor === 'TruckX') {
            this.props.dispatch(verifyCredentialsAndSignLOA(eldData));
            // item.imageStatus = 'IMAGE_NOT_VERIFIED'                 
            this.setState({showModal: true, showVerificationStatusText: 'Verifying Credentials...', verifyingImage: verifying, selectedELDName: itemData.eldVendorDisplayName, selectedELDImage: itemData.imagePath, currentELDData: itemData })
        }
        else {
            let insideItemArr = itemData.eldMetadataParams;
            let noofValidation = 0;
            outerloop: for (let insideElementIndex = 0; insideElementIndex < insideItemArr.length; insideElementIndex++) {
                if (insideItemArr[insideElementIndex].paramIsRequired && (insideItemArr[insideElementIndex].paramValue == null || insideItemArr[insideElementIndex].paramValue === undefined || insideItemArr[insideElementIndex]?.paramValue.trim() === '')) {
                    this.setState(state => {
                        const list = state.tabData.map((item, j) => {
                            if (item.eldVendorId === itemData.eldVendorId) {
                                for (let index = 0; index < itemData.eldMetadataParams.length; index++) {
                                    const element = itemData.eldMetadataParams[index];
                                    element.isShowValidation = true;
                                }
                            }
                        });
                        return {
                            list,
                        };
                    });
                    break outerloop;
                }
                else {
                    noofValidation++;
                    if (insideItemArr.length === noofValidation) {
                        if (itemData.eldVendor === 'Samsara') {
                            itemData.eldMetadataParams.forEach((element, index) => {
                                if (element.paramName === 'apikey') {
                                    element.paramValue = 'samsara_api_' + element.paramValue
                                }
                            });
                        }
                        this.setState({ showModal: true, showVerificationStatusText: 'Verifying Credentials...', verifyingImage: verifying, selectedELDName: itemData.eldVendorDisplayName, selectedELDImage: itemData.imagePath, currentELDData: itemData })
                        this.props.dispatch(verifyCredentialsAndSignLOA(eldData));
                    }
                }
            }
        }
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    setIndex(tabIndex) {
        this.setState({ tabIndex: tabIndex })
    }
    carrierCodes() {
        let carrierCode = [];
        carrierCode.push(
            this.state.dummyData.invitationData.carrierInfo.carrierCodes.map((val, index) => {
                return (
                    <h5 className='list-cont-data'><span id='iconCOB'>#</span>{val.codeType}-{val.codeValue}</h5>
                )
            })
        )
        return carrierCode
    }
    addDefaultSrc(ev) {
        ev.target.src = noPhoto
    }
    render() {
        var Modal = ReactBootstrap.Modal;
        if (this.state.showExpirymessage.length === 0) {
            return (<div>
                <article className='parent-container bg-white rounded'>
                    <div className='row parent-row'>
                        <div className='col-sm-3 col-md-3 col-lg-3 col-xs-3 onboard-left-side'>
                            <div className='left-row-img'>
                                <div className='in-cob-img-css'><img src={this.props.location.state.tcData.logo} className='logo-img-css' onError={this.addDefaultSrc} alt='Not Found' /> </div>
                                <div className='left-row-after-img'>
                                    <div className='list-cont-data'>
                                        <h5 className='list-cont-data'><Icofont icon='icofont-user-alt-3' id='iconCOB' className='cob-icon-css' /> &nbsp;
                                            {this.state.dummyData.invitationData?.contactInfo?.firstName}&nbsp;{this.state.dummyData.invitationData?.contactInfo?.lastName}
                                        </h5>
                                    </div>
                                    <div className='list-cont-data'>
                                        <h5 className='list-cont-data'><Icofont icon='icofont-truck-alt' id='iconCOB' className='cob-icon-css' /> &nbsp;
                                            {this.state.dummyData.invitationData.carrierInfo?.carrierName}
                                        </h5>
                                    </div>
                                    <div className='list-cont-data'>
                                        <h5 className='list-cont-data'><Icofont icon='icofont-envelope' id='iconCOB' className='cob-icon-css' /> &nbsp;
                                            {this.state.dummyData.invitationData?.contactInfo?.email}
                                        </h5>
                                    </div>
                                    <div className='list-cont-data'>
                                        {this.carrierCodes()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-9 col-md-9 col-lg-9 col-xs-9 onboard-right-side'>
                            <article className='table-data truck-data  bg-white rounded'>
                                <div className='tab-content' id='eldTabsContent'>
                                    {this.state.tabData.map((tabcontent, tabIndex) => (
                                        <>
                                            <h5 className='eldVendorNameCss'>{tabcontent.eldVendorDisplayName}</h5>
                                            <img src={path + tabcontent.eldVendor} alt='To be loaded' onError={this.addDefaultSrc} className='eldLogo' />
                                            <div className="eld-container">
                                                {/* API credentials and T&C starts */}
                                                <div className='eld-img-left col-md-7'>
                                                    {tabcontent.eldVendor !== 'TruckX' ?
                                                        <>
                                                            <h5 className='subTitleCss'>API Credentials</h5>
                                                            <div className='subTitleCss'>
                                                                <a href='https://truckercloud.zendesk.com/hc/en-us/articles/4415549969435' target='_blank' rel='noopener noreferrer' className='cob-link'>Where do I find my credentials?</a>
                                                            </div>
                                                            <div>
                                                                {tabcontent.overallStatus === 'VERIFIED' && (!tabcontent.disableELDFormFields || !tabcontent.ShowInputField) ?
                                                                    // Verified ELD's Field Data
                                                                    <div className='form-check-header-data'>
                                                                        <input className='form-check-input'
                                                                            type='checkbox'
                                                                            id='exiteldChcekbox'
                                                                            readOnly={tabcontent.disableELDFormFields}
                                                                            defaultChecked={tabcontent.isUtilizeCredentials}
                                                                            onChange={(e) => { !tabcontent.disableELDFormFields ? this.handleExitingEld(tabcontent.eldVendorId) : this.preventCheck(e) }} />
                                                                        <label className='div-content' >&nbsp;
                                                                            Utilize my existing ELD provider credentials
                                                                        </label>
                                                                    </div>
                                                                    : tabcontent.overallStatus === 'VERIFIED' || tabcontent.overallStatus === 'ERROR' && (!tabcontent.disableELDFormFields || tabcontent.ShowInputField) ?
                                                                        <>
                                                                            {
                                                                                tabcontent.eldMetadataParams.map((innsideItem, j) => {
                                                                                    return <div className='col newCol' key={j}>
                                                                                        {tabcontent.eldVendor === 'Samsara' ?
                                                                                            //Samsara ELD
                                                                                            <>
                                                                                                <span className='samsaraPrefix'>samsara_api_</span>
                                                                                                <input type='text' className='form-control samasarlabelCssForVerifying  requiredfield json-col'
                                                                                                    placeholder={innsideItem.paramIsRequired ?
                                                                                                        innsideItem.paramName + ' *' : innsideItem.paramName}
                                                                                                    id={innsideItem.paramName}
                                                                                                    // readOnly={!innsideItem.paramIsRequired}
                                                                                                    onChange={(e) => { this.handleInput(e, tabcontent, this.props.j) }}
                                                                                                    readOnly={tabcontent.disableELDFormFields}
                                                                                                    disabled={tabcontent.disableELDFormFields}
                                                                                                    value={innsideItem?.paramDefaultValue}
                                                                                                />
                                                                                            </>
                                                                                            //Other ELDs
                                                                                            : <input type={innsideItem?.paramName.includes('password') ? 'password' : 'text'} className='form-control labelCssForVerifying  requiredfield json-col'
                                                                                                placeholder={innsideItem.paramIsRequired ?
                                                                                                    innsideItem.paramName + ' *' : innsideItem.paramName}
                                                                                                id={innsideItem.paramName}
                                                                                                // readOnly={!innsideItem.paramIsRequired}
                                                                                                onChange={(e) => { this.handleInput(e, tabcontent, this.props.j) }}
                                                                                                value={innsideItem?.paramDefaultValue}
                                                                                                readOnly={tabcontent.disableELDFormFields}
                                                                                                disabled={tabcontent.disableELDFormFields}
                                                                                            />}
                                                                                        {(innsideItem.isShowValidation && innsideItem.paramIsRequired && (innsideItem.paramValue === undefined || innsideItem.paramValue === null || innsideItem.paramValue === 'undefined' || innsideItem.paramValue === '')) && <span className='shadow-input'>Please Fill Required data</span>}<br />
                                                                                    </div>
                                                                                }
                                                                                )}
                                                                        </>
                                                                        //Not Yet Verified ELD's Field Data
                                                                        : tabcontent.eldMetadataParams.map((innsideItem, j) => {
                                                                            return <div className='col newCol' key={j}>
                                                                                {tabcontent.eldVendor === 'Samsara' ?
                                                                                    //Samsara ELD
                                                                                    <>
                                                                                        <span className='samsaraPrefix'>samsara_api_</span>
                                                                                        <input type='text' className='form-control samasarlabelCssForVerifying  requiredfield json-col'
                                                                                            placeholder={innsideItem.paramIsRequired ?
                                                                                                innsideItem.paramName + ' *' : innsideItem.paramName}
                                                                                            id={innsideItem.paramName}
                                                                                            readOnly={!innsideItem.paramIsRequired}
                                                                                            onChange={(e) => { this.handleInput(e, tabcontent, this.props.j) }}
                                                                                            value={innsideItem?.paramDefaultValue}
                                                                                        />
                                                                                    </>
                                                                                    //Other ELDs
                                                                                    : <input type={innsideItem?.paramName.includes('password') ? 'password' : 'text'} className='form-control labelCssForVerifying  requiredfield json-col'
                                                                                        placeholder={innsideItem.paramIsRequired ?
                                                                                            innsideItem.paramName + ' *' : innsideItem.paramName}
                                                                                        id={innsideItem.paramName}
                                                                                        readOnly={!innsideItem.paramIsRequired}
                                                                                        onChange={(e) => { this.handleInput(e, tabcontent, this.props.j) }}
                                                                                        value={innsideItem?.paramDefaultValue}
                                                                                    />}
                                                                                {(innsideItem.isShowValidation && innsideItem.paramIsRequired && (innsideItem.paramValue === undefined || innsideItem.paramValue === null || innsideItem.paramValue === 'undefined' || innsideItem.paramValue === '')) && <span className='shadow-input'>Please Fill Required data</span>}<br />
                                                                            </div>
                                                                        }
                                                                        )}
                                                            </div>
                                                        </>
                                                        :
                                                        //Truckx ELD Field Data
                                                        <div className='form-check-header-data TruckX-Div'>
                                                            <input className='form-check-input'
                                                                type='checkbox'
                                                                id='exiteldChcekbox'
                                                                readOnly={tabcontent.disableELDFormFields}
                                                                defaultChecked={tabcontent.isUtilizeCredentials}
                                                                onChange={(e) => { !tabcontent.disableELDFormFields ? this.handleExitingEld(tabcontent.eldVendorId) : this.preventCheck(e) }} />
                                                            <label className='div-content' >&nbsp;
                                                                Utilize my existing ELD provider credentials
                                                            </label>
                                                        </div>
                                                    }
                                                    <button type='button' className='btn btn-primary in-cob-button'
                                                    disabled={!tabcontent.isUtilizeCredentials || tabcontent.disableELDFormFields || tabcontent.isInvalidCredentials}
                                                    onClick={(e) => { this.verifyCredentials(tabIndex, tabcontent) }}>Verify Credentials</button><br/>
                                                {!tabcontent.disableELDFormFields && tabcontent.attemptsLeft ?
                                                    <div className='attempt-css'>Only 3 attempts are allowed&nbsp;<span className='error'>- left : {tabcontent.attemptsLeft}</span></div>
                                                    :tabcontent.attemptsLeft === 0 ?
                                                        <div className='failed-attempt-css'>You have reached the maximum number of failed attempts of ELD Verification. Please click the invitation email to restart the verification.
                                                            <span>
                                                                <button type='button' className='btn btn-primary btn-sm in-cob-modal failed-attempt-btn' data-dismiss='modal' onClick={() => { this.clickAction() }}> OK </button>
                                                            </span>
                                                        </div>
                                                        : null
                                                }
                                                </div>
                                                <div className='eld-img-right col-md-5'>
                                                    {
                                                        tabcontent.imageStatus === 'IMAGE_VERIFIED' ?
                                                            <div className='img text-center'>
                                                                <img src={verified} className='verify-img-css2' alt='Not Found' /><br />
                                                                <h6 className='opt-sub-heading-css2'><span className='contentCss1' >Credentials Verified</span></h6>
                                                            </div>
                                                            : tabcontent.imageStatus === 'IMAGE_NOT_VERIFIED' ?
                                                                <div className='img text-center'>
                                                                    <img src={notVerified} className='verify-img-css2' alt='Not Found' /><br />
                                                                    <h6 className='opt-sub-heading-css3'><span className='contentCss2' >Credentials Verification Failed</span></h6>
                                                                </div>
                                                                : null
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                    <div>
                                    </div>
                                </div>

                                <div className='stepper-wrapper'>
                                    <div className='stepper-item completed'>
                                        <div className='step-counter'><i className='icofont-tick-mark'></i></div>
                                        <div className='step-name'>Select Providers</div>
                                    </div>
                                    <div className='stepper-item completed'>
                                        <div className='step-counter'><i className='icofont-tick-mark'></i></div>
                                        <div className='step-name'>Data Permissions</div>
                                    </div>
                                    <ProgressBar bgcolor={'var(--main-color)'} completed={this.state.verifiedNoOfEldProgress} />
                                    <div className={this.state.verifiedNoOfEldProgress === 0 ? 'stepper-item active' : 'stepper-item completed'}>
                                        <div className='step-counter'><i className='icofont-tick-mark'></i></div>
                                        <div className='step-name'>Verify Providers</div>
                                    </div>
                                </div>
                                <div className='outer' >
                                    <div className='row' >
                                        <div className='col-md-1 wave-img' >
                                            <img src={wave} alt='Not Found' />
                                        </div>
                                        <div className='col welcome-header' >
                                            <span className='welcomeCss'>
                                                Welcome back to TruckerCloud!
                                            </span> <br />
                                            <span>
                                                Your are invited to connect with <strong className='contentCss'> {this.state.dummyData.invitationData.invitationInfo.invitingUserFirstName}  {this.state.dummyData.invitationData.invitationInfo.invitingUserLastName} </strong>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <div className='in-cob-footer'>
                                <div className='footer-buttons'>
                                    <button type='button' className={this.state.verifiedNoOfEldProgress === 100 && !this.state.isSignedUp ? 'btn btn-primary3 footer-button' : 'btn btn-primary3 footer-button-signup'}>  <span className='checkmark'>L</span> &nbsp; {this.state.tabData.length} ELD Data Shared</button>
                                    <button type='button' className={this.state.verifiedNoOfEldProgress === 100 && !this.state.isSignedUp ? 'btn btn-primary2 footer-button' : 'btn btn-primary2 footer-button-signup'} >  <span className='checkmarkDouble'>L</span>  <span className='checkmarkDoubleBelow' style={{ float: 'left', fontSize: '9px', marginRight: '-5px', marginTop: '-1px' }}>L</span> &nbsp;{verifiedNoOfEld} ELD  authorized</button>
                                    {this.state.verifiedNoOfEldProgress === 100 && !this.state.isSignedUp
                                        ? <button type='button' disabled={this.state.isSignedUp} className='btn btn-primary footer-button eld-veify-btn' onClick={() => { this.finalSUbmit() }}>Complete Sign Up</button>
                                        : null
                                    }
                                </div>
                            </div>
                            
                            <Modal show={this.state.showModal} onHide={this.close} className='' centered dialogClassName='modal-30w' id='breadcrumbpopup'>
                                <Modal.Header closeButton>
                                    <Modal.Title className='w-100' >{this.state.selectedELDName}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='modal-body'>
                                        <div className='img text-center'>
                                            <img src={this.state.verifyingImage} className='verify-img-css' alt='Not Found' /><br />
                                            <h6 className='opt-sub-heading-css'><span className='contentCss' >{this.state.showVerificationStatusText}</span></h6>
                                        </div>
                                    </div>
                                    <Modal.Footer>
                                        {
                                            this.state.showVerificationStatusText === 'Credentials Verification Failed' ?
                                                <button type='button' className='btn btn-primary btn-sm in-cob-modal' data-dismiss='modal' onClick={this.close}>Retry</button>
                                                : null
                                        }
                                        {
                                            this.state.showNext && this.state.verifiedNoOfEldProgress < 100 ?
                                                <button type='button' className='btn btn-primary btn-sm in-cob-modal' data-dismiss='modal' onClick={() => { this.switchToNextELD() }}>Next ELD </button>
                                                : this.state.isOkayButton ?
                                                    <button type='button' className='btn btn-primary btn-sm in-cob-modal' data-dismiss='modal' onClick={() => { this.clickAction() }}>Ok </button>
                                                    : this.state.verifiedNoOfEldProgress === 100
                                                        ? <button type='button' disabled={this.state.isSignedUp} className='btn btn-primary btn-sm in-cob-modal' data-dismiss='modal' onClick={() => { this.finalSUbmit() }}>Complete Sign Up</button>
                                                        : null
                                        }
                                    </Modal.Footer>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </article>

            </div>

            )
        } else if (this.state.showExpirymessage.length > 1) {
            return (
                <Delayed data={this.state.showExpirymessage} />
            )
        }
    }
}
const mapStateToProps = state => {
    return {
        verifiedLOAData: state.companyReducer.verifiedLOAData,
        signedUPLOAData: state.companyReducer.signedUPLOAData,
        loading: state.companyReducer.loading,
        searchFlag: state.companyReducer.searchFlag,
        error: state.companyReducer.error,
    }
}

export default connect(mapStateToProps)(reduxForm({
    form: 'AuthorizeCarrierForm'
})(AuthorizeCarrierCopy))
