const RegexValidators = (type, value) => {
    if (type === 'url') {
        const urlPattern = "^https?:\\/\\/(?:www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=\\s]*)$"
        const checkIfValidURL = new RegExp(urlPattern, 'i')

        return !!checkIfValidURL.test(value)
    }
    
    return false;
}

export default RegexValidators;