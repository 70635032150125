import { fork } from 'redux-saga/effects';
import watchUserAuthentication from './watchers';
import { dashboardSaga, trackingListSaga, eldAssetsListSaga  } from './dashboardSaga';
import { ChangeLoadStatus, loadSaga } from './loadSaga';
import { carrierSaga } from './carrierSaga';
import { commonlist } from './commonSaga';
import { userlist } from './userSaga';
//import { emailtemplatelist } from './emailTemplateSaga';
import {companySaga} from './companySaga';
import {assetSaga} from './assetSaga';
import {forgotpwdsaga} from './authSaga';
export default function* startForman() {
    yield fork(watchUserAuthentication);
    yield fork(userlist);
    yield fork(dashboardSaga);
    yield fork(trackingListSaga); 
    yield fork(eldAssetsListSaga);
   // yield fork(emailtemplatelist);
    yield fork(commonlist);
    yield fork(carrierSaga);
    yield fork(ChangeLoadStatus);  
    yield fork(loadSaga);
    yield fork(companySaga);
    yield fork(assetSaga);
    yield fork(forgotpwdsaga);
}
