import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUserAction } from '../../actions/authActions';
import Changepassword from './changepassword';
import {Link} from 'react-router-dom';

class AppUser extends Component{
	logoutHandler=(e)=>{
		e.preventDefault();
		this.props.dispatch(logoutUserAction());  
	}
	render(){
		const { userName, userType } =this.props.loggedUser;
		let redirecturl = localStorage.getItem('redirecturl');	
		var userTypeVal;
		if ( this.props.userAuthenticated === false && localStorage.getItem('userName')==null) {
			localStorage.clear();
			if(redirecturl)
			{
				window.location=redirecturl;
			}else{
				return(
					<Redirect to={{ pathname: '/'}} />
				)
			}
		}
		if(userType==='shipper')
		{
			 userTypeVal = "Customer"
		}else{
			 userTypeVal = userType
		}
		return(
			<div className="my-2 my-lg-0">
		    	<div className="dropdown">
				  	<div className="dropdown-toggle loggedin-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				    	<span>{userName}</span>
				    	<span>[{ userTypeVal }]</span>
				  	</div>
				  	<div className="dropdown-menu dropdown-menu-right animate slideIn" aria-labelledby="dropdownMenuButton">
						<Link to="#" className="userProfile"> User Profile</Link>
						{/* <a className="dropdown-item" data-toggle="modal" data-target=".changepassword">Change Password</a> */}
				    	<a className="dropdown-item" href="/#" onClick={(e) => this.logoutHandler(e) }>Logout</a>
				  	</div>
				</div>
				<Changepassword />
		    </div>
		)
	}
}

const mapStateToProps = state => ({
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    userAuthenticated: state.authReducer.userAuthenticated
});

export default connect(mapStateToProps)(AppUser)