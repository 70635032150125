import React from "react";
import "./spinner.css";

const SimpleLoadingSpinner = () => {
  return (
    <div className="spinner-container">
        <div className="loading-spinner mx-auto">
        </div>
    </div>
  );
}

export default SimpleLoadingSpinner;