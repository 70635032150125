import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { carrierInvitation } from '../../actions/companyActions';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import noPhoto from '../../no-photo-available.png'
import { history } from '../../helpers/history';
import { withRouter } from 'react-router';
import { appBaseUrl, getBaseUrl, getUrlParams } from '../../helpers/appUtil';
import $ from 'jquery';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import FAQModal from '../../helpers/FAQModal';
import NewEld from '../../helpers/NewEld';
import { getCompanyProfilePublicService, getDynamicDocumentURL } from '../../services/companyService';
import MiniDrawerELD from '../../muiComponents/Sidebar/ELDSidebar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline'
import { Container, TypoGraph } from '../../muiComponents'
import ELDVerificationModal from '../../helpers/ELDVerificationModalMaterialUI';
import Icon from '../../muiComponents/icon'
import ReactTooltip from 'react-tooltip';
import MenuIcon from '@mui/icons-material/Menu';
import EldCardList from '../onboarding/EldCardList';
import { Button } from '@mui/material';
import { GetCookie, SetCookie } from '../../helpers/Cookies';
// import logo from '../../truckergig-logo.png';
require('dotenv').config();
var localFilteredData;

class carrierOnboardMaterialUI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedData: [],
            invitationData: {},
            error: '',
            isDataUpdated: false,
            mainModalOpen: false,
            newEldOpen: false,
            drawerOpen: false,
            preselectedModalScreen: 0,
            returnFromOAuth: false
            // logo: logo
        }
        this.checkedArray = []
        this.carrierInviteReponse = {};
        this.preChecked = [];
        this.temp = []
        this.setELDName = this.setELDName.bind(this)
        this.selectedFilterData = this.selectedFilterData.bind(this)
        this.redirectVerifyELD = this.redirectVerifyELD.bind(this)
        this.diableCheck = this.diableCheck.bind(this)
        this.handleMainModalClose = this.handleMainModalClose.bind(this)
        this.handleELDModalClose = this.handleELDModalClose.bind(this)
    }
    componentDidMount = () => {
        this.getCompanyProfile()
        let theme = process.env.REACT_APP_BASE_URL + 'publicresource/getbycode/css?originUrl=' + getBaseUrl();
        $('<link/>', { rel: 'stylesheet', href: theme }).appendTo('head');
        if (this.props.location.search) {
            let urlparam = getUrlParams(this.props.location.search);
            if (urlparam.invitationkey) {
                document.title = appBaseUrl() + 'System';
                this.setState({ invitationkey: urlparam.invitationkey })
            }
            this.carrierInvitation(urlparam.invitationkey);
        }
        else {
            this.props.history.push('/login');
        }
        if (Object.keys(this.props.carrierInvitationRes).length > 0) {
            this.setState({
                invitationData: this.props.carrierInvitationRes
            });
            this.carrierInviteReponse = this.props.carrierInvitationRes;
        }
    }
    getCompanyProfile = () => {
        // this.setState({
        //     // logo:response.companyProfile?.logo
        //     logo: 'https://truckercloudv2.s3.us-east-2.amazonaws.com/staging/coddocuments/614.PNG?1672922106768'
        // });
        getCompanyProfilePublicService().then((response) => {
            if (response && response.companyProfile) {
                this.setState({
                    logo: response.companyProfile?.logo
                });
            }
        });
    }
    componentWillReceiveProps(prevProps, nextProps) {
        if (prevProps !== nextProps) {
            if (prevProps.carrierInvitationRes.hasOwnProperty('message')) {
                this.setState({ error: prevProps.carrierInvitationRes.message })
                return
            }
            this.carrierInviteReponse = prevProps.carrierInvitationRes;
            localFilteredData = prevProps.carrierInvitationRes;
            this.setState({
                invitationData: prevProps.carrierInvitationRes,
                invitationToken: this.carrierInviteReponse.invitationInfo.invitationToken,
                isDataUpdated: true
            });
            this.preChecked = this.carrierInviteReponse.eldInfo.filter(val => val.overallStatus === 'VERIFIED')
            if (sessionStorage.getItem('hasLocalData')) {
                this.checkedArray = JSON.parse(sessionStorage.getItem('localData'))
                this.checkedArray.forEach(element => {
                    this.temp.push(element)
                });
                this.preChecked.forEach(element => {
                    this.temp.push(element)
                });
                this.temp = this.removeDuplicate(this.temp)
                this.setState({ checkedData: this.temp })
            }
            else {
                this.checkedArray = this.carrierInviteReponse.eldInfo.filter(val => val.overallStatus === 'VERIFIED')
            }
            this.setState({
                checkedData: this.checkedArray,
                prevcheckedData: this.checkedArray
            })
            if (this.props?.history?.location?.state?.dummyData?.checkedData.length > 0) {
                this.checkedArray = this.props?.history?.location?.state?.dummyData?.checkedData
                this.setState({
                    checkedData: this.props?.history?.location?.state?.dummyData?.checkedData,
                    prevcheckedData: this.props?.history?.location?.state?.dummyData?.checkedData
                })
            }
            this.setStateForOAuthReturn(this.carrierInviteReponse.eldInfo)
        }
    }
    setStateForOAuthReturn(eldInfo) {
        let urlparam = getUrlParams(this.props.location.search);
        if (urlparam.code) {    // OAuth parameter
            var oauthELD;
            let eldIndex = GetCookie('eldIndex', true)
            if (eldIndex !== null && eldIndex !== undefined && eldIndex >= 0) {
                this.setState({ 
                    returnFromOAuth: true,
                    preselectedModalScreen: 3,
                    oAuthCode: decodeURIComponent(urlparam.code)
                })
                eldInfo.every((eld, index) => {
                    if (eld.oAuth && index === eldIndex) {
                        oauthELD = eld
                        return false
                    }
                    return true
                })
            }
            if (oauthELD) {
                this.redirectVerifyELD(oauthELD, eldIndex)
            }
            else {
                this.setState({ error: 'We couldn\'t find the ELD with OAuth' })
            }
        }
    }
    setELDName(eld_name, eld_id, eldVendor) {
        let eldDetails = { eld_name: eld_name, eld_id: eld_id, eldVendor: eldVendor };
        this.setState({ eldDetails: eldDetails })
    }
    async redirectVerifyELD(state, eldIndex) {
        if (this.state.returnFromOAuth)
            this.setState({ mainModalOpen: true, checkedData: [state] })
        else 
            await this.setState({ mainModalOpen: true, checkedData: [state] })

        for (let index = 0; index < this.state.checkedData.length; index++) {
            const element = this.state.checkedData[index];
            if (element !== undefined) {
                element.tcURL = await this.callTCURL(element.eldVendor)
                for (let index2 = 0; index2 < element.eldMetadataParams.length; index2++) {
                    const element2 = element.eldMetadataParams[index2];
                    element2.isShowValidation = false;
                }
            }
        }
    }
    addDefaultSrc(ev) {
        ev.target.src = noPhoto
    }
    diableCheck(data) {
        for (let i = 0; i < this.state.checkedData.length; i++) {
            if (this.state.checkedData[i].eldProviderId === data) {
                return true
            }
        }
    }
    selectedFilterData(data) {
        for (let i = 0; i < this.state.checkedData.length; i++) {
            if (this.state.checkedData[i].eldProviderId === data) {
                return true
            }
        }
    }
    componentDidUpdate() {
        if (!this.state.mainModalOpen && this.state.checkedData.length > 0) {
            this.setState({ checkedData: [] })
            if (this.state.prevcheckedData.length > 4) {
                toastr.info('Info', 'Only 5 ELD can be selected');
            }
        }
    }
    toLower(str) {
        return str = str.toLowerCase();
    }
    carrierInvitation = (urlparam) => {
        this.props.dispatch(carrierInvitation(urlparam))
    }

    displayCompanies() {
        let eldData = []
        const eldListLength = this.state.invitationData.eldInfo.length;
        // let path = 'https://carrierapi.truckercloud.com/api/v1/elds/logo/'
        let path = process.env.REACT_APP_CARRIER_API_URL + 'elds/logo/'
        for (let index = 0; index < eldListLength; index++) {
            eldData.push({
                id: index,
                website_Name:
                    <span key={index} className='website_name'>
                        {
                            this.state.invitationData.eldInfo[index].eldVendor === 'LbTechnology' ?
                                <a href={'https://lbtechnologyinc.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>lbtechnologyinc.com</a>
                                : this.state.invitationData.eldInfo[index].eldVendor === 'OmnitracsVLV' ?
                                    <a href={'https://www.omnitracs.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>omnitracs.com</a>
                                    : this.state.invitationData.eldInfo[index].eldVendor === 'ThreeMD' ?
                                        <a href={'https://3mdinc.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>3mdinc.com</a>
                                        : this.state.invitationData.eldInfo[index].eldVendor === 'EldMandateHOS' ?
                                            <a href={'https://www.eldmandate.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>eldmandate.com</a>
                                            : this.state.invitationData.eldInfo[index].eldVendor === 'SkyBits' ?
                                                <a href={'https://sky-bits.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>sky-bits.com</a>
                                                : this.state.invitationData.eldInfo[index].eldVendor === 'Geotab-ATT' ?
                                                    <a href={'https://www.geotab.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>geotab.com</a>
                                                    : this.state.invitationData.eldInfo[index].eldVendor === 'RandMcNallyDriverTech' ?
                                                        <a href={'https://www.randmcnally.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>randmcnally.com</a>
                                                        : this.state.invitationData.eldInfo[index].eldVendor === 'Motive' ?
                                                            <a href={'https://gomotive.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>gomotive.com</a>
                                                            : this.state.invitationData.eldInfo[index].eldVendor === 'VerizonConnectReveal' ?
                                                                <a href={'https://www.verizonconnect.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>verizonconnect.com</a>
                                                                : this.state.invitationData.eldInfo[index].eldVendor === 'EldMandatePlus' ?
                                                                    <a href={'https://www.eldmandate.biz/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>eldmandate.biz</a>
                                                                    : this.state.invitationData.eldInfo[index].eldVendor === 'ISAAC' ?
                                                                        <a href={'https://www.isaacinstruments.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>isaacinstruments.com</a>
                                                                        : this.state.invitationData.eldInfo[index].eldVendor === 'Pedigree' ?
                                                                            <a href={'https://pedigreetechnologies.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>pedigreetechnologies.com</a>
                                                                            : <a href={`https://www.${this.state.invitationData.eldInfo[index].eldVendor}.com`} target='_blank' rel='noopener noreferrer' className='website_anchor'>{this.toLower(this.state.invitationData.eldInfo[index].eldVendor)}.com</a>
                        }
                    </span>,
                faq_credentials: <div data-toggle='modal' data-target='#faqModal' onClick={() => { this.setELDName(this.state.invitationData.eldInfo[index].eldVendorDisplayName, this.state.invitationData.eldInfo[index].eldVendorId, this.state.invitationData.eldInfo[index].eldVendor) }}><a href="javascript:;">{this.state.invitationData.eldInfo[index]?.eldVendorDisplayName} FAQ <Icon name={'link'} width={20} height={20} /></a></div>,
                eld_name: <div>{this.state.invitationData.eldInfo[index]?.eldVendorDisplayName}</div>,
                eld_brand:
                    <div
                        data-tip data-for='tool-tip-max-eld'
                        style={this.state.invitationData.eldInfo[index]?.overallStatus === 'VERIFIED' || (!this.diableCheck(this.state.invitationData.eldInfo[index]?.eldProviderId) && this.state.checkedData.length > 4) ? { opacity: '.3' } : { opacity: '1', cursor: 'pointer' }}
                        key={this.state.invitationData.eldInfo[index]?.eldProviderId}
                        aria-hidden='true' data-toggle='modal' data-backdrop='dismiss' data-target='#eldVerificationModal'
                        disabled={this.state.invitationData.eldInfo[index].overallStatus === 'VERIFIED' || this.selectedFilterData(this.state.invitationData.eldInfo[index]?.eldProviderId)}
                        onClick={this.state.invitationData.eldInfo[index].overallStatus === 'VERIFIED' || this.selectedFilterData(this.state.invitationData.eldInfo[index]?.eldProviderId) ? (e) => e.preventDefault() : () => { this.redirectVerifyELD(this.state.invitationData.eldInfo[index], index) }}
                    >
                        <img src={path + this.state.invitationData.eldInfo[index]?.eldVendor} className='eldImage' onError={this.addDefaultSrc} alt='Not Found' />
                        {
                            this.state.prevcheckedData.length > 4 ?
                                <ReactTooltip id='tool-tip-max-eld' style={{ zIndex: '1200' }}>
                                    You have already verified 5 ELDs. Please click on Done button to complete onboarding.
                                </ReactTooltip> : null
                        }
                    </div>
            })
        }
        return eldData
    }
    carrierCodes() {
        let carrierCode = [];
        carrierCode.push(
            this.state.invitationData.carrierInfo.carrierCodes.map((val, index) => {
                return (
                    <h5 className='list-cont-data' key={index}><span id='iconCOB'>#</span> {val.codeType}-{val.codeValue}</h5>
                )
            })
        )
        return carrierCode
    }
    preventCheck(e, index) {
        if (this.state.invitationData.eldInfo[index]?.overallStatus === 'VERIFIED') {
            e.target.checked = true
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            return false;
        }
        e.target.checked = false
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        return false;

    }

    previousCheck(data) {
        for (let i = 0; i < this.props?.history?.location?.state?.dummyData?.checkedData.length; i++) {
            if (this.props?.history?.location?.state?.dummyData?.checkedData[i].eldProviderId === data) {
                return true
            }
        }
    }
    searchELDs(e) {
        let code = (e.keyCode ? e.keyCode : e.which);
        const input = e.target.value.toLowerCase();
        if (input.length === 0 || code === 27) {
            this.setState(prevState => ({
                invitationData: {
                    ...prevState.invitationData,
                    eldInfo: localFilteredData.eldInfo
                }
            }))
        }
        else {
            const filteredArray = localFilteredData.eldInfo.filter(obj => {
                return obj.eldVendor.toLowerCase().includes(input);
            });
            this.setState(prevState => ({
                invitationData: {
                    ...prevState.invitationData,
                    eldInfo: filteredArray
                }
            }))
        }
    }
    nextPage() {
        history.push('/verify_providers');
    }
    removeDuplicate(data) {
        const unique = [];
        data.map(x => unique.filter(a => a.eldProviderId === x.eldProviderId).length > 0 ? null : unique.push(x));
        return unique;
    }
    async callTCURL(eldName) {
        let url = `${process.env.REACT_APP_CARRIER_API_URL}carriers/terms-and-conditions/${this.state.invitationkey}/${eldName}`
        let finalLink;
        await getDynamicDocumentURL(url).then((response) => {
            finalLink = response.url;
        });
        return await encodeURI(finalLink)
    }

    handleMainModalOpen = async () => {
        await this.setState({ ...this.state, mainModalOpen: true })
    }
    handleMainModalClose = async () => {
        await this.setState({ ...this.state, mainModalOpen: false })
    }
    handleELDModalOpen = async () => {
        await this.setState({ ...this.state, newEldOpen: true })
    }
    handleELDModalClose = async () => {
        await this.setState({ ...this.state, newEldOpen: false })
    }
    toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({ ...this.state, drawerOpen: open });
    };

    render() {
        if (this.state.error && this.state.error.length > 0) {
            return (
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <Box
                        component="main"
                        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
                    >
                        <Container>
                            <Container className='headerContainer'>
                                <Container>
                                </Container>
                            </Container>
                            <Container>
                                <TypoGraph variant="h3" mb={0} sx={{ fontSize: '20px', fontWeight: '400', textAlign:'center' }}>
                                    We're sorry, there is an error with your invitation: {this.state.error}
                                </TypoGraph>
                                <TypoGraph variant="h3" mb={0} sx={{ fontSize: '16px', fontWeight: '400', textAlign:'center', marginTop: '1rem' }}>
                                    Please contact our support team at support@truckercloud.com
                                </TypoGraph>
                            </Container>
                        </Container>
                    </Box>
                </Box>
            )
        }
        else {
            if (Object.keys(this.state.invitationData).length > 0) {
                return (
                    <Box sx={{ display: 'flex' }}>
                        <CssBaseline />
                        <MiniDrawerELD 
                            passingData={this.state.logo} 
                            state={this.state} 
                            open={this.state.drawerOpen} 
                            toggleDrawer={this.toggleDrawer}
                        />
                        <Box
                            component="main"
                            sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
                        >
                            <Container>
                                <Container className='headerContainer'>
                                    <Container>
                                        <Button 
                                            className="btn btn-secondary" 
                                            aria-label="drawer" 
                                            sx={{ 
                                                display: { xs: 'block', md: 'none' }, 
                                                marginRight:'1rem', 
                                                border: '1px solid darkgrey',
                                                width: '1rem'
                                            }} 
                                            onClick={this.toggleDrawer(!this.state.drawerOpen)}
                                        >
                                            <MenuIcon />
                                        </Button>
                                    </Container>
                                    <Container className='headerAction'>
                                        <div className='input-container1'>
                                            <button type="button" className="btn btn-primary btn-sm newELDBTN" onClick={this.handleELDModalOpen}>Request New ELD</button>
                                            {
                                                this.state.prevcheckedData.length > 4 ?
                                                <a href='/#' className='btn btn-primary1 btn-sm authbtn2' data-toggle='modal'>Done</a> : null
                                            }
                                        </div>
                                    </Container>
                                </Container>
                                <Container>
                                    <TypoGraph variant="h1" mb={0} sx={{ fontSize: '24px', fontWeight: '700' }}>
                                        Select your ELD to Onboard
                                    </TypoGraph>
                                </Container>
                                <Box sx={{ width: '100%', margin: '1rem 0rem' }}>
                                    <EldCardList
                                        companies={this.state.invitationData.eldInfo}
                                        path={process.env.REACT_APP_CARRIER_API_URL + 'elds/logo/'}
                                        checkedData={this.state.checkedData}
                                        filterData={this.selectedFilterData}
                                        redirectVerifyELD={this.redirectVerifyELD}
                                        setELDName={this.setELDName}
                                        diableCheck={this.diableCheck}
                                        onboardedELDs={this.state.checkedData.length}
                                        previouslyOnboardedELDs={this.state.prevcheckedData.length}
                                    />
                                    
                                    <FAQModal state={this.state.eldDetails} />
                                    <NewEld state={this.state} close={this.handleELDModalClose} />
                                    {
                                        this.state.checkedData.length > 0 && this.state.prevcheckedData.length < 5 ?
                                        <ELDVerificationModal 
                                            state={this.state} 
                                            handleClose={this.handleMainModalClose}  
                                            preselectedScreen={this.state.preselectedModalScreen}
                                            dynamicImage={this.state.returnFromOAuth ? "verifying" : null}
                                            returnFromOAuth={this.state.returnFromOAuth}
                                            origin="instant"
                                        /> 
                                        : null
                                    }
                                </Box>
                            </Container>
                        </Box>
                    </Box>
                );
            }
        }
    }
}

const mapStateToProps = (state) => {
    let carrierInvitationList = {};
    if (state.companyReducer.carrierInvitation) {
        carrierInvitationList = state.companyReducer.carrierInvitation;
    }
    return {
        carrierInvitationRes: carrierInvitationList
    };
}

export default connect(mapStateToProps)(withRouter(carrierOnboardMaterialUI))
