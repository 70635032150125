export const validate = values => {
    
    const errors = {}
    if (!values.companyEmail) {
        errors.companyEmail = 'Email can not be left blank.!'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.companyEmail)) {
        errors.companyEmail = 'Make sure that you entered valid email address.!'
    }
    if (!values.companyName) {
        errors.companyName = 'Carriername can not be left blank.!'
    } 
    if (!values.currentStateCode) {
        errors.currentStateCode = 'State can not be left blank.!'
    }
    if (!values.templateId) {
        errors.templateId = 'Select email template.!'
    }
    if (!values.phoneNumber) {
        errors.phoneNumber = 'Phone No. can not be left blank.!'
    }
    else if (!/^\d{10}$/i.test(values.phoneNumber)) { 
        errors.phoneNumber = 'Make sure that you entered valid Phone number.!'
    }
    // else if (!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(values.phoneNumber)) {
    //     errors.phoneNumber = 'Make sure that you entered valid Phone number.!'
    // }
    if (!values.bulkuploadfilename) {
        errors.bulkuploadfilename = 'File cannot be left blank.!'
    } 
    return errors
}