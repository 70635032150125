import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridActionsCellItem,
  GridSelectionModel,
} from '@mui/x-data-grid'
import { GridRenderCellParams, gridClasses } from '@mui/x-data-grid'
import Box from '@mui/material/Box'
// import { dataGridSx } from '../../muiComponents/constants/tableData'
import {  Icon, TypoGraph, Container, Card } from '../../muiComponents'
import styles from './table.css'
import colors from '../../theme/colors'

function CustomToolbar(props) {
  const { title, selectionModel, actionList, handleSelectionActions } =props || {}
  const isSelectionModel = selectionModel?.length
  console.log('CustomToolbar',props)
  return (
    <Container
      className={`${'tableHeaderBarContainer'} ${
        isSelectionModel && 'selectionBg'
      }`}
    >
      <TypoGraph variant="h2" mb={0} align="left">
        {title}
      </TypoGraph>
      <GridToolbarContainer className={'filterBarContainer'}>
        {isSelectionModel ? (
          <Container className={'rowSelectionContainer'}>
            <TypoGraph
              color={colors.primary}
            >{`${isSelectionModel} rows selected`}</TypoGraph>
            {actionList?.map(
              (item, index) =>
                item?.selection !== false && (
                  <Icon
                    key={`key-${index}`}
                    name={item?.icon}
                    size={item?.iconSize || 30}
                    onClick={() =>
                      handleSelectionActions &&
                      handleSelectionActions(item?.name, selectionModel)
                    }
                    iconButton
                  />
                )
            )}
          </Container>
        ) : (
          <>
            <GridToolbarQuickFilter debounceMs={700} />
            <Icon
              iconButton
              name="icon_filter"
              size={15}
              onClick={() => console.log('filters')}
            />
            <GridToolbarColumnsButton
              startIcon={<Icon name="icon_column" size={15} />}
              sx={{ fontSize: '0px' }}
              color={'neutral'}
              className={'filterBarButton'}
            />
            <GridToolbarDensitySelector
              sx={{ fontSize: '0px' }}
              color={'neutral'}
              className={'filterBarButton'}
            />
            <GridToolbarExport
              sx={{ fontSize: '0px' }}
              color={'neutral'}
              className={'filterBarButton'}
            />
          </>
        )}
      </GridToolbarContainer>
    </Container>
  )
}

const TableComponent = (props) => {
  const {
    loading, // shows the loader inside the table
    data, // array of datas which will be paried from the column name
    totalCount, // table total count
    paginationMode = 'client', // you can change the paginationMode to handle pagination in server side or client side
    columns, // colums with array of objects
    title = '', // shows the title in top of the table
    defaultPageSize = 10,
    checkboxSelection = true, // To show or hide the checkbox selection in table
    actionList, // list of actions items to show while hover the row
    handleActions, // func to handle the actions
    handleSelectionActions, // func to handle the row selection actions
    handleCheckboxSelection, // func to handle the checkbox selection
    handlePagination, // func to handle the pagination
  } = props
  console.log('props',props)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [customColumns, setCustomColumns] = useState(() => columns)
  const [selectionModel, setSelectionModel] = useState()
  const [currentPage, setCurrentPage] = useState(0)
  const dataGridSx = {
    '.MuiDataGrid-cell': {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: 14
    },
    '.MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
      fontFamily: 'poppins',
      fontSize: 14
    },
    '.MuiDataGrid-columnSeparator--sideRight': {
      display: 'none',
    },
    '.MuiDataGrid-columnHeaderDraggableContainer': {
      width: 'auto',
    },
    '.MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
    '& .actionButton': {
      display: 'none',
    },
    '.MuiDataGrid-actionsCell': {
      position: 'absolute',
      right: '2rem'
    },
    [`& .${gridClasses.row}:hover`]: {
      '.actionButton': {
        display: 'block',
      },
    },
    '.MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '.MuiDataGrid-columnHeader:focus-within': {
      outline: 'none',
    },
    '.MuiDataGrid-selectedRowCount ': {
      visibility: 'hidden',
    },
    background: 'transparent',
    '.MuiDataGrid-colCellTitle': {
      background: 'red'
    }
  }
  /*
   * function to build list of action cells
   * {param} id => will contain the row ID and will be passed to handleActions
   * ${return} => list of action array
   */
  const buildActionData = useCallback(
    (id) => {
      const actionItems = actionList?.map((item) => {
        return (
          <GridActionsCellItem
            icon={<Icon name={item?.icon} size={item?.iconSize || 25} />}
            label={item?.name}
            className={`actionButton ${item?.className}`}
            onClick={() => handleActions && handleActions(id, item?.name)}
            color="inherit"
          />
        )
      })
      return actionItems
    },
    [actionList, handleActions]
  )

  /**
   * This effect is used to merge the action object with columns
   * This logic will only if we list of actions from the props
   */
  useEffect(() => {
    const action = {
      field: 'actions',
      type: 'actions',
      width: 0,
      cellClassName: 'actions',
      getActions: ({ id : id}) => buildActionData(id),
    }
    const actionsLength = actionList?.length
    if (actionsLength) {
      setCustomColumns([...columns, action])
    }
  }, [actionList, columns, buildActionData])

  return (
    <Card sxContent={{ padding: 0, paddingBottom: '0!important' }}>
      <Box sx={{ height: 550, width: '100%' }}>
        <DataGrid
          sx={dataGridSx}
          rows={data}
          columns={customColumns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize)
            handlePagination && handlePagination(currentPage, newPageSize)
          }}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          loading={loading}
          paginationMode={paginationMode}
          // rowCount={totalCount}
          // onPageChange={(page) => {
          //   setCurrentPage(page)
          //   handlePagination && handlePagination(page, pageSize)
          // }}
          // components={{
          //   Toolbar: () => (
          //     <CustomToolbar
          //       title={title}
          //       selectionModel={selectionModel}
          //       actionList={actionList}
          //       handleSelectionActions={handleSelectionActions}
          //     />
          //   ),
          // }}
          checkboxSelection={checkboxSelection}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel)
            handleCheckboxSelection &&
              handleCheckboxSelection(newSelectionModel)
          }}
          selectionModel={selectionModel}
          disableColumnFilter
          disableSelectionOnClick
        />
      </Box>
    </Card>
  )
}

export default TableComponent
