import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';

const ResponsiveSubtitle = (props) => {
    const theme = useTheme();
    const isMobileView = !useMediaQuery(theme.breakpoints.up('md'));
    
    return (
        !isMobileView &&
        <Typography
            {...props}
        >
            {props.children}
        </Typography>
    );
}

export default ResponsiveSubtitle;