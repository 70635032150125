import * as types from '../actions';

const initialState = {
    data: [],
    pagination: [],
    loading: false,
    error: ''
};

export default function(state = initialState, action) {       
    switch(action.type) {
        case types.USER_LIST:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.USER_LIST_SUCCESS:   
            return { 
                ...state,
                data: action.response.Users,
                loading: false,
            };
        case types.USER_LIST_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            }; 
        case types.ADD_USER_LIST:
        return { 
            ...state,
            loading: true,
            error:''
        };
        case types.ADD_USER_LIST_SUCCESS:   
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.ADD_USER_LIST_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.CHANGE_PASSWORD:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.CHANGE_PASSWORD_SUCCESS:   
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.CHANGE_PASSWORD_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };  
        case types.DRIVER_ADD:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.DRIVER_ADD_SUCCESS:   
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.DRIVER_ADD_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            }; 
        case types.DRIVER_EDIT:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.DRIVER_EDIT_SUCCESS:   
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.DRIVER_EDIT_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            }; 
        case types.DRIVER_DELETE:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.DRIVER_DELETE_SUCCESS:   
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.DRIVER_DELETE_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            }; 
        case types.PROFILE_VIEW:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.PROFILE_VIEW_SUCCESS:   
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.PROFILE_VIEW_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.PROFILE_EDIT:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.PROFILE_EDIT_SUCCESS:   
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.PROFILE_EDIT_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.SHIPPER_LIST:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.SHIPPER_LIST_SUCCESS:   
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.SHIPPER_LIST_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            }
        case types.SHIPPER_ADD:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.SHIPPER_ADD_SUCCESS:   
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.SHIPPER_ADD_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};

// const initState = {}

// const userReducer = (state = initState, action) => {
//   	return state;
// };

// export default userReducer;