export const STATUS = {
    LOAD:{
        REQUESTED : {
            loadStatusId:1
        },
        MYLOADS: {
            loadStatusId:4
        },
        TENDERED : {
            loadStatusId:3
        },
        AVAILABLE : {
            loadStatusId:6
        },
        ACTIVE : {
            loadStatusId:7
        },
        COMPLETED : {
            loadStatusId:12
        },
        ACCEPTED : {
            loadStatusId:2
        },
        ASSIGNED : {
            loadStatusId:5
        },
        DELIVERY_CONFIRMED : {
            loadStatusId:8
        },
        DELIVERY_UNCONFIRMED : {
            loadStatusId:9
        },
        DELIVERED : {
            loadStatusId:10
        },
        PAID_CLOSE : {
            loadStatusId:11
        },
        CONFIRMED : {
            loadStatusId:13
        },
        CANCELED : {
            loadStatusId:14
        },
        PICKED_UP : {
            loadStatusId:15
        },
        ONMOVE : {
            loadStatusId:16
        },
        PAID : {
            loadStatusId:17
        },
        CLOSED : {
            loadStatusId:18
        },
        DISPATCHEDENROUTE : {
            loadStatusId:13
        },
        CANCELLED:{
            loadStatusId:14
        }
    }
};

export const TASKSTATUS = {
    TASK:{
        ASSIGNED : {
            statusval:1
        },   
        COMPLETED : {
            statusval:2
        },
        DELETED : {
            statusval:3
        }
    }
};