import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { reduxForm } from 'redux-form';
import { carrierSearchNew, makePropsEmpty } from '../../actions/companyActions';
import searchingImage from '../../search.svg';
import { validate } from './validation';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import InviteCarrier from './InviteCarrier';
import ReactTooltip from 'react-tooltip';
import { TableDataComponent } from 'UI-Component';
import { checkSplChar } from '../../helpers/searchValidation';
import SearchCarrierComponent from '../uicomponents/SearchCarrierComponent';
import { Link } from 'react-router-dom';

const dataColumns = [
    {
        text: 'Carrier Name',
        dataField: 'Company_Name',
        sort: true,
        headerStyle: { width: '150px' }
    },
    {
        text: 'Carrier Legal Name',
        dataField: 'Carrier_Legal_Name',
        sort: true,
        headerStyle: { width: '150px' }
    },
    {
        text: 'Name',
        dataField: 'Name',
        sort: true,
        headerStyle: { width: '75px' }
    },
    {
        text: 'MC #',
        dataField: 'MC_NO',
        sort: true,
        headerStyle: { width: '70px' }
    },
    {
        text: 'DOT #',
        dataField: 'DOT_NO',
        sort: true,
        headerStyle: { width: '90px' }
    },
    {
        text: 'SCAC #',
        dataField: 'SCAC_NO',
        sort: true,
        headerStyle: { width: '75px' }
    },
    {
        text: 'UUID #',
        dataField: 'UUID_NO',
        sort: true,
        headerStyle: { width: '75px' }
    },
    {
        text: 'ELD Provider',
        dataField: 'ELD_Provider',
        sort: true,
        headerStyle: { width: '100px' }
    },
    {
        text: '#Trucks',
        dataField: 'Trucks_Count',
        sort: true,
        headerStyle: { width: '75px' }
    },
    {
        text: 'Status',
        dataField: 'Status',
        sort: true,
        headerStyle: { width: '150px' }
    },
    {
        text: 'FMCSA Status',
        dataField: 'fmcscStatus',
        sort: true,
        headerStyle: { width: '120px' }
    }
];
let params = {}
class CarrierSearch extends Component {
    constructor() {
        super();
        this.state = {
            radio1: true,
            radio2: false,
            radio3: false,
            radio4: false,
            mcNumber: '',
            carriername: '',
            uuId: '',
            dotNumber: '',
            codeType: '',
            codeValue: '',
            isShowSearchImg: false,
            passingData: [],
            mycarriers: [],
            searchText: '',
            searchValue: true,
            carrierNameError: '',
            mcNumberError: '',
            dotNumberError: '',
            uuIdError: ''
        };
        this.onChangeValue = this.onChangeValue.bind(this);
    }
    async onChangeValue(event) {
        if (event.target.value) {
            this.setState({
                searchValue: false,
                carrierNameError: '',
                mcNumberError: '',
                dotNumberError: '',
                uuIdError: ''
            })
        } else {
            this.setState({
                searchValue: true
            })
        }
        //enabling or disabling fields based on radio selected along with emptying the old values 
        if (event.target.id === 'carriernameid') {
            await this.setState({
                radio1: true,
                radio2: false,
                radio3: false,
                radio4: false,
                carriername: event.target.value,
                mcNumber: '',
                dotNumber: '',
                uuId: '',
                codeType: '',
                codeValue: '',
                mcNumberError: '',
                dotNumberError: '',
                uuIdError: '',
                searchText: event.target.value
            });
            params = { carrierName: this.state.carriername }
        }
        else if (event.target.id === 'mcnumberid') {
            await this.setState({
                radio1: false,
                radio2: true,
                radio3: false,
                radio4: false,
                carriername: '',
                mcNumber: event.target.value,
                dotNumber: '',
                uuId: '',
                codeType: 'MC',
                codeValue: event.target.value,
                carrierNameError: '',
                dotNumberError: '',
                uuIdError: '',
                searchText: this.state.codeType + '# ' + event.target.value
            });
            params = {
                codeType: this.state.codeType,
                codeValue: this.state.codeValue
            }
        }
        else if (event.target.id === 'dotnumberid') {
            await this.setState({
                radio1: false,
                radio2: false,
                radio3: true,
                radio4: false,
                carriername: '',
                mcNumber: '',
                uuId: '',
                dotNumber: event.target.value,
                codeType: 'DOT',
                mcNumberError: '',
                carrierNameError: '',
                uuIdError: '',
                codeValue: event.target.value,
                searchText: this.state.codeType + '# ' + event.target.value
            });
            params = {
                codeType: this.state.codeType,
                codeValue: this.state.codeValue
            }
        }
        else if (event.target.id === 'uuidId') {
            await this.setState({
                radio1: false,
                radio2: false,
                radio3: false,
                radio4: true,
                uuId: event.target.value,
                carriername: '',
                dotNumber: '',
                mcNumber: '',
                codeType: 'UUID',
                codeValue: event.target.value,
                carrierNameError: '',
                dotNumberError: '',
                mcNumberError: '',
                searchText: this.state.codeType + '# ' + event.target.value
            });
            params = {
                codeType: this.state.codeType,
                codeValue: this.state.codeValue
            }
        }
    }
    invitecarrier = (rowdata) => {
        this.setState(
            {
                passingData: rowdata
            }
        );
    }
    async closeModal() {
        await this.setState({ isShowSearchImg: false })
        document.getElementById('modal1').style.display = 'none'
        this.setState({
            radio1: true,
            radio2: false,
            radio3: false,
            radio4: false,
            mcNumber: '',
            carriername: '',
            uuId: '',
            dotNumber: '',
            codeType: '',
            codeValue: '',
            carrierNameError: '',
            mcNumberError: '',
            dotNumberError: '',
            uuIdError: '',
            mycarriers: []
        });
        document.getElementById('addcarrierprop').style.display = 'none'
        this.props.dispatch(makePropsEmpty(''));
        this.props.initialize('CarrierSearchForm')
    }
    async componentWillReceiveProps(prevProps) {
        if (prevProps && prevProps.data !== this.props.data) {
            await this.sleep(2000);
            await this.setState({ isShowSearchImg: false, searchValue: true })
        }
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    async searchbtnclk(e) {
        await this.setState({
            carriername: this.state.carriername,
            mcNumber: this.state.mcNumber,
            dotNumber: this.state.dotNumber,
            uuId: this.state.uuId
        })
        //checking validation for mc,dot and scac no
        if (this.state.radio1) {
            if (this.state.carriername.length < 5) {
                this.setState({ carrierNameError: 'Carrier Name Should be atleast 5 character' })
                return;
            }
            if (!checkSplChar.regex.test(this.state.carriername)) {
                this.setState({ carrierNameError: 'Special characters are not allowed:# ? % \\\ ' })
                return;
            }
        }
        else if (this.state.radio2) {
            if (!checkSplChar.mcregex.test(this.state.mcNumber)) {
                this.setState({ mcNumberError: 'MC Number should be numeric' })
                return;
            }
            if (this.state.mcNumber.length < 1) {
                this.setState({ mcNumberError: 'MC Number Should be atleast 1 character' })
                return;
            }
        }
        else if (this.state.radio3) {
            if (!checkSplChar.dotregex.test(this.state.dotNumber)) {
                this.setState({ dotNumberError: 'DOT Number should be numeric' })
                return;
            }
            if (this.state.dotNumber.length < 1) {
                this.setState({ dotNumberError: 'DOT Number Should be atleast 1 character' })
                return;
            }
        }
        else if (this.state.radio4) {
            if (this.state.uuId.length < 2) {
                this.setState({ uuIdError: 'UUID Should be atleast 2 character' })
                return;
            }
            if (!checkSplChar.dotregex.test(this.state.uuId)) {
                this.setState({ uuIdError: 'UUID should be numeric' })
                return;
            }
        }
        await this.setState({ isShowSearchImg: true })
        this.props.dispatch(carrierSearchNew(params));
        return;
    }
    searchedCarriers() {
        if (this.props.data) {
            for (let i = 0; i < this.props.data.length; i++) {
                if (this.props.data[i].eldInfo && this.props.data[i].eldInfo.length > 0) {
                    this.props.data[i].eldInfo.map((row, index) => {
                        this.state.mycarriers.push({
                            Company_Name: this.props.data[i].carrierInfo.carrierName,
                            Carrier_Legal_Name: this.props.data[i].carrierInfo.carrierLegalName,
                            Name: this.props.data[i].contactInfo.hasOwnProperty('firstName') && this.props.data[i].contactInfo.hasOwnProperty('lastName') ? (this.props.data[i].contactInfo.firstName === null ? '' : this.props.data[i].contactInfo.firstName) + ' ' + (this.props.data[i].contactInfo.lastName === null ? '' : this.props.data[i].contactInfo.lastName) : null,
                            MC_NO: this.props.data[i].carrierInfo.carrierCodes.map((carrierCodes, index) =>
                                <span key={index}>
                                    {
                                        'codeType' in carrierCodes && carrierCodes.codeType === 'MC' ? carrierCodes.codeValue : ''
                                    }
                                </span>),
                            DOT_NO: this.props.data[i].carrierInfo.carrierCodes.map((carrierCodes, index) =>
                                <span key={index}>
                                    {
                                        'codeType' in carrierCodes && carrierCodes.codeType === 'DOT' ? carrierCodes.codeValue : ''
                                    }
                                </span>),
                            SCAC_NO: this.props.data[i].carrierInfo.carrierCodes.map((carrierCodes, index) =>
                                <span key={index}>
                                    {
                                        'codeType' in carrierCodes && carrierCodes.codeType === 'SCAC' ? carrierCodes.codeValue : ''
                                    }
                                </span>),
                            UUID_NO: this.props.data[i].carrierInfo.carrierCodes.map((carrierCodes, index) =>
                                <span key={index}>
                                    {
                                        'codeType' in carrierCodes && carrierCodes.codeType === 'UUID' ? carrierCodes.codeValue : ''
                                    }
                                </span>),
                            ELD_Provider: this.props.data[i].eldInfo[index].eldVendorDisplayName,
                            Trucks_Count: this.props.data[i].eldInfo[index].numberOfTrucks,
                            fmcscStatus: this.props.data[i].statusInfo.fmcsaStatus,
                            Status: <span>
                                <ReactTooltip id='carrierRectify'>
                                    {this.props.data[i].carrierInfo.carrierName} is already factored. Contact carrier to rectify
                                </ReactTooltip>{
                                    this.props.data[i].statusInfo.status === 'ONBOARDED' ? <button id='statusOnboarded' className='btn btn-primary btn-sm' >{this.props.data[i].statusInfo.status}</button>
                                        : this.props.data[i].statusInfo.status === 'IN_NETWORK' ? <button id='statusInNetwork' onClick={(e) => { this.invitecarrier(this.props.data[i], this.props.data[i].statusInfo.status); }} data-backdrop='false' data-toggle='modal' data-target='#modal2' className='btn btn-primary btn-sm' >IN NETWORK</button>
                                            : this.props.data[i].statusInfo.status === 'FACTORED' ? <button data-tip data-for='carrierRectify' style={{ cursor: 'not-allowed' }} id='statusFactored' data-backdrop='false' className='btn btn-primary btn-sm' >FACTORED</button>
                                                : null}</span>
                        })
                    })
                }
                else {
                    if (this.props.data[i].statusInfo.fmcsaStatus === 'INACTIVE') {
                        this.state.mycarriers.push({
                            Company_Name: this.props.data[i].carrierInfo.carrierName,
                            Carrier_Legal_Name: this.props.data[i].carrierInfo.carrierLegalName,
                            MC_NO: this.props.data[i].carrierInfo.carrierCodes.map((carrierCodes, index) =>
                                <span key={index}>
                                    {
                                        'codeType' in carrierCodes && carrierCodes.codeType === 'MC' ? carrierCodes.codeValue : ''
                                    }
                                </span>),
                            DOT_NO: this.props.data[i].carrierInfo.carrierCodes.map((carrierCodes, index) =>
                                <span key={index}>
                                    {
                                        'codeType' in carrierCodes && carrierCodes.codeType === 'DOT' ? carrierCodes.codeValue : ''
                                    }
                                </span>),
                            SCAC_NO: this.props.data[i].carrierInfo.carrierCodes.map((carrierCodes, index) =>
                                <span key={index}>
                                    {
                                        'codeType' in carrierCodes && carrierCodes.codeType === 'SCAC' ? carrierCodes.codeValue : ''
                                    }
                                </span>),
                            UUID_NO: this.props.data[i].carrierInfo.carrierCodes.map((carrierCodes, index) =>
                                <span key={index}>
                                    {
                                        'codeType' in carrierCodes && carrierCodes.codeType === 'UUID' ? carrierCodes.codeValue : ''
                                    }
                                </span>),
                            ELD_Provider: '',
                            Trucks_Count: '',
                            fmcscStatus: this.props.data[i].statusInfo.fmcsaStatus,
                            Status: <span>
                                <ReactTooltip id='carrierLicensed'>
                                    The carrier is not licensed to operate
                                </ReactTooltip>
                                <button data-tip data-for='carrierLicensed' style={{ cursor: 'not-allowed' }} id='statusNotInNetwork' data-backdrop='false' className='btn btn-primary btn-sm' >NOT IN NETWORK</button></span>
                        })
                    } else {
                        this.state.mycarriers.push({
                            Company_Name: this.props.data[i].carrierInfo.carrierName,
                            Carrier_Legal_Name: this.props.data[i].carrierInfo.carrierLegalName,
                            MC_NO: this.props.data[i].carrierInfo.carrierCodes.map((carrierCodes, index) =>
                                <span key={index}>
                                    {
                                        'codeType' in carrierCodes && carrierCodes.codeType === 'MC' ? carrierCodes.codeValue : ''
                                    }
                                </span>),
                            DOT_NO: this.props.data[i].carrierInfo.carrierCodes.map((carrierCodes, index) =>
                                <span key={index}>
                                    {
                                        'codeType' in carrierCodes && carrierCodes.codeType === 'DOT' ? carrierCodes.codeValue : ''
                                    }
                                </span>),
                            SCAC_NO: this.props.data[i].carrierInfo.carrierCodes.map((carrierCodes, index) =>
                                <span key={index}>
                                    {
                                        'codeType' in carrierCodes && carrierCodes.codeType === 'SCAC' ? carrierCodes.codeValue : ''
                                    }
                                </span>),
                            UUID_NO: this.props.data[i].carrierInfo.carrierCodes.map((carrierCodes, index) =>
                                <span key={index}>
                                    {
                                        'codeType' in carrierCodes && carrierCodes.codeType === 'UUID' ? carrierCodes.codeValue : ''
                                    }
                                </span>),
                            ELD_Provider: '',
                            Trucks_Count: '',
                            fmcscStatus: this.props.data[i].statusInfo.fmcsaStatus,
                            Status: <span>
                                <button onClick={(e) => { this.invitecarrier(this.props.data[i], this.props.data[i].statusInfo.status); }} id='statusNotInNetwork' data-toggle='modal' data-target='#modal2' data-backdrop='false' className='btn btn-primary btn-sm' >NOT IN NETWORK</button></span>
                        })
                    }
                }
            }
        }
        return this.state.mycarriers
    }
    render() {
        let carrier_nodata = 'No carriers matched your search. Please Invite a new carrier';
        this.state.mycarriers = [];
        if (this.props.data && this.props.data.length > 0) {
            carrier_nodata = this.props.data.message;
            document.getElementById('addcarrierprop').style.display = 'block'
        }
        else if (this.state.radio4 && this.props.data && this.props.data.length === 0) {
            carrier_nodata = <div>No carriers matched your search.<Link onClick={(e) => { this.invitecarrier({ uuId: this.state.uuId, status: 'NOT_IN_NETWORK' }) }} id='carrierNotInNetwork' data-toggle='modal' data-target='#modal2' data-backdrop='false'> Please Invite a new carrier</Link></div>
            document.getElementById('addcarrierprop').style.display = 'block'
        }
        else if (this.props.data && this.props.data.length === 0) {
            carrier_nodata = 'No carriers matched your search. Please Invite a new carrier';
            document.getElementById('addcarrierprop').style.display = 'block'
        }
        else if (this.props.data && this.state.isShowSearchImg) {
            carrier_nodata = this.props.data.message;
            document.getElementById('addcarrierprop').style.display = 'block'
        }
        return (
            <div className='modal fade' id='modal1' data-keyboard='false' tabIndex='-1' role='dialog' aria-labelledby='myLargeModalLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' id='exampleModalLabel'>Search for carriers</h5>
                            <button type='button' className='close' id='carrieraddmodalbox' aria-label='Close' onClick={(e) => { this.closeModal(e); }}>
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>
                        <div className='modal-body table-data'>
                            <div className='tab-content' id='myTabContent'>
                                <div className='tab-pane fade show active' id='truckinfo' role='tabpanel' aria-labelledby='truckinfo-tab'>
                                    <SearchCarrierComponent passedData={this.state} onChange={(e) => { this.onChangeValue(e) }} searchClick={(e) => { this.searchbtnclk(e) }} />
                                    <div className='addcarrierproperties' id='addcarrierprop' style={{ display: 'none' }} >
                                        {this.state.isShowSearchImg ?
                                            <div>
                                                <div className='search-carriers-div'><img src={searchingImage} className='search-carriers-img' alt='Searching...' />
                                                </div>
                                                <div className='search-carriers-div'>Searching For&nbsp;<span className='searchTextCss'>{this.state.searchText}</span></div>
                                            </div>
                                            : <TableDataComponent tableData={this.searchedCarriers()} columnData={dataColumns} noDataIndication={carrier_nodata} />
                                        }
                                    </div>
                                </div>
                                <InviteCarrier carrierData={this.state.passingData} codeValue={this.state.codeValue} codeType={this.state.codeType} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }
}
const mapStateToProps = state => {
    return {
        data: state.companyReducer.searchData,
        loading: state.companyReducer.loading,
        error: state.companyReducer.error
    }
}
export default connect(mapStateToProps)(reduxForm({
    form: 'CarrierSearchForm', validate
})(CarrierSearch))