import React, { Component } from 'react'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

class renderField extends Component {
   render() {
      const { input, label, controlClass, id, labelnotavailabe, data, disable, type, meta: { touched, error } } = this.props
      return (
         <div className={`form-group ${(touched && error) ? 'error' : ''}`}>
            {(labelnotavailabe) ? '' : <label>{label}</label>}
            <div>
               <input {...input} text={`${(data) ? data : ''}`} id={`${(id) ? id : ''}`} placeholder={label} type={type} disabled={`${(disable) ? disable : ''}`} className={`form-control ${(controlClass) ? controlClass : ''}`} />
               {touched && (error && <span className="error">{error}</span>)}
            </div>
         </div>
      )
   }
}
class renderTextArea extends Component {
   render() {
      const { textarea, label, controlClass, meta: { touched, error } } = this.props
      return (
         <div className={`form-group ${(touched && error) ? 'error' : ''}`}>
            <label>{label}</label>
            <div>
               <textarea {...textarea}
                  placeholder={label}
                  rows="4" cols="60" className={`form-control ${(controlClass) ? controlClass : ''}`}></textarea>
            </div>
         </div>
      )
   }
}
class renderSelect extends Component {
   render() {
      const { input, data, label, selectlabel, controlClass, idval, keyColumn, keyValue, dataattr, dataattr2, dataattr3, dataattr1, dataattr4, dataattr5, dataattr6, dataattr7, dataattr8, dataattr9, dataattr10, dataattr11, dataattr12, dataattr13, dataattr14, dataattr15, dataattr16, labelnotavailabe, meta: { touched, error } } = this.props
      // const optName = 'name';
      var key_column = keyColumn;
      var key_value = keyValue;
      var data_attr = dataattr;
      var data_attr1 = dataattr1;
      var data_attr2 = dataattr2;
      var data_attr3 = dataattr3;
      var data_attr4 = dataattr4;
      var data_attr5 = dataattr5;
      var data_attr6 = dataattr6;
      var data_attr7 = dataattr7;
      // var data_attr8 = dataattr8;
      var data_attr9 = dataattr9;
      var data_attr10 = dataattr10;
      var data_attr11 = dataattr11;
      var data_attr12 = dataattr12;
      var data_attr13 = dataattr13;
      var data_attr14 = dataattr14;
      var data_attr15 = dataattr15;
      var data_attr16 = dataattr16;
      if (typeof keyColumn === 'undefined') {
         key_column = "id";
      }
      if (typeof keyValue === 'undefined') {
         key_value = "name";
      }
      if (typeof dataattr === 'undefined') {
         data_attr = "";
      }
      if (typeof dataattr1 === 'undefined') {
         data_attr1 = "";
      }
      if (typeof dataattr2 === 'undefined') {
         data_attr2 = "";
      }
      if (typeof dataattr3 === 'undefined') {
         data_attr3 = "";
      }
      if (typeof dataattr4 === 'undefined') {
         data_attr4 = "";
      }
      if (typeof dataattr5 === 'undefined') {
         data_attr5 = "";
      }
      if (typeof dataattr6 === 'undefined') {
         data_attr6 = "";
      }
      if (typeof dataattr7 === 'undefined') {
         data_attr7 = "";
      }
      // if (typeof dataattr8 === 'undefined') {
      //    data_attr8 = "";
      // }
      if (typeof dataattr9 === 'undefined') {
         data_attr9 = "";
      }
      if (typeof dataattr10 === 'undefined') {
         data_attr10 = "";
      }
      if (typeof dataattr11 === 'undefined') {
         data_attr11 = "";
      }
      if (typeof dataattr12 === 'undefined') {
         data_attr12 = "";
      }
      if (typeof dataattr13 === 'undefined') {
         data_attr13 = "";
      }
      if (typeof dataattr14 === 'undefined') {
         data_attr14 = "";
      }
      if (typeof dataattr15 === 'undefined') {
         data_attr15 = "";
      }
      if (typeof dataattr16 === 'undefined') {
         data_attr16 = "";
      }
      return (
         <div className={`form-group ${(touched && error) ? 'error' : ''}`}>
            {(labelnotavailabe) ? <label></label> : <label>{label}</label>}
            <select {...input} placeholder={label} id={(idval) ? idval : ''} className={`form-control ${(controlClass) ? controlClass : ''}`}>
               <option value="">{`Select ${(label) ? label : selectlabel}`}</option>
               {
                  Array.isArray(data) ? data.map((option, index) =>
                     <option
                        value={option[key_column]} data-location={option[data_attr]} data-lat={option[data_attr4]}
                        data-long={option[data_attr5]} data-speed={option[data_attr6]} data-utcdate={option[data_attr7]}
                        data-make={option[data_attr1]} data-driverpay={option[data_attr2]}
                        data-drivinglicenseexpdate={option[data_attr3]} data-lanedetailsindex={index} data-sourcecity={option[data_attr9]}
                        data-destinationcity={option[data_attr10]} data-sourceaddress={option[data_attr11]} data-destinationaddress={option[data_attr12]} data-originstate={option[data_attr13]} data-destinationstate={option[data_attr14]}
                        data-originzip={option[data_attr15]} data-destinationzip={option[data_attr16]}
                        key={option[key_column]}>{option[key_value]}
                     </option>) : ''
               }
            </select>
            {touched && error && <span className="error">{error}</span>}
         </div>
      )
   }
}
class renderSelectForMultiple extends Component {
   render() {
      const { input, data, label, selectlabel, controlClass, idval, labelnotavailabe, meta: { touched, error } } = this.props
      // const optName = 'name';
      return (
         <div className={`form-group ${(touched && error) ? 'error' : ''}`}>
            {(labelnotavailabe) ? '' : <label>{label}</label>}
            <select {...input} placeholder={label} id={(idval) ? idval : ''} className={`form-control ${(controlClass) ? controlClass : ''}`} multiple={true} value={[]}>
               <option value="">{`Select ${(label) ? label : selectlabel}`}</option>
               {
                  Array.isArray(data) ? data.map((option, index) =>
                     <option
                        value={option.id}
                        key={option.id}>{option.name}
                     </option>) : ''
               }
            </select>
            {touched && error && <span className="error">{error}</span>}
         </div>
      )
   }
}
class renderCheckbox extends Component {
   render() {
      const { input, label, type, controlClass, meta: { touched, error } } = this.props
      return (
         <div>
            <input {...input} placeholder={label} type={type} className={`form-control ${(controlClass) ? controlClass : ''}`} /> <label>{label}</label>
            {touched && (error && <span className="error">{error}</span>)}
         </div>
      )
   }
}
class renderCheckbox1 extends Component {
   render() {
      const { input, label, name, id, value, meta: { touched, error } } = this.props
      return (
         <div className='parentDivCss'>
            <input type="checkbox" id={id} name={name} value={value} className='checkBoxCss' />&ensp;
            <label for="vehicle3" className='labelCss'> {label}</label>
            {touched && (error && <span className="error">{error}</span>)}
         </div>
      )
   }
}
class renderRadioButton extends Component {
   render() {
      const { input, label1, label2, controlClass, id, meta: { touched, error } } = this.props
      return (
         <div>
            <label><input {...input} type='radio' name={id} value={label1} className={`${(controlClass) ? controlClass : ''}`} />{label1}</label>
            <br />
            <label><input {...input} type='radio' name={id} value={label2} className={`${(controlClass) ? controlClass : ''}`} />{label2}</label>
            <br />{touched && (error && <span className="error">{error}</span>)}
         </div>
      )
   }
}
const renderRadioField = ({ placeholder, type, name, id, checked, value, disabled, meta: { touched, error } }) => (
   <div>
      <input type="radio" id={id} name={name} checked={checked} value={value} />
      <input placeholder={placeholder} id={id} value={value} type={type} className='labelCssForSearch' disabled={disabled} />
      {touched && (error && <span className="error">{error}</span>)}
   </div>
);
class renderPodNumber extends Component {
   render() {
      const { input, controlClass, data, type, meta: { touched, error } } = this.props
      return (
         <div className={`form-group ${(touched && error) ? 'error' : ''}`}>
            <div>
               <input {...input} text={`${(data) ? data : ''}`} type={type} className={`form-control ${(controlClass) ? controlClass : ''}`} />
               {touched && (error && <span className="error">{error}</span>)}
            </div>
         </div>
      )
   }
}
class renderFieldforTableTextBox extends Component {
   render() {
      const { input, data, disable, type, meta: { touched, error } } = this.props
      return (
         <div>
            $ <input {...input} text={`${(data) ? data : ''}`} type={type} disabled={`${(disable) ? disable : ''}`} autoComplete="off" />
            {touched && (error && <span className="error">{error}</span>)}
         </div>
      )
   }
}
class renderDateTimePicker extends Component {
   render() {
      const { input: { onChange, value }, label, labelnotavailabe, meta: { touched, error } } = this.props
      return (
         <div className={`form-group ${(touched && error) ? 'error' : ''}`}>
            {(labelnotavailabe) ? '' : <label>{label}</label>}
            <div>
               <DateTimePicker dropDown={true}
                  onChange={onChange}
                  format="MM-DD-YYYY"
                  time={false}
                  value={!value ? null : new Date(value)} />
            </div>
         </div>
      )
   }
}
class renderSelectStringVal extends Component {
   render() {
      const { input, data, label, selectlabel, controlClass, idval, keyColumn, keyValue,
         // dataattr, dataattr2, dataattr3, dataattr1, dataattr4, dataattr5, dataattr6, dataattr7, dataattr8, dataattr9, dataattr10, dataattr11, dataattr12, dataattr13, dataattr14, dataattr15, dataattr16,
         labelnotavailabe, meta: { touched, error } } = this.props
      // const optName = 'name';
      var key_column = keyColumn;
      var key_value = keyValue;
      // var data_attr = dataattr;
      // var data_attr1 = dataattr1;
      // var data_attr2 = dataattr2;
      // var data_attr3 = dataattr3;
      // var data_attr4 = dataattr4;
      // var data_attr5 = dataattr5;
      // var data_attr6 = dataattr6;
      // var data_attr7 = dataattr7;
      // var data_attr8 = dataattr8;
      // var data_attr9 = dataattr9;
      // var data_attr10 = dataattr10;
      // var data_attr11 = dataattr11;
      // var data_attr12 = dataattr12;
      // var data_attr13 = dataattr13;
      // var data_attr14 = dataattr14;
      // var data_attr15 = dataattr15;
      // var data_attr16 = dataattr16;
      if (typeof keyColumn === 'undefined') {
         key_column = "id";
      }
      if (typeof keyValue === 'undefined') {
         key_value = "name";
      }
      // if (typeof dataattr === 'undefined') {
      //    data_attr = "";
      // }
      // if (typeof dataattr1 === 'undefined') {
      //    data_attr1 = "";
      // }
      // if (typeof dataattr2 === 'undefined') {
      //    data_attr2 = "";
      // }
      // if (typeof dataattr3 === 'undefined') {
      //    data_attr3 = "";
      // }
      // if (typeof dataattr4 === 'undefined') {
      //    data_attr4 = "";
      // }
      // if (typeof dataattr5 === 'undefined') {
      //    data_attr5 = "";
      // }
      // if (typeof dataattr6 === 'undefined') {
      //    data_attr6 = "";
      // }
      // if (typeof dataattr7 === 'undefined') {
      //    data_attr7 = "";
      // }
      // if (typeof dataattr8 === 'undefined') {
      //    data_attr8 = "";
      // }
      // if (typeof dataattr9 === 'undefined') {
      //    data_attr9 = "";
      // }
      // if (typeof dataattr10 === 'undefined') {
      //    data_attr10 = "";
      // }
      // if (typeof dataattr11 === 'undefined') {
      //    data_attr11 = "";
      // }
      // if (typeof dataattr12 === 'undefined') {
      //    data_attr12 = "";
      // }
      // if (typeof dataattr13 === 'undefined') {
      //    data_attr13 = "";
      // }
      // if (typeof dataattr14 === 'undefined') {
      //    data_attr14 = "";
      // }
      // if (typeof dataattr15 === 'undefined') {
      //    data_attr15 = "";
      // }
      // if (typeof dataattr16 === 'undefined') {
      //    data_attr16 = "";
      // }
      return (
         <div className={`form-group ${(touched && error) ? 'error' : ''}`}>
            {(labelnotavailabe) ? <label></label> : <label>{label}</label>}
            <select {...input} placeholder={label} id={(idval) ? idval : ''} className={`form-control ${(controlClass) ? controlClass : ''}`}>
               <option value="">{`Select ${(label) ? label : selectlabel}`}</option>
               {
                  Array.isArray(data) ? data.map((option, index) =>
                     <option
                        value={option[key_value]}
                        key={option[key_column]}>{option[key_value]}
                     </option>) : ''
               }
            </select>
            {touched && error && <span className="error">{error}</span>}
         </div>
      )
   }
}
//Created New reusable component for input Form Using Redux-form
class renderInputField extends Component {
   render() {
      const { input, label, placeholder, controlClass, id, labelnotavailabe, data, disable, type, meta: { touched, error } } = this.props
      return (
         <>
            {(labelnotavailabe) ? '' : <label>{label}</label>}
            <input {...input} text={`${(data) ? data : ''}`} id={`${(id) ? id : ''}`} placeholder={placeholder} type={type} disabled={`${(disable) ? disable : ''}`} className={`form-control ${(controlClass) ? controlClass : ''}`} />
            {touched && (error && <span className="error">{error}</span>)}
         </>
      )
   }
}
class renderSingleSelect extends Component {
   render() {
      const { placeholder, input, data, label, controlClass, idval, keyColumn, keyValue, dataattr, dataattr2, meta: { touched, error } } = this.props
      // const optName = 'name';
      var key_column = keyColumn;
      var key_value = keyValue;
      var data_attr = dataattr;
      // var data_attr1 = dataattr1;
      // var data_attr2 = dataattr2;
      var data_attr4 = dataattr2;
      if (typeof keyColumn === 'undefined') {
         key_column = "id";
      }
      if (typeof keyValue === 'undefined') {
         key_value = "name";
      }
      if (typeof dataattr === 'undefined') {
         data_attr = "";
      }
      // if (typeof dataattr1 === 'undefined') {
      //    data_attr1 = "";
      // }
      return (
         <>
            <select {...input} placeholder={label} id={(idval) ? idval : ''} className={`form-control ${(controlClass) ? controlClass : ''}`}>
               <option value="">{`Select ${(label) ? label : placeholder}`}</option>
               {
                  Array.isArray(data) ? data.map((option, index) =>
                     <option
                        value={option[key_column]} data-location={option[data_attr]} data-lat={option[data_attr4]}
                        key={option[key_column]}>{option[key_value]}
                     </option>) : ''
               }
            </select>
            {touched && error && <span className="error">{error}</span>}
         </>
      )
   }
}
class renderDatePicker extends Component {
   render() {
      const { placeholder, onChange, controlClass, meta: { touched, error } } = this.props
      return (
         <div className='col master col-md-3'>
            <DateTimePicker
               className={controlClass}
               onChange={onChange}
               placeholder={placeholder}
               dropDown
            />
            {touched && (error && <span className="error">{error}</span>)}
         </div>
      )
   }
}

export { renderRadioField, renderDatePicker, renderSingleSelect, renderInputField, renderField, renderSelect, renderCheckbox1, renderCheckbox, renderSelectForMultiple, renderRadioButton, renderPodNumber, renderFieldforTableTextBox, renderDateTimePicker, renderTextArea, renderSelectStringVal }
