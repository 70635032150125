import * as types from '../actions';

const initialState = {
    data: [],
    loading: false,
    error: ''
};

export default function(state = initialState, action) {  
    switch(action.type) {
        case types.POST_NEW_ELD_DATA:
            return { 
                ...state,
                loading: true,
                error:''
            };      
        case types.POST_NEW_ELD_DATA_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.POST_NEW_ELD_DATA_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};