import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
// import { uniqBy } from 'lodash';
// import { rolesConfig } from './roles';
import { UserRoutes } from './routes';
import * as Routes from './index';
import TopBar from '../components/widgets/TopBarOld';
import PageNotFound from '../components/pages/PageNotFound';
import { connect } from 'react-redux';
import { commonlist } from '../actions/commonActions';
// import {mapboxCredentials} from '../services/commonService';
import { logoutUserAction } from '../actions/authActions';
import $ from 'jquery';
require('dotenv').config();
class PrivateRoutes extends Component {
state = {
	allowedRoutes: [],
	ismenuCollapsed:true,
};
toastrOptions = {
	onHideComplete:() =>{ this.gotoLogout(); },
	onCloseButtonClick:() =>{ this.gotoLogout(); }
};
gotoLogout = () =>{
	this.props.dispatch(logoutUserAction());
}
componentDidMount() {	
	let theme = process.env.REACT_APP_BASE_URL + 'publicresource/get/css/?companyId='+localStorage.getItem('companyId');
	$('<link/>', {rel: 'stylesheet', href: theme}).appendTo('head');
	let roles = localStorage.getItem('userType');
	if (roles) {
		roles = [roles];
		let allowedRoutes = roles.reduce((acc, role) => {
			return [...acc, ...UserRoutes[role].routes];
		}, []);
		let newRoutes = allowedRoutes.map(function(x , exactComp) {
			exactComp='';
			let originalRoutes = {...x,exactComp};
		return originalRoutes
		});
		this.setState({
		allowedRoutes:newRoutes
		});
	}
	else {
	//this.props.history.push('/');
	}	

	this.props.dispatch(commonlist());  
}
handleMenuClickCallback = (childData) =>{
	this.setState({ismenuCollapsed: childData})
	console.log('private routes ismenuCollapsed',this.state.ismenuCollapsed)
}
render() {
	const { classes } = this.props; //v.3
		return (
			<div className="wrapper">
				<TopBar  parentCallbackFromTopBar = {this.handleMenuClickCallback}/>
					<section className="box-wrap" style={{ marginLeft: this.state.ismenuCollapsed?'13.5rem':'2rem'}}>
						<Switch>
						{
							this.state.allowedRoutes.map((onRoute) => (							
							onRoute.exactComp = Routes[onRoute.component],								
							<Route
								exact render={() => <onRoute.exactComp history={this.props.history} timeZones={this.props.timeZones} statelist={this.props.statelist} geofenceradiuslist={this.props.geofenceradiuslist} geofencemailtimelist={this.props.geofencemailtimelist} assets={this.props.assets} country={this.props.country} 
								loadpoint={this.props.loadpoint} loadstatus={this.props.loadstatus} eldproviderlist={this.props.eldprovider} 
								trucktypelist={this.props.trucktype}  fueltypelist={this.props.fueltype} drivertypelist={this.props.drivertype} specialitytypelist={this.props.specialitytype} citylist={this.props.citylist} ziplist={this.props.ziplist} subscriptionplanlist={this.props.subscriptionplanlist} documenttypelist={this.props.documenttype} loadlist={this.props.bloadlist}/>}
								path={`${this.props.match.path}${onRoute.url}`}
								key={onRoute.url} />
							))
						}  
						<Route path="*" component={PageNotFound} />
						</Switch>
					</section>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	//console.log(state.commonReducer.timeZones);
return{
	statelist : state.commonReducer.statelist,
	//geofenceradiuslist : state.commonReducer.geofenceradiuslist,
	//geofencemailtimelist : state.commonReducer.geofencemailtimelist,
	country   : state.commonReducer.country,
	// assets    : state.commonReducer.assets,
	loadpoint : state.commonReducer.loadpoint,
	// loadstatus : state.commonReducer.loadstatus,
	eldprovider : state.commonReducer.eldproviderlist,
	trucktype:    state.commonReducer.trucktypelist,
	// fueltype:    state.commonReducer.fueltypelist,
	// drivertype:   state.commonReducer.drivertypelist,
	specialitytype:  state.commonReducer.specialitytypelist,
	// citylist:  state.commonReducer.citylist,
	// ziplist:  state.commonReducer.ziplist,
	// subscriptionplanlist:state.commonReducer.subscriptionplanlist,
	// documenttype:state.commonReducer.documenttypelist,
	// bloadlist:state.commonReducer.loadlist
	timeZones:state.commonReducer.timeZones
}    
}
export default connect(mapStateToProps)(PrivateRoutes)