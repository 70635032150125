import React, { useState } from 'react';
import { withRouter } from 'react-router';
import logo from '../../truckercloud-logo.png';
import { Col, Image, Row } from 'react-bootstrap';
import SimpleLoadingSpinner from '../common/SimpleLoadingSpinner';
import { getBaseUrl, getUrlParams } from '../../helpers/appUtil';
import { SetCookie } from '../../helpers/Cookies';

const CarrierInstallation = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [resultMessage, setResultMessage] = useState(null);

    const marketplaceOnboardingSeparator = '~';
    let isDirectOnboarding = false;
    let urlParams;
    let clientID;
    let identifier;
    let identifierParts;
    let errorMessage;
    let domainUrl = getBaseUrl();
    
    const hasValidUrlParams = (urlParams) => {
        return urlParams.identifier && 
            urlParams.identifier.length > 0 && 
            urlParams.cid &&
            urlParams.cid.length > 0 &&
            urlParams.identifier.includes(marketplaceOnboardingSeparator) &&
            (!urlParams.error || 
            urlParams.error.length === 0);
    }

    if (props.location.search.length > 0) {
        let url = props.location.search;
        urlParams = getUrlParams(url);
        if (hasValidUrlParams(urlParams) === true)
        {
            clientID = urlParams.cid;
            SetCookie('oauthCsrfKey', clientID, 0.1, true)

            identifier = urlParams.identifier;
            identifierParts = identifier.split(marketplaceOnboardingSeparator);
            isDirectOnboarding = true;

            const redirectURI = window.location.protocol + '//' + domainUrl + '/cob/direct?identifier=' + identifier
            const oAuthURL = "https://api.samsara.com/oauth2/authorize?client_id="+clientID+"&redirect_uri="+redirectURI+"&response_type=code&state="+clientID

            window.location.href = oAuthURL;
        }
        else {
            errorMessage = urlParams.error_description ?? "";
            console.log('Invalid identifier');
        }
    }
    else {
        console.log('No params found');
    }

    // Error Message
    if (errorMessage !== '' && errorMessage !== undefined) {
        return (
            <div style={{ overflow: 'hidden' }}>
                <Row className="mt-5">
                    <Col className="text-center">
                        <Image src={logo} alt="Company Logo" fluid style={{ maxWidth: '30vmax' }} />

                        <Row className="mt-5">
                            <Col>
                                <h3 style={{ fontSize: '3.5vmin' }}>Sorry! We couldn't validate your onboarding.</h3>
                                <h3 style={{ fontSize: '3vmin' }}>An error has ocurred in the process.</h3>
                                <h5 style={{ fontSize: '2.5vmin'}} className="mt-5">Contact support@truckercloud.com if the issue persists.</h5>
                                <p className="text-muted mt-3">ELD error response</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }

    if (!identifierParts || identifierParts === undefined || identifierParts.length !== 2) {
        return (
            <div style={{ overflow: 'hidden' }}>
                <Row className="mt-5">
                    <Col className="text-center">
                        <Image src={logo} alt="Company Logo" fluid style={{ maxWidth: '20vmax' }} />

                        <Row className="mt-5">
                            <Col>
                                <h3 style={{ fontSize: '3.5vmin' }}>Sorry! There is a communication issue</h3>
                                <h3 style={{ fontSize: '3vmin' }}>The communication between our platform and the marketplace seems to be broken. Please try again.</h3>
                                <h5 style={{ fontSize: '2.5vmin'}} className="mt-5">Contact support@truckercloud.com if the issue persists.</h5>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
    else {
        return (
            <div style={{ overflow: 'hidden' }}>
                <Row className="mt-5">
                    <Col className="text-center">
                        <Image src={logo} alt="Company Logo" fluid style={{ maxWidth: '30vmax' }} />

                        <Row className="mt-3 p-5">
                            <Col>
                                <h3 style={{ fontSize: '3.5vmin' }}>Welcome to the TruckerCloud Integration</h3>
                                <h3 style={{ fontSize: '3vmin' }}>Please, wait while we redirect you to continue the process.</h3>
                                <div className="mt-5">
                                    {isLoading ? <SimpleLoadingSpinner /> : <h3 style={{ fontSize: '2.5vmin', color: resultMessage.color }}>{resultMessage.message}</h3>}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
};

export default withRouter(CarrierInstallation);