import React, { Component } from 'react';
import {checkLoginByRedirectParams} from './../../services/commonService';
import {getUrlParams} from '../../helpers/appUtil';
import Loader from './../common/loader';

class Redirected extends Component{
    constructor(props){
        super(props);
        this.state={}
    }
    componentDidMount = () =>{
        let params = getUrlParams(this.props.location.search);
        if(params.hasOwnProperty('key') && params.hasOwnProperty('user') && params.hasOwnProperty('url')){
            let param = {
                payload:{
                    authorizationKey:params.key,
                    userId:params.user
                }
            }
            checkLoginByRedirectParams(param).then((response)=>{
                if(response){
                    if(response.status && response.status==='BAD_REQUEST'){
                        window.location = params.url;
                    }else if(response.AuthToken){
                        let data = response;
                        let menuList=[];
                        if(data.hasOwnProperty('Components')){
                            menuList=data.Components;
                        }
                        localStorage.setItem('userMenu', menuList);
                        localStorage.setItem('companyId', data.CompanyId);
                        localStorage.setItem('userName', data.UserName);
                        localStorage.setItem('authToken', data.AuthToken);
                        localStorage.setItem('Company_Name', data.Company_Name);	
                        localStorage.setItem('Password', data.Password);				
                        localStorage.setItem('IsLoadDriverShow', data.IsLoadDriverShow);	
                        localStorage.setItem('userType', data.UserType.toLowerCase());
                        localStorage.setItem('mapboxKey',data.MAPBOX_API_KEY);
                        localStorage.setItem('UserTypeId',data.UserTypeId);
                        localStorage.setItem('UserId',data.UserId);
                        localStorage.setItem('Company_Profile',JSON.stringify(data.Company_Profile));
                        localStorage.setItem('MAPBOX_ACCOUNT', data.MAPBOX_ACCOUNT);
                        localStorage.setItem('GOOGLE_MAP_KEY', data.GOOGLE_MAP_KEY);
                        localStorage.setItem('SUBSCRIPTION_PLAN_ID', data.SUBSCRIPTION_PLAN_ID);
                        localStorage.setItem('redirecturl', params.url);
                        window.location = '/pages/dashboard';
                    }

                }else {
                    window.location = params.url;
                }                
            });
        } else {
            window.location = '/login';
        }
    }

    render = () =>{
        return <Loader/>
    }
}

export default Redirected;