import * as types from '../actions';

const initialState = {
    preferredCompanyList: [],
    loading: false,
    type: '',
    error: '',
    preference_changes: null,
    data: [],
    carrierAddFlag: false,
    carriersearchFlag: false,
    carrierbulkuploadflag: false,
    invitedCarriers: [],
    signedUPLOAData:[],
    verifiedLOAData:[],
    myCarriers: [],
    activeDeliveries: [],
    pastDeliveries: [],
    activeFuelAdvanceS: [],
    pastFuelAdvanceS: [],
    favIconUpdatData: [],
    updatedData:[],
    carrierInvitation: {}
};

export default function (state = initialState, action) {
    state.preference_changes = null;
    switch (action.type) {
        case types.PREFERRED_COMPANY_LIST_BY_TYPE:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.PREFERRED_COMPANY_LIST_BY_TYPE_SUCCESS:
            return {
                ...state,
                preferredCompanyList: action.data.preferredCompanyList,
                data: action.data,
                loading: false,
            };
        case types.PREFERRED_COMPANY_LIST_BY_TYPE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case types.PREFERRED_COMPANY_LIST:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.PREFERRED_COMPANY_LIST_SUCCESS:
            return {
                ...state,
                preferredCompanyList: action.data.preferredCompanyList,
                loading: false,
            };
        case types.PREFERRED_COMPANY_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case types.CHANGE_PREFERRED_REQUEST:
            return {
                ...state,
                loading: true,
                error: ''
            }
        case types.CHANGE_PREFERRED_RESPONSE_SUCCESS:
            return {
                ...state,
                loading: true,
                error: '',
                preference_changes: action.data
            }
        case types.CARRIER_SEARCH:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.CARRIER_SEARCH_SUCCESS:
            return {
                ...state,
                data: action.data,
                carriersearchFlag: true,
                loading: false,
            };
        case types.CARRIER_SEARCH_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case types.CARRIER_SEARCH_NEW:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.CARRIER_SEARCH_NEW_SUCCESS:
            return {
                ...state,
                searchData: action.data,
                loading: false,

            };
        case types.CARRIER_SEARCH_NEW_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        //Making the props value empty while closing the modal
        case types.MAKE_PROPS_EMPTY:
            return {
                ...state,
                loading: false,
                searchData: [],
                error: ''
            };
        case types.MAKE_PROPS_EMPTY_SUCCESS:
            return {
                ...state,
                searchData: [],
                loading: false,
            };
        case types.MAKE_PROPS_EMPTY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case types.CARRIER_SEARCH_FLAG_ACTION:
            return {
                ...state,
            };
        case types.CARRIER_ADD:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.CARRIER_ADD_SUCCESS:
            return {
                ...state,
                data: action.data,
                carrierAddFlag: true,
                loading: false,
            };
        case types.CARRIER_ADD_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case types.CARRIER_ADD_FLAG_ACTION:
            return {
                ...state,
                carrierAddFlag: false,
                data: action.data,
            };
        case types.COMPANY_NAME_LIST:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.COMPANY_NAME_LIST_SUCCESS:
            return {
                ...state,
                companyNamelist: action.data,
                data: action.data,
                loading: false,
            };
        case types.COMPANY_NAME_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case types.GEOFENCE_COMPANY_NAME_LIST:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.GEOFENCE_COMPANY_NAME_LIST_SUCCESS:
            return {
                ...state,
                geofenceCompanyNamelist: action.data,
                data: action.data,
                loading: false,
            };
        case types.GEOFENCE_COMPANY_NAME_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case types.GEOFENCE_CARRIER_COMPANY_NAME_LIST:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.GEOFENCE_CARRIER_COMPANY_NAME_LIST_SUCCESS:
            return {
                ...state,
                geofenceCarrierCompanyNamelist: action.data,
                data: action.data,
                loading: false,
            };
        case types.GEOFENCE_CARRIER_COMPANY_NAME_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        // for radius list
        case types.GEOFENCE_RADIUS_LIST:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.GEOFENCE_RADIUS_LIST_SUCCESS:
            return {
                ...state,
                geofenceRadiusList: action.data,
                data: action.data,
                loading: false,
            };
        case types.GEOFENCE_RADIUS_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        // for Mail Time
        case types.GEOFENCE_MAIL_TIME_LIST:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.GEOFENCE_MAIL_TIME_LIST_SUCCESS:
            return {
                ...state,
                geofenceMailTimeList: action.data,
                data: action.data,
                loading: false,
            };
        case types.GEOFENCE_MAIL_TIME_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case types.GEOFENCE_RADIUS_STATUS:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.GEOFENCE_RADIUS_STATUS_SUCCESS:
            return {
                ...state,
                data: action.data,
                loading: false,
            };
        case types.GEOFENCE_RADIUS_STATUS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case types.CARRIER_BULK_UPLOAD:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.CARRIER_BULK_UPLOAD_SUCCESS:
            return {
                ...state,
                data: action.data,
                type: action.type,
                loading: false,
                carrierbulkuploadflag: true
            };
        case types.CARRIER_BULK_UPLOAD_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case types.CARRIER_BULK_UPLOAD_FlAG:
            return {
                ...state,
                carrierbulkuploadflag: false,
            };
        case types.GET_CARRIER_LIST_FACTOR:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.GET_CARRIER_LIST_FACTOR_SUCCESS:
            return {
                ...state,
                data: action.data,
                loading: false,
            };
        case types.GET_CARRIER_LIST_FACTOR_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case types.GET_INVITED_COMPANY_LIST:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.GET_INVITED_COMPANY_LIST_SUCCESS:
            return {
                ...state,
                invitedCarriers: action.data,
                data: action.data,
                loading: false,
            };
        case types.GET_INVITED_COMPANY_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case types.INVITED_NEW_CARRIER:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.INVITED_NEW_CARRIER_SUCCESS:
            return {
                ...state,
                inviteCarrier: action.data,
                loading: false,

            };
        case types.INVITED_NEW_CARRIER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case types.GET_MY_CARRIER_COMPANY_LIST:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.GET_MY_CARRIER_COMPANY_LIST_SUCCESS:
            return {
                ...state,
                myCarriers: action.data,
                data: action.data,
                loading: false,
            };
        case types.GET_MY_CARRIER_COMPANY_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        //SIGN LOA
        case types.VERIFY_CREDENTIALS_AND_SIGN_LOA:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.VERIFY_CREDENTIALS_AND_SIGN_LOA_SUCCESS:
            return {
                ...state,
                verifiedLOAData: action.data,
                searchFlag: true,
                loading: false,
            };
        case types.VERIFY_CREDENTIALS_AND_SIGN_LOA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        //TC WIDGET SIGN LOA
        case types.TC_WIDGET_VERIFY_CREDENTIALS_AND_SIGN_LOA:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.TC_WIDGET_VERIFY_CREDENTIALS_AND_SIGN_LOA_SUCCESS:
            return {
                ...state,
                verifiedLOAData: action.data,
                searchFlag: true,
                loading: false,
            };
        case types.TC_WIDGET_VERIFY_CREDENTIALS_AND_SIGN_LOA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        //OAUTH TC SIGN LOA
        case types.OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA_SUCCESS:
            return {
                ...state,
                verifiedLOAData: action.data,
                searchFlag: true,
                loading: false,
            };
        case types.OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        //OAUTH TC SIGN LOA (WIDGET)
        case types.OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA_IN_WIDGET:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA_IN_WIDGET_SUCCESS:
            return {
                ...state,
                verifiedLOAData: action.data,
                searchFlag: true,
                loading: false,
            };
        case types.OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA_IN_WIDGET_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        //Complete carrier signup
        case types.COMPLETE_SIGNUP_CARRIERS:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.COMPLETE_SIGNUP_CARRIERS_SUCCESS:
            return {
                ...state,
                signedUPLOAData: action.data,
                searchFlag: true,
                loading: false,
            };
        case types.COMPLETE_SIGNUP_CARRIERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        //tc-widget-carrier-invitation
        case types.GET_TC_WIDGET_CARRIER_INVITATION:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.GET_TC_WIDGET_CARRIER_INVITATION_SUCCESS:
            return {
                ...state,
                carrierInvitation: action.data,
                loading: false,
            };
        case types.GET_TC_WIDGET_CARRIER_INVITATION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        //sweepstakes-carrier-invitation
        case types.GET_SWEEPSTAKES_CARRIER_INVITATION:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.GET_SWEEPSTAKES_CARRIER_INVITATION_SUCCESS:
            return {
                ...state,
                carrierBulkInvitation: action.data,
                loading: false,
            };
        case types.GET_SWEEPSTAKES_CARRIER_INVITATION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        //TC Widget Complete carrier signup
        case types.COMPLETE_TC_WIDGET_SIGNUP_CARRIERS:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.COMPLETE_TC_WIDGET_SIGNUP_CARRIERS_SUCCESS:
            return {
                ...state,
                signedUPLOAData: action.data,
                searchFlag: true,
                loading: false,
            };
        case types.COMPLETE_TC_WIDGET_SIGNUP_CARRIERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case types.GET_CARRIER_INVITATION:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.GET_CARRIER_INVITATION_SUCCESS:
            return {
                ...state,
                carrierInvitation: action.data,
                loading: false,
            };
        case types.GET_CARRIER_INVITATION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        //FACTORING APIS
        //CONFIRM DELIVERY
        case types.GET_ACTIVE_DELEVERIES_LIST:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.GET_ACTIVE_DELEVERIES_LIST_SUCCESS:
            return {
                ...state,
                activeDeliveries: action.data,
                loading: false
            };
        case types.GET_ACTIVE_DELEVERIES_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case types.GET_PAST_DELEVERIES_LIST:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.GET_PAST_DELEVERIES_LIST_SUCCESS:
            return {
                ...state,
                pastDeliveries: action.data,
                loading: false
            };
        case types.GET_PAST_DELEVERIES_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        // FUEL ADVANCE
        case types.GET_ACTIVE_FUEL_ADVANCE_LIST:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.GET_ACTIVE_FUEL_ADVANCE_LIST_SUCCESS:
            return {
                ...state,
                activeFuelAdvanceS: action.data,
                loading: false
            };
        case types.GET_ACTIVE_FUEL_ADVANCE_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case types.GET_PAST_FUEL_ADVANCE_LIST:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.GET_PAST_FUEL_ADVANCE_LIST_SUCCESS:
            return {
                ...state,
                pastFuelAdvanceS: action.data,
                loading: false
            };
        case types.GET_PAST_FUEL_ADVANCE_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
            
        case types.UPDATE_FACTOR_BASED_ON_INVOICE:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.UPDATE_FACTOR_BASED_ON_INVOICE_SUCCESS:
            return {
                ...state,
                updatedData: action.data,
                loading: false
            };
        case types.UPDATE_FACTOR_BASED_ON_INVOICE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case types.SAVE_COMPANY_LOGO:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case types.SAVE_COMPANY_LOGO_SUCCESS:
            return {
                ...state,
                data: action.data,
                loading: false,
            };
        case types.SAVE_COMPANY_LOGO_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case types.SAVE_COMPANY_FAVICON:
            return {
                ...state,
                loading: true,
                error: ''
            };
            case types.UPDATE_NEW_LOGO:
                return {
                    ...state,
                    loading: true,
                    error: ''
                };
            case types.UPDATE_NEW_LOGO_SUCCESS:
                console.log('action.data',action.data)
                return {
                    ...state,
                    data: action.data,
                    loading: false,
                };
            case types.UPDATE_NEW_LOGO_ERROR:
                return {
                    ...state,
                    loading: false,
                    error: action.error
                };
        case types.SAVE_COMPANY_FAVICON_SUCCESS:
            return {
                ...state,
                favIconUpdatData: action.data,
                loading: false,
            };
        case types.SAVE_COMPANY_FAVICON_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return {
                ...state
            }
    }
};
