import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import Loader from './loader';
import $ from 'jquery';
import 'jquery-validation';
import { formData } from '../../helpers/formData';
import { appBaseUrl } from '../../helpers/appUtil';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { profileview } from '../../actions/userActions';
import { getCompanyProfileService, saveCompanyProfileService, uploadProfileBannerService } from '../../services/companyService';
import { BrandingComponent } from 'UI-Component';
import { SaveNewFavICon, SaveNewLogo } from '../../actions/companyActions';
import CircularProgress from '@material-ui/core/CircularProgress';
class Branding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageReady: false,
            loading: null,
            profile: {
                companyProfileId: null,
                companyId: null,
                colourCode: null,
                phoneNumber: null,
                logo: null,
                email: null,
                banner: null,
            },
            companyProfile: JSON.parse(localStorage.getItem('Company_Profile')),
            colorClass: 'ctrlHide'
        }
        this.box = React.createRef();
    }
    componentWillReceiveProps(prevProps, nextProps) {
        if (prevProps !== nextProps) {
            switch (prevProps.data.Company.companyTypeId) {
                case 1:
                    return prevProps.data.Company.companyType = 'Shipper'
                case 2:
                    return prevProps.data.Company.companyType = 'Broker'
                case 3:
                    return prevProps.data.Company.companyType = 'Carrier'
                case 4:
                    return prevProps.data.Company.companyType = 'Driver'
                case 5:
                    return prevProps.data.Company.companyType = 'Client'
                case 6:
                    return prevProps.data.Company.companyType = 'Internal'
                case 7:
                    return prevProps.data.Company.companyType = 'Tech Partner'
                case 8:
                    return prevProps.data.Company.companyType = 'Factor'
                default:
                    return '';
            }
        }
    }
    componentDidMount = () => {
        // Adding a click event listener
        document.addEventListener('click', this.handleOutsideClick);
        document.title = appBaseUrl() + 'Company Profile';
        this.props.dispatch(profileview());
        let param = {
            payload: {
                companyId: localStorage.getItem('companyId')
            }
        }
        getCompanyProfileService(param).then((response) => {
            if (response.companyProfile) {
                this.setState({
                    ...this.state,
                    profile: response.companyProfile,
                    pageReady: true
                });
            }
        });
    }
    handleOutsideClick = (event) => {
        if (this.box && !this.box.current.contains(event.target)) {
            this.closePicker()
        }
    }

    set_colourCode = (e) => {
        this.setState({
            ...this.state,
            profile: {
                ...this.state.profile,
                colourCode: e.target.value
            }
        });
    }
    processFile(imageInput) {
        if (imageInput) {
            var pattern = /image-*/;
            if (!imageInput.type.match(pattern)) {
                return true;
            }

            // here you can do whatever you want with your image. Now you are sure that it is an image
        }
    }
    saveLogo = (e) => {
        let fd = new FormData();
        let files = e.target.files[0];
        fd.append('file', files);
        fd.append('companyId', localStorage.getItem('companyId'));
        let param = {
            payload: fd
        }
        if (files) {
            if (this.processFile(files)) {
                toastr.error('Error!', 'Please use image files only');
            }
            else {
                var img = new Image();
                img.src = window.URL.createObjectURL(files);
                img.onload = function () {
                    var width = img.naturalWidth,
                        height = img.naturalHeight;
                    window.URL.revokeObjectURL(img.src);
                    if (width <= 230 & height <= 35) {
                        //perfect size
                    }

                    else {
                        //fail
                        toastr.error('Error!', 'Please use image with a maximum of 230px width and 35px height ');
                    }
                };
                img.addEventListener('load', (event) => {
                    var width = img.naturalWidth,
                        height = img.naturalHeight;
                    if (width <= 230 & height <= 35) {
                        this.props.dispatch(SaveNewLogo(param.payload));
                    }
                });
            }
        }
    }
    saveBanner = (e) => {
        let fd = new FormData();
        let files = e.target.files[0];
        fd.append('file', files);
        fd.append('companyId', localStorage.getItem('companyId'));
        let param = {
            payload: fd
        }
        if (files) {
            if (this.processFile(files)) {
                toastr.error('Error!', 'Please use image files only');
            }
            else {
                var img = new Image();
                img.src = window.URL.createObjectURL(files);
                img.onload = function () {
                    var width = img.naturalWidth,
                        height = img.naturalHeight;
                    window.URL.revokeObjectURL(img.src);
                    if (width <= 400 & height <= 400) {
                        //perfect size
                    }
                    else {
                        //fail
                        toastr.error('Error!', 'Please use image with a maximum of 400px width and 400px height ');
                    }
                };
                img.addEventListener('load', (event) => {
                    var width = img.naturalWidth,
                        height = img.naturalHeight;
                    if (width <= 400 & height <= 400) {
                        this.setState({ loading: true })
                        uploadProfileBannerService(param).then((response) => {
                            if (response.BannerUrl) {
                                toastr.success('Success!', 'Updated Company Banner');
                                this.setState({
                                    ...this.state,
                                    tmpBanner: response.BannerUrl,
                                    loading: false,
                                    profile: {
                                        ...this.state.profile,
                                        banner: response.BannerUrl
                                    }
                                });
                            } else {
                                this.setState({ loading: false })
                                toastr.error('Oops!', 'Unable to Update Company Banner');
                            }
                        });
                    }
                });
            }
        }
    }
    applyPreview = (e) => {
        document.documentElement.style.setProperty('--main-color', this.state.profile.colourCode);
        this.setState({
            colorClass: 'ctrlHide',
        });
    }
    closePicker = (e) => {
        this.setState({
            colorClass: 'ctrlHide',
        });
    }
    saveFavicon = (e) => {
        let fd = new FormData();
        let files = e.target.files[0];
        fd.append('file', files);
        fd.append('companyId', localStorage.getItem('companyId'));
        let param = {
            payload: fd
        }
        if (files) {
            if (this.processFile(files)) {
                toastr.error('Error!', 'Please use image files only');
            }
            else {
                var img = new Image();
                img.src = window.URL.createObjectURL(files);
                img.onload = function () {
                    var width = img.naturalWidth,
                        height = img.naturalHeight;
                    window.URL.revokeObjectURL(img.src);
                    if (width <= 500 & height <= 500) {
                        //perfect size
                    }
                    else {
                        //fail
                        toastr.error('Error!', 'Please use image with a maximum of 500px width and 500px height ');
                    }
                };
                img.addEventListener('load', (event) => {
                    var width = img.naturalWidth,
                        height = img.naturalHeight;
                    if (width <= 500 & height <= 500) {
                        this.props.dispatch(SaveNewFavICon(param.payload));
                    }
                });
            }
        }
    }
    saveProfile = (e) => {
        e.preventDefault();
        $('#companyProf').validate({
            rules: {
                colourCode: {
                    required: true
                },
                phoneNumber: {
                    required: true
                },
                email: {
                    required: true,
                    email: true
                }
            },
            messages: {
                colourCode: {
                    required: 'Colour code can not be left blank.!'
                },
                phoneNumber: {
                    required: 'Phone Number can not be left blank.!'
                },
                email: {
                    required: 'Email can not be left blank.!',
                    email: 'Email not valid'
                }
            }
        });
        if ($('#companyProf').valid()) {
            this.setState({ loading: true })
            let form = document.getElementById('companyProf');
            let form_data = formData(form);
            form_data['companyId'] = localStorage.getItem('companyId');
            if (this.state.tmpLogo) {
                form_data['logo'] = this.state.tmpLogo;
            } else {
                form_data['logo'] = this.state.profile.logo;
            }
            if (this.state.tmpBanner) {
                form_data['banner'] = this.state.tmpBanner;
            } else {
                form_data['banner'] = this.state.profile.banner;
            }
            let param = {
                payload: form_data
            }
            saveCompanyProfileService(param).then((response) => {
                if (response.status) {
                    document.documentElement.style.setProperty('--main-color', this.state.profile.colourCode);
                    toastr.success('Success', response.message);
                    this.setState({
                        ...this.state,
                        tmpLogo: '',
                        tmpBanner: '',
                        tmpFavicon: '',
                        loading: false
                    });
                    this.setState({
                        colorClass: 'ctrlHide',
                    });
                } else {
                    this.setState({ loading: false })
                    toastr.error('Oops!', 'Unable to save company profile');
                }
            });
        }
    }
    handleChangeComplete = (color) => {
        this.setState({
            ...this.state,
            // colorClass: 'ctrlHide',
            profile: {
                ...this.state.profile,
                colourCode: color.hex
            }
        });
    };
    openColor = () => {
        let colorClass = (this.state.colorClass === 'ctrlShow') ? 'ctrlHide' : 'ctrlShow';
        this.setState({
            ...this.state,
            colorClass: colorClass
        });
    }
    componentDidUpdate(prevProps) {
        if (this.props.someValue !== prevProps.someValue) {
            this.props.change('formName', 'formField', 'newFormValue');
        }
        if (this.props.favIconUpdatData !== prevProps.favIconUpdatData) {
            if (this.props.favIconUpdatData?.FaviconUrl) {
                // similar to previous proposal but adds a random parameter to avoid caching
                let oldFavicon = document.getElementById('favicon')
                var link = document.createElement('link')
                link.id = 'favicon';
                link.type = 'image/x-icon'
                link.rel = 'icon';
                link.href = this.props.favIconUpdatData.FaviconUrl + '?=' + Math.random();
                if (oldFavicon) {
                    document.head.removeChild(oldFavicon);
                }
                document.head.appendChild(link);
                this.setState({
                    ...this.state,
                    profile: {
                        ...this.state.profile,
                        favicon: this.props.favIconUpdatData.FaviconUrl
                    }
                });
                toastr.success('Success', 'Updated Favicon');
            }
            else {
                toastr.error('Oops!', 'Failed to Update Favicon');
            }
        }
        else if (this.props.companyProfileData !== prevProps.companyProfileData) {
            if (this.props.companyProfileData?.LogoUrl) {
                this.setState({
                    ...this.state,
                    profile: {
                        ...this.state.profile,
                        logo: this.props.companyProfileData.LogoUrl
                    }
                });
                localStorage.setItem('Company_Profile', JSON.stringify(this.state.profile));
                toastr.success('Success', 'Updated Company Logo');
            }
            else {
                toastr.error('Oops!', 'Failed to  Update Company Logo');
            }
        }
    }
    render = () => {
        this.props.initialize(this.props.data.Company);
        if (this.state.pageReady) {
            return (
                <article className='table-data truck-data shadow bg-white rounded'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2>Branding</h2>
                        </div>
                    </div>
                    <p>&nbsp;</p>
                    <div className='tab-content' id='myTabContent'>
                        <div className='tab-pane fade show active' id='branding' role='tabpanel' aria-labelledby='branding-tab'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <form id='companyProf' enctype='multipart/form-data'>
                                        <div className='loaderDiv'>
                                            {this.props.loading ? <CircularProgress className='CircularProgressCSS' /> : this.state.loading ? <CircularProgress className='CircularProgressCSS' /> : null}
                                        </div>
                                        <BrandingComponent state={this.state}
                                            saveLogo={(e) => { this.saveLogo(e) }}
                                            saveBanner={(e) => { this.saveBanner(e) }}
                                            saveFavicon={(e) => { this.saveFavicon(e) }}
                                            set_colourCode={(e) => { this.set_colourCode(e) }}
                                            openColor={(e) => { this.openColor(e) }}
                                            handleChangeComplete={this.handleChangeComplete}
                                            handlePreview={this.applyPreview}
                                            closePicker={this.closePicker}
                                            handleApply={this.saveProfile}
                                            box={this.box}
                                            calculateLogoImageSize={this.calculateLogoImageSize}
                                        />
                                        {/* <div className='row'>
                                            <div className='col text-center'>
                                                <button type='button' className='btn btn-primary' onClick={(e) => { this.saveProfile(e) }}>Save</button>
                                            </div>
                                        </div> */}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            );
        } else {
            return <Loader />;
        }
    }
}
const PageOneForm = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    form: 'initializeFromState'
})(Branding)
const mapStateToProps = (state) => {
    return {
        data: state.userReducer.data,
        loading: state.companyReducer.loading,
        companyProfileData: state.companyReducer.data,
        favIconUpdatData: state.companyReducer.favIconUpdatData,
        CityVal: state.loadReducer.CityVal,
        ZipVal: state.loadReducer.ZipVal,
        form: 'profiledetailsview'
    }
}
const PageOneFormContainer = connect(mapStateToProps)(PageOneForm)
export default PageOneFormContainer
