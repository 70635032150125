import React from 'react';
import { Card, CardMedia, CardContent, CardActions, Button, CardActionArea, Typography } from '@mui/material';
import ReactTooltip from 'react-tooltip';

const EldCard = (props) => {
    if (props.available) {
        return (
            <Card 
                variant="outlined"
                sx={{ height: '100%', position: 'relative' }}
            >
                <CardActionArea onClick={(e) => {
                    props.clickEvent(e, props.eld, props.index)
                }}>
                    <CardMedia
                        component="img"
                        alt="ELD Logo" 
                        image={props.logo}
                        sx={{
                            maxHeight: { xs: '6rem', sm: '8rem', md: '10rem' },
                            objectFit: 'contain',
                            padding: '1rem'
                        }}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div" className="text-center" sx={{ fontSize: '0.9rem' }}>
                            {props.name}
                        </Typography>
                    </CardContent>
                    {
                        props.previouslyOnboardedELDs > 4 ?
                            <ReactTooltip id='tool-tip-max-eld' style={{ zIndex: '1200' }}>
                                You have already verified 5 ELDs. Please click on Done button to complete onboarding.
                            </ReactTooltip> : null
                    }
                </CardActionArea>
                <CardActions style={{ display:'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button 
                        size="medium"
                        onClick={props.findMyCredentialsClick}
                        style={{ position:'absolute', bottom: 0, left: 0, right: 0 }}
                        data-toggle="modal" 
                        data-target="#faqModal" 
                    >
                        Find my Credentials
                    </Button>
                </CardActions>
            </Card>
        );
    }
    else {
        return (
            <Card 
                variant="outlined"
                sx={{ height: '100%', backgroundColor: 'lightgrey' }}
            >
                <CardMedia
                    component="img"
                    alt="ELD Logo" 
                    image={props.logo}
                    sx={{
                        maxHeight: { xs: '6rem', sm: '8rem', md: '10rem' },
                        objectFit: 'contain',
                        padding: '1rem'
                    }}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" className="text-center" sx={{ fontSize: '0.9rem' }}>
                        {props.name}
                    </Typography>
                </CardContent>
                {
                    props.previouslyOnboardedELDs > 4 ?
                        <ReactTooltip id='tool-tip-max-eld' style={{ zIndex: '1200' }}>
                            You have already verified 5 ELDs. Please click on Done button to complete onboarding.
                        </ReactTooltip> : null
                }
                <CardActions>
                    <h5 className="text-center" style={{ color:'indianred', textAlign: 'center'}}>Already onboarded!</h5>
                </CardActions>
            </Card>
        );
    }
}

export default EldCard;