import React, { Component } from 'react';
import Loader from './loader';
import 'jquery-validation';
import { appBaseUrl } from '../../helpers/appUtil';
import {  reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { profileview } from '../../actions/userActions';
import { getCompanyProfileService} from '../../services/companyService';
import {CompanyConfigComponent} from 'UI-Component';

class CompanyProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageReady: false,
            profile: {
                companyProfileId: null,
                companyId: null,
                phoneNumber: null,
                email: null
            }
        }
    }
    componentWillReceiveProps(prevProps, nextProps) {
        if (prevProps !== nextProps) {
            switch (prevProps.data.Company.companyTypeId) {
                case 1:
                    return prevProps.data.Company.companyType = 'Shipper'
                case 2:
                    return prevProps.data.Company.companyType = 'Broker'
                case 3:
                    return prevProps.data.Company.companyType = 'Carrier'
                case 4:
                    return prevProps.data.Company.companyType = 'Driver'
                case 5:
                    return prevProps.data.Company.companyType = 'Client'
                case 6:
                    return prevProps.data.Company.companyType = 'Internal'
                case 7:
                    return prevProps.data.Company.companyType = 'Tech Partner'
                case 8:
                    return prevProps.data.Company.companyType = 'Factor'
                default:
                    return '';
            }
        }
    }
    componentDidMount = () => {
        document.title = appBaseUrl() + 'Company Profile';
        this.props.dispatch(profileview());
        let param = {
            payload: {
                companyId: localStorage.getItem('companyId')
            }
        }
        getCompanyProfileService(param).then((response) => {
            if (response.companyProfile) {
                this.setState({
                    ...this.state,
                    profile: response.companyProfile,
                    pageReady: true
                });
            }
        });
    }
    setDefaultProfile = () => {
        this.setState({
            ...this.state,
            profile: {
                companyProfileId: null,
                companyId: null,
                phoneNumber: null,
                email: null,
            }
        });
    }
    set_phoneNumber = (e) => {
        this.setState({
            ...this.state,
            profile: {
                ...this.state.profile,
                phoneNumber: e.target.value
            }
        });
    }
    set_email = (e) => {
        this.setState({
            ...this.state,
            profile: {
                ...this.state.profile,
                email: e.target.value
            }
        });
    }
    componentDidUpdate(prevProps) {
        if (this.props.someValue !== prevProps.someValue) {
            this.props.change('formName', 'formField', 'newFormValue');
        }
    }
    render = () => {
        this.props.initialize(this.props.data.Company);
        if (this.state.pageReady) {
            return (
                <article className='table-data truck-data shadow bg-white rounded'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2>Company Config</h2>
                        </div>
                    </div>
                    <hr/>
                    <div className='tab-content' id='myTabContent'>
                        <div className='tab-pane fade  show active' id='companyProfileTab' role='tabpanel' aria-labelledby='company-profile-tab'>
                            <div className='row'>
                                <div className='col col-md-12'>
                                    <form className='tg-forms'>
                                        <CompanyConfigComponent />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            );
        } else {
            return <Loader />;
        }
    }
}
const PageOneForm = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    form: 'initializeFromState'
})(CompanyProfile)
const mapStateToProps = (state) => {
    return {
        data: state.userReducer.data,
        CityVal: state.loadReducer.CityVal,
        ZipVal: state.loadReducer.ZipVal,
        form: 'profiledetailsview'
    }
}
const PageOneFormContainer = connect(mapStateToProps)(PageOneForm)
export default PageOneFormContainer
