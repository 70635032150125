import React from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

class Factoring extends React.Component {
    render() {
        return (
            <div className='row no-gutters'>
                <div className="wrapper2">
                    <div className="card2">
                        <i className="fas fa-bus cardIconCss"></i>
                        <Card.Title className='modal-title doc-color'>Delivery Confirmations</Card.Title>
                        <Card.Text>
                            To Confirm the Asset completed the Delivery<br />
                            <button className='btn btn-primary1 btn-sm viewbtn' >
                                <Link to="/pages/factoring/deliveryConfirmed">View</Link>
                            </button>
                        </Card.Text>
                    </div>
                    <div className="card2">
                        <i className="far fa-money-bill-alt  cardIconCss "></i>
                        <Card.Title className='modal-title doc-color'>Fuel Advances</Card.Title>
                        <Card.Text>
                            To Confirm the Location of the Asset for Fuel Advance<br />
                            <button className='btn btn-primary1 btn-sm viewbtn'>
                                <Link to="/pages/factoring/fuelAdvances" >View</Link>
                            </button>
                        </Card.Text>
                    </div>
                </div>
            </div>
        );
    }
}

export default Factoring;