import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import GoogleMapBreadcrump from './GoogleMapBreadcrump';
import { eldAssetList, brokerdashboardAction, brokerdashboardMultipleEldAction } from '../../../actions/dashboardActions';
import { Row, Col } from 'react-bootstrap';
import { carrierListForBrokerService, } from '../../../services/companyService';
import $ from 'jquery'
import { appBaseUrl } from '../../../helpers/appUtil';
import { DashboardTrucksComponent, GetCarrier, GetEld, DashboardGoogleMap } from 'UI-Component'
import loadVehicleicon from '../../../images/loadedtruck.png'

require('dotenv').config();

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedData: [],
			checkedData: [],
			viewFlag: false,
			routeData: [],
			updated: 0,
			TrucksNum: [],
			mapZoom: 3.5,
			mapCenter: [-96, 37],
			truckState: 'active',
			mapViewMode: 'static',
			truckInfo: {},
			carriers: [],
			eldList: [],
			pageView: '',
			carrierNames: [],
			disabled: false,
			mapFlag: 0
		}
		this.selectedMenu = 0;
		this.userType = localStorage.getItem('userType');
		this.SUBSCRIPTION_PLAN_ID = localStorage.getItem('SUBSCRIPTION_PLAN_ID');
	}
	componentDidMount() {
		document.title = appBaseUrl() + 'Dashboard';
		let param = {
			companytypeid: 3
		}
		carrierListForBrokerService(param).then((response) => {
			if (response.companyList) {
				this.setState({
					...this.state,
					isReady: true,
					carrierNames: response.companyList
				});
			}
			else {
				this.setState({
					...this.state,
					isReady: true,
					carrierNames: []
				});
			}
		});

		let params = {
			carrierCompanyId: 0,
			carriercompanyIdFlag: true
		}
		this.props.dispatch(brokerdashboardAction(params));
	}
	componentDidUpdate = (prevProps, prevState, snapshot) => {
		if (this.props.dashboard.dashboard && this.props.dashboard.dashboard.eldList) {
			if (prevProps.dashboard.dashboard && prevProps.dashboard.dashboard.eldList) {
				if (this.props.dashboard.dashboard.eldList !== prevProps.dashboard.dashboard.eldList) {

					if (this.props.dashboard.dashboard.eldList.length > 0) {
						if (this.selectedMenu === 0) {
							this.selectedMenu = this.props.dashboard.dashboard.eldList[0].ELD_Provider_Id;
						}
					}
				}
			} else {
				if (this.props.dashboard.dashboard.eldList.length > 0) {
					this.selectedMenu = this.props.dashboard.dashboard.eldList[0].ELD_Provider_Id;
				}
			}
		}
		if (this.state.updated === 0 && this.props.dashboard.dashboard) {
			if (this.state.truckState === 'active') {
				if (this.props.dashboard.dashboard.eldList.length > 0) {
					if (this.state.selectedData !== this.props.dashboard.dashboard.active) {
						this.setState({
							updated: 1,
							selectedData: this.props.dashboard.dashboard.active,
							checkedData: this.props.dashboard.dashboard.active,
							viewFlag: false,
							routeData: [],
							TrucksNum: this.props.dashboard.dashboard.active,
							carriers: this.props.dashboard.dashboard.carrierList,
							eldList: this.props.dashboard.dashboard.eldList,
							mapZoom: 3.5,
							disabled: false
						});
					}
				}
			} else if (this.state.truckState === 'ideal') {
				if (this.props.dashboard.dashboard.eldList.length > 0) {
					if (this.state.selectedData !== this.props.dashboard.dashboard.idle) {
						this.setState({
							updated: 1,
							selectedData: this.props.dashboard.dashboard.idle,
							checkedData: this.props.dashboard.dashboard.idle,
							viewFlag: false,
							routeData: [],
							TrucksNum: this.props.dashboard.dashboard.idle,
							carriers: this.props.dashboard.dashboard.carrierList,
							eldList: this.props.dashboard.dashboard.eldList,
							mapZoom: 3.5,
							disabled: false
						});
					}
				}
			} else if (this.state.truckState === 'stopped') {
				if (this.props.dashboard.dashboard.eldList.length > 0) {
					if (this.state.selectedData !== this.props.dashboard.dashboard.stopped) {
						this.setState({
							updated: 1,
							selectedData: this.props.dashboard.dashboard.stopped,
							checkedData: this.props.dashboard.dashboard.stopped,
							viewFlag: false,
							routeData: [],
							TrucksNum: this.props.dashboard.dashboard.stopped,
							carriers: this.props.dashboard.dashboard.carrierList,
							eldList: this.props.dashboard.dashboard.eldList,
							mapZoom: 3.5,
							disabled: false
						});
					}
				}
			}
		}
	}

	handleTabClicked = (val, stopped) => {
		if (this.props.dashboard.dashboard) {
			if (val === 1) {			// For Active Trucks 			
				this.setState({
					updated: 1,
					selectedData: this.props.dashboard.dashboard.active,
					checkedData: this.props.dashboard.dashboard.active,
					viewFlag: false,
					routeData: [],
					TrucksNum: this.props.dashboard.dashboard.active,
					mapZoom: 3.5,
					mapCenter: [-96, 37],
					truckState: 'active',
					mapViewMode: 'static',
					eldList: this.props.dashboard.dashboard.eldList,
					disabled: false,
					mapFlag: this.state.mapFlag + 1
				});
			} else if (val === 2) {		// For Idle Trucks			
				this.setState({
					updated: 1,
					selectedData: this.props.dashboard.dashboard.idle,
					checkedData: this.props.dashboard.dashboard.idle,
					viewFlag: false,
					routeData: [],
					TrucksNum: this.props.dashboard.dashboard.idle,
					mapZoom: 3.5,
					mapCenter: [-96, 37],
					truckState: 'ideal',
					mapViewMode: 'static',
					eldList: this.props.dashboard.dashboard.eldList,
					disabled: false,
					mapFlag: this.state.mapFlag + 1
				});
			} else if (val === 3) {		// For Stopped Trucks			
				this.setState({
					updated: 1,
					selectedData: this.props.dashboard.dashboard.stopped,
					checkedData: this.props.dashboard.dashboard.stopped,
					viewFlag: false,
					routeData: [],
					TrucksNum: this.props.dashboard.dashboard.stopped,
					mapZoom: 3.5,
					mapCenter: [-96, 37],
					truckState: 'stopped',
					mapViewMode: 'static',
					eldList: this.props.dashboard.dashboard.eldList,
					disabled: false,
					mapFlag: this.state.mapFlag + 1
				});
			}
		}
	}
	handletruckClicked = (selectedTruck) => {
		if (selectedTruck.length === 1) {					// change to route Map			
			this.setState({
				updated: 1,
				viewFlag: true,
				routeData: selectedTruck,
				mapZoom: 12,
				mapFlag: this.state.mapFlag + 1
			});
		} else {											// restore original map
			this.setState({
				updated: 1,
				viewFlag: false,
				routeData: [],
				mapZoom: 12,
				mapFlag: this.state.mapFlag + 1
			});
		}
	}
	handlecheckList = (filteredEle) => {
		switch (filteredEle.length) {
			case 0:
				this.setState({
					updated: 1,
					viewFlag: false,
					checkedData: filteredEle,
					routeData: [],
					mapZoom: 12,
					mapFlag: this.state.mapFlag + 1
				});
				break;
			case 1:
				this.setState({
					updated: 1,
					viewFlag: true,
					routeData: filteredEle,
					checkedData: filteredEle,
					mapZoom: 12,
					mapFlag: this.state.mapFlag + 1
				});
				break;
			default:
				this.setState({
					updated: 1,
					viewFlag: false,
					checkedData: filteredEle,
					routeData: [],
					mapZoom: 12,
					mapFlag: this.state.mapFlag + 1
				});
				break;
		}
	}
	handleEldprovider = (id) => {
		this.props.dispatch(eldAssetList(id));
	}
	_truckClick = (truckInfo) => {
		let mapViewMode = 'static';
		if (this.state.truckState === 'active') {
			mapViewMode = 'moving';
		}
		this.setState({
			...this.state,
			checkedData: [truckInfo],
			mapZoom: 12,
			mapCenter: [truckInfo.longitude, truckInfo.latitude],
			mapViewMode: mapViewMode,
			truckInfo: truckInfo,
			mapFlag: this.state.mapFlag + 1
		});
	}


	showBreadcrump = () => {
		let userType = localStorage.getItem('userType').toLowerCase();
		if (userType === 'shipper') {
			return <Fragment></Fragment>;
		} else {
			return <GoogleMapBreadcrump trucks={this.state.TrucksNum} timeZones={this.props.timeZones} />
		}

	}

	carrierSelect = (key) => {
		this.selectedMenu = key;
		let params = {
			carrierCompanyId: key
		}
		this.props.dispatch(brokerdashboardAction(params));
		this.setState({
			...this.state,
			mapViewMode: 'static',
			mapZoom: 3.5,
			mapCenter: [-96, 37],
			updated: 0,
			mapFlag: this.state.mapFlag + 1
		});
	}

	eldSelect = (key) => {
		var findcarrierId = $('#findCarrierId option:selected').val();
		this.selectedMenu = key;
		let params = {
			eldProviderId: key,
			carrierCompanyId: findcarrierId
		}
		this.props.dispatch(brokerdashboardAction(params));
		this.setState({
			...this.state,
			mapViewMode: 'static',
			mapCenter: [-96, 37],
			mapZoom: 3.5,
			updated: 0
		});
	}

	loadCarrierDashboardData = (e) => {
		$('#fromDateTime_input').val('');
		$('#toDateTimevalue_input').val('');
		$('#toTime_input').val('');
		$('#toTimevalue_input').val('');
		$('#breadcrumbtruckno').val('');
		let carrierCompanyId = $(e.target).val();
		let params = {
			carrierCompanyId: carrierCompanyId
		}
		this.selectedMenu = 0;
		if (carrierCompanyId !== 0) {
			this.setState({
				...this.state,
				updated: 0,
				disabled: true

			});
		}
		this.props.dispatch(brokerdashboardAction(params));
	}

	selectELD = (e, ELD_Provider_Id) => {
		$('#fromDateTime_input').val('');
		$('#toDateTimevalue_input').val('');
		$('#toTime_input').val('');
		$('#toTimevalue_input').val('');
		$('#breadcrumbtruckno').val('');

		let carrierCompanyId = $('#findCarrierId').val();
		let eldProviderId = $('#findELdId').val();
		let params = {
			carrierCompanyId: carrierCompanyId,
			eldProviderId: $(e.target).val()
		}
		this.setState({
			...this.state,
			updated: 0
		});
		this.props.dispatch(brokerdashboardMultipleEldAction(params));
	}

	getMenu = () => {
		let elds = [];
		if (this.state.eldList && this.state.eldList.length > 0) {
			elds = this.state.eldList.map((row, index) => {
				return <button onClick={(e) => { this.selectELD(e, row.ELD_Provider_Id) }} key={index} className={`btn ${(row.ELD_Provider_Id === this.selectedMenu) ? 'btn-primary' : 'btn-light'}`}>{row.ELD_Provider_Disp_Name}</button>
			});
		}
		if (elds.length > 0) {
			return <div className='dashboard-top-menu eldliststyle' >{elds}</div>;
		} else {
			return <Fragment></Fragment>
		}
	}

	getCarrierDropdown = () => {
		return <div className='carrierDropdown'>
			<div className='col col-md-12'>
				<label htmlFor='carrierdropdownLabel' className='carrierdropdownLabel' ><b>Select Carrier</b></label>
				<select disabled={this.state.disabled} className='form-control' id='findCarrierId' onChange={(e) => { this.loadCarrierDashboardData(e) }}>
					<option value='0'>Select Carrier</option>
					{
						this.state.carrierNames.length > 0 ? (this.state.carrierNames.map(function (company, index) {
							return (
								<option key={index} value={company.Invited_Company_Id}>{company.Company_Name}</option>
							)
						})) : (<option>Loading.....</option>)
					}
				</select>
			</div>
		</div>
	}
	getEldProviderDropdown = () => {
		if (this.state.eldList.length > 0) {
			return <div className='carrierDropdown'>
				<div className='col col-md-12'>
					<label htmlFor='carrierdropdownLabel' className='carrierdropdownLabel' ><b>Select ELD Providers</b></label>
					<select className='form-control' id='findELdId' onChange={(e) => { this.selectELD(e) }} >
						{
							(this.state.eldList && this.state.eldList.length > 0) ? (this.state.eldList.map(function (row, index) {
								return (
									<option key={index} value={row.ELD_Provider_Id}>{row.ELD_Provider_Disp_Name}</option>
								)
							})) : null
						}
					</select>
				</div>
			</div>
		}
	}
	getDashboardMap = (TOKEN) => {
		let mapCenter = {
			lat: parseFloat(this.state.mapCenter[1]),
			lng: parseFloat(this.state.mapCenter[0])
		}
		let mapZoom = 5;
		return <DashboardGoogleMap mapZoom={mapZoom} mapCenter={mapCenter} mapData={this.state.checkedData} truckstate={this.state.truckState} mapViewMode={this.state.mapViewMode} truckInfo={this.state.truckInfo} location={this.props.history.location}
			mapFlag={this.state.mapFlag} envData={process.env.REACT_APP_BASE_URL} />
	}

	render() {
		const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
		var dashboard = {};
		var stopped = {};
		var idle = {};
		var active = {};
		if (this.props.dashboard.dashboard) {
			dashboard = this.props.dashboard.dashboard;
			stopped = dashboard.stopped;
			idle = dashboard.idle;
			active = dashboard.active;
		}
		if (this.state.isReady) {
			{ this.showBreadcrump() }
			return (
				<article className='dashboard-data shadow bg-white rounded'>
					<Row className='no-gutters'>
						<Col md={3} className='pos-rel'>
							{<GetCarrier state={this.state} userType={this.userType} onChange={(e) => { this.loadCarrierDashboardData(e) }} />}
							{<GetEld state={this.state} dashboard={this.props?.dashboard?.dashboard} eldList={this.props?.dashboard?.dashboard?.eldList} onChange={(e) => { this.selectELD(e) }} />}
							<DashboardTrucksComponent
								disabled={this.state.disabled} stopped={stopped} idle={idle} active={active} loading={this.props.loading}
								tabClicked={(val) => { this.handleTabClicked(val) }}
								truckClicked={(selectedTruck) => { this.handletruckClicked(selectedTruck) }}
								selectedData={this.state.selectedData} truckstate={this.state.truckState}
								checkList={(filteredEle) => { this.handlecheckList(filteredEle) }}
								_truckClick={(truckInfo) => { this._truckClick(truckInfo); }}
								loadloadVehicleicon={loadVehicleicon}
							/>
							{this.showBreadcrump()}
						</Col>
						<Col md={9}>
							{this.getDashboardMap(TOKEN)}
						</Col>
					</Row>
				</article>
			)
		} else {
			return (
				<div className='text-center'>
					<div className='spinner-border' role='status'>
						<span className='sr-only'>Loading...</span>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		dashboard: state.dashboardReducer.data,
		loading: state.dashboardReducer.loading
	}
}
export default connect(mapStateToProps)(Dashboard)