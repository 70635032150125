import { authHeader } from '../helpers/authHeader';
import {checkUnauthorized} from '../helpers/appUtil';
require('dotenv').config();


export const emailtemplatelistservice = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const EMAIL_DATA_API_ENDPOINT = BASE_URL+'emailtemplateresource/list?companyId='+localStorage.getItem('companyId')+'&templateTypeId='+request.payload.id;
    
    return fetch(EMAIL_DATA_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const updateTemplateStatus = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const EMAIL_TEMPLATE_DELETE_API = BASE_URL+'emailtemplateresource/delete?templateId='+request.payload.templateId
    return fetch(EMAIL_TEMPLATE_DELETE_API,{
        method: "POST",        
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);          
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const emailtemplatetypelist = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const EMAIL_DATA_API_ENDPOINT = BASE_URL+'emailtemplatetyperesource/list';
    
    return fetch(EMAIL_DATA_API_ENDPOINT, {
        method: 'POST',
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const getemailtemplatetypelist = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const USER_DATA_API_ENDPOINT = BASE_URL+'emailtemplatetyperesource/get?templateTypeId='+request.payload.id;
    
    return fetch(USER_DATA_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};



export const emailaccountlist = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const USER_DATA_API_ENDPOINT = BASE_URL+'carrieremailaccount/list';
    
    return fetch(USER_DATA_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};

export const verifiedemailaccountlist = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const USER_DATA_API_ENDPOINT = BASE_URL+'carrieremailaccount/list/verified';
    
    return fetch(USER_DATA_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};

export const getWeblinks = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const USER_DATA_API_ENDPOINT = BASE_URL+'weblinks/get?templateTypeId='+request;
    
    return fetch(USER_DATA_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};


export const getallWeblinks = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const USER_DATA_API_ENDPOINT = BASE_URL+'weblinks/getall';
    
    return fetch(USER_DATA_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};

export const getallTags = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const USER_DATA_API_ENDPOINT = BASE_URL+'templatetags/getall';
    
    return fetch(USER_DATA_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};


export const addNewTemplate = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const ADD_USER_API_ENDPOINT = BASE_URL+'emailtemplateresource/add';

    return fetch(ADD_USER_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);         
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const updateTemplate = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const ADD_USER_API_ENDPOINT = BASE_URL+'emailtemplateresource/update';

    return fetch(ADD_USER_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);         
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}