import React, { Component } from 'react';
import logo from '../../truckergig-logo.png';
import { connect } from 'react-redux';
import { MailSendForgetPwd,ForgetPwdFlag } from '../../actions/authActions';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../helpers/FormControls';
import { validate } from './forgtpwdvalidation';
import { toastr } from 'react-redux-toastr';
import { history } from '../../helpers/history';
import {appBaseUrl} from '../../helpers/appUtil';

require('dotenv').config();
function submit(values)
{
	//console.log(values);
	const frmData = {};
	frmData.useremail= (values.frgtpwdEmail) ? (values.frgtpwdEmail) : ("");
	this.props.dispatch(MailSendForgetPwd(frmData));
}
class Forgetpwd extends Component{
	componentDidMount() {
		document.title = appBaseUrl() + "Forget Password";
	}
	componentDidUpdate=()=>
	{
		if(this.props.forgetpwdflag===true)
		{
			if(this.props.forgetpaswdval.message==="Mail Send Sucessfully"){
				toastr.info('Info', "Please Check Your Mail");
				this.props.dispatch(ForgetPwdFlag())
				history.push("/login");
			}
			else if(this.props.forgetpaswdval.message==="EmailId is Not Found"){
				toastr.error('Error', "Please Enter Your Registered Email Id");
			}
		}
	}

	render(){
        const { handleSubmit } = this.props
		return(	
			<html lang="en">			
			<body>
			  <div className ="container">
			      <div className="mx-auto frgtpwd">
                         <div className="column columnclass">
						     <div className="img text-center" >
								<img src={logo} alt='Not Found'/>
							</div>
							<div className="text text-center" style ={{padding: '1rem'}}>
								<h5>Forgot Password? </h5>
							</div>
							<p>Please enter your registered email address</p>
							<form onSubmit={handleSubmit(submit.bind(this))}>
							    <div className="form-group">
                                    <Field type="text" className="form-control" controlClass="requiredfield" name="frgtpwdEmail" id="email" component={renderField} label="Email address" />                          
                                </div>
								<div className="row">
                                    <div className="col col-md-12">
                                    <div className="form-group btnresetpwd">
                                        <button type="submit" className="btn btn-primary btn-sm">Reset Password</button>&nbsp;</div>
                                    </div>
                                </div>
							</form>
						 </div>
				    </div>
              </div>		
			</body>
	    </html>
		)
	}
}


const mapStateToProps = state => {
return {
		data: state.authReducer.data,
		forgetpaswdval:state.authReducer.data,
        loading: state.authReducer.loading,
		error: state.authReducer.error,
		forgetpwdflag:state.authReducer.forgetpwdflag,
    }
}
export default connect(mapStateToProps) (reduxForm({
    form: 'forgetpwd',  
    validate                
})(Forgetpwd))