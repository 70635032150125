import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {getOnboardedCarrierService} from './../../services/companyService';
import {assetListServiceForTechService} from './../../services/assetService';
class TrucksList extends Component {
	constructor(props) {
		super(props);
		this.state={
			modaldata:[],
			carrierNames:[],
			assetList:[]
		 };	
	}
	componentDidMount() {	
		const params={				
			assettype: 'truck',
			carrierid:0		
		}
		assetListServiceForTechService(params).then((response)=>{
			let assetList=[];
			if(response && response.assetList){
				assetList=response.assetList;
			}
			this.setState({
				...this.state,
				assetList:assetList
			});
				
		});		
		
		getOnboardedCarrierService().then((response) =>{
			if(response.companyList){
				this.setState({
					...this.state,
					carrierNames:response.companyList
				});
			}
		});	
	}
	
	loadAssets =(e)=>{
		let carrierid = e.target.value;		
		const params={				
			assettype: 'truck',
			carrierid:carrierid		
		}				
		assetListServiceForTechService(params).then((response)=>{
			let assetList=[];
			if(response && response.assetList){
				assetList=response.assetList;
			}
			this.setState({
				...this.state,
				assetList:assetList
			});
				
		});
	}

	getCarrierDropdown = () => {
		return ( <div className="row">
		<div className="col-3">
			  <label>Select Carrier</label>
			  <select className="form-control" id="findCarrierId" onChange={(e) => {this.loadAssets(e)}}>
					<option value="0">All</option>
					{
						this.state.carrierNames.length>0?(this.state.carrierNames.map(function(company, index) { 
							return(
								<option key={index} value={company.Company_Id}>{company.Company_Name}</option>
						)          
						})):(<option>Loading.....</option>)
						} 
				</select>
		   </div>
		   </div>
		)
	}

	render(){
		// //console.log(this.props.trucks)
		var products=[];
		var indicationhtml = "No Data Found";		
		if(this.props.loading){
			 indicationhtml = 
			<div className="spinner-border" role="status">
			 {/* <td data-toggle="collapse" colspan="11" className="react-bs-table-no-data"></td> */}
			 <div className="row react-bs-table-no-data"></div>
			 </div>
		}
		else if(this.state.assetList){
			 products = this.state.assetList.map(
				row =>{
					return({
						id:row.Asset_Id,
						Truck:row.Asset_Number,
						Year:row.Model_Year,
						Make:row.Make,
						Model:row.Model,
						VIN:row.Identification_No,
						Plate:row.License_Plate_No,
						Color:row.Color,
						Company_Name:row.Company_Name
					});
				}
			);
		}
		else
		{
			products=[];
			 indicationhtml = "No Data Found";
		}
	
	   const { SearchBar } = Search;
       const columns=[	
			  {
				dataField: 'Company_Name',
				text: 'Company Name',
				sort: true
			  },			
		      {
				dataField: 'Truck',
				text: 'Asset #',
				sort: true
		      },
		      {
				dataField: 'Year',
				text: 'Year',
				sort: true
		      },
		      {
				dataField: 'Make',
				text: 'Make',
				sort: true
		      },
		      {
				dataField: 'Model',
				text: 'Model',
				sort: true
		      },
		      {
				dataField: 'VIN',
				text: 'VIN',
				sort: true
		      },
		      {
				dataField: 'Plate',
				text: 'Plate #',
				sort: true
		      },
		      {
				dataField: 'Color',
				text: 'Color',
				sort: true
		      }]
			  const customTotal = (from, to, size) => (
				<span className="react-bootstrap-table-pagination-total">
				  Showing { from } to { to } of { size } Results
				</span>
			  );
			  
			  const options = {
				paginationSize: 4,
				pageStartIndex: 1,
				firstPageText: 'First',
				prePageText: 'Back',
				nextPageText: 'Next',
				lastPageText: 'Last',
				nextPageTitle: 'First page',
				prePageTitle: 'Pre page',
				firstPageTitle: 'Next page',
				lastPageTitle: 'Last page',
				showTotal: true,
				paginationTotalRenderer: customTotal
			  };
		return(
			<article className="table-data truck-data shadow bg-white rounded">
				
				<div className="row no-gutters">					
					<div className="col-md-12">
						<ul className="nav nav-tabs" id="myTrucks" role="tablist">
							  <li className="nav-item">
								<a className="nav-link active" id="mytruck-tab" data-toggle="tab" href="#mytruck" role="tab" aria-controls="mytruck" aria-selected="true"><i className="icofont-truck-loaded"></i> My Trucks</a>
							  </li>
						</ul>
						{this.getCarrierDropdown()}
						<div className="tab-content" id="myTabContent">
							  <div className="tab-pane fade show active" id="mytruck" role="tabpanel" aria-labelledby="mytruck-tab">								 
									    <ToolkitProvider
											keyField="id"
											data={ products }
											columns={ columns }
											search
										 >
											{
											props => (
												<div>
												<SearchBar { ...props.searchProps } />
												<BootstrapTable
													{ ...props.baseProps } noDataIndication={indicationhtml}  pagination={ paginationFactory(options) } hover bordered={ false }/>
												</div>
											)
											}
										</ToolkitProvider>
							  </div>
							  <div className="tab-pane fade" id="newtruck" role="tabpanel" aria-labelledby="newtruck-tab">								 
							  </div>	
						</div>
					</div>
				</div>	
			</article>
		)
	}	
}	
export default TrucksList
