import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { shipperandlanedetails } from '../../../actions/loadActions';
import { STATUS } from '../../../helpers/status';
import AddLoadPopup from './AddLoadPopup';
import LoadList from './LoadList';
import UniversalLoadEditcopy from './UniversalLoadEditcopy';


class Loads extends Component{

	constructor(props){
		super(props);
		this.myloads=[];
		this.requested = [];
		this.accepted = [];
		this.tendered = [];
		this.delivery_uc = [];
		this.delivery_c = [];
		this.paid_close = [];		
		this.currentStatus = STATUS.LOAD.MYLOADS;
		this.loadLists=[];
		this.state={editLoadValues:''}
	}
		
	componentDidMount() {		
        document.title = 'Loads';
		this.props.dispatch(shipperandlanedetails());
	}
	callbackFunction = (childData) => {
		console.log('childData',childData)
			this.setState({editLoadValues: childData})
	}
	callbackFunctionFromLoadList = (childData) => {
		console.log('childData',childData)
		this.setState({editLoadValues: childData})
}
	componentDidUpdate()
	{
        // if(this.props.data.status=="CREATED")
        // {              
        //     toastr.success('Success', 'Load has been Successfully Created!');
		// 	const params={
		// 		...STATUS.LOAD.REQUESTED
		// 	}
		// 	this.props.dispatch(loadActionfrquickdispatch(params));
		// 	document.getElementById('addmodalpopup').click()
        // }
        // else if(this.props.data.status=="ALREADY_REPORTED")
        // {
        //     toastr.error('Error', 'Load Already Exists!');
           
        // }
    }
	setLoadEditValues= async(val) => {
		console.log('loads.js',val)
		await this.setState({
			editableValues: val,
		})
}
	render(){		
		if(this.props.data.status === 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}
		return(
			<div className="wrapper">
				<Fragment>		
				    <LoadList editloadvalues={(val) => { this.setLoadEditValues(val) }} parentCallback = {this.callbackFunction} carriernamelist={this.props.carriernamelist} state={this.props.statelist} loadpoint={this.props.loadpoint} trucktypelist={this.props.trucktypelist} shippernameAndLane={this.props.ShipperNameAndLaneDetails}/>									
					<AddLoadPopup state={this.props.statelist} citylist={this.props.citylist} loadpoint={this.props.loadpoint} carriernamelist={this.props.carriernamelist} trucktypelist={this.props.trucktypelist} shippernameAndLane={this.props.ShipperNameAndLaneDetails}/>					
					<UniversalLoadEditcopy editLoadValues={this.state.editLoadValues} state={this.props.statelist} citylist={this.props.citylist} loadpoint={this.props.loadpoint} carriernamelist={this.props.carriernamelist} trucktypelist={this.props.trucktypelist} shippernameAndLane={this.props.ShipperNameAndLaneDetails}/>					
				</Fragment>		
			</div>
		)
	}
}
const mapStateToProps = state => {
	return {
	data: state.loadReducer.data,
	ShipperNameAndLaneDetails:state.loadReducer.Shipperandlanedetails.companyTypeList,
    loading: state.loadReducer.loading,
	error: state.loadReducer.error,
	quickdispatchloads:state.loadReducer.data,
	}
}

export default connect(mapStateToProps)(Loads)