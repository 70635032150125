import React, { Component } from 'react';
import { TypoGraph } from '../muiComponents';
import Modal from 'react-bootstrap/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import connectImg from '../assets/icons/connect.svg'
import { ListItemButton, ListItemIcon } from '@mui/material';
import Icon from '../muiComponents/icon';
import Alert from '@mui/material/Alert';
import verified from '../verifiedELD.png';
import verifying from '../VerificationInProgress.png';
import { verifyCredentialsAndSignLOA, completeSignUPCarriers, tcWidgetVerifyCredentialsAndSignLOA, oAuthVerifyingCredentialsAndSignLOA, oAuthVerifyingCredentialsAndSignLOAInWidget, completeTCWidgetSignUPCarriers } from '../actions/companyActions';
import { connect } from 'react-redux';
import notVerified from '../VerificationFailedELD.png';
import { toastr } from 'react-redux-toastr';
import ReactTooltip from 'react-tooltip';
import Grid from '@mui/material/Unstable_Grid2';
import { ButtonToolbar, ButtonGroup, Button, InputGroup, Form } from 'react-bootstrap';
import ResponsiveSubtitle from '../muiComponents/typograph/ResponsiveSubtitle';
import CloseIcon from '@mui/icons-material/Close';
import { GetCookie, SetCookie, DeleteCookie } from './Cookies';
import OAuthRedirectLogic from './onboarding/oAuth/OAuthRedirectLogic';
import CompanyPostOnboarding from './onboarding/CompanyPostOnboarding';
import { getUrlParams } from './appUtil';

const style = {
  pt: { xs: '0', md: '6'},
  px: { xs: '0', md: '6'},
  pb: { xs: '0', md: '3'},
  suggestedListBtn: {
    height: 50,
    borderRadius: 2,
    marginTop: 1,
    marginBottom: 1,
    opacity: '.5',
    cursor: 'default'
  },
  suggestedListBtn2: {
    height: 50,
    borderRadius: 2,
    marginTop: 1,
    marginBottom: 1,
  },
  listBtn: {
    height: 50,
    borderRadius: 2,
    marginTop: { xs:'0', md: '1' },
    marginBottom: { xs: '0', md: '1' },
    paddingTop: { xs:'0', md: '1' },
    paddingBottom: { xs: '0', md: '1' },
    cursor: 'default'
  },
  mainDataHeader: {
    fontFamily: 'poppins',
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
    letterSpacing: "2.3px",
    color: "#212121",
    textTransform: "uppercase",
    marginLeft: '1rem'
  },
  mainDataHeader2: {
    fontFamily: 'poppins',
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
    letterSpacing: "2.3px",
    color: "#212121",
    textTransform: "uppercase",
  },
  firstListIcon: {
    justifyContent: 'center',
    marginTop: '-.75rem'
  },
  secondListIcon: {
    minWidth: 0,
    justifyContent: 'center',
    marginLeft: { xs: '10px', md: '20px' },
    marginRight: { xs: '0px', md: '10px' }
  },
  alertText: {
    display: 'flex',
    justifyContent: 'center',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    color: '#014361'
  },
  alertELDText: {
    color: '#44a3db',
    marginLeft: '5px',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
  }
};
const eldTitle = {
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "16px",
  letterSpacing: "0.3px",
  color: "#212121",
  subTitle: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    letterSpacing: "0.2px",
    color: "#666666"
  }
}
const ONBOARDING_SCREENS = {
  STARTING_SCREEN: 0,
  PERMISSIONS_DETAIL: 1,
  AUTHENTICATION_PARAMS: 2,
  OPERATION_RESULT: 3
}
var tempELDData = [];
class ELDVerificationModal extends Component {
  constructor(props) {
    super(props);
    const companyId = this.props.state.invitationData.invitationInfo.invitingCompanyId
    let oAuth = this.props.state.checkedData[0].oAuth.find(o => o.companyId === companyId)
    let oAuthAvailable = this.selectedEldValidForOAuth(props.state.checkedData[0], props.state.invitationData.invitationInfo, oAuth) ?? false;
    this.state = {
      withOAuth: oAuthAvailable,
      selectedELD: 0, // (oAuthAvailable === true) ? eldIndex : 0,
      selectedScreen: (oAuthAvailable === true && props.state.checkedData[0].eldVendor === (GetCookie('eldVendor', true) ?? '')) ? props.preselectedScreen :  ONBOARDING_SCREENS.STARTING_SCREEN,
      eldDataArr: this.props.state.checkedData,
      tabData: this.props.state.checkData,
      isShowNextButton: false,
      showVerificationStatusText: 'Verifying Credentials...',
      disableInput: [],
      preparedELDData: this.prepareELDData(this.props.state),
      invitationData: this.props.state,
      verifiedELDData: [],
      dynamicImage: (oAuthAvailable === true && props.dynamicImage === 'verifying') ? verifying : null,
    }
    let originCookie = GetCookie('origin', true);
    if (originCookie === null || originCookie === undefined || originCookie !== 'widget')
      SetCookie('origin', props.origin, 0.1, true);
  }

  componentDidMount() {
    let inputDisable = [];
    const iData = this.state.invitationData.invitationData
    console.log(this.state.eldDataArr)
    const eldSelected = this.state.eldDataArr[this.state.selectedELD]
    
    // OAuth -----
    const companyId = this.props.state.invitationData.invitationInfo.invitingCompanyId
    const eldInfo = this.state.eldDataArr[this.state.selectedELD]
    const oAuth = eldInfo.oAuth.find(o => o.companyId === companyId)
    let eldValidForOAuth = this.selectedEldValidForOAuth(eldSelected, iData.invitationInfo, oAuth)
    if (eldValidForOAuth === true && this.props.returnFromOAuth && this.props.returnFromOAuth === true) {
      let eldDataSharing = GetCookie('eldDataSharing', true) ?? '';
      if (eldDataSharing && eldDataSharing !== '') {
        this.state.eldDataArr[this.state.selectedELD].eldDataSharing = eldDataSharing;
        DeleteCookie('eldDataSharing', true);
      }
      this.setState({ isCallAPI: true })
      this.verifyCredentials(this.state.selectedELD, this.state.preparedELDData[this.state.selectedELD])
    }
    // -----------
  }
  selectedEldValidForOAuth(eldSelected, invitationInfo, oAuth) {
    let result = eldSelected && invitationInfo && eldSelected.oAuth && eldSelected.oAuth.length > 0 && invitationInfo.canUseOAuth && invitationInfo.canUseOAuth === true && oAuth && oAuth !== undefined && oAuth !== null;
    return result
  }
  clickAction() {
    window.location.href = process.env.REACT_APP_REDIRECT_URL;
  }
  prepareELDData(dataToBePrepared) {
    var preparedTabData = []
    dataToBePrepared.checkedData.forEach((element, index) => {
      if (element && element.overallStatus && (element.overallStatus === 'VERIFIED' || element.eldVendor === 'TruckX')) {
        preparedTabData.push({ 
          ...element, 
          verifyingImage: '', 
          eldIndex: index, 
          overallStatus: 'VERIFIED', 
          disableELDFormFields: false, 
          isUtilizeCredentials: false, 
          isDisableVerifyBtn: true, 
          isInvalidCredentials: false, 
          methodName: (index) => { this.setIndex(index) },
          isDataShared: false 
        })
      }
      else {
        preparedTabData.push({
          ...element, 
          verifyingImage: '', 
          eldIndex: index, 
          overallStatus: 'NOT_VERIFIED', 
          attemptsLeft: 3, 
          disableELDFormFields: false, 
          isUtilizeCredentials: true, 
          isDisableVerifyBtn: false, 
          isInvalidCredentials: false, 
          methodName: (index) => { this.setIndex(index) }, 
          isDataShared: false
        })
      }
    });
    return preparedTabData;
  }
  handleInput(e, item, i) {
    console.log(e, item, i)
    item.isInvalidCredentials = false;
    if (item.eldVendor === "Samsara") {
      item.eldMetadataParams.forEach((element, index) => {
        if (element.paramName === e.target.id) {
          element.samsaraAPIKeyValue = e.target.value.trim()
          element.paramValue = 'samsara_api_' + e.target.value.trim()
        }
      });
    }
    else {
      item.eldMetadataParams.forEach((element, index) => {
        if (element.paramName === e.target.id) {
          element.paramValue = e.target.value.trim()
        }
      });
    }
    this.setState({ preparedELDData: [item] })
  }

  handleExitingEld(index) {
    this.setState(state => {
      const list = state.preparedELDData.map((item, j) => {
        if (item.eldProviderId === index) {
          item.isUtilizeCredentials = !item.isUtilizeCredentials;
          item.isDisableVerifyBtn = !item.isDisableVerifyBtn;
        } else {
          return item;
        }
      });
      return {
        list,
      };
    });
  }

  preventCheck(e) {
    if (e.target.checked === true) {
      e.target.checked = false
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      return false;
    }
    e.target.checked = true
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    return false;
  }

  signUP = () => {
    if (this.props.isWidget) {
      this.props.dispatch(completeTCWidgetSignUPCarriers(this.state, this.props.state));
    }
    else {
      this.props.dispatch(completeSignUPCarriers(this.state, this.props.state));
    }
  }

  async componentWillReceiveProps(prevProps) {
    if (prevProps && prevProps.verifiedLOAData !== this.props.verifiedLOAData) {
      if (prevProps.verifiedLOAData && prevProps.verifiedLOAData.status === 'OK') {
        tempELDData.push(this.state.eldDataArr[0])
        this.state.eldDataArr[0].overallStatus = "VERIFIED";
        this.props.state.prevcheckedData.push(this.state.eldDataArr[0])
        await this.setState({
          dynamicImage: verified,
          isShowVerifyImg: true,
          showVerificationStatusText: 'Credentials Verified',
          showVerificationSubtittle: `Your ${this.state.selectedELDName} credentials have been verified.`,
          isClickedSignUP: false,
          disableVerifyBtn: true,
          isShowNextButton: false,
          screenCount: this.state.screenCount + 1,
          verifiedCount: this.state.verifiedCount + 1,
          isShowDoneButton: true,
          verifiedELDData: tempELDData
        }, () => {
          sessionStorage.setItem('fieldDisable', JSON.stringify(this.state.fieldDisable));
          sessionStorage.setItem('screenCount', this.state.screenCount);
        });
      }
      else if (prevProps.verifiedLOAData && (prevProps.verifiedLOAData.status === 'BAD_REQUEST' || prevProps.verifiedLOAData.status === 'UNAUTHORIZED' || prevProps.verifiedLOAData.status === 'INTERNAL_SERVER_ERROR')) {
        await this.setState({
          dynamicImage: notVerified,
          isShowVerifyImg: true,
          showVerificationStatusText: 'Credentials Failed',
          showVerificationSubtittle: `Your ${this.state.selectedELDName} credentials could not be verified. Please try again.`,
          isClickedSignUP: false,
          isShowingButton: true,
          disableVerifyBtn: true,
          isShowNextButton: true,
        });
      }
      else if (prevProps.verifiedLOAData && prevProps.verifiedLOAData.status === 'TOO_MANY_REQUESTS') {
        await this.setState({
          dynamicImage: notVerified,
          isShowVerifyImg: true,
          isOkayButton: true,
          showVerificationStatusText: 'Credentials Failed',
          showVerificationSubtittle: 'You have reached the maximum number of failed attempts of “ELD Verification”. Please click the invitation email to restart the verification.',
          isClickedSignUP: false,
          isShowingButton: true,
          disableVerifyBtn: true,
          isShowNextButton: true,
          isShowOKButton: true
        });
      }
    }
    else if (prevProps && prevProps.signedUPLOAData !== this.props.signedUPLOAData) {
      let invitingCompanyName = this.state.invitationData.invitationData.invitationInfo.invitingCompanyName
      let httpCode = prevProps.signedUPLOAData.code;
      let response;
      if (prevProps.signedUPLOAData.status === 'OK') {
        this.setState({ isSignedUp: true })
        toastr.success('Success!', prevProps.signedUPLOAData.message);
        this.sleep(10000);
        response = CompanyPostOnboarding(invitingCompanyName, true, httpCode);
      }
      else {
        toastr.error('Oops!', prevProps.signedUPLOAData.message);
        this.sleep(10000);
        response = CompanyPostOnboarding(invitingCompanyName, false, httpCode);
      }
      if (response !== null && response !== undefined) {
        if (response.status === false) {
          console.log(response.message)
        }
        DeleteCookie('companyState', true)
        DeleteCookie('successURL', true)
        DeleteCookie('failureURL', true)
        window.location.href = response.url;
      }
    }
    else {
      console.log('some API error')
    }
  }

  async verifyCredentials(i, itemData) {
    if (itemData.overallStatus !== 'VERIFIED') {
      let arr = this.state.disableInput.slice();
      this.setState({ disableInput: arr })
    }
    await this.setState({
      isClickedSignUP: false,
      selectedELDName: itemData.eldVendorDisplayName,
      showVerificationStatusText: 'Verifying Credentials...',
      showVerificationSubtittle: 'We are verifying credentials, this should only take a moment.',
      utilizeExistingEldCredentials: !this.state.utilizeExistingEldCredentials,
      enableError: true
    })
    if (itemData.overallStatus === 'VERIFIED' || itemData.eldVendor === 'TruckX') {
      itemData.utilizeExistingEldCredentials = true;
      itemData.isDisableNext = false;
      await this.setState({ 
        utilizeExistingEldCredentials: true, 
        selectedScreen: ONBOARDING_SCREENS.OPERATION_RESULT,
        isCallAPI: true, 
        dynamicImage: verifying, 
        isShowVerifyImg: true })
    }
    else {
      let insideItemArr = itemData.eldMetadataParams;
      let noofValidation = 0;
      outerloop: for (let insideElementIndex = 0; insideElementIndex < insideItemArr.length; insideElementIndex++) {
        if (insideItemArr[insideElementIndex].paramIsRequired && (insideItemArr[insideElementIndex].paramValue == null || insideItemArr[insideElementIndex].paramValue === undefined || insideItemArr[insideElementIndex]?.paramValue.trim() === '')) {
          this.setState(state => {
            const list = state.preparedELDData.map((item, j) => {
              if (item.eldVendorId === itemData.eldVendorId) {
                for (let index = 0; index < itemData.eldMetadataParams.length; index++) {
                  const element = itemData.eldMetadataParams[index];
                  element.isShowValidation = true;
                }
              }
            });
            return {
              list,
            };
          });
          break outerloop;
        }
        else {
          noofValidation++;
          if (insideItemArr.length === noofValidation) {
            await this.setState({
              isCallAPI: true, selectedScreen: ONBOARDING_SCREENS.OPERATION_RESULT, showModal: true,
              showVerificationSubtittle: 'We are verifying credentials, this should only take a moment.',
              showVerificationStatusText: 'Verifying Credentials...', verifyingImage: verifying, selectedELDName: itemData.eldVendorDisplayName, selectedELDImage: itemData.imagePath, currentELDData: itemData
            })
          }
        }
      }
    }
    
    if (this.state.isCallAPI) {
      await this.setState({ dynamicImage: verifying })
      const companyId = this.state.invitationData.invitationData.invitationInfo.invitingCompanyId
      const companyName = this.state.invitationData.invitationData.invitationInfo.invitingCompanyName
      if (this.state.withOAuth && this.state.withOAuth === true) {
        const oAuth = itemData.oAuth.find(oAuth => oAuth.companyId === companyId)
        if (oAuth && oAuth.clientId.length > 0 && this.props.returnFromOAuth && this.props.returnFromOAuth === true) {   // OAuth credentials process
          if (this.props.isWidget) {
            this.props.dispatch(oAuthVerifyingCredentialsAndSignLOAInWidget(this.state))
          }
          else {
            this.props.dispatch(oAuthVerifyingCredentialsAndSignLOA(this.state))
          }
        }
        else {
          if (this.props.isWidget) {
            this.props.dispatch(tcWidgetVerifyCredentialsAndSignLOA(this.state));
          }
          else {
            this.props.dispatch(verifyCredentialsAndSignLOA(this.state));
          }
        }
      }
      else {
        if (this.props.isWidget) {
          this.props.dispatch(tcWidgetVerifyCredentialsAndSignLOA(this.state));
        }
        else {
          this.props.dispatch(verifyCredentialsAndSignLOA(this.state));
        }
      }
      await this.setState({ isCallAPI: false })
    }
  }
  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  handleChecked(e, item, i) {
    if (e.target.id === 'hoursOfServiceData') {
      item.eldDataSharing[e.target.id] = !item.eldDataSharing[e.target.id]
      if (item.eldDataSharing[e.target.id] === true) {
        item.eldDataSharing.driverData = item.eldDataSharing[e.target.id]
        document.getElementById("driverData").checked = item.eldDataSharing[e.target.id]
      }
    }
    else {
      item.eldDataSharing[e.target.id] = !item.eldDataSharing[e.target.id]
      if (item.eldDataSharing[e.target.id] === false) {
        item.eldDataSharing.hoursOfServiceData = false
        document.getElementById("hoursOfServiceData").checked = false
      }
    }
  }

  evaluateOAuth() {
    const iData = this.state.invitationData.invitationData
    const companyId = iData.invitationInfo.invitingCompanyId
    const eldInfo = this.state.eldDataArr[this.state.selectedELD]
    const oAuth = eldInfo.oAuth.find(o => o.companyId === companyId)
    let oAuthAvailable = this.selectedEldValidForOAuth(this.state.eldDataArr[this.state.selectedELD], iData.invitationInfo, oAuth);
    return oAuthAvailable
  }

  authorizeOnboarding(showNextButton = null) {
    const iData = this.state.invitationData.invitationData
    const companyId = iData.invitationInfo.invitingCompanyId
    const companyName = iData.invitationInfo.invitingCompanyName
    const eldInfo = this.state.eldDataArr[this.state.selectedELD]
    const oAuth = eldInfo.oAuth.find(o => o.companyId === companyId)
    let oAuthAvailable = this.selectedEldValidForOAuth(this.state.eldDataArr[this.state.selectedELD], iData.invitationInfo, oAuth);
    if (oAuthAvailable === true) {
      this.state.eldDataArr[this.state.selectedELD].eldDataSharing.driverData = true
      this.state.eldDataArr[this.state.selectedELD].eldDataSharing.hoursOfServiceData = true
      // FIX C&F <-> Motive
      const isCfinsMotive = (this.state.eldDataArr[this.state.selectedELD].eldVendorDisplayName.includes('Motive') && companyName.toLowerCase() === 'crum & forster');
      const oAuthUrl = OAuthRedirectLogic(oAuth, eldInfo, this.state.selectedELD, isCfinsMotive)
      if (oAuthUrl && oAuthUrl.length > 0) {
        let origin = GetCookie('origin', true);
        if (origin !== null && origin !== undefined && origin === 'widget') {
          let urlParams = getUrlParams(window.location.href)
          if (urlParams && urlParams.state && urlParams.state !== "") {
            SetCookie('companyState', urlParams.state, 0.1, true)
            SetCookie('successURL', urlParams.success, 0.1, true)
            SetCookie('failureURL', urlParams.failure, 0.1, true)
          }
        }
        SetCookie('invitationkey', iData.invitationInfo.invitationToken, 0.1, true) // 0.1 hours (6 min)
        SetCookie('eldDataSharing', eldInfo.eldDataSharing, 0.1, true) // 0.1 hours (6 min)
        SetCookie('eldVendor', this.state.eldDataArr[this.state.selectedELD].eldVendor, 0.1, true)

        console.log('oAuthUrl: ' + oAuthUrl)
        window.location.href = oAuthUrl
      }
      else {
        alert('We couldn\'t generate your access to the ELD Login. Please try again later, or contact TruckerCloud support team.')
      }
    }
    else {
      if (!showNextButton || showNextButton === true) {
        this.setState({ selectedScreen: ONBOARDING_SCREENS.AUTHENTICATION_PARAMS });
      }
      else {
        this.setState({ selectedScreen: ONBOARDING_SCREENS.AUTHENTICATION_PARAMS, isShowNextButton: false })
      }
    }
  }

  render() {
    const isOAuth = this.evaluateOAuth()
    console.log('isOAuth: ' + isOAuth)
    let path = process.env.REACT_APP_CARRIER_API_URL + 'elds/logo/'
    // let path = 'https://carrierapi.truckercloud.com/api/v1/elds/logo/'
    return (
      <Modal 
        size="lg" 
        show={this.props.state.mainModalOpen} 
        onHide={() => null}
        centered={true}
      >
        <Modal.Body>
          <Box sx={style}>
            <div className="justify-content-end text-right">
              <Button variant="light" onClick={this.props.handleClose}>
                <CloseIcon />
              </Button>
            </div>
            {/* Logos header */}
            <Grid container spacing={0} sx={{marginBottom:'2rem'}}> 
              <Grid lg={4} lgOffset={1} sx={{ display: {xs:'none', lg:'flex'} }} justifyContent="center">
                <span className='logo1'>
                  <img src={this.props.state.logo} alt='Not Found' style={{ width: 'auto', height: '75px', marginLeft: 'auto', marginRight: 'auto' }} />
                </span>
              </Grid>
              <Grid lg={2} sx={{ display: {xs:'none', lg:'flex'} }} justifyContent="center" alignItems="center">
                <span className='logo2'>
                  <img src={connectImg} alt='Not Found' style={{ width: '32px', height: '32px' }} />
                </span>
              </Grid>
              <Grid xs={6} lg={4} xsOffset={3} lgOffset={0} display="flex" justifyContent="center">
                <span className='logo3'>
                  <img src={path + this.state.eldDataArr[this.state.selectedELD].eldVendor} alt='Not Found' style={{ height: '75px', maxWidth: '320px', marginLeft: 'auto', marginRight: 'auto' }} />
                </span>
              </Grid>
            </Grid>
            {/* Header texts for T&C */}
            {
              this.state.selectedScreen < ONBOARDING_SCREENS.OPERATION_RESULT ? 
                <Grid container sx={{ marginBottom:'2rem' }}>
                  <Grid xs={12}>
                    <Typography id="modal-modal-title" variant="h2" component="h1" align='center' sx={{ fontFamily: 'poppins', fontSize: '20px', fontWeight: '600' }}>
                      TruckerCloud will be able to connect to &nbsp;{this.state.eldDataArr[this.state.selectedELD].eldVendorDisplayName}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2, fontFamily: 'poppins', fontSize: '16px', fontWeight: '400', letterSpacing: '0.3px', color: '#212121' }} align='center'>
                      By authorizing, you agree to TruckerCloud's <a href={this.state.eldDataArr[this.state.selectedELD].tcURL} target='_blank' rel='noopener noreferrer' > <span className='cob-link '>Terms & Conditions </span> </a>
                    </Typography>
                  </Grid>
                </Grid>
                : null
            }
            {/* Modal Contents by screen */}
            {
              this.state.preparedELDData.map((tabcontent, tabIndex) => (
                this.state.selectedScreen === ONBOARDING_SCREENS.STARTING_SCREEN ?
                  <Grid container key={tabIndex} spacing={1} className='parent-row'>
                    {/* Required Data */}
                    <Grid xs={6} 
                      className='permission-left-side'
                      sx={{ paddingLeft: {
                          xs: '0',
                          md: '5rem'
                        } 
                      }}>
                      <Typography id="modal-modal-title" sx={style.mainDataHeader}>
                        Required Data
                      </Typography>
                      <ListItemButton sx={style.listBtn}>
                        <ListItemIcon
                          sx={style.firstListIcon}
                        >
                          <Icon name={'authorize'} width={25} height={25} />&ensp;&ensp;
                        </ListItemIcon>
                        <div className='listDivCss'>
                          <TypoGraph
                            variant="body1"
                            mb={0}
                            sx={eldTitle}
                          >
                            Authorization
                          </TypoGraph>
                          <ResponsiveSubtitle sx={eldTitle.subTitle}>
                            Authorization to retrieve ELD data
                          </ResponsiveSubtitle>
                        </div>
                      </ListItemButton>
                      <ListItemButton sx={style.listBtn}>
                        <ListItemIcon
                          sx={style.firstListIcon}
                        >
                          <Icon name={'location'} width={25} height={25} />&ensp;&ensp;
                        </ListItemIcon>
                        <div className='listDivCss'>
                          <TypoGraph
                            variant="body1"
                            mb={0}
                            sx={eldTitle}
                          >
                            Location Data
                          </TypoGraph>
                          <ResponsiveSubtitle sx={eldTitle.subTitle}>
                            GPS location data
                          </ResponsiveSubtitle>
                        </div>
                      </ListItemButton>
                      <ListItemButton sx={style.listBtn} >
                        <ListItemIcon
                          sx={style.firstListIcon}
                        >
                          <Icon name={'vehicle'} width={25} height={25} />&ensp;&ensp;
                        </ListItemIcon>
                        <div className='listDivCss'>
                          <TypoGraph
                            variant="body1"
                            mb={0}
                            sx={eldTitle}
                          >
                            Vehicle Data
                          </TypoGraph>
                          <ResponsiveSubtitle sx={eldTitle.subTitle}>
                            Critical vehicle data (Make, Model, VIN)
                          </ResponsiveSubtitle>
                        </div>
                      </ListItemButton>
                    </Grid>
                    {/* Suggested Data */}
                    <Grid xs={6} className='permission-right-side' 
                      sx={{ paddingLeft: {
                          xs: '0',
                          md: '5rem'
                        } }}>
                      <Typography id="modal-modal-title" sx={style.mainDataHeader}>
                        Suggested Data
                      </Typography>
                      <ListItemButton sx={style.listBtn}>
                        <ListItemIcon
                          sx={style.firstListIcon}
                        >
                          <Icon name={'driver'} width={25} height={25} />&ensp;&ensp;
                        </ListItemIcon>
                        <div className='listDivCss'>
                          <TypoGraph
                            variant="body1"
                            mb={0}
                            sx={eldTitle}
                          >
                            Driver Data
                          </TypoGraph>
                          <ResponsiveSubtitle sx={eldTitle.subTitle}>
                            Driver's ID, Email, Phone
                          </ResponsiveSubtitle>
                        </div>
                      </ListItemButton>
                      <ListItemButton sx={style.listBtn}>
                        <ListItemIcon
                          sx={style.firstListIcon}
                        >
                          <Icon name={'clock'} width={25} height={25} />&ensp;&ensp;
                        </ListItemIcon>
                        <div className='listDivCss'>
                          <TypoGraph
                            variant="body1"
                            mb={0}
                            sx={eldTitle}
                          >
                            Hours of Service
                          </TypoGraph>
                          <ResponsiveSubtitle sx={eldTitle.subTitle}>
                            Hours of Service
                          </ResponsiveSubtitle>
                        </div>
                      </ListItemButton>
                      <ListItemButton sx={style.listBtn}>
                        <ListItemIcon
                          sx={style.firstListIcon}
                        >
                          <Icon name={'engine'} width={25} height={25} />&ensp;&ensp;
                        </ListItemIcon>
                        <div className='listDivCss'>
                          <TypoGraph
                            variant="body1"
                            mb={0}
                            sx={eldTitle}
                          >
                            Engine Data
                          </TypoGraph>
                          <ResponsiveSubtitle sx={eldTitle.subTitle}>
                            Diagnostics & maintenance
                          </ResponsiveSubtitle>
                        </div>
                      </ListItemButton>
                      <ListItemButton sx={style.listBtn}>
                        <ListItemIcon
                          sx={style.firstListIcon}
                        >
                          <Icon name={'safety'} width={25} height={25} />&ensp;&ensp;
                        </ListItemIcon>
                        <div className='listDivCss'>
                          <TypoGraph
                            variant="body1"
                            mb={0}
                            sx={eldTitle}
                          >
                            Safety
                          </TypoGraph>
                          <ResponsiveSubtitle sx={eldTitle.subTitle}>
                            Safety events
                          </ResponsiveSubtitle>
                        </div>
                      </ListItemButton>
                    </Grid>
                  </Grid>
                : this.state.selectedScreen === ONBOARDING_SCREENS.PERMISSIONS_DETAIL ?
                  <Grid container key={tabIndex} spacing={1} className='parent-row'>
                    {/* Required Data */}
                    <Grid xs={6} className='permission-left-side' 
                      sx={{ paddingLeft: {
                          xs: '0',
                          md: '5rem'
                        } }}>
                      <Typography id="modal-modal-title" sx={style.mainDataHeader}>
                        Required Data
                      </Typography>
                      {/* Authorization */}
                      <ListItemButton sx={style.listBtn}>
                        <ListItemIcon
                          sx={style.firstListIcon}
                        >
                          <Icon name={'authorize'} width={25} height={25} />&ensp;&ensp;
                        </ListItemIcon>
                        <div className='listDivCss'>
                          <TypoGraph
                            variant="body1"
                            mb={0}
                            sx={eldTitle}
                          >
                            Authorization
                          </TypoGraph>
                          <ResponsiveSubtitle sx={eldTitle.subTitle}>
                            Authorization to retrieve ELD data
                          </ResponsiveSubtitle>
                        </div>
                      </ListItemButton>
                      {/* Location Data */}
                      <ListItemButton sx={style.listBtn}>
                        <ListItemIcon
                          sx={style.firstListIcon}
                        >
                          <Icon name={'location'} width={25} height={25} />&ensp;&ensp;
                        </ListItemIcon>
                        <div className='listDivCss'>
                          <TypoGraph
                            variant="body1"
                            mb={0}
                            sx={eldTitle}
                          >
                            Location Data
                          </TypoGraph>
                          <ResponsiveSubtitle sx={eldTitle.subTitle}>
                            GPS location data
                          </ResponsiveSubtitle>
                        </div>
                      </ListItemButton>
                      {/* Vehicle Data */}
                      <ListItemButton sx={style.listBtn} >
                        <ListItemIcon
                          sx={style.firstListIcon}
                        >
                          <Icon name={'vehicle'} width={25} height={25} />&ensp;&ensp;
                        </ListItemIcon>
                        <div className='listDivCss'>
                          <TypoGraph
                            variant="body1"
                            mb={0}
                            sx={eldTitle}
                          >
                            Vehicle Data
                          </TypoGraph>
                          <ResponsiveSubtitle sx={eldTitle.subTitle}>
                            Critical vehicle data (Make, Model, VIN)
                          </ResponsiveSubtitle>
                        </div>
                      </ListItemButton>
                    </Grid>
                    {/* Suggested Data */}
                    <Grid xs={6} className='permission-right-side' 
                      sx={{ paddingLeft: {
                          xs: '0',
                          md: '5rem'
                        } }}>
                      <Typography id="modal-modal-title" sx={style.mainDataHeader}>
                        Suggested Data
                      </Typography>
                      {/* Driver Data */}
                      <ListItemButton sx={!tabcontent.eldDataSharing.driverDataEnabled ? style.suggestedListBtn : style.suggestedListBtn2} data-tip data-for='tool-tip1'>
                        <div className="form-check checkbox-lg">
                          <input className='form-check-input'
                            type='checkbox'
                            id='driverData'
                            defaultChecked={tabcontent.eldDataSharing.driverData || isOAuth === true}
                            readOnly={!tabcontent.eldDataSharing.driverDataEnabled || tabcontent.disableELDFormFields}
                            onChange={(e) => { tabcontent.eldDataSharing.driverDataEnabled && !tabcontent.eldDataSharing.hoursOfServiceData && isOAuth === false ? this.handleChecked(e, tabcontent, tabIndex) : this.preventCheck(e, tabIndex, tabcontent) }} />
                        </div>
                        <ListItemIcon
                          sx={style.secondListIcon}
                        >
                          <Icon name={'driver'} width={25} height={25} />&ensp;&ensp;
                        </ListItemIcon>
                        <div className='listDivCss'>
                          <TypoGraph
                            variant="body1"
                            mb={0}
                            sx={eldTitle}
                          >
                            Driver Data
                          </TypoGraph>
                          <ResponsiveSubtitle sx={eldTitle.subTitle}>
                            Driver's ID, Email, Phone
                          </ResponsiveSubtitle>
                        </div>
                        {
                          !tabcontent.eldDataSharing.driverDataEnabled || isOAuth === true ? 
                            <ReactTooltip id='tool-tip1' place='right' arrowColor='var(--main-color)'>
                              Driver Data sharing is disabled for the ELD
                            </ReactTooltip> 
                            : null
                        }
                      </ListItemButton>
                      {/* Hours of Service */}
                      <ListItemButton sx={!tabcontent.eldDataSharing.hoursOfServiceDataEnabled ? style.suggestedListBtn : style.suggestedListBtn2} data-tip data-for='tool-tip2'>
                        <div className="form-check checkbox-lg">
                          <input className='form-check-input'
                            type='checkbox'
                            id='hoursOfServiceData'
                            defaultChecked={tabcontent.eldDataSharing.hoursOfServiceData || isOAuth === true}
                            readOnly={!tabcontent.eldDataSharing.hoursOfServiceDataEnabled || tabcontent.disableELDFormFields}
                            onChange={(e) => { tabcontent.eldDataSharing.hoursOfServiceDataEnabled && isOAuth === false ? this.handleChecked(e, tabcontent, tabIndex) : this.preventCheck(e, tabIndex, tabcontent) }} />
                        </div>
                        <ListItemIcon
                          sx={style.secondListIcon}
                        >
                          <Icon name={'clock'} width={25} height={25} />&ensp;&ensp;
                        </ListItemIcon>
                        <div className='listDivCss'>
                          <TypoGraph
                            variant="body1"
                            mb={0}
                            sx={eldTitle}
                          >
                            Hours of Service
                          </TypoGraph>
                          <ResponsiveSubtitle sx={eldTitle.subTitle}>
                            Hours of Service
                          </ResponsiveSubtitle>
                        </div>
                        {!tabcontent.eldDataSharing.hoursOfServiceDataEnabled || isOAuth === true ? <ReactTooltip id='tool-tip2' place='right' arrowColor='var(--main-color)'>
                          Hours of Service Data sharing is disabled for the ELD
                        </ReactTooltip> : null}
                      </ListItemButton>
                      {/* Engine Data */}
                      <ListItemButton sx={!tabcontent.eldDataSharing.engineDataEnabled ? style.suggestedListBtn : style.suggestedListBtn2} data-tip data-for='tool-tip3'>
                        <div className="form-check checkbox-lg">
                          <input className='form-check-input'
                            type='checkbox' id='engineData'
                            defaultChecked={tabcontent.eldDataSharing.engineData}
                            readOnly={!tabcontent.eldDataSharing.engineDataEnabled || tabcontent.disableELDFormFields}
                            onChange={(e) => { tabcontent.eldDataSharing.engineDataEnabled && isOAuth === false ? this.handleChecked(e, tabcontent, tabIndex) : this.preventCheck(e, tabIndex, tabcontent) }} />
                        </div>
                        <ListItemIcon
                          sx={style.secondListIcon}
                        >
                          <Icon name={'engine'} width={25} height={25} />&ensp;&ensp;
                        </ListItemIcon>
                        <div className='listDivCss'>
                          <TypoGraph
                            variant="body1"
                            mb={0}
                            sx={eldTitle}
                          >
                            Engine Data
                          </TypoGraph>
                          <ResponsiveSubtitle sx={eldTitle.subTitle}>
                            Diagnostics & maintenance
                          </ResponsiveSubtitle>
                        </div>
                        {!tabcontent.eldDataSharing.engineDataEnabled || isOAuth === true ?
                          <ReactTooltip id='tool-tip3' place='right' arrowColor='var(--main-color)'>
                            Engine Data sharing is disabled for the ELD
                          </ReactTooltip> : null}
                      </ListItemButton>
                      {/* Safety */}
                      <ListItemButton sx={style.suggestedListBtn} data-tip data-for='tool-tip4'>
                        <div className="form-check checkbox-lg">
                          <input className='form-check-input'
                            type='checkbox'
                            id='safetyData'
                            defaultChecked={tabcontent.eldDataSharing.safetyData}
                            readOnly={!tabcontent.eldDataSharing.safetyDataEnabled || tabcontent.disableELDFormFields}
                            onChange={(e) => { tabcontent.eldDataSharing.safetyDataEnabled && isOAuth === false ? this.handleChecked(e, tabcontent, tabIndex) : this.preventCheck(e, tabIndex, tabcontent) }}
                          />
                        </div>
                        <ListItemIcon
                          sx={style.secondListIcon}
                        >
                          <Icon name={'safety'} width={25} height={25} />&ensp;&ensp;
                        </ListItemIcon>
                        <div className='listDivCss'>
                          <TypoGraph
                            variant="body1"
                            mb={0}
                            sx={eldTitle}
                          >
                            Safety
                          </TypoGraph>
                          <ResponsiveSubtitle sx={eldTitle.subTitle}>
                            Safety events
                          </ResponsiveSubtitle>
                        </div>
                        <ReactTooltip id='tool-tip4' place='right' arrowColor='var(--main-color)'>
                          Safety events Data sharing is disabled for the ELD
                        </ReactTooltip>
                      </ListItemButton>
                    </Grid>
                  </Grid>
                : this.state.selectedScreen === ONBOARDING_SCREENS.AUTHENTICATION_PARAMS ?
                  <div key={tabIndex}>
                    {
                    tabcontent.eldVendor !== 'TruckX' ?
                      <Grid container spacing={1}>
                        {
                          tabcontent.overallStatus === 'VERIFIED' && (!tabcontent.disableELDFormFields || !tabcontent.ShowInputField)
                            ?// Verified ELD's Field Data
                            <Grid display="flex" justifyContent="center" alignItems="center">
                              <div className='form-check-header-data checkbox-lg3'>
                                <input className='form-check-input'
                                  type='checkbox'
                                  id='exiteldChcekbox'
                                  readOnly={tabcontent.disableELDFormFields}
                                  defaultChecked={tabcontent.isUtilizeCredentials}
                                  onChange={(e) => { this.handleExitingEld(this.state.eldDataArr[this.state.selectedELD].eldProviderId) }} />
                                <label className='div-content' >&nbsp;
                                  Utilize my existing ELD provider credentials
                                </label>
                              </div>
                            </Grid>
                          : tabcontent.overallStatus === 'VERIFIED' || tabcontent.overallStatus === 'ERROR' && (!tabcontent.disableELDFormFields || tabcontent.ShowInputField)
                            ?
                            <>
                              {
                                tabcontent.eldMetadataParams.map((innsideItem, j) => {
                                  if (innsideItem.paramIsVisible && innsideItem.paramIsVisible === true) {
                                    return (
                                      <Grid xs={6} sm={4} lg={2} key={j} display="flex" justifyContent="center" alignItems="center">
                                      {
                                        tabcontent.eldVendor === 'Samsara' ?
                                          //Samsara ELD
                                          <div className='newCol2' style={{ paddingLeft: '2rem'}}>
                                            <InputGroup className="mb-3">
                                              <InputGroup.Text id="basic-addon1">samsara_api_</InputGroup.Text>
                                              <Form.Control
                                                className='form-control form-input2 samasarlabelCssForVerifying2 requiredfield json-col'
                                                placeholder={innsideItem.paramIsRequired ? innsideItem.paramName + ' *' : innsideItem.paramName}
                                                id={innsideItem.paramName}
                                                onChange={(e) => { this.handleInput(e, tabcontent, this.props.j) }}
                                                readOnly={tabcontent.disableELDFormFields}
                                                disabled={tabcontent.disableELDFormFields}
                                                value={innsideItem.paramDefaultValue ? innsideItem.paramDefaultValue : innsideItem.samsaraAPIKeyValue}
                                              />
                                            </InputGroup>
                                          </div>
                                          //Other ELDs
                                          : <div className='form-group'>
                                              <input type={innsideItem?.paramName.includes('password') ? 'password' : 'text'}
                                                className='form-control form-input2  requiredfield json-col'
                                                placeholder={innsideItem.paramIsRequired ?
                                                  innsideItem.paramName + ' *' : innsideItem.paramName}
                                                id={innsideItem.paramName}
                                                // readOnly={!innsideItem.paramIsRequired}
                                                onChange={(e) => { this.handleInput(e, tabcontent, this.props.j) }}
                                                value={innsideItem.paramDefaultValue ? innsideItem.paramDefaultValue : innsideItem.paramValue}
                                                readOnly={tabcontent.disableELDFormFields}
                                                disabled={tabcontent.disableELDFormFields}
                                              />
                                              {(innsideItem.isShowValidation && innsideItem.paramIsRequired && (innsideItem.paramValue === undefined || innsideItem.paramValue === null || innsideItem.paramValue === 'undefined' || innsideItem.paramValue === '')) && <span className='shadow-input2'>Please Fill Required data</span>}<br />
                                            </div>
                                        }
                                      </Grid>
                                    )
                                  }}
                                )}
                            </>
                            //Not Yet Verified ELD's Field Data
                            : tabcontent.eldMetadataParams.map((innsideItem, j) => {
                              if (innsideItem.paramIsVisible && innsideItem.paramIsVisible === true) {
                                return (
                                  <Grid key={j} display="flex" justifyContent="center" alignItems="center">
                                  {tabcontent.eldVendor === 'Samsara' ?
                                    //Samsara ELD
                                    <div id="divSamsara"
                                      style={{ marginLeft: '2rem'}}>
                                        <InputGroup className="mb-3">
                                          <InputGroup.Text id="basic-addon1">samsara_api_</InputGroup.Text>
                                          <Form.Control
                                            className='form-control form-input2 samasarlabelCssForVerifying2 requiredfield json-col'
                                            placeholder={innsideItem.paramIsRequired ? innsideItem.paramName + ' *' : innsideItem.paramName}
                                            id={innsideItem.paramName}
                                            readOnly={!innsideItem.paramIsRequired}
                                            onChange={(e) => { this.handleInput(e, tabcontent, this.props.j) }}
                                            value={innsideItem.paramDefaultValue ? innsideItem.paramDefaultValue : innsideItem.samsaraAPIKeyValue}
                                          />
                                        </InputGroup>
                                    </div>
                                    //Other ELDs
                                    : <div className='form-group'>
                                        <input type={innsideItem?.paramName.includes('password') ? 'password' : 'text'}
                                          className='form-control form-input2  requiredfield json-col'
                                          placeholder={innsideItem.paramIsRequired ?
                                            innsideItem.paramName + ' *' : innsideItem.paramName}
                                          id={innsideItem.paramName}
                                          readOnly={!innsideItem.paramIsRequired}
                                          onChange={(e) => { this.handleInput(e, tabcontent, this.props.j) }}
                                          // value={innsideItem?.paramDefaultValue}
                                          value={innsideItem.paramDefaultValue ? innsideItem.paramDefaultValue : innsideItem.paramValue}
                                        />
                                      </div>
                                  }

                                  {(innsideItem.isShowValidation && innsideItem.paramIsRequired && (innsideItem.paramValue === undefined || innsideItem.paramValue === null || innsideItem.paramValue === 'undefined' || innsideItem.paramValue === '')) && <span className='shadow-input2'>Please Fill Required data</span>}<br />
                                  </Grid>
                                )
                              }}
                            )}
                      </Grid>
                      :
                      //Truckx ELD Field Data
                      <div className='form-check-header-data TruckX-Div checkbox-lg3'>
                        <input className='form-check-input'
                          type='checkbox'
                          id='exiteldChcekbox'
                          readOnly={tabcontent.disableELDFormFields}
                          defaultChecked={tabcontent.isUtilizeCredentials}
                          onChange={(e) => { this.handleExitingEld(this.state.eldDataArr[this.state.selectedELD].eldProviderId) }} />
                        <label className='div-content' >&nbsp;
                          Utilize my existing ELD provider credentials
                        </label>
                      </div>
                    }
                    <div style={{ marginTop: '1rem' }}>
                      <a href='https://truckercloud.zendesk.com/hc/en-us/articles/4415549969435' target='_blank' rel='noopener noreferrer' className='cob-link cob-href'>
                        <Alert sx={style.alertText} severity="info">Learn how to obtain this information from
                          <span style={style.alertELDText}>{this.state.eldDataArr[this.state.selectedELD].eldVendorDisplayName}&ensp;&ensp;
                          </span>
                          <Icon name={'credentials'} width={15} height={15} sx={{ color: 'blue' }} />
                        </Alert>
                      </a>
                    </div>
                  </div>
                :
                <div key={tabIndex}>
                  <Typography id="modal-modal-title" align='center' sx={{
                    fontSize: '20px',
                    color: '#212121',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '24px'
                  }}>
                    {this.state.showVerificationStatusText}
                  </Typography>
                  <Typography id="modal-modal-description" sx={{
                    mt: 2,
                    color: '#212121',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '16px',
                    lineHeight: '24px'
                  }}
                    align='center' variant="subtitle2">
                    {this.state.showVerificationSubtittle}
                  </Typography><br />
                  <div className='verifyImgDivCss'>
                    <img src={this.state.dynamicImage} alt={'alternate text'} className='verify-carriers-img' />
                  </div><br />
                </div>
              ))
            }
            {/* Buttons on Modal Bottom */}
            <div className='row eld-btn-ftr'>
              <div className='col col-md-12'>
                <div className='form-group form-group-btns justify-content-between'>
                  <ButtonToolbar
                    className="justify-content-between"
                    aria-label="Onboarding navigation actions"
                  >
                    <ButtonGroup aria-label="Left side buttons" size="md">
                    {
                      // this.state.selectedScreen === ONBOARDING_SCREENS.STARTING_SCREEN ?
                        // <Button variant="outline-primary" className="btn-primary1" onClick={this.props.handleClose}>Close</Button>
                      this.state.selectedScreen > ONBOARDING_SCREENS.PERMISSIONS_DETAIL && this.state.selectedScreen < ONBOARDING_SCREENS.OPERATION_RESULT ?
                        <Button variant="outline-secondary" className="btn-primary1" onClick={(e) => { this.setState({ 
                          selectedScreen: ((this.state.selectedScreen === ONBOARDING_SCREENS.AUTHENTICATION_PARAMS) ? ONBOARDING_SCREENS.STARTING_SCREEN : this.state.selectedScreen - 1)
                        }); }}>Back</Button>
                      : null
                    }
                    </ButtonGroup>
                    <ButtonToolbar 
                      className="justify-content-end"
                    >
                    <ButtonGroup aria-label="Right side buttons" size="md" style={{ marginRight: '1rem' }}>
                      {
                        this.state.selectedScreen === ONBOARDING_SCREENS.STARTING_SCREEN ?
                          <Button variant="outline-secondary" className="btn-primary1" onClick={(e) => { this.setState({ selectedScreen: ONBOARDING_SCREENS.PERMISSIONS_DETAIL }); }}>Permissions Management</Button>
                        : this.state.selectedScreen === ONBOARDING_SCREENS.PERMISSIONS_DETAIL ?
                          <Button variant="outline-secondary" className="btn-primary1" onClick={(e) => { this.setState({ selectedScreen: ONBOARDING_SCREENS.STARTING_SCREEN }); }}>Confirm Permissions</Button>
                        : null
                      }
                      </ButtonGroup>
                      <ButtonGroup aria-label="Right side buttons" size="lg">
                      {
                        this.state.selectedScreen < ONBOARDING_SCREENS.AUTHENTICATION_PARAMS ?
                          <Button variant="primary" onClick={(e) => this.authorizeOnboarding(e)}>Authorize</Button>
                        : this.state.selectedScreen > ONBOARDING_SCREENS.AUTHENTICATION_PARAMS && this.state.isShowNextButton && !this.state.isShowOKButton ?
                          <Button variant="primary" onClick={(e) => this.authorizeOnboarding(e, false)}>Try Again</Button>
                          : this.state.selectedScreen > ONBOARDING_SCREENS.AUTHENTICATION_PARAMS && this.state.isShowNextButton ?
                            <Button variant="primary" onClick={(e) => { this.clickAction() }}>Okay</Button>
                            : !this.state.isShowDoneButton && this.state.selectedScreen < ONBOARDING_SCREENS.OPERATION_RESULT ?
                              <Button variant="primary"
                                disabled={this.state.preparedELDData[this.state.selectedELD].isDisableVerifyBtn}
                                onClick={(e) => { this.verifyCredentials(this.state.selectedELD, this.state.preparedELDData[this.state.selectedELD]) }}>Verify Credentials</Button>
                              : null
                      }
                      </ButtonGroup>
                      {
                        this.state.selectedScreen === ONBOARDING_SCREENS.OPERATION_RESULT && this.state.isShowDoneButton ?
                        <>
                          <Button variant="primary" className="btn-lg" onClick={this.signUP}>Done</Button>
                          {
                            this.props.state.prevcheckedData.length < 5 ?
                              <Button variant="outline-primary" className="btn-primary1 addAnotherELD" style={{ borderRadius: '50px' }} onClick={this.props.handleClose}>Add Another ELD</Button> 
                              : null
                          }
                        </>
                        : null
                      }
                    </ButtonToolbar>
                  </ButtonToolbar>
                </div>
              </div>
            </div>
          </Box>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    verifiedLOAData: state.companyReducer.verifiedLOAData,
    signedUPLOAData: state.companyReducer.signedUPLOAData,
    loading: state.companyReducer.loading,
    searchFlag: state.companyReducer.searchFlag,
    error: state.companyReducer.error,
  }
}

export default connect(mapStateToProps)((ELDVerificationModal))