import React, { Component } from 'react';
import NewELDList from './NewELDList';
import $ from 'jquery';
import 'jquery-validation';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { addNewELD } from '../actions/assetAction';
import { Box } from '@mui/material';
import Modal from 'react-bootstrap/Modal';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

const style = {
  position: 'absolute',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: '0px 16px 24px 8px rgba(0, 0, 0, 0.25)',
  borderRadius: '9px',
  boxShadow: 24,
  outline: 'none'
};

class NewEld extends Component {
  state = {
    isSubmit: false,
    eldName: '',
    eldWebsiteUrl: '',
    eldCredentials: [
      {
        index: Math.random(),
        credType: "",
        credValue: ""
      }
    ]
  };
  handleChange = e => {
    if (
      ["credType", "credValue"].includes(
        e.target.name
      )
    ) {
      let eldCredentials = [...this.state.eldCredentials];
      eldCredentials[e.target.dataset.id][e.target.name] = e.target.value;
      this.setState(eldCredentials);

    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  addNewRow = e => {
    if (this.state.eldCredentials.length < 5)
      this.setState(prevState => ({
        eldCredentials: [
          ...prevState.eldCredentials,
          {
            index: Math.random(),
            credType: "",
            credValue: ""
          }
        ]
      }));
  };
  deleteRow = index => {
    this.setState({
      eldCredentials: this.state.eldCredentials.filter(
        (s, sindex) => index !== sindex
      )
    });
  };
  clickOnDelete(record) {
    this.setState({
      eldCredentials: this.state.eldCredentials.filter(r => r !== record)
    });
  }
  submitRequest = (event) => {
    event.preventDefault();
    $('#newEldForm').validate({
      rules: {
        eldName: {
          required: true
        },
        eldWebsiteUrl: {
          required: true
        },
        eldCredentials: {
          required: true
        }
      },
      messages: {
        eldName: {
          required: 'ELD name can not be blank.!'
        },
        eldWebsiteUrl: {
          required: 'Webite URL can not be blank.!'
        },
      },
      errorPlacement: function (error, element) {
        error.insertAfter(element);
      }
    });
    this.setState({ isSubmit: true })
    let eldData = this.state;
    eldData.invitationToken = this.props.state.invitationToken
    if ($('#newEldForm').valid()) {
      this.props.dispatch(addNewELD(eldData, this.props.state.invitationToken));
    };
  }
  componentWillReceiveProps(prevProps) {
    if (prevProps && prevProps.data !== this.props.data) {
      if (prevProps.data.status === "OK") {
        toastr.success('Success', prevProps.data.message);
        this.props.close()
        document.getElementById('newEldForm').reset()
        this.setState({ isSubmit: false,
          eldCredentials: [{
            index: Math.random(),
            credType: "",
            credValue: ""}] 
      })
      }
      else {
        toastr.error('Error', prevProps.data.message);
      }
    }
  }

  render() {
    return (
      <Modal id='newELDModal' show={this.props.state.newEldOpen} onHide={this.props.close} size="md" centered={true}>
        <Modal.Body style={{ padding: 0}}>
          {/* <Box sx={style}> */}
            <form onSubmit={this.submitRequest} onChange={this.handleChange} id='newEldForm'>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Add New ELD</h5>
                    <button type="button" className="close" data-dismiss="modal" id="modalclose" onClick={this.props.close} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className='modal-body'>
                    <Grid container spacing={0}>
                      <Grid md={6}>
                        <div className='form-group'>
                            <input
                                name={'eldName'}
                                id='eldName'
                                placeholder={'ELD Name'}
                                type={'text'}
                                className={'form-control json-col requiredfield'}
                            />
                        </div>
                      </Grid>
                      <Grid md={6}>
                        <div className='form-group'>
                            <input
                                name={'eldWebsiteUrl'}
                                id='eldWebsiteUrl'
                                placeholder={'ELD Website address'}
                                type={'url'}
                                className={'form-control json-col requiredfield'}
                            />
                        </div>
                      </Grid>
                    </Grid>
                    <div className='row row-label'>
                        <h5>ELD Credentials</h5>
                    </div>
                    <div className='row'>
                        <div className='col form-group'>
                            <NewELDList
                                add={this.addNewRow}
                                delete={this.clickOnDelete.bind(this)}
                                eldCredentials={this.state.eldCredentials}
                                isSubmit={this.state.isSubmit}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal-footer'>
                    <button type="submit" className='btn btn-primary btn-sm' disabled={this.props.loading}>Submit Request</button>
                    <button type='button' className='btn btn-primary btn-sm' onClick={this.props.close}>Close</button>
                </div>
            </form>
          {/* </Box> */}
        </Modal.Body>
    </Modal>
    );
  }
}
const mapStateToProps = state => {
  return {
    data: state.assetReducer.data,
    loading: state.assetReducer.loading
  }
}

export default connect(mapStateToProps)(NewEld);