import React from 'react';
import { TabsBar, TableDataComponent } from 'UI-Component';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { activeFuelAdvances, companyNameListAction, pastFuelAdvances, UpdateFactors } from '../../actions/companyActions';
import { toastr } from 'react-redux-toastr';
import CreateFuelAdvanceModal from './CreateFuelAdvanceModal';
import MaterialUITabs from '../uicomponents/DataTableGridMUIX';
import { Box } from '@mui/material';
const fuel_advance_columns = [
    {
        text: 'Invoice',
        dataField: 'Invoice_Id',
        headerStyle: { width: '80px' },
        sort: true
    },
    {
        text: 'Carrier Name',
        headerStyle: { width: '275px' },
        dataField: 'Carrier_Name',
        sort: true
    },
    {
        text: 'Asset No',
        dataField: 'Asset_No',
        headerStyle: { width: '120px' },
        sort: true
    },
    {
        text: 'Pickup Location',
        dataField: 'Pickup_Location',
        headerStyle: (colum, colIndex) => {
            return { width: '275px' };
          },
        sort: true
    },
    {
        text: 'Pickup Location Status',
        dataField: 'PU_LOC_Status',
        headerStyle: { width: '210px' },
        sort: true
    },
    {
        text: 'Scheduled Pickup Date & Time',
        dataField: 'Sced_PU_Time',
        headerStyle: { width: '260px' },
        sort: true
    },
    {
        text: 'Scheduled Pickup Time Status',
        dataField: 'SP_status',
        headerStyle: { width: '250px' },
        sort: true
    },
    {
        text: 'Action',
        dataField: 'Refresh_Action',
        headerStyle: { width: '100px' }
    }
];

class FuelAdvance extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isMenuOpen: false , tabIndex: 0}
        this.getIndex = this.getIndex.bind(this)
        this.toggleMenu = this.toggleMenu.bind(this)
    }
    componentDidMount = () => {
        this.getActiveFuelAdvances(0);
        this.props.dispatch(companyNameListAction());
    }
    getActiveFuelAdvances = (newTabIndex) => {
        this.setState({ tabIndex: newTabIndex })
        let payload = { staus: 'active' }
        this.props.dispatch(activeFuelAdvances(payload))
        sessionStorage.setItem('isActiveTab', 'active');
    }
    getPastFuelAdvances = (newTabIndex) => {
        this.setState({ tabIndex: newTabIndex })
        let payload = { staus: 'past' }
        this.props.dispatch(pastFuelAdvances(payload))
        sessionStorage.setItem('isActiveTab', 'past');
    }
    componentDidUpdate = (prevProps) => {
        if (this.props.postFuelAdvData !== prevProps.postFuelAdvData) {
            if (this.props.postFuelAdvData?.status === 'OK') {
                toastr.success('Success', this.props.postFuelAdvData.message);
                this.getActiveFuelAdvances(0);
                document.getElementById('addFuelAdvanceModalpopup').click()
            }
            else if (this.props.postFuelAdvData?.status === 'BAD_REQUEST') {
                toastr.error('Error', this.props.postFuelAdvData.validationErrors[0]);
            }
        }
        if (this.props.updatedFactorData !== prevProps.updatedFactorData) {
            console.log(this.props.updatedFactorData )
            if (this.props.updatedFactorData?.status === 'OK') {
                toastr.success('Success', this.props.updatedFactorData.message);
            }
            else if (this.props.updatedFactorData?.status === 'BAD_REQUEST') {
                toastr.error('Error', this.props.updatedFactorData.validationErrors[0]);
            }
            else{
                toastr.error('Error', this.props.updatedFactorData.message);
            }
        }
    }
    updtateFactor = invoiceID => {
        console.log('invoiceID',invoiceID)
        this.props.dispatch(UpdateFactors(invoiceID))
    }
    getIndex(index, selectedId) {
        this.setState({ activeId: selectedId })
    }
    active_Fuel_Advance_Data() {
        let activeFuelAdvData = [];
        this.props.ActiveFuelAdvanceData.map((row, index) => {
            activeFuelAdvData.push({
                id: row.invoiceNumber,
                Invoice_Id: row.invoiceNumber,
                Carrier_Name: row.carrierInfo?.carrierName,
                Asset_No: row.vehicleInfo?.assetNumber,
                Pickup_Location: row.pickupInfo?.location,
                PU_LOC_Status: <div key={index} data-tip data-for={'pulocstatustooltip' + row.invoiceNumber}>{row.pickupInfo.locationStatus === true ? <span className='matched'>Matched</span> : <span className='unmatched'>Unmatched</span>}
                    <ReactTooltip id={'pulocstatustooltip' + row.invoiceNumber} >
                        <p>Closest Timestamp: {row.closestPickupMatchInfo.closestDate ? moment(row.closestPickupMatchInfo.closestDate).format('MM-DD-YYYY HH:mm A') : ''}</p>
                        <p>Closest Miles From Time Point: {row.closestPickupMatchInfo?.milesFromPoint}</p>
                        <p>Closest Latitude: {row.closestPickupMatchInfo?.latitude}</p>
                        <p>Closest Longitude: {row.closestPickupMatchInfo?.longitude}</p>
                    </ReactTooltip>
                </div>,
                Sced_PU_Time: moment(row.pickupInfo?.pickupTime).format('MM-DD-YYYY HH:mm A'),
                SP_status: <div key={index} data-tip data-for={'putimestatustooltip' + row.invoiceNumber}>{row.pickupInfo.pickupTimeStatus === true ? <span className='matched'>Matched</span> : <span className='unmatched'>Unmatched</span>}
                    <ReactTooltip id={'putimestatustooltip' + row.invoiceNumber} >
                        <p>Closest Timestamp: {row.closestPickupMatchInfo.closestTimeDate ? moment(row.closestPickupMatchInfo.closestTimeDate).format('MM-DD-YYYY HH:mm A') : ''}</p>
                        <p>Closest Miles From Time Point: {row.closestPickupMatchInfo?.milesFromClosetTimePoint}</p>
                        <p>Closest Latitude: {row.closestPickupMatchInfo?.latitudeClosestTime}</p>
                        <p>Closest Longitude: {row.closestPickupMatchInfo?.longitudeClosestTime}</p>
                    </ReactTooltip>
                </div>,
                Refresh_Action:<button onClick={(e) => {this.updtateFactor(row.invoiceNumber)}} id='refreshBtn' className='btn btn-primary btn-sm' >
                <span className='refresh'>Update</span>
            </button>
            });
        return activeFuelAdvData;
        });
        return activeFuelAdvData;
    }
    past_Fuel_Advance_Data() {
        let pastFuelAdvData = [];
        this.props.PastFuelAdvanceData.map((row, index) => {
            pastFuelAdvData.push({
                id: row.invoiceNumber,
                Invoice_Id: row.invoiceNumber,
                Carrier_Name: row.carrierInfo?.carrierName,
                Asset_No: row.vehicleInfo?.assetNumber,
                Pickup_Location: row.pickupInfo?.location,
                PU_LOC_Status: <div key={index} data-tip data-for={'pulocstatustooltip' + row.invoiceNumber}>{row.pickupInfo.locationStatus === true ? <span className='matched'>Matched</span> : <span className='unmatched'>Unmatched</span>}
                    <ReactTooltip id={'pulocstatustooltip' + row.invoiceNumber} >
                        <p>Closest Timestamp: {row.closestPickupMatchInfo.closestDate ? moment(row.closestPickupMatchInfo.closestDate).format('MM-DD-YYYY HH:mm A') : ''}</p>
                        <p>Closest Miles From Time Point: {row.closestPickupMatchInfo?.milesFromPoint}</p>
                        <p>Closest Latitude: {row.closestPickupMatchInfo?.latitude}</p>
                        <p>Closest Longitude: {row.closestPickupMatchInfo?.longitude}</p>
                    </ReactTooltip>
                </div>,
                Sced_PU_Time: moment(row.pickupInfo?.pickupTime).format('MM-DD-YYYY HH:mm A'),
                SP_status: <div key={index} data-tip data-for={'putimestatustooltip' + row.invoiceNumber}>{row.pickupInfo.pickupTimeStatus === true ? <span className='matched'>Matched</span> : <span className='unmatched'>Unmatched</span>}
                    <ReactTooltip id={'putimestatustooltip' + row.invoiceNumber} >
                        <p>Closest Timestamp: {row.closestPickupMatchInfo.closestTimeDate ? moment(row.closestPickupMatchInfo.closestTimeDate).format('MM-DD-YYYY HH:mm A') : ''}</p>
                        <p>Closest Miles From Time Point: {row.closestPickupMatchInfo?.milesFromClosetTimePoint}</p>
                        <p>Closest Latitude: {row.closestPickupMatchInfo?.latitudeClosestTime}</p>
                        <p>Closest Longitude: {row.closestPickupMatchInfo?.longitudeClosestTime}</p>
                    </ReactTooltip>
                </div>,
                Refresh_Action:<button onClick={(e) => {this.updtateFactor(row.invoiceNumber)}} id='refreshBtn' className='btn btn-primary btn-sm' >
                <span className='refresh'>Update</span>
            </button>
            });
        return pastFuelAdvData;
        });
        return pastFuelAdvData;
    }
    toggleMenu() {
        this.setState({ isMenuOpen: !this.state.isMenuOpen })
    }
    render() {
        let indicationhtml = '';
        if (this.props.loading) {
            indicationhtml =
                <div className='spinner-border' role='status'>
                    <div className='row react-bs-table-no-data'></div>
                </div>
        }
        else {
            indicationhtml = 'No Data Found';
        }
        return (
            <article className='table-data truck-data shadow bg-white rounded' id='htmlclr'>
                <div className='row no-gutters'>
                    <h5 className="modal-title doc-color">Fuel Advances</h5>
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className='col-md-12 tab-btn'>
                                <div className='d-flex justify-content-end'>
                                    <ul className='ul-horizontal tab-menus'>
                                        <li>
                                            <button type="button" className='btn btn-primary btn-sm' data-toggle='modal' data-backdrop='false' id='createFuelAdvanceModalID' data-target='.createFuelAdvanceModal'>Create Fuel Advance</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <MaterialUITabs tabsData={
                            [{ component: 'Active', tabID: 'active-tab', refData: '#active', methodName: () => { this.getActiveFuelAdvances(0) } },
                            { component: 'Past', tabID: 'past-tab', refData: '#past', methodName: () => { this.getPastFuelAdvances(1) } }
                            ]}
                            getIndex={this.getIndex}
                            activeTab={'#active'}
                            state={this.state}
                        />
                        <div className='tab-content' id='myTabContent'>
                         <Box >
                                {this.state.tabIndex === 0 && (
                                    <Box>
                                <TableDataComponent tableData={this.active_Fuel_Advance_Data()} columnData={fuel_advance_columns} noDataIndication={indicationhtml} />
                                    </Box>
                                )}
                                {this.state.tabIndex === 1 && (
                                    <Box>
                                <TableDataComponent tableData={this.past_Fuel_Advance_Data()} columnData={fuel_advance_columns} noDataIndication={indicationhtml} />
                                    </Box>
                                )}
                            </Box>
                    </div>
                    </div>
                    <CreateFuelAdvanceModal state={this.props.statelist} citylist={this.props.citylist} carriernamelist={this.props.carriernamelist} />
                </div>
            </article>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        loading: state.companyReducer.loading,
        ActiveFuelAdvanceData: state.companyReducer.activeFuelAdvanceS,
        PastFuelAdvanceData: state.companyReducer.pastFuelAdvanceS,
        postFuelAdvData: state.loadReducer.postFuelAdvData,
        updatedFactorData: state.companyReducer.updatedData,
    }
}
export default connect(mapStateToProps)(FuelAdvance);