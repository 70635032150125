import { authHeader } from '../helpers/authHeader';
import {checkUnauthorized} from '../helpers/appUtil';
require('dotenv').config();

export const getRolesAndAccessService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    let COMPANY_LIST_API_ENDPOINT = BASE_URL+'roleresource/role/get';
    let join = '?';
    if(request){
        if(request.companyTypeId){
            COMPANY_LIST_API_ENDPOINT += join + 'companyTypeId='+request.companyTypeId;
            join = '&'
        }

        if(request.companyId){
            COMPANY_LIST_API_ENDPOINT += join + 'companyId='+request.companyId;
            join = '&'
        }

        if(request.roleId){
            COMPANY_LIST_API_ENDPOINT += join + 'roleId='+request.roleId;
            join = '&'
        }
    }

    return fetch(COMPANY_LIST_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const saveRoleAccessService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL+'roleaccessresource/roleaccess/update'; 
    return fetch(COMPANY_LIST_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request),  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}