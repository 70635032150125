import { authHeader } from '../helpers/authHeader';
import {checkUnauthorized} from '../helpers/appUtil';
require('dotenv').config();


export const emailaccountlistservice = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const EMAIL_ACCOUNT_API_ENDPOINT = BASE_URL+'carrieremailaccount/list';
    
    return fetch(EMAIL_ACCOUNT_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const addNewAccount = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const ADD_ACCOUNT_API_ENDPOINT = BASE_URL+'carrieremailaccount/add';

    return fetch(ADD_ACCOUNT_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);         
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const updateAccount = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_ACCOUNT_API_ENDPOINT = BASE_URL+'carrieremailaccount/update';

    return fetch(UPDATE_ACCOUNT_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);         
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}


