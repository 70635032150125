import { authHeader } from '../helpers/authHeader';
import {checkUnauthorized} from '../helpers/appUtil';
require('dotenv').config();

export const assetListService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
     const TRUCK_API_ENDPOINT = BASE_URL+'assetsresource/assets/list?assettype='+request.payload.assettype;
  
    return fetch(TRUCK_API_ENDPOINT,{
        method: "POST",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);           
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const assetListServiceForTech = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
     const TRUCK_API_ENDPOINT = BASE_URL+'assetsresource/assets/carrierwiselist?assettype='+request.payload.assettype;
  
    return fetch(TRUCK_API_ENDPOINT,{
        method: "POST",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);           
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const optInOutAssetsService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
   const TRUCK_API_ENDPOINT = BASE_URL+'optinout/assets?assettype='+request.assettype;
  
    return fetch(TRUCK_API_ENDPOINT,{
        method: "GET",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);           
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const saveOptInOutService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
   const TRUCK_API_ENDPOINT = BASE_URL+'optinout/save?assetid='+request.assetid + '&inout_flag='+request.inout_flag;
  
    return fetch(TRUCK_API_ENDPOINT,{
        method: "POST",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const assetAddService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const ADD_TRUCK_API_ENDPOINT = BASE_URL+'assetsdata/add';
    return fetch(ADD_TRUCK_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);           
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const assetUpdateService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_TRUCK_API_ENDPOINT = BASE_URL+'assetsdata/update';
    return fetch(UPDATE_TRUCK_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {   
        checkUnauthorized(responseData.status, responseData.message);         
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const companyAuthEldService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
   const COMPANY_AUTH_ELD_ENDPOINT = BASE_URL+'assetsearchresource/geteldlist'
  
    return fetch(COMPANY_AUTH_ELD_ENDPOINT,{
        method: "GET",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {    
        checkUnauthorized(responseData.status, responseData.message);         
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const latsTrackingByAssetIdService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
   const COMPANY_AUTH_ELD_ENDPOINT = BASE_URL+'trackingresource/getLatsTrackingByAssetId?assetId='+request.assetId
  
    return fetch(COMPANY_AUTH_ELD_ENDPOINT,{
        method: "GET",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const getActiveTruckTracking = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    let COMPANY_AUTH_ELD_ENDPOINT = BASE_URL+'trackingresource/getActiveTruckTracking?assetId='+request.assetId
    
    if(request.fromDate){
        COMPANY_AUTH_ELD_ENDPOINT = COMPANY_AUTH_ELD_ENDPOINT + "&fromDate=" + request.fromDate
    }

    return fetch(COMPANY_AUTH_ELD_ENDPOINT,{
        method: "GET",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        return {};
    });
}


export const getDispatchTrackingByLoadIdService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
   const COMPANY_AUTH_ELD_ENDPOINT = BASE_URL+'trackingresource/getDispatchTrackingByLoadId?loadId='+request.loadId
  
    return fetch(COMPANY_AUTH_ELD_ENDPOINT,{
        method: "GET",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        return {};
    });
}


export const assetVerifyService = (request) => {
    //console.log(request);
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_TRUCK_API_ENDPOINT = BASE_URL+'assetsresource/asset/verify?assetId='+request.payload;
    return fetch(UPDATE_TRUCK_API_ENDPOINT,{
        method: "PUT",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {   
        checkUnauthorized(responseData.status, responseData.message);         
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const assetListServiceForTechService = (request) => {    
    const BASE_URL= process.env.REACT_APP_BASE_URL;
     const TRUCK_API_ENDPOINT = BASE_URL+'assetsresource/assets/techpartner/carrierwiselist?assettype='+request.assettype+"&carrierid="+request.carrierid;
  
    return fetch(TRUCK_API_ENDPOINT,{
        method: "POST",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);           
        return responseData;
    })
    .catch(error => {
        return {};
    });
}
export const newELDPostService = (request) => {    
    const BASE_URL= process.env.REACT_APP_CARRIER_API_URL;
     //removing index key from array and storing into a new array result
     const result = request.payload.eldCredentials.map(({ index, ...rest }) => ({ ...rest }));
     //removing empty key value pairs and storing it in results array
     const results = result.filter(element => {
       if (element.credType !== '' || element.credValue !== '') {
         return true;
       }
       return false;
     });
    //  const ELD_API_ENDPOINT = BASE_URL+'elds/request?invitationToken=NGY2ODUxMDMtMjFkNS00MjA1LThhOTMtMzdjMmM3ODI3NDI5';
     const ELD_API_ENDPOINT = BASE_URL+'elds/request?invitationToken='+request.payload.invitationToken;
    let resultAfterDuplicateRemoval = results.reduce((acc, {credType,credValue}) => 
      (acc[credType] = credValue, acc), {})
     let postBody={eldCredentials: resultAfterDuplicateRemoval,
                    eldName: request.payload.eldName,
                    eldWebsiteUrl:request.payload.eldWebsiteUrl};
                    // console.log('postBody',postBody)
    return fetch(ELD_API_ENDPOINT,{
        method: "POST",
        headers: {
			'Content-Type': 'application/json'
	  	},
        body:JSON.stringify(postBody),
    })
    .then((response) => response.json())
    .catch(error => {
        return {};
    });
}
