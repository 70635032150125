import { put, call, takeEvery } from 'redux-saga/effects';
import { userlistservice,addNewUser,changepasswordService,driveraddService,drivereditService,driverdeleteService,profileviewService,profileeditService,shipperListService,shipperAddService } from '../services/userService';
import * as types from '../actions';

//User List
// async function fetchAsync(func) {  
//     const response = await func();
//     if (response.ok) {
//         return await response.json();
//     }
//     throw new Error("Unexpected error!!!");
// }
function* fetchData(payload) { 
    try {
            const response = yield call(userlistservice, payload);    
            yield put({type: types.USER_LIST_SUCCESS, response});
    } catch (error) {
            yield put({ type: types.USER_LIST_ERROR, error })
    }
}

function* addUsers(payload) {
    try {
        const response = yield call( addNewUser, payload ); 
        yield put({type: types.ADD_USER_LIST_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.ADD_USER_LIST_ERROR, error: e.message});
    }
}

function* changepassword(payload) {
    try {
        const response = yield call( changepasswordService, payload ); 
        yield put({type: types.CHANGE_PASSWORD_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.CHANGE_PASSWORD_ERROR, error: e.message});
    }
}

function* driveradd(payload) {
    try {
        const response = yield call( driveraddService, payload ); 
        yield put({type: types.DRIVER_ADD_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.DRIVER_ADD_ERROR, error: e.message});
    }
}

function* driveredit(payload) {
    try {
        const response = yield call( drivereditService, payload ); 
        yield put({type: types.DRIVER_EDIT_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.DRIVER_EDIT_ERROR, error: e.message});
    }
}

function* driverdelete(payload) {
    try {
        const response = yield call( driverdeleteService, payload ); 
        yield put({type: types.DRIVER_DELETE_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.DRIVER_DELETE_ERROR, error: e.message});
    }
}

function* profileview(payload) {
    try {
        const response = yield call( profileviewService, payload ); 
        yield put({type: types.PROFILE_VIEW_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.PROFILE_VIEW_ERROR, error: e.message});
    }
}

function* profileedit(payload) {
    try {
        const response = yield call( profileeditService, payload ); 
        yield put({type: types.PROFILE_EDIT_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.PROFILE_EDIT_ERROR, error: e.message});
    }
}
function* shipperList(payload) {
    try {
        const response = yield call( shipperListService, payload ); 
        yield put({type: types.SHIPPER_LIST_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.SHIPPER_LIST_ERROR, error: e.message});
    }
}
function* shipperAdd(payload) {
    try {
        const response = yield call( shipperAddService, payload ); 
        yield put({type: types.SHIPPER_ADD_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.SHIPPER_ADD_ERROR, error: e.message});
    }
}
export function* userlist() {  
    yield takeEvery(types.USER_LIST, fetchData);
    yield takeEvery(types.ADD_USER_LIST, addUsers);
    yield takeEvery(types.CHANGE_PASSWORD, changepassword);
    yield takeEvery(types.DRIVER_ADD, driveradd);
    yield takeEvery(types.DRIVER_EDIT, driveredit);
    yield takeEvery(types.DRIVER_DELETE, driverdelete);
    yield takeEvery(types.PROFILE_VIEW, profileview);
    yield takeEvery(types.PROFILE_EDIT, profileedit);
    yield takeEvery(types.SHIPPER_LIST, shipperList);
    yield takeEvery(types.SHIPPER_ADD, shipperAdd);

}