import React, { Component } from 'react';
import { cancelledloadsbyloadid } from '../../../actions/loadActions';
import restoreImg from '../../../restore-confirm.png'
import { connect } from 'react-redux';

class CancelLoadConfirmModal extends Component {
  cancelLoad(param){
    console.warn('param',param)
			this.props.dispatch(cancelledloadsbyloadid(param))
  }
  render() {
    console.warn('cancelLoad',this.props?.loadTab)
    return (
        <div className='modal' id='cancelConfirmloadModal'>
        <div className='modal-dialog modal-dialog-centered modal-lz'>
          <div className='modal-content'>
          <div className="modal-header">
          <h5 className='opt-heading-css'>Cancel Load</h5>              
        </div>
            <div className='modal-body'>
            <div className='img text-center'>
            <img src={restoreImg} alt='Not Found'/><br/>
            <h6 className='opt-sub-heading-css'>Do you want to cancel load  <span className='contentCss' >{this.props.loadTab?.toBeCancelledLoadNo} ?</span></h6>              
            <p>Once it is cancelled it will be available in cancelled tab</p>
            </div>
            </div>
            <div className='modal-footer'>
              <button type='button'className='btn btn-primary btn-sm' data-dismiss='modal'>Go back</button>
              <button type='button' data-dismiss='modal' className='btn btn-primary btn-sm sendbtn ' onClick={(e) => {this.cancelLoad(this.props.loadTab?.toBeCancelledLoadID)}}>Yes cancel </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
	return {
		data: state.loadReducer.data,
		LoadList: state.loadReducer.data,
		companyList: state.loadReducer.companyList,
		loading: state.loadReducer.loading,
	}
}
export default connect(mapStateToProps)(CancelLoadConfirmModal);