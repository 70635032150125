import React, { Component, Fragment } from 'react';
import RoleMenu from './RoleMenu';
import AppUser from './AppUser';
import { withRouter } from 'react-router';
import { history } from '../../helpers/history';
import Header from '../Header/Header';
import { HeaderBar } from 'UI-Component';
import { connect } from 'react-redux';

class TopBar extends Component {
	state = {
		loggedUser: {
			companyId: localStorage.getItem('companyId'),
			userName: localStorage.getItem('userName'),
			userType: localStorage.getItem('userType'),
		},
		userMenu: localStorage.getItem('userMenu'),
		newLogo: '',
		companyProfile: ((localStorage.getItem('Company_Profile') !== undefined && localStorage.getItem('Company_Profile') !== null) ? JSON.parse(localStorage.getItem('Company_Profile')) : '')
	}
	dashboardpage = (e) => {
		e.preventDefault();
		var url = '/pages/dashboard';
		history.push(url);
	}
	componentDidUpdate(prevProps) {
		if (this.props.companyProfile.LogoUrl !== prevProps.companyProfile.LogoUrl) {
			this.setState({
				...this.state,
				companyProfile: {
					...this.state.companyProfile,
					logo: this.props.companyProfile.LogoUrl
				}
			})
			console.log('this.state.companyProfile',this.state.companyProfile)
		}
	}
	handleCallback = (childData) => {
		this.setState({ data: childData })
		this.props.parentCallbackFromTopBar(childData);
	}
	render() {
		const { pathname } = this.props.location;
		let urlArray = pathname.split('/').map(String);
		let menuList = [];
		if (pathname === '/broker/drivers') {
			menuList = '';
		}
		if (pathname === '/shipper/drivers') {
			menuList = '';
		}
		else if (urlArray[2] !== 'carrier-drivers') {
			menuList = this.state.userMenu;
		}
		else {
			menuList = '';
		}
		return (
			<Fragment>
				<header>
					<nav className='navbar navbar-expand-lg navbar-light bg-light'>
						<HeaderBar passingData={this.state.companyProfile} onClick={(e) => { this.dashboardpage(e) }} />
						<div className='collapse navbar-collapse' id='navbarSupportedContent'>
							<RoleMenu />
							<AppUser loggedUser={this.state.loggedUser} />
						</div>
					</nav>
				</header>
				<Header userMenu={menuList} userType={this.state.loggedUser.userType} parentCallback={this.handleCallback} />
			</Fragment>
		)
	}

}
const mapStateToProps = (state) => {
	return {
		companyProfile: state.companyReducer.data,
        companyProfileData: state.companyReducer.data,
	}
}
export default withRouter(connect(mapStateToProps)(TopBar))