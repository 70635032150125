const DefaultPermissions = {
    authorization: true,
    location: true,
    vehicle: true,
    driver: false,
    hos: false,
    engine: false,
    dvir: false
}

export default DefaultPermissions;