export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_ERROR = 'GET_DASHBOARD_DATA_ERROR';
export const GET_TRUCK_DATA = 'GET_TRUCK_DATA';
export const GET_TRUCK_DATA_SUCCESS = 'GET_TRUCK_DATA_SUCCESS';
export const GET_TRUCK_DATA_ERROR = 'GET_TRUCK_DATA_ERROR';
export const SEARCH_TRUCK_DATA = 'SEARCH_TRUCK_DATA';
export const SEARCH_TRUCK_DATA_SUCCESS = 'SEARCH_TRUCK_DATA_SUCCESS';
export const SEARCH_TRUCK_DATA_ERROR = 'SEARCH_TRUCK_DATA_ERROR';
export const VIEW_TRUCK_DATA = 'VIEW_TRUCK_DATA';
export const VIEW_TRUCK_DATA_SUCCESS = 'VIEW_TRUCK_DATA_SUCCESS';
export const VIEW_TRUCK_DATA_ERROR = 'VIEW_TRUCK_DATA_ERROR';
export const POST_NEW_TRUCK_DATA = 'POST_NEW_TRUCK_DATA';
export const POST_NEW_TRUCK_DATA_SUCCESS = 'POST_NEW_TRUCK_DATA_SUCCESS';
export const POST_NEW_TRUCK_DATA_ERROR = 'POST_NEW_TRUCK_DATA_ERROR';
export const POST_UPDATE_TRUCK_DATA = 'POST_UPDATE_TRUCK_DATA';
export const POST_UPDATE_TRUCK_DATA_SUCCESS = 'POST_UPDATE_TRUCK_DATA_SUCCESS';
export const POST_UPDATE_TRUCK_DATA_ERROR = 'POST_UPDATE_TRUCK_DATA_ERROR';
export const GET_LOAD_DATA = 'GET_LOAD_DATA';
export const GET_LOAD_DATA_SUCCESS = 'GET_LOAD_DATA_SUCCESS';
export const GET_LOAD_DATA_ERROR = 'GET_LOAD_DATA_ERROR';
export const GET_SHIPPER_LOAD_DATA = 'GET_SHIPPER_LOAD_DATA';
export const GET_SHIPPER_LOAD_DATA_SUCCESS = 'GET_SHIPPER_LOAD_DATA_SUCCESS';
export const GET_SHIPPER_LOAD_DATA_ERROR = 'GET_SHIPPER_LOAD_DATA_ERROR';
export const GET_LOAD_DATA_CANCEL = 'GET_LOAD_DATA_CANCEL';
export const GET_LOAD_DATA_CANCEL_SUCCESS = 'GET_LOAD_DATA_CANCEL_SUCCESS';
export const GET_LOAD_DATA_CANCEL_ERROR = 'GET_LOAD_DATA_CANCEL_ERROR';
export const GET_LOAD_DATA_QUICKDISPATCH = 'GET_LOAD_DATA_QUICKDISPATCH';
export const GET_LOAD_DATA_QUICKDISPATCH_SUCCESS = 'GET_LOAD_DATA_QUICKDISPATCH_SUCCESS';
export const GET_LOAD_DATA_QUICKDISPATCH_ERROR = 'GET_LOAD_DATA_QUICKDISPATCH_ERROR';
export const POST_NEW_LOAD_DATA = 'POST_NEW_LOAD_DATA';
export const POST_NEW_LOAD_DATA_SUCCESS = 'POST_NEW_LOAD_DATA_SUCCESS';
export const POST_NEW_LOAD_DATA_ERROR = 'POST_NEW_LOAD_DATA_ERROR';
export const COMMON_LIST = 'COMMON_LIST';
export const COMMON_LIST_SUCCESS = 'COMMON_LIST_SUCCESS';
export const COMMON_LIST_ERROR = 'COMMON_LIST_ERROR';
export const USER_LIST = 'USER_LIST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_ERROR = 'USER_LIST_ERROR';
// for email template
// export const EMAIL_TEMPLATE_LIST        = 'EMAIL_TEMPLATE_LIST';
// export const EMAIL_TEMPLATE_LIST_SUCCESS = 'EMAIL_TEMPLATE_LIST_SUCCESS';
// export const EMAIL_TEMPLATE_LIST_ERROR   = 'EMAIL_TEMPLATE_LIST_ERROR';
export const UPDATE_LOAD_STATUS = 'UPDATE_LOAD_STATUS';
export const UPDATE_LOAD_STATUS_SUCCESS = 'UPDATE_LOAD_STATUS_SUCCESS';
export const UPDATE_LOAD_STATUS_ERROR = 'UPDATE_LOAD_STATUS_ERROR';
export const CHANGE_LOAD_STATUS = 'CHANGE_LOAD_STATUS';
export const CHANGE_LOAD_STATUS_SUCCESS = 'CHANGE_LOAD_STATUS_SUCCESS';
export const CHANGE_LOAD_STATUS_ERROR = 'CHANGE_LOAD_STATUS_ERROR';
export const SAVE_POD_LOAD = 'SAVE_POD_LOAD';
export const SAVE_POD_LOAD_SUCCESS = 'SAVE_POD_LOAD_SUCCESS';
export const SAVE_POD_LOAD_ERROR = 'SAVE_POD_LOAD_ERROR';
export const TENDER_LOAD = 'TENDER_LOAD';
export const TENDER_LOAD_SUCCESS = 'TENDER_LOAD_SUCCESS';
export const TENDER_LOAD_ERROR = 'TENDER_LOAD_ERROR';
export const COMPANY_LIST_BY_TYPE = 'COMPANY_LIST_BY_TYPE';
export const COMPANY_LIST_BY_TYPE_SUCCESS = 'COMPANY_LIST_BY_TYPE_SUCCESS';
export const COMPANY_LIST_BY_TYPE_ERROR = 'COMPANY_LIST_BY_TYPE_ERROR';
export const GET_ASSET_ORDER = 'GET_ASSET_ORDER';
export const GET_ASSET_ORDER_SUCCESS = 'GET_ASSET_ORDER_SUCCESS';
export const GET_ASSET_ORDER_ERROR = 'GET_ASSET_ORDER_ERROR';
export const UPDATE_TENDER_LOAD = 'UPDATE_TENDER_LOAD';
export const UPDATE_TENDER_LOAD_SUCCESS = 'UPDATE_TENDER_LOAD_SUCCESS';
export const UPDATE_TENDER_LOAD_ERROR = 'UPDATE_TENDER_LOAD_ERROR';
export const EDIT_TRUCK_DATA = 'EDIT_TRUCK_DATA';
export const EDIT_TRUCK_DATA_SUCCESS = 'EDIT_TRUCK_DATA_SUCCESS';
export const EDIT_TRUCK_DATA_ERROR = 'EDIT_TRUCK_DATA_ERROR';
export const GET_CONTACT_DATA = 'GET_CONTACT_DATA';
export const GET_CONTACT_DATA_SUCCESS = 'GET_CONTACT_DATA_SUCCESS';
export const GET_CONTACT_DATA_ERROR = 'GET_CONTACT_DATA_ERROR';
export const EDIT_CONTACT_DATA = 'EDIT_CONTACT_DATA';
export const EDIT_CONTACT_DATA_SUCCESS = 'EDIT_CONTACT_DATA_SUCCESS';
export const EDIT_CONTACT_DATA_ERROR = 'EDIT_CONTACT_DATA_ERROR';
export const ADD_USER_LIST = 'ADD_USER_LIST';
export const ADD_USER_LIST_SUCCESS = 'ADD_USER_LIST_SUCCESS';
export const ADD_USER_LIST_ERROR = 'ADD_USER_LIST_ERROR';
export const EDIT_CARRIER_DATA = 'EDIT_CARRIER_DATA';
export const EDIT_CARRIER_DATA_SUCCESS = 'EDIT_CARRIER_DATA_SUCCESS';
export const EDIT_CARRIER_DATA_ERROR = 'EDIT_CARRIER_DATA_ERROR';
export const EDIT_DOCUMENT_DATA = 'EDIT_DOCUMENT_DATA';
export const EDIT_DOCUMENT_DATA_SUCCESS = 'EDIT_DOCUMENT_DATA_SUCCESS';
export const EDIT_DOCUMENT_DATA_ERROR = 'EDIT_DOCUMENT_DATA_ERROR';
export const GET_TRUCK_LIST = 'GET_TRUCK_LIST';
export const GET_TRUCK_LIST_SUCCESS = 'GET_TRUCK_LIST_SUCCESS';
export const GET_TRUCK_LIST_ERROR = 'GET_TRUCK_LIST_ERROR';
export const GET_TRACKING_LIST = 'GET_TRACKING_LIST';
export const GET_TRACKING_LIST_SUCCESS = 'GET_TRACKING_LIST_SUCCESS';
export const GET_TRACKING_LIST_ERROR = 'GET_TRACKING_LIST_ERROR';
export const TRUCK_NO_AND_LOCATION = 'TRUCK_NO_AND_LOCATION';
export const TRUCK_NO_AND_LOCATION_SUCCESS = 'TRUCK_NO_AND_LOCATION_SUCCESS';
export const TRUCK_NO_AND_LOCATION_ERROR = 'TRUCK_NO_AND_LOCATION_ERROR';
export const DRIVER_BY_COMPANY = 'DRIVER_BY_COMPANY';
export const DRIVER_BY_COMPANY_SUCCESS = 'DRIVER_BY_COMPANY_SUCCESS';
export const DRIVER_BY_COMPANY_ERROR = 'DRIVER_BY_COMPANY_ERROR';
export const LOAD_ACCESSIORAL = 'LOAD_ACCESSIORAL';
export const LOAD_ACCESSIORAL_SUCCESS = 'LOAD_ACCESSIORAL_SUCCESS';
export const LOAD_ACCESSIORAL_ERROR = 'LOAD_ACCESSIORAL_ERROR';
export const SAVE_ASSET_ORDER = 'SAVE_ASSET_ORDER';
export const SAVE_ASSET_ORDER_SUCCESS = 'SAVE_ASSET_ORDER_SUCCESS';
export const SAVE_ASSET_ORDER_ERROR = 'SAVE_ASSET_ORDER_ERROR';
export const GET_ELD_ASSEST_LIST = 'GET_ELD_ASSEST_LIST';
export const GET_ELD_ASSEST_LIST_SUCCESS = 'GET_ELD_ASSEST_LIST_SUCCESS';
export const GET_ELD_ASSEST_LIST_ERROR = 'GET_ELD_ASSEST_LIST_ERROR';
export const SET_GOFLAG = 'SET_GOFLAG';
export const SAVE_QUICK_DISPATCH = 'SAVE_QUICK_DISPATCH';
export const SAVE_QUICK_DISPATCH_SUCCESS = 'SAVE_QUICK_DISPATCH_SUCCESS';
export const SAVE_QUICK_DISPATCH_ERROR = 'SAVE_QUICK_DISPATCH_ERROR';
export const SAVE_BROKER_QUICK_DISPATCH = 'SAVE_BROKER_QUICK_DISPATCH';
export const SAVE_BROKER_QUICK_DISPATCH_SUCCESS = 'SAVE_BROKER_QUICK_DISPATCH_SUCCESS';
export const SAVE_BROKER_QUICK_DISPATCH_ERROR = 'SAVE_BROKER_QUICK_DISPATCH_ERROR';
export const SHIPPER_AND_LANE_DETAILS = 'SHIPPER_AND_LANE_DETAILS';
export const SHIPPER_AND_LANE_DETAILS_SUCCESS = 'SHIPPER_AND_LANE_DETAILS_SUCCESS';
export const SHIPPER_AND_LANE_DETAILS_ERROR = 'SHIPPER_AND_LANE_DETAILS_ERROR';
export const GET_CITY_BY_STATE = 'GET_CITY_BY_STATE';
export const GET_CITY_BY_STATE_SUCCESS = 'GET_CITY_BY_STATE_SUCCESS';
export const GET_CITY_BY_STATE_ERROR = 'GET_CITY_BY_STATE_ERROR';
export const GET_ZIP_BY_CITY = 'GET_ZIP_BY_CITY';
export const GET_ZIP_BY_CITY_SUCCESS = 'GET_ZIP_BY_CITY_SUCCESS';
export const GET_ZIP_BY_CITY_ERROR = 'GET_ZIP_BY_CITY_ERROR';
export const SAVE_LOAD_POST_ORDER_DISPATCH = 'SAVE_LOAD_POST_ORDER_DISPATCH';
export const SAVE_LOAD_POST_ORDER_DISPATCH_SUCCESS = 'SAVE_LOAD_POST_ORDER_DISPATCH_SUCCESS';
export const SAVE_LOAD_POST_ORDER_DISPATCH_ERROR = 'SAVE_LOAD_POST_ORDER_DISPATCH_ERROR'
export const ADD_CONTACT_DATA = 'ADD_CONTACT_DATA';
export const ADD_CONTACT_DATA_SUCCESS = 'ADD_CONTACT_DATA_SUCCESS';
export const ADD_CONTACT_DATA_ERROR = 'ADD_CONTACT_DATA_ERROR';
export const LIST_CONTACT_DATA = 'LIST_CONTACT_DATA';
export const LIST_CONTACT_DATA_SUCCESS = 'LIST_CONTACT_DATA_SUCCESS';
export const LIST_CONTACT_DATA_ERROR = 'LIST_CONTACT_DATA_ERROR';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_ERROR = 'DELETE_CONTACT_ERROR';
export const TRUCK_BULK_UPLOAD = 'TRUCK_BULK_UPLOAD';
export const TRUCK_BULK_UPLOAD_SUCCESS = 'TRUCK_BULK_UPLOAD_SUCCESS';
export const TRUCK_BULK_UPLOAD_ERROR = 'TRUCK_BULK_UPLOAD_ERROR';
export const LOAD_BULK_UPLOAD = 'LOAD_BULK_UPLOAD';
export const LOAD_BULK_UPLOAD_SUCCESS = 'LOAD_BULK_UPLOAD_SUCCESS';
export const LOAD_BULK_UPLOAD_ERROR = 'LOAD_BULK_UPLOAD_ERROR';
export const TENDER_CHANGE_LOAD_STATUS = 'TENDER_CHANGE_LOAD_STATUS';
export const TENDER_CHANGE_LOAD_STATUS_SUCCESS = 'TENDER_CHANGE_LOAD_STATUS_SUCCESS';
export const TENDER_CHANGE_LOAD_STATUS_ERROR = 'TENDER_CHANGE_LOAD_STATUS_ERROR';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const EDIT_LOAD_VALUES = 'EDIT_LOAD_VALUES';
export const EDIT_LOAD_VALUES_SUCCESS = 'EDIT_LOAD_VALUES_SUCCESS';
export const EDIT_LOAD_VALUES_ERROR = 'EDIT_LOAD_VALUES_ERROR';
export const UPDATE_LOAD = 'UPDATE_LOAD';
export const UPDATE_LOAD_SUCCESS = 'UPDATE_LOAD_SUCCESS';
export const UPDATE_LOAD_ERROR = 'UPDATE_LOAD_ERROR';
export const LANE_DETAILS_LIST = 'LANE_DETAILS_LIST';
export const LANE_DETAILS_LIST_SUCCESS = 'LANE_DETAILS_LIST_SUCCESS';
export const LANE_DETAILS_LIST_ERROR = 'LANE_DETAILS_LIST_ERROR';
export const DRIVER_ADD = 'DRIVER_ADD';
export const DRIVER_ADD_SUCCESS = 'DRIVER_ADD_SUCCESS';
export const DRIVER_ADD_ERROR = 'DRIVER_ADD_ERROR';
export const DRIVER_EDIT = 'DRIVER_EDIT';
export const DRIVER_EDIT_SUCCESS = 'DRIVER_EDIT_SUCCESS';
export const DRIVER_EDIT_ERROR = 'DRIVER_EDIT_ERROR';
export const DRIVER_DELETE = 'DRIVER_DELETE';
export const DRIVER_DELETE_SUCCESS = 'DRIVER_DELETE_SUCCESS';
export const DRIVER_DELETE_ERROR = 'DRIVER_DELETE_ERROR';
export const ASSET_LIST = 'ASSET_LIST';
export const ASSET_LIST_SUCCESS = 'ASSET_LIST_SUCCESS';
export const ASSET_LIST_ERROR = 'ASSET_LIST_ERROR';
export const ASSET_ADD = 'ASSET_ADD';
export const ASSET_ADD_SUCCESS = 'ASSET_ADD_SUCCESS';
export const ASSET_ADD_ERROR = 'ASSET_ADD_ERROR';
export const ASSET_UPDATE = 'ASSET_UPDATE';
export const ASSET_UPDATE_SUCCESS = 'ASSET_UPDATE_SUCCESS';
export const ASSET_UPDATE_ERROR = 'ASSET_UPDATE_ERROR';
export const QUICK_DISPATCH_LOAD_ACTION = 'QUICK_DISPATCH_LOAD_ACTION';
//export const QUICK_DISPATCH_LOAD_ACTION_SUCCESS    = 'QUICK_DISPATCH_LOAD_ACTION_SUCCESS';
export const QUICK_DISPATCH_BROKER_LOAD_ACTION = 'QUICK_DISPATCH_BROKER_LOAD_ACTION';
//export const QUICK_DISPATCH_BROKER_LOAD_ACTION_SUCCESS    = 'QUICK_DISPATCH_BROKER_LOAD_ACTION_SUCCESS';
export const LOAD_POST_ORDER_FlAG = 'LOAD_POST_ORDER_FlAG';
//export const LOAD_POST_ORDER_FlAG_SUCCESS    = 'LOAD_POST_ORDER_FlAG_SUCCESS';
export const UPDATE_STATUS_LOAD_FlAG = 'UPDATE_STATUS_LOAD_FlAG';
export const CHANGE_LOAD_STATUS_FlAG = 'CHANGE_LOAD_STATUS_FlAG';
export const BULK_UPLOAD_LOAD_FlAG = 'BULK_UPLOAD_LOAD_FlAG';
export const PREFERRED_COMPANY_LIST_BY_TYPE = 'PREFERRED_COMPANY_LIST_BY_TYPE';
export const PREFERRED_COMPANY_LIST_BY_TYPE_SUCCESS = 'PREFERRED_COMPANY_LIST_BY_TYPE_SUCCESS';
export const PREFERRED_COMPANY_LIST_BY_TYPE_ERROR = 'PREFERRED_COMPANY_LIST_BY_TYPE_ERROR';
export const PREFERRED_COMPANY_LIST = 'PREFERRED_COMPANY_LIST';
export const PREFERRED_COMPANY_LIST_SUCCESS = 'PREFERRED_COMPANY_LIST_SUCCESS';
export const PREFERRED_COMPANY_LIST_ERROR = 'PREFERRED_COMPANY_LIST_ERROR';
export const CHANGE_PREFERRED_REQUEST = 'CHANGE_PREFERRED_REQUEST';
export const CHANGE_PREFERRED_RESPONSE_SUCCESS = 'CHANGE_PREFERRED_RESPONSE_SUCCESS';
export const CHANGE_PREFERRED_RESPONSE_FAILED = 'CHANGE_PREFERRED_RESPONSE_FAILED';
export const PROFILE_VIEW = 'PROFILE_VIEW';
export const PROFILE_VIEW_SUCCESS = 'PROFILE_VIEW_SUCCESS';
export const PROFILE_VIEW_ERROR = 'PROFILE_VIEW_ERROR';
export const PROFILE_EDIT = 'PROFILE_EDIT';
export const PROFILE_EDIT_SUCCESS = 'PROFILE_EDIT_SUCCESS';
export const PROFILE_EDIT_ERROR = 'PROFILE_EDIT_ERROR';
export const GET_LOAD_DATA_DISPATCH = 'GET_LOAD_DATA_DISPATCH';
export const GET_LOAD_DATA_DISPATCH_SUCCESS = 'GET_LOAD_DATA_DISPATCH_SUCCESS';
export const GET_LOAD_DATA_DISPATCH_ERROR = 'GET_LOAD_DATA_DISPATCH_ERROR';
export const QUICK_DISPATCH = 'QUICK_DISPATCH';
export const QUICK_DISPATCH_SUCCESS = 'QUICK_DISPATCH_SUCCESS';
export const QUICK_DISPATCH_ERROR = 'QUICK_DISPATCH_ERROR';
export const CHK_AUTH_TOKEN = 'CHK_AUTH_TOKEN';
export const CHK_AUTH_TOKEN_SUCCESS = 'CHK_AUTH_TOKEN_SUCCESS';
export const CHK_AUTH_TOKEN_ERROR = 'CHK_AUTH_TOKEN_ERROR';
export const CHK_AUTH_TOKEN_fLAG = 'CHK_AUTH_TOKEN_fLAG';
export const CHK_AUTH_TOKEN_VALID = 'CHK_AUTH_TOKEN_VALID';
export const CHK_AUTH_TOKEN_VALID_SUCCESS = 'CHK_AUTH_TOKEN_VALID_SUCCESS';
export const CHK_AUTH_TOKEN_VALID_ERROR = 'CHK_AUTH_TOKEN_VALID_ERROR';
export const GET_DOCUMENT_DATA = 'GET_DOCUMENT_DATA';
export const GET_DOCUMENT_DATA_SUCCESS = 'GET_DOCUMENT_DATA_SUCCESS';
export const GET_DOCUMENT_DATA_ERROR = 'GET_DOCUMENT_DATA_ERROR';
export const LIST_DOCUMENT_DATA = 'LIST_DOCUMENT_DATA';
export const LIST_DOCUMENT_DATA_SUCCESS = 'LIST_DOCUMENT_DATA_SUCCESS';
export const LIST_DOCUMENT_DATA_ERROR = 'LIST_DOCUMENT_DATA_ERROR';
export const POST_DOCUMENT_DATA = 'POST_DOCUMENT_DATA';
export const POST_DOCUMENT_DATA_SUCCESS = 'POST_DOCUMENT_DATA_SUCCESS';
export const POST_DOCUMENT_DATA_ERROR = 'POST_DOCUMENT_DATA_ERROR';
export const EDIT_DOCUMENTS_DATA = 'EDIT_DOCUMENTS_DATA';
export const EDIT_DOCUMENTS_DATA_SUCCESS = 'EDIT_DOCUMENTS_DATA_SUCCESS';
export const EDIT_DOCUMENTS_DATA_ERROR = 'EDIT_DOCUMENTS_DATA_ERROR';
export const CANCELLED_LOAD_fLAG = 'CANCELLED_LOAD_fLAG';
export const DELETE_PROPS_DATA = 'DELETE_PROPS_DATA';
export const CANCELLED_LOADS_BYLOADID = 'CANCELLED_LOADS_BYLOADID';
export const CANCELLED_LOADS_BYLOADID_SUCCESS = 'CANCELLED_LOADS_BYLOADID_SUCCESS';
export const CANCELLED_LOADS_BYLOADID_ERROR = 'CANCELLED_LOADS_BYLOADID_ERROR';
export const NOTYETDISPATCHED_EDITDRIVERTRUCK = 'NOTYETDISPATCHED_EDITDRIVERTRUCK';
export const NOTYETDISPATCHED_EDITDRIVERTRUCK_SUCCESS = 'NOTYETDISPATCHED_EDITDRIVERTRUCK_SUCCESS';
export const NOTYETDISPATCHED_EDITDRIVERTRUCK_ERROR = 'NOTYETDISPATCHED_EDITDRIVERTRUCK_ERROR';
export const ONCHANGE_TRUCK = 'ONCHANGE_TRUCK';
export const ONCHANGE_TRUCK_SUCCESS = 'ONCHANGE_TRUCK_SUCCESS';
export const ONCHANGE_TRUCK_ERROR = 'ONCHANGE_TRUCK_ERROR';
export const ONCHANGE_DRIVER = 'ONCHANGE_DRIVER';
export const ONCHANGE_DRIVER_SUCCESS = 'ONCHANGE_DRIVER_SUCCESS';
export const ONCHANGE_DRIVER_ERROR = 'ONCHANGE_DRIVER_ERROR';
export const QUICK_DISPATCH_INTELLITRANS = 'QUICK_DISPATCH_INTELLITRANS';
export const QUICK_DISPATCH_INTELLITRANS_SUCCESS = 'QUICK_DISPATCH_INTELLITRANS_SUCCESS';
export const QUICK_DISPATCH_INTELLITRANS_ERROR = 'QUICK_DISPATCH_INTELLITRANS_ERROR';
export const CARRIER_SEARCH = 'CARRIER_SEARCH';
export const MAKE_PROPS_EMPTY = 'MAKE_PROPS_EMPTY';
export const MAKE_PROPS_EMPTY_SUCCESS = 'MAKE_PROPS_EMPTY_SUCCESS ';
export const MAKE_PROPS_EMPTY_ERROR = 'MAKE_PROPS_EMPTY_ERROR ';
export const CARRIER_SEARCH_NEW = 'CARRIER_SEARCH_NEW';
export const CARRIER_SEARCH_NEW_SUCCESS = 'CARRIER_SEARCH_NEW_SUCCESS';
export const CARRIER_SEARCH_NEW_ERROR = 'CARRIER_SEARCH_NEW_ERROR';
export const CARRIER_SEARCH_SUCCESS = 'CARRIER_SEARCH_SUCCESS';
export const CARRIER_SEARCH_ERROR = 'CARRIER_SEARCH_ERROR';
export const CARRIER_SEARCH_FLAG_ACTION = 'CARRIER_SEARCH_FLAG_ACTION';
export const CARRIER_ADD = 'CARRIER_ADD';
export const CARRIER_ADD_SUCCESS = 'CARRIER_ADD_SUCCESS';
export const CARRIER_ADD_ERROR = 'CARRIER_ADD_ERROR';
export const CARRIER_ADD_FLAG_ACTION = 'CARRIER_ADD_FLAG_ACTION';
export const SHIPPER_LIST = 'SHIPPER_LIST';
export const SHIPPER_LIST_SUCCESS = 'SHIPPER_LIST_SUCCESS';
export const SHIPPER_LIST_ERROR = 'SHIPPER_LIST_ERROR';
export const SHIPPER_ADD = 'SHIPPER_ADD';
export const SHIPPER_ADD_SUCCESS = 'SHIPPER_ADD_SUCCESS';
export const SHIPPER_ADD_ERROR = 'SHIPPER_ADD_ERROR';
export const MAIL_SEND_FRGT_PWD = 'MAIL_SEND_FRGT_PWD';
export const MAIL_SEND_FRGT_PWD_SUCCESS = 'MAIL_SEND_FRGT_PWD_SUCCESS';
export const MAIL_SEND_FRGT_PWD_ERROR = 'MAIL_SEND_FRGT_PWD_ERROR';
export const SAVE_NEW_PWD = 'SAVE_NEW_PWD';
export const SAVE_NEW_PWD_SUCCESS = 'SAVE_NEW_PWD_SUCCESS';
export const SAVE_NEW_PWD_ERROR = 'SAVE_NEW_PWD_ERROR';
export const LOAD_ADD_FLAG = 'LOAD_ADD_FLAG';
export const FORGET_PWD_FLAG = 'FORGET_PWD_FLAG';
export const SAVE_FORGET_PWD_FLAG = 'SAVE_FORGET_PWD_FLAG';
export const COMPANY_AUTH_ELD = 'COMPANY_AUTH_ELD';
export const COMPANY_AUTH_ELD_SUCCESS = 'COMPANY_AUTH_ELD_SUCCESS';
export const COMPANY_AUTH_ELD_ERROR = 'COMPANY_AUTH_ELD_ERROR';
export const BROKER_TENDER = 'BROKER_TENDER';
export const BROKER_TENDER_SUCCESS = 'BROKER_TENDER_SUCCESS';
export const BROKER_TENDER_ERROR = 'BROKER_TENDER_ERROR';
export const BROKER_TENDER_FLAG = 'BROKER_TENDER_FLAG';
export const COMPANY_NAME_LIST = 'COMPANY_NAME_LIST';
export const COMPANY_NAME_LIST_SUCCESS = 'COMPANY_NAME_LIST_SUCCESS';
export const COMPANY_NAME_LIST_ERROR = 'COMPANY_NAME_LIST_ERROR';
export const GEOFENCE_COMPANY_NAME_LIST = 'GEOFENCE_COMPANY_NAME_LIST';
export const GEOFENCE_COMPANY_NAME_LIST_SUCCESS = 'GEOFENCE_COMPANY_NAME_LIST_SUCCESS';
export const GEOFENCE_COMPANY_NAME_LIST_ERROR = 'GEOFENCE_COMPANY_NAME_LIST_ERROR';
export const GEOFENCE_CARRIER_COMPANY_NAME_LIST = 'GEOFENCE_CARRIER_COMPANY_NAME_LIST';
export const GEOFENCE_CARRIER_COMPANY_NAME_LIST_SUCCESS = 'GEOFENCE_CARRIER_COMPANY_NAME_LIST_SUCCESS';
export const GEOFENCE_CARRIER_COMPANY_NAME_LIST_ERROR = 'GEOFENCE_CARRIER_COMPANY_NAME_LIST_ERROR';
export const GET_LOAD_REPORTS = 'GET_LOAD_REPORTS';
export const GET_LOAD_REPORTS_SUCCESS = 'GET_LOAD_REPORTS_SUCCESS';
export const GET_LOAD_REPORTS_ERROR = 'GET_LOAD_REPORTS_ERROR';
export const GET_LOAD_STATUS = 'GET_LOAD_STATUS';
export const GET_LOAD_STATUS_SUCCESS = 'GET_LOAD_STATUS_SUCCESS';
export const GET_LOAD_STATUS_ERROR = 'GET_LOAD_STATUS_ERROR';
export const TRIP_REG_REPORT_STATUS = 'TRIP_REG_REPORT_STATUS';
export const TRIP_REG_REPORT_STATUS_SUCCESS = 'TRIP_REG_REPORT_STATUS_SUCCESS';
export const TRIP_REG_REPORT_STATUS_ERROR = 'TRIP_REG_REPORT_STATUS_ERROR';
export const TRUCK_SUMMARY_REPORT = 'TRUCK_SUMMARY_REPORT';
export const TRUCK_SUMMARY_REPORT_SUCCESS = 'TRUCK_SUMMARY_REPORT_SUCCESS';
export const TRUCK_SUMMARY_REPORT_ERROR = 'TRUCK_SUMMARY_REPORT_ERROR';
export const LOAD_SUMMARY_REPORT = 'LOAD_SUMMARY_REPORT';
export const LOAD_SUMMARY_REPORT_SUCCESS = 'LOAD_SUMMARY_REPORT_SUCCESS';
export const LOAD_SUMMARY_REPORT_ERROR = 'LOAD_SUMMARY_REPORT_ERROR';
export const FILE_SIZE_DB = 'FILE_SIZE_DB';
export const FILE_SIZE_DB_SUCCESS = 'FILE_SIZE_DB_SUCCESS';
export const FILE_SIZE_DB_ERROR = 'FILE_SIZE_DB_ERROR';
export const FILE_RECORDS_SIZE_DB = 'FILE_RECORDS_SIZE_DB';
export const FILE_RECORDS_SIZE_DB_SUCCESS = 'FILE_RECORDS_SIZE_DB_SUCCESS';
export const FILE_RECORDS_SIZE_DB_ERROR = 'FILE_RECORDS_SIZE_DB_ERROR';
export const HOS_REPORT = 'HOS_REPORT';
export const HOS_REPORT_SUCCESS = 'HOS_REPORT_SUCCESS';
export const HOS_REPORT_ERROR = 'HOS_REPORT_ERROR';
export const TASK_LIST = 'TASK_LIST';
export const TASK_LIST_SUCCESS = 'TASK_LIST_SUCCESS';
export const TASK_LIST_ERROR = 'TASK_LIST_ERROR';
export const TASK_ADD = 'TASK_ADD';
export const TASK_ADD_SUCCESS = 'TASK_ADD_SUCCESS';
export const TASK_ADD_ERROR = 'TASK_ADD_ERROR';
export const TASK_CHANGE_STATUS = 'TASK_CHANGE_STATUS';
export const TASK_CHANGE_STATUS_SUCCESS = 'TASK_CHANGE_STATUS_SUCCESS';
export const TASK_CHANGE_STATUS_ERROR = 'TASK_CHANGE_STATUS_ERROR';
export const GEOFENCE_RADIUS_STATUS = 'GEOFENCE_RADIUS_STATUS';
export const GEOFENCE_RADIUS_STATUS_SUCCESS = 'GEOFENCE_RADIUS_STATUS_SUCCESS';
export const GEOFENCE_RADIUS_STATUS_ERROR = 'GEOFENCE_RADIUS_STATUS_ERROR';
export const CARRIER_BULK_UPLOAD = 'CARRIER_BULK_UPLOAD';
export const CARRIER_BULK_UPLOAD_SUCCESS = 'CARRIER_BULK_UPLOAD_SUCCESS';
export const CARRIER_BULK_UPLOAD_ERROR = 'CARRIER_BULK_UPLOAD_ERROR';
export const CARRIER_BULK_UPLOAD_FlAG = 'CARRIER_BULK_UPLOAD_FlAG';
export const GET_DASHBOARD_DATA_FACTOR = 'GET_DASHBOARD_DATA_FACTOR';
export const GET_DASHBOARD_DATA_FACTOR_SUCCESS = 'GET_DASHBOARD_DATA_FACTOR_SUCCESS';
export const GET_DASHBOARD_DATA_FACTOR_ERROR = 'GET_DASHBOARD_DATA_FACTOR_ERROR';
export const GET_DASHBOARD_DATA_FACTOR_MUTIPLE_ELD = 'GET_DASHBOARD_DATA_FACTOR_MUTIPLE_ELD';
export const GET_DASHBOARD_DATA_FACTOR_MUTIPLE_ELD_SUCCESS = 'GET_DASHBOARD_DATA_FACTOR_MUTIPLE_ELD_SUCCESS';
export const GET_DASHBOARD_DATA_FACTOR_MUTIPLE_ELD_ERROR = 'GET_DASHBOARD_DATA_FACTOR_MUTIPLE_ELD_ERROR';
// newly added
export const GEOFENCE_RADIUS_LIST = 'GEOFENCE_RADIUS_LIST';
export const GEOFENCE_RADIUS_LIST_SUCCESS = 'GEOFENCE_RADIUS_LIST_SUCCESS';
export const GEOFENCE_RADIUS_LIST_ERROR = 'GEOFENCE_RADIUS_LIST_ERROR';
export const GEOFENCE_MAIL_TIME_LIST = 'GEOFENCE_MAIL_TIME_LIST';
export const GEOFENCE_MAIL_TIME_LIST_SUCCESS = 'GEOFENCE_MAIL_TIME_LIST_SUCCESS';
export const GEOFENCE_MAIL_TIME_LIST_ERROR = 'GEOFENCE_MAIL_TIME_LIST_ERROR';
export const GET_CARRIER_LIST_FACTOR = 'GET_CARRIER_LIST_FACTOR';
export const GET_CARRIER_LIST_FACTOR_SUCCESS = 'GET_CARRIER_LIST_FACTOR_SUCCESS';
export const GET_CARRIER_LIST_FACTOR_ERROR = 'GET_CARRIER_LIST_FACTOR_ERROR';
export const GET_ASSETS_LIST_TECHPARTNER = 'GET_ASSETS_LIST_TECHPARTNER';
export const GET_ASSETS_LIST_TECHPARTNER_SUCCESS = 'GET_ASSETS_LIST_TECHPARTNER_SUCCESS';
export const GET_ASSETS_LIST_TECHPARTNER_ERROR = 'GET_ASSETS_LIST_TECHPARTNER_ERROR';
export const GET_DASHBOARD_DATA_BROKER = 'GET_DASHBOARD_DATA_BROKER';
export const GET_DASHBOARD_DATA_BROKER_SUCCESS = 'GET_DASHBOARD_DATA_BROKER_SUCCESS';
export const GET_DASHBOARD_DATA_BROKER_ERROR = 'GET_DASHBOARD_DATA_BROKER_ERROR';
export const GET_DASHBOARD_DATA_BROKER_MULTIPLE_ELD = 'GET_DASHBOARD_DATA_BROKER_MULTIPLE_ELD';
export const GET_DASHBOARD_DATA_BROKER_MULTIPLE_ELD_SUCCESS = 'GET_DASHBOARD_DATA_BROKER_MULTIPLE_ELD_SUCCESS';
export const GET_DASHBOARD_DATA_BROKER_MULTIPLE_ELD_ERROR = 'GET_DASHBOARD_DATA_BROKER_MULTIPLE_ELD_ERROR';
export const GET_INVITED_COMPANY_LIST = 'GET_INVITED_COMPANY_LIST';
export const GET_INVITED_COMPANY_LIST_SUCCESS = 'GET_INVITED_COMPANY_LIST_SUCCESS';
export const GET_INVITED_COMPANY_LIST_ERROR = 'GET_INVITED_COMPANY_LIST_ERROR';
export const INVITED_NEW_CARRIER = 'INVITED_NEW_CARRIER';
export const INVITED_NEW_CARRIER_SUCCESS = 'INVITED_NEW_CARRIER_SUCCESS';
export const INVITED_NEW_CARRIER_ERROR = 'INVITED_NEW_CARRIER_ERROR';
export const GET_MY_CARRIER_COMPANY_LIST = 'GET_MY_CARRIER_COMPANY_LIST';
export const GET_MY_CARRIER_COMPANY_LIST_SUCCESS = 'GET_MY_CARRIER_COMPANY_LIST_SUCCESS';
export const GET_MY_CARRIER_COMPANY_LIST_ERROR = 'GET_MY_CARRIER_COMPANY_LIST_ERROR';
export const GET_CARRIER_INVITATION = 'GET_CARRIER_INVITATION';
export const GET_CARRIER_INVITATION_SUCCESS = 'GET_CARRIER_INVITATION_SUCCESS';
export const GET_CARRIER_INVITATION_ERROR = 'GET_CARRIER_INVITATION_ERROR';
export const VERIFY_CREDENTIALS_AND_SIGN_LOA = 'VERIFY_CREDENTIALS_AND_SIGN_LOA';
export const VERIFY_CREDENTIALS_AND_SIGN_LOA_SUCCESS = 'VERIFY_CREDENTIALS_AND_SIGN_LOA_SUCCESS';
export const VERIFY_CREDENTIALS_AND_SIGN_LOA_ERROR = 'VERIFY_CREDENTIALS_AND_SIGN_LOA_ERROR';
export const COMPLETE_SIGNUP_CARRIERS = 'COMPLETE_SIGNUP_CARRIERS';
export const COMPLETE_SIGNUP_CARRIERS_SUCCESS = 'COMPLETE_SIGNUP_CARRIERS_SUCCESS';
export const COMPLETE_SIGNUP_CARRIERS_ERROR = 'COMPLETE_SIGNUP_CARRIERS_ERROR';
export const GET_TC_WIDGET_CARRIER_INVITATION = 'GET_TC_WIDGET_CARRIER_INVITATION';
export const GET_TC_WIDGET_CARRIER_INVITATION_SUCCESS = 'GET_TC_WIDGET_CARRIER_INVITATION_SUCCESS';
export const GET_TC_WIDGET_CARRIER_INVITATION_ERROR = 'GET_TC_WIDGET_CARRIER_INVITATION_ERROR';
export const COMPLETE_TC_WIDGET_SIGNUP_CARRIERS = 'COMPLETE_TC_WIDGET_SIGNUP_CARRIERS';
export const COMPLETE_TC_WIDGET_SIGNUP_CARRIERS_SUCCESS = 'COMPLETE_TC_WIDGET_SIGNUP_CARRIERS_SUCCESS';
export const COMPLETE_TC_WIDGET_SIGNUP_CARRIERS_ERROR = 'COMPLETE_TC_WIDGET_SIGNUP_CARRIERS_ERROR';
export const TC_WIDGET_VERIFY_CREDENTIALS_AND_SIGN_LOA = 'TC_WIDGET_VERIFY_CREDENTIALS_AND_SIGN_LOA';
export const TC_WIDGET_VERIFY_CREDENTIALS_AND_SIGN_LOA_SUCCESS = 'TC_WIDGET_VERIFY_CREDENTIALS_AND_SIGN_LOA_SUCCESS';
export const TC_WIDGET_VERIFY_CREDENTIALS_AND_SIGN_LOA_ERROR = 'TC_WIDGET_VERIFY_CREDENTIALS_AND_SIGN_LOA_ERROR';
export const OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA = 'OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA';
export const OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA_SUCCESS = 'OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA_SUCCESS';
export const OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA_ERROR = 'OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA_ERROR';
export const OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA_IN_WIDGET = 'OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA_IN_WIDGET';
export const OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA_IN_WIDGET_SUCCESS = 'OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA_IN_WIDGET_SUCCESS';
export const OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA_IN_WIDGET_ERROR = 'OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA_IN_WIDGET_ERROR';
export const GET_SWEEPSTAKES_CARRIER_INVITATION = 'GET_SWEEPSTAKES_CARRIER_INVITATION';
export const GET_SWEEPSTAKES_CARRIER_INVITATION_SUCCESS = 'GET_SWEEPSTAKES_CARRIER_INVITATION_SUCCESS';
export const GET_SWEEPSTAKES_CARRIER_INVITATION_ERROR = 'GET_SWEEPSTAKES_CARRIER_INVITATION_ERROR';
//NEW ELD ADD
export const POST_NEW_ELD_DATA = 'POST_NEW_ELD_DATA';
export const POST_NEW_ELD_DATA_SUCCESS = 'POST_NEW_ELD_DATA_SUCCESS';
export const POST_NEW_ELD_DATA_ERROR = 'POST_NEW_ELD_DATA_ERROR';
export const GET_LOAD_DATA_RESTORE = 'GET_LOAD_DATA_RESTORE';
export const GET_LOAD_DATA_RESTORE_SUCCESS = 'GET_LOAD_DATA_RESTORE_SUCCESS';
export const GET_LOAD_DATA_RESTORE_ERROR = 'GET_LOAD_DATA_RESTORE_ERROR';
export const POST_EDIT_LOAD_DATA = 'POST_EDIT_LOAD_DATA';
export const POST_EDIT_LOAD_DATA_SUCCESS = 'POST_EDIT_LOAD_DATA_SUCCESS';
export const POST_EDIT_LOAD_DATA_ERROR = 'POST_EDIT_LOAD_DATA_ERROR';
export const GET_ACTIVE_DELEVERIES_LIST = 'GET_ACTIVE_DELEVERIES_LIST';
export const GET_ACTIVE_DELEVERIES_LIST_SUCCESS = 'GET_ACTIVE_DELEVERIES_SUCCESS';
export const GET_ACTIVE_DELEVERIES_LIST_ERROR = 'GET_ACTIVE_DELEVERIES_ERROR';
export const GET_ACTIVE_FUEL_ADVANCE_LIST = 'GET_ACTIVE_FUEL_ADVANCE_LIST';
export const GET_ACTIVE_FUEL_ADVANCE_LIST_SUCCESS = 'GET_ACTIVE_FUEL_ADVANCE_LIST_SUCCESS';
export const GET_ACTIVE_FUEL_ADVANCE_LIST_ERROR = 'GET_ACTIVE_FUEL_ADVANCE_LIST_ERROR';
export const GET_PAST_DELEVERIES_LIST = 'GET_PAST_DELEVERIES_LIST';
export const GET_PAST_DELEVERIES_LIST_SUCCESS = 'GET_PAST_DELEVERIES_LIST_SUCCESS';
export const GET_PAST_DELEVERIES_LIST_ERROR = 'GET_ACTIVE_DELEVERIES_ERROR';
export const GET_PAST_FUEL_ADVANCE_LIST = 'GET_PAST_FUEL_ADVANCE_LIST';
export const GET_PAST_FUEL_ADVANCE_LIST_SUCCESS = 'GET_PAST_FUEL_ADVANCE_LIST_SUCCESS';
export const GET_PAST_FUEL_ADVANCE_LIST_ERROR = 'GET_PAST_FUEL_ADVANCE_LIST_ERROR';
export const POST_NEW_DELIVERY_DATA = 'POST_NEW_DELIVERY_DATA';
export const POST_NEW_DELIVERY_DATA_SUCCESS = 'POST_NEW_DELIVERY_DATA_SUCCESS';
export const POST_NEW_DELIVERY_DATA_ERROR = 'POST_NEW_DELIVERY_DATA_ERROR';
export const POST_NEW_FUEL_ADVANCE_DATA = 'POST_NEW_FUEL_ADVANCE_DATA';
export const POST_NEW_FUEL_ADVANCE_DATA_SUCCESS = 'POST_NEW_FUEL_ADVANCE_DATA_SUCCESS';
export const POST_NEW_FUEL_ADVANCE_DATA_ERROR = 'POST_NEW_FUEL_ADVANCE_DATA_ERROR';
export const SAVE_COMPANY_LOGO = 'SAVE_COMPANY_LOGO';
export const SAVE_COMPANY_LOGO_SUCCESS = 'SAVE_COMPANY_LOGO_SUCCESS';
export const SAVE_COMPANY_LOGO_ERROR = 'SAVE_COMPANY_LOGO_ERROR';
export const SAVE_COMPANY_FAVICON = 'SAVE_COMPANY_FAVICON';
export const SAVE_COMPANY_FAVICON_SUCCESS = 'SAVE_COMPANY_FAVICON_SUCCESS';
export const SAVE_COMPANY_FAVICON_ERROR = 'SAVE_COMPANY_FAVICON_ERROR';
export const UPDATE_FACTOR_BASED_ON_INVOICE = 'UPDATE_FACTOR_BASED_ON_INVOICE';
export const UPDATE_FACTOR_BASED_ON_INVOICE_SUCCESS = 'UPDATE_FACTOR_BASED_ON_INVOICE_SUCCESS';
export const UPDATE_FACTOR_BASED_ON_INVOICE_ERROR = 'UPDATE_FACTOR_BASED_ON_INVOICE_ERROR';
export const UPDATE_NEW_LOGO = 'UPDATE_NEW_LOGO';
export const UPDATE_NEW_LOGO_SUCCESS = 'UPDATE_NEW_LOGO_SUCCESS';
export const UPDATE_NEW_LOGO_ERROR = 'UPDATE_NEW_LOGO_ERROR';














