import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { ChangeLoadStatus, companyListByType, loadActionfrquickdispatch, loadAction, TenderDetails, cancelledloadsbyloadid, loadActionRestore } from '../../../actions/loadActions';
import { STATUS } from '../../../helpers/status';
import moment from 'moment';
import QuickDispatch from './QuickDispatch';
import mapboxgl from 'mapbox-gl';
import $ from 'jquery';
import { convertDistance, getDistance } from 'geolib';
import momentLocalizer from 'react-widgets-moment';
import * as ReactBootstrap from 'react-bootstrap';
import { getVehicleTrackingService } from '../../../services/loadService';
import { formatLocation, getMapboxKey } from '../../../helpers/appUtil';
import { TableDataComponent } from 'UI-Component';
import cancel from '../../../cancel.png';
import edit from '../../../edit.png';
import liveTrack from '../../../live-track.png';
import restore from '../../../restore.png';
// import filterImg from '../../../filterImg.png';
import RestoreLoadPopup from './RestoreLoadPopup';
import CancelLoadConfirmModal from './CancelLoadConfirmModal';
import MaterialUITabs from '../../uicomponents/DataTableGridMUIX';
import { Box } from '@mui/material';

require('dotenv').config();
momentLocalizer(moment)
// var filterMenuOptions = {
// 	Size: ["XS", "S", "M", "L", "XL"],
// 	Color: ["Red", "Green", "Blue", "Black", "White"],
// 	For: ["Men", "Women", "Children", "Infants"]
// };
var loadRequested;
class LoadList extends Component {
	constructor(props) {
		super(props);
		this.getIndex = this.getIndex.bind(this)
		this.myloads = [];
		this.requested = [];
		this.notyetdispatched = [];
		this.dispatched = [];
		this.delivered = [];
		this.accepted = [];
		this.cancelledLoadsData = [];
		this.tendered = [];
		this.delivery_uc = [];
		this.delivery_c = [];
		this.paid_close = [];
		this.currentStatus = STATUS.LOAD.REQUESTED;
		this.cancelled = [];
		this.totalItemsCount = 1;
		this.activePage = 1;
		this.itemsCountPerPage = 5;
		this.pageRangeDisplayed = 5;
	}
	getIndex(index, selectedId) {
		this.setState({ activeId: selectedId })
	}
	state = {
		modaldata: [],
		cancelledloads: [],
		checkboxval: false,
		mapobject: null,
		showModal: false,
		distance: '',
		tabIndex: 0
	}
	componentDidMount() {
		$('#notyetdispatchedbrkr').hide();
		mapboxgl.accessToken = getMapboxKey();
		localStorage.setItem('cancelLoadIds', 0)
	}
	handleGetSelectedData = () => {
		if (this.node.selectionContext.selected.length === 0) {
			toastr.info('Info', 'Select Load!');
			return;
		}
		else {
			const params = {
				loadsId: this.node.selectionContext.selected
			}

			this.props.dispatch(cancelledloadsbyloadid(params))
		}
	}
	callRestoreLoad = (params) => {
		this.props.dispatch(loadActionRestore(params));
	}

	selectLoad = (e, index, row) => {
		row[index].checkboxvalue = e.target.checked;
		this.setState({
			checkboxval: e.target.checked
		});
	}

	addloadFun = () => {
		$('#LoadDateVal_input').val('');
	}

	tabChangefrquickdispatch = (type,index) => {
		this.setState({ tabType: type.loadStatusId,tabIndex:index });
		if (type.loadStatusId === 8) {
			$('#reqcancelbtnbrkr').hide();
			$('#notyetdispatchedbrkr').show();
			$('#addloadbroker').hide();

		}
		else if (type.loadStatusId === 1) {
			$('#reqcancelbtnbrkr').show();
			$('#notyetdispatchedbrkr').hide();
			$('#addloadbroker').show();
		}
		else {
			$('#reqcancelbtnbrkr').hide();
			$('#notyetdispatchedbrkr').hide();
			$('#addloadbroker').hide();
		}
		const params = {
			...STATUS.LOAD.REQUESTED,
		}
		this.props.dispatch(loadActionfrquickdispatch(params));
		this.indicationfrLoadlist(true);
		this.currentStatus = type;
	}

	tabChange = (type, index) => {
		console.log('type,index', type, index)
		this.setState({ tabType: type.loadStatusId,tabIndex:index });
		if (type.loadStatusId === 8) {
			$('#reqcancelbtnbrkr').hide();
			$('#notyetdispatchedbrkr').show();
			$('#addloadbroker').hide();

		}
		else if (type.loadStatusId === 1) {
			$('#reqcancelbtnbrkr').show();
			$('#notyetdispatchedbrkr').hide();
			$('#addloadbroker').show();
		}
		else {
			$('#reqcancelbtnbrkr').hide();
			$('#notyetdispatchedbrkr').hide();
			$('#addloadbroker').hide();
		}
		const params = {
			...type,
		}
		this.props.dispatch(loadAction(params));
		this.currentStatus = type;
	}

	getModal = (item, dispatch) => {
		this.setState({ showModal: true, modaldata: item });
	};

	dispacthFun(load) {
		const params = {
			loadId: load
		}
		this.props.dispatch(ChangeLoadStatus(params));
	}

	openTenderOrder(id) {
		const params = {
			loadId: id
		}
		const params1 = {
			companytypeid: 3
		}
		this.props.dispatch(TenderDetails(params));
		this.props.dispatch(companyListByType(params1));
	}
	componentDidUpdate() {
		if (this.props.restoredloadflag === true) {
			if (this.props.data.cancelledloadlist?.status === 'OK') {
				toastr.success('Success', this.props.data.cancelledloadlist.message);
				const params = {
					...(STATUS.LOAD.CANCELED)
				}
				this.props.dispatch(loadAction(params));
			}
			else if (this.props.data.cancelledloadlist?.status === 'BAD_REQUEST') {
				toastr.error('Error', this.props.data.cancelledloadlist.validationErrors[0]);
				const params = {
					...(STATUS.LOAD.CANCELED)
				}
				this.props.dispatch(loadAction(params));
			}

		}

	}

	editloadpopup(loadid) {
		// const params = {
		// 	loadId: loadid
		// }
		// this.props.dispatch(editloadvalues(params));
	}
	restoreloadpopup(loadid, loadNo) {
		const params = {
			loadId: loadid,
			loadNo: loadNo
		}
		this.setState({ loadData: params })
	}
	cancelloadpopup(loadid, loadNo) {
		this.setState({ toBeCancelledLoadID: loadid, toBeCancelledLoadNo: loadNo })
	}
	handleTabClicked = async (index, val) => {
		await this.setState({
			...this.state,
			toBeCancelledLoadID: val[index].loadId,
			toBeCancelledLoadNo: val[index].loadNumber
		})
	}
	setLoadEditValues = async (e, val) => {
		await this.setState({
			editableValues: val,
		})
		this.props.parentCallback(this.state.editableValues);
	}
	close = (e) => {
		this.setState({
			showModal: false
		});
	}
	createMarkerElement(id, imageurl, height, width) {
		let el = document.createElement('div');
		el.className = 'marker';
		el.id = 'map-markers-' + id;
		el.style.backgroundImage =
			'url(' + imageurl + ')';
		el.style.width = width;
		el.style.height = height;
		return el;
	}
	initHistoryMap() {
		try {
			//return;
			this.setState({
				...this.state,
				showModal: true
			});
			let mapelement = document.querySelector('#map');
			if (mapelement === null) {
				return false;
			}
			let body = document.body,
				html = document.documentElement;
			let height = Math.min(body.scrollHeight, body.offsetHeight,
				html.clientHeight, html.scrollHeight, html.offsetHeight);
			height -= 150;
			mapelement.style.height = height + 'px';
			let mapobject = new mapboxgl.Map({
				container: 'map', // container id
				style: 'mapbox://styles/mapbox/streets-v11', //stylesheet location
				center: [-96, 37], // starting position
				zoom: 3.5 // st arting zoom
			});
			mapobject.on('load', function () {

			});
			this.setState({
				mapobject: mapobject
			});
			return true;
		}
		catch (e) {
			return false;
		}
	}
	plotInitialMarkers(mapobject, trackingdata) {
		let firstMarkerImage = 'https://maps.google.com/mapfiles/ms/icons/red.png';
		let finalMarkerImage = 'https://maps.google.com/mapfiles/ms/icons/green.png';
		let initialMarkerEl = this.createMarkerElement(0, firstMarkerImage, '32px', '32px');
		let finalMarkerEl = this.createMarkerElement(trackingdata.length - 1, finalMarkerImage, '32px', '32px');
		this.addMarkerToMap(initialMarkerEl, mapobject, trackingdata[0]);
		this.addMarkerToMap(finalMarkerEl, mapobject, trackingdata[trackingdata.length - 1]);
	}
	plotMovementMarkers(mapobject, trackingdata) {
		let pointsImage = 'https://truckercloud.s3.us-east-2.amazonaws.com/images/blue.png';
		trackingdata.forEach(function (data, i) {
			let pointsElement = this.createMarkerElement(i, pointsImage, '8px', '8px');
			this.addMarkerToMap(pointsElement, mapobject, data);
		}.bind(this));
	}
	addMarkerToMap(markerelement, mapobject, locationdata) {
		let speed = parseFloat(locationdata.speed);
		if (isNaN(speed)) {
			speed = '';
		}
		else {
			speed = speed.toFixed(2) + ' mph';
		}
		let location = formatLocation(locationdata.address);
		let popup = new mapboxgl.Popup({
			offset: 25,
			closeButton: false,
			closeOnClick: false
		}).setHTML('<span className="map-popup-label">Truck No. : </span><span className="map-popup-label">' + locationdata.vehicleno + '</span></br><span className="map-popup-label1"> Date : </span><span className="map-popup-label">' + locationdata.utctime + '</span></br><span className="map-popup-label1">Speed : </span><span className="map-popup-label">' + speed + '</span></br><span className="map-popup-label2">Location : </span><span className="map-popup-label">' + location + '</span></br>');
		let newmarker = new mapboxgl.Marker(markerelement)
			.setLngLat([locationdata.longitude, locationdata.latitude])
			.setPopup(popup)
			.addTo(mapobject);
		let markerDiv = newmarker.getElement();
		markerDiv.addEventListener('mouseenter', () => newmarker.togglePopup());
		markerDiv.addEventListener('mouseleave', () => newmarker.togglePopup());
	}
	checkIfDataDuplicate(previouslat, previouslon, currentlat, currentlon) {
		let response = false;
		if (previouslat === currentlat && previouslon === currentlon) {
			response = true;
		}
		previouslat = previouslat.toFixed(5);
		previouslon = previouslon.toFixed(5);
		currentlat = currentlat.toFixed(5);
		currentlon = currentlon.toFixed(5);
		if (previouslat === currentlat && previouslon === currentlon) {
			response = true;
		}
		return response;
	}
	removeDuplicateData(coords) {
		let cleandata = [];
		let previouslat = 0.0;
		let previouslon = 0.0;
		coords.forEach(function (data, i) {
			if (!this.checkIfDataDuplicate(previouslat, previouslon, parseFloat(data.latitude), parseFloat(data.longitude))) {
				cleandata.push(data);
			}
			previouslat = parseFloat(data.latitude);
			previouslon = parseFloat(data.longitude);
		}.bind(this));
		return cleandata;
	}
	calculateDistance(trackingData, coords) {
		var distance = getDistance(
			{ latitude: trackingData[0].latitude, longitude: trackingData[0].longitude },
			{ latitude: trackingData[coords.length - 1].latitude, longitude: trackingData[coords.length - 1].longitude }
		);

		distance = convertDistance(distance, 'km');
		this.setState({
			...this.state,
			distance: distance.toFixed(2)
		});
	}
	async handleVehicleData(req) {
		const response = await getVehicleTrackingService(req);
		if (response.loadVehicleTrackingList === null) {
			toastr.info('Info', 'No Data Available!');
		}
		else if (response.length === 0) {
			toastr.info('Info', 'No Data Available!');
		}
		else if (response.length > 0) {
			this.showMap(response)
		}
		else {
			toastr.info('Info', 'No Data Available!');
		}
	}
	editLoadFun = async (e, row) => {
		await this.setState({ loadValues: row })
		this.setLoadEditValues(e, row)
		$('#LoadDateVal_input').val('');
	}
	showMap(response) {
		this.initHistoryMap();
		//force initializing map
		let mapobject = this.state.mapobject;
		let geojson = {
			'type': 'FeatureCollection',
			'features': [
				{
					'type': 'Feature',
					'geometry': {
						'type': 'LineString',
						'coordinates': []
					}
				}
			]
		};
		//force initializing map
		let plottimer = null;
		let i = 0;
		let pathdata = [];
		let currentzoom = 15;
		let coords = response.map(data => {
			return {
				latitude: parseFloat(data.latitude),
				longitude: parseFloat(data.longitude),
				vehicleno: data.license_Plate_No,
				speed: data.speed,
				utctime: data.convertedDate,
				address: data.location
			}
		});

		this.calculateDistance(response, coords);
		pathdata = coords;
		mapobject.setCenter([coords[0].longitude, coords[0].latitude]);
		mapobject.setZoom(currentzoom);
		let datalength = pathdata.length;
		this.plotInitialMarkers(mapobject, pathdata);
		mapobject.on('load', function () {
			mapobject.addSource('movementroute', {
				'type': 'geojson',
				'data': geojson
			});
			mapobject.addLayer({
				'id': 'historypath',
				'type': 'line',
				'source': 'movementroute',
				'layout': {
					'line-join': 'round',
					'line-cap': 'round'
				},
				'paint': {
					'line-color': 'red',
					'line-opacity': 0.75,
					'line-width': 3
				}
			});
			plottimer = setInterval(function () {
				if (pathdata[i].latitude && pathdata[i].longitude) {
					let currentposition = [pathdata[i].longitude, pathdata[i].latitude];
					geojson.features[0].geometry.coordinates.push(currentposition);
					mapobject.getSource('movementroute').setData(geojson);
					i++;
					if (!mapobject.getBounds().contains(currentposition)) {
						currentzoom = currentzoom - 1;
						mapobject.setZoom(currentzoom);
					}
					if (i >= datalength) {
						clearInterval(plottimer);
						this.plotMovementMarkers(mapobject, pathdata)
					}
				}
			}.bind(this, mapobject), 100);
		}.bind(this));
	}
	render() {
		console.log('this.state.tabIndex',this.state.tabIndex )
		var indicationhtml;
		var Modal = ReactBootstrap.Modal;
		const { loading } = this.props;
		if (this.props.LoadList !== undefined) {
			if (this.currentStatus === STATUS.LOAD.REQUESTED) {
				this.requested = this.props.LoadList;
			}
			else if (this.currentStatus === STATUS.LOAD.TENDERED) {
				this.tendered = this.props.LoadList;
			}
			else if (this.currentStatus === STATUS.LOAD.DISPATCHEDENROUTE) {
				this.dispatched = this.props.LoadList;
			}
			else if (this.currentStatus === STATUS.LOAD.DELIVERY_CONFIRMED) {
				this.notyetdispatched = this.props.LoadList;
			}
			else if (this.currentStatus === STATUS.LOAD.DELIVERED) {
				this.delivered = this.props.LoadList;
			}
			else if (this.currentStatus === STATUS.LOAD.CANCELLED) {
				this.cancelledLoadsData = this.props.LoadList;
			}
		}
		var notyetdispatched = [];
		var dispatched = [];
		var delivered = [];
		var cancelledLoadsData = [];
		if (loading) {
			indicationhtml =
				<div className='spinner-border' role='status'>
					<div className='row react-bs-table-no-data'></div>
				</div>
		}
		else {
			indicationhtml = 'No Data Found';
			var loadreqlist = [];

			if (this.requested.length > 0) {
				 loadreqlist = this.requested;

				loadRequested = [];
				loadRequested = this.requested.map(
					row => {
						var loadDate = '';
						if (row.loadDate !== '') {
							loadDate = moment(row.loadDate).format('MM-DD-YYYY');
						}
						else {
							loadDate = '';
						}
						var pickUpLocation = '';
						if (row.city !== null && row.state !== null && row.zipCode !== null) {
							pickUpLocation = row.city + ', ' + row.state + ', ' + row.zipCode
						}
						else if (row.city !== null && row.state !== null) {
							pickUpLocation = row.city + ', ' + row.state
						}
						else if (row.city !== null && row.zipCode !== null) {
							pickUpLocation = row.city + ', ' + row.zipCode
						}
						else if (row.city !== null) {
							pickUpLocation = row.city
						}
						else if (row.state !== null) {
							pickUpLocation = row.state
						}
						else if (row.zipCode !== null) {
							pickUpLocation = row.zipCode
						}

						var DeliveryLocation = '';
						if (row.city !== null && row.state !== null && row.zipCode !== null) {
							DeliveryLocation = row.city + ', ' + row.state + ', ' + row.zipCode
						}
						else if (row.city !== null && row.state !== null) {
							DeliveryLocation = row.city + ', ' + row.state
						}
						else if (row.city !== null && row.zipCode !== null) {
							DeliveryLocation = row.city + ', ' + row.zipCode
						}
						else if (row.city !== null) {
							DeliveryLocation = row.city
						}
						else if (row.state !== null) {
							DeliveryLocation = row.state
						}
						else if (row.zipCode !== null) {
							DeliveryLocation = row.zipCode
						}
						return ({
							id: row.loadId,
							loadNumber: row.loadNumber,
							loadDate: loadDate,
							shipperName: row.shipperInfo?.name,
							customerName: row.customerName,
							carrierName: row.carrierInfo?.name,
							consigneeName: row.consigneeName,
							consigneeAddress: ((row.consigneeAddress1 === null ? '' : row.consigneeAddress1) + ' ' + (row.consigneeAddress2 === null ? '' : row.consigneeAddress2)),
							weight: row.weight,
							originCityName: row.originCityName,
							city: row.city,
							commodity: row.commodity,
							equipmentType: row.truckTypeName,
							loadPoints: row.loadPoints,
							basecharge: row.baseCharges,
							fulecharge: row.fuelCharges,
							accessiorialcharge: row.accessorialCharges,
							totalrevenue: row.totalRevenue,
							loadedmiles: row.loadedMiles,
							emptymiles: row.emptyMiles,
							totalmiles: row.totalMiles,
							driverpay: row.driverPay,
							tenderprice: row.shipperLoadPrice,
							carrierTenderPrice: row.carrierTenderPrice,
							rate: row.rate,
							totalBrokerNet: row.totalBrokerNet,
							originState: row.orginStateName,
							assetNumber: row.vehicleInfo?.assetNumber,
							distance: row.distance,
							destinationState: row.destinationStateName,
							pickUpLocation: pickUpLocation,
							DeliveryLocation: DeliveryLocation,
							action: <div><a href='/#' onClick={() => { this.dispacthFun(row.loadId); }} className='btn_carrier_move btn btn-sm btn-primary'>Accept</a>
								&nbsp;&nbsp;&nbsp;<a href='/#' className='btn_carrier_move btn btn-sm btn-primary' data-toggle='modal' onClick={() => { this.editloadpopup(row.loadId); }} data-target='#editloadpopup'>Edit</a></div>
						});
					}
				);
			}
			else {
				loadRequested = [];
				indicationhtml = 'No Data Found'
			}
			//assigned Tab
			if (this.notyetdispatched.length > 0) {
				notyetdispatched = []
				notyetdispatched = this.notyetdispatched.map(
					(row, index) => {
						return ({
							id: row.loadId,
							loadNumber: row.loadNumber,
							loadDate: moment(row.loadDate).format('MM-DD-YYYY'),
							shipperName: row.shipperInfo?.name,
							customerName: row.customerName,
							carrierName: row.carrierInfo?.name,
							consigneeName: row.consigneeName,
							consigneeAddress: ((row.consigneeAddress1 === null ? '' : row.consigneeAddress1) + ' ' + (row.consigneeAddress2 === null ? '' : row.consigneeAddress2)),
							weight: row.weight,
							originCityName: row.city,
							city: row.city,
							commodity: row.commodity,
							equipmentType: row.truckTypeName,
							rate: row.rate,
							carrierTenderPrice: row.carrierTenderPrice,
							totalBrokerNet: row.totalBrokerNet,
							action: <span>
								<img src={edit} alt='Not Found' data-toggle='modal' id='editloadbroker' data-target='.editLoadModal' onClick={(e) => { this.editLoadFun(e, row); }} /> &ensp;
								{/* <img src={edit} /> */}
								<img src={cancel} alt='Not Found' data-toggle='modal' id='cancelConfirmload' data-target='#cancelConfirmloadModal' onClick={() => { this.cancelloadpopup(row.loadId, row.loadNumber); }} />
							</span>,
							loadPoints: row.loadPoints,
							basecharge: row.baseCharges,
							fulecharge: row.fuelCharges,
							accessiorialcharge: row.accessorialCharges,
							totalrevenue: row.totalRevenue,
							loadedmiles: row.loadedMiles,
							emptymiles: row.emptyMiles,
							totalmiles: row.totalMiles,
							driverpay: row.driverPay,
							tenderprice: row.shipperLoadPrice,
							originState: row.orginStateName,
							destinationState: row.destinationStateName,
							assetNumber: row.vehicleInfo?.assetNumber,
							// driverName: drivername,
							distance: row.distance,
							checkboxvalue: this.state.checkboxval,
							// pickUpLocation: pickUpLocation,
							// DeliveryLocation: DeliveryLocation,
							locationchckbox: <input type='checkbox' id={'chkvaluefrshipper' + row.loadId} defaultChecked={true} />,
							pickUpdate: row.loadDate,
							contactNumber: row.contactNumber,
						});
					}
				);
			}
			else {
				notyetdispatched = [];
				indicationhtml = 'No Data Found'
			}
			//Dispatched Tab

			if (this.dispatched.length > 0) {
				dispatched = []
				dispatched = this.dispatched.map(
					row => {
						return ({
							id: row.loadId,
							loadNumber: row.loadNumber,
							// loadDate: loadDate,
							loadDate: moment(row.loadDate).format('MM-DD-YYYY'),
							shipperName: row.shipperInfo?.name,
							customerName: row.customerName,
							carrierName: row.carrierInfo?.name,
							consigneeName: row.consigneeName,
							consigneeAddress: ((row.consigneeAddress1 === null ? '' : row.consigneeAddress1) + ' ' + (row.consigneeAddress2 === null ? '' : row.consigneeAddress2)),
							weight: row.weight,
							originCityName: row.city,
							city: row.city,
							commodity: row.commodity,
							equipmentType: row.truckTypeName,
							action: <span> <img src={liveTrack} alt='Not Found' onClick={(e) => this.handleVehicleData(row.loadId)} /></span>,
							loadPoints: row.loadPoints,
							basecharge: row.baseCharges,
							fulecharge: row.fuelCharges,
							accessiorialcharge: row.accessorialCharges,
							totalrevenue: row.totalRevenue,
							loadedmiles: row.loadedMiles,
							emptymiles: row.emptyMiles,
							totalmiles: row.totalMiles,
							driverpay: row.driverPay,
							tenderprice: row.shipperLoadPrice,
							carrierTenderPrice: row.carrierTenderPrice,
							rate: row.rate,
							totalBrokerNet: row.totalBrokerNet,
							originState: row.orginStateName,
							destinationState: row.destinationStateName,
							assetNumber: row.vehicleInfo?.assetNumber,
							// driverName: drivername,
							distance: row.distance,
							// pickUpLocation: pickUpLocation,
							// DeliveryLocation: DeliveryLocation,
							pickUpdate: row.loadDate,
							contactNumber: row.contactNumber
						});
					}
				);
			}
			else {
				dispatched = [];
				indicationhtml = 'No Data Found'
			}
			//delivered tab
			if (this.delivered.length > 0) {
				delivered = []
				delivered = this.delivered.map(
					row => {
						return ({
							id: row.loadId,
							loadNumber: row.loadNumber,
							// loadDate: loadDate,
							loadDate: moment(row.loadDate).format('MM-DD-YYYY'),
							shipperName: row.shipperInfo?.name,
							customerName: row.customerName,
							carrierName: row.carrierInfo?.name,
							consigneeName: row.consigneeName,
							consigneeAddress: ((row.consigneeAddress1 === null ? '' : row.consigneeAddress1) + ' ' + (row.consigneeAddress2 === null ? '' : row.consigneeAddress2)),
							weight: row.weight,
							originCityName: row.city,
							city: row.city,
							commodity: row.commodity,
							equipmentType: row.truckTypeName,
							loadPoints: row.loadPoints,
							basecharge: row.baseCharges,
							fulecharge: row.fuelCharges,
							accessiorialcharge: row.accessorialCharges,
							totalrevenue: row.totalRevenue,
							loadedmiles: row.loadedMiles,
							emptymiles: row.emptyMiles,
							totalmiles: row.totalMiles,
							driverpay: row.driverPay,
							tenderprice: row.shipperLoadPrice,
							carrierTenderPrice: row.carrierTenderPrice,
							originState: row.orginStateName,
							destinationState: row.destinationStateName,
							assetNumber: row.vehicleInfo?.assetNumber,
							// driverName: drivername,
							distance: row.distance,
							// pickUpLocation: pickUpLocation,
							// DeliveryLocation: DeliveryLocation,
							// deliverydate: deliverydate,
							pickUpdate: row.loadDate,
							contactNumber: row.contactNumber,
							action: <span> <img src={liveTrack} alt='To Be Loaded' onClick={(e) => this.handleVehicleData(row.loadId)} /></span>,
							truckMap: <a href='/#' onClick={(e) => this.handleVehicleData(row.loadId)} className='icon_truck_location btn pc-mailer-tooltip' ><img src={'/images/mapicon.png'} alt='To Be Loaded' /></a>,
						});
					}
				);
			}
			else {
				delivered = [];
				indicationhtml = 'No Data Found'
			}
			//cancelled tab
			if (this.cancelledLoadsData.length > 0) {
				cancelledLoadsData = this.cancelledLoadsData;
				cancelledLoadsData = [];
				cancelledLoadsData = this.cancelledLoadsData.map(
					row => {
						return ({
							id: row.loadId,
							loadNumber: row.loadNumber,
							loadDate: row.loadDate,
							shipperName: row.shipperInfo?.name,
							customerName: row.customerName,
							carrierName: row.carrierInfo?.name,
							consigneeName: row.consigneeName,
							consigneeAddress: ((row.consigneeAddress1 === null ? '' : row.consigneeAddress1) + ' ' + (row.consigneeAddress2 === null ? '' : row.consigneeAddress2)),
							weight: row.weight,
							originCityName: row.city,
							city: row.city,
							commodity: row.commodity,
							equipmentType: row.truckTypeName,
							loadPoints: row.loadPoints,
							basecharge: row.baseCharges,
							fulecharge: row.fuelCharges,
							accessiorialcharge: row.accessorialCharges,
							totalrevenue: row.totalRevenue,
							loadedmiles: row.loadedMiles,
							emptymiles: row.emptyMiles,
							totalmiles: row.totalMiles,
							driverpay: row.driverPay,
							tenderprice: row.shipperLoadPrice,
							carrierTenderPrice: row.carrierTenderPrice,
							originState: row.orginStateName,
							destinationState: row.destinationStateName,
							assetNumber: row.vehicleInfo?.assetNumber,
							distance: row.distance,
							brokerName: row.brokerName,
							shipperref: row.shipperReferenceNumber,
							pickUpdate: row.loadDate,
							contactNumber: row.contactNumber,
							cancelledDate: row.cancelDate,
							action: <span> <img src={restore} alt='Not Found' data-toggle='modal' id='restoreload' data-target='#restoreloadModal' onClick={() => { this.restoreloadpopup(row.loadId, row.loadNumber); }} /></span>,
						});
					}
				);
			}
			else {
				cancelledLoadsData = [];
				indicationhtml = 'No Data Found'
			}
		}
		const { SearchBar } = Search;
		const accptdcolumns = [
			{
				text: 'Load #',
				sort: true,
				dataField: 'loadNumber'
			},
			{
				text: 'Date',
				sort: true,
				dataField: 'loadDate'
			},
			{
				text: 'Shipper',
				sort: true,
				dataField: 'shipperName'
			},
			{
				text: 'Carrier',
				sort: true,
				dataField: 'carrierName'
			},
			{
				text: 'Truck No',
				sort: true,
				dataField: 'assetNumber'
			},
			{
				text: 'Action',
				dataField: 'action'
			}
		]

		const notyetdispatchedcolumns = [
			{
				text: 'Load #',
				sort: true,
				dataField: 'loadNumber'
			},
			{
				text: 'Date',
				sort: true,
				dataField: 'loadDate'
			},
			{
				text: 'Shipper name',
				sort: true,
				dataField: 'shipperName'
			},
			{
				text: 'Carrier',
				sort: true,
				dataField: 'carrierName'
			},
			{
				text: 'Truck No',
				sort: true,
				dataField: 'assetNumber'
			},
			{
				text: 'Action',
				dataField: 'action'
			}
		]

		const deliverdcolumns = [
			{
				text: 'Load #',
				sort: true,
				dataField: 'loadNumber'
			},
			{
				text: 'Date',
				sort: true,
				dataField: 'loadDate'
			},
			{
				text: 'Shipper',
				sort: true,
				dataField: 'shipperName'
			},
			{
				text: 'Carrier',
				sort: true,
				dataField: 'carrierName'
			},
			{
				text: 'Truck No',
				sort: true,
				dataField: 'assetNumber'
			},
			{
				text: 'Action',
				dataField: 'action'
			}
		]
		const cancelledcolumns = [
			{
				text: 'Load #',
				dataField: 'loadNumber',
				sort: true
			},
			{
				text: 'Date',
				dataField: 'loadDate',
				sort: true
			},
			{
				text: 'Shipper',
				dataField: 'shipperName',
				sort: true
			},
			{
				text: 'Carrier',
				sort: true,
				dataField: 'carrierName'
			},
			{
				text: 'Cancelled Date',
				dataField: 'cancelledDate',
				sort: true
			},
			{
				text: 'Action',
				dataField: 'action'
			}
		];
		const expandRow2 = {
			renderer: row => (
				<article className='table-data truck-data shadow bg-white rounded'>
					<div className='row no-gutters'>
						<div className='col-md-12'>
							<ul className='nav nav-tabs' id='myTrucks' role='tablist'>
								<li className='nav-item'>
									<a className='nav-link active' id={`pickup-tab_${row.id}`} data-toggle='tab' href={`#pickup_${row.id}`} role='tab' aria-controls='pickup' aria-selected='true' >
										pickup Details
									</a>
								</li>
								<li className='nav-item'>
									<a className='nav-link' id='delivery-tab' data-toggle='tab' href={`#delivery_${row.id}`} role='tab' aria-controls='delivery' aria-selected='false' >
										Delivery Details
									</a>
								</li>
							</ul>
							<div className='tab-content' id='myTabContent'>
								<div className='tab-pane fade show active' id={`pickup_${row.id}`} role='tabpanel' aria-labelledby='pickup-tab'>
									<table className='table table-sm'>
										<thead>
											<tr>
												<th scope='col'>Address</th>
												<th scope='col'>Contact</th>
												{/* <th scope='col'>Pickup Date / Time</th> */}
												<th scope='col'>Earliest Time</th>
												<th scope='col'>Latest Time</th>
											</tr>
										</thead>
										<tbody>
											{
												row.loadPoints.length ? (row.loadPoints.map(function (doc, index) {
													if (doc.typeId === 2) {
														var contactDetails = '';
														let contactNumber = '';
														if (doc.contactNumber !== null) {
															contactNumber = doc.contactNumber
														}
														if (doc.city !== null && doc.state !== null && doc.zipCode !== null) {
															contactDetails = doc.city + ', ' + doc.state + ', ' + doc.zipCode
														}
														else if (doc.city !== null && doc.state !== null) {
															contactDetails = doc.city + ', ' + doc.state
														}
														else if (doc.city !== null && doc.zipCode !== null) {
															contactDetails = doc.city + ', ' + doc.zipCode
														}
														else if (doc.city !== null) {
															contactDetails = doc.city
														}
														else if (doc.state !== null) {
															contactDetails = doc.state
														}
														else if (doc.zipCode !== null) {
															contactDetails = doc.zipCode
														}
														return (
															<tr key={index}>
																<td>{contactDetails}</td>
																<td>{contactNumber}</td>
																{/* <td>{row.pickUpdate}</td> */}
																<td>{moment(doc.earliestTime).format('MM-DD-YYYY')}</td>
																<td>{moment(doc.latestTime).format('MM-DD-YYYY')}</td>
															</tr>
														)
													}
												})) : (<tr><td className='text-center' colSpan='4'>No Record Found</td></tr>)
											}
										</tbody>
									</table>
								</div>
								<div className='tab-pane fade' id={`delivery_${row.id}`} role='tabpanel' aria-labelledby='delivery-tab'>
									<table className='table table-sm'>
										<thead>
											<tr>
												<th scope='col'>Address</th>
												<th scope='col'>Contact</th>
												{/* <th scope='col'>Pickup Date / Time</th> */}
												<th scope='col'>Earliest Time</th>
												<th scope='col'>Latest Time</th>
											</tr>
										</thead>
										<tbody>
											{
												row.loadPoints.length ? (row.loadPoints.map(function (doc, index) {
													if (doc.typeId === 3) {
														var contactDetails = '';
														let contactNumber = '';
														if (doc.contactNumber !== null) {
															contactNumber = doc.contactNumber
														}
														if (doc.city !== null && doc.state !== null && doc.zipCode !== null) {
															contactDetails = doc.city + ', ' + doc.state + ', ' + doc.zipCode
														}
														else if (doc.city !== null && doc.state !== null) {
															contactDetails = doc.city + ', ' + doc.state
														}
														else if (doc.city !== null && doc.zipCode !== null) {
															contactDetails = doc.city + ', ' + doc.zipCode
														}
														else if (doc.city !== null) {
															contactDetails = doc.city
														}
														else if (doc.state !== null) {
															contactDetails = doc.state
														}
														else if (doc.zipCode !== null) {
															contactDetails = doc.zipCode
														}
														return (
															<tr key={index}>
																<td>{contactDetails}</td>
																<td>{contactNumber}</td>
																{/* <td>{row.pickUpdate}</td> */}
																<td>{moment(doc.earliestTime).format('MM-DD-YYYY')}</td>
																<td>{moment(doc.latestTime).format('MM-DD-YYYY')}</td>
															</tr>
														)
													}
												})) : (<tr><td className='text-center' colSpan='4'>No Record Found</td></tr>)
											}
										</tbody>
									</table>
								</div>
								<div className='tab-pane fade' id={`brokertender_${row.id}`} role='tabpanel' aria-labelledby='brokertender-tab'>
									<table className='table table-sm'>
										<thead>
											<tr>
												<th scope='col'>Base Charge</th>
												<th scope='col'>Fuel Charge</th>
												<th scope='col'>Accessorial Charge</th>
												<th scope='col'>Tendered Price</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>{(row.basecharge === null) ? <i>&nbsp;</i> : (row.basecharge)}</td>
												<td>{(row.fulecharge === null) ? <i>&nbsp;</i> : (row.fulecharge)}</td>
												<td>{(row.accessiorialcharge === null) ? <i>&nbsp;</i> : (row.accessiorialcharge)}</td>
												<td>{(row.carrierTenderPrice === null) ? <i>&nbsp;</i> : (row.carrierTenderPrice)}</td>
											</tr>
										</tbody>
									</table>
								</div>

							</div>
						</div>
					</div>
				</article>
			),
			showExpandColumn: true,
			expandByColumnOnly: true,
			clickToSelect: false,
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				if (isAnyExpands) {
					return (
						<i className='icofont-minus-circle'></i>
					);
				}
				return (
					<i className='icofont-plus-circle'></i>
				);
			},
			expandColumnRenderer: ({ expanded }) => {
				if (expanded) {
					return (
						<i className='icofont-minus-circle'></i>
					);
				}
				return (
					<i className='icofont-plus-circle'></i>
				);
			}
		};
		const customTotal = (from, to, size) => (
			<span className='react-bootstrap-table-pagination-total'>
				Showing {from} to {to} of {size} Results
			</span>
		);

		const options = {
			paginationSize: 4,
			pageStartIndex: 1,
			firstPageText: 'First',
			prePageText: 'Back',
			nextPageText: 'Next',
			lastPageText: 'Last',
			nextPageTitle: 'First page',
			prePageTitle: 'Pre page',
			firstPageTitle: 'Next page',
			lastPageTitle: 'Last page',
			showTotal: true,
			paginationTotalRenderer: customTotal
		};

		return (
			<article className='table-data truck-data shadow bg-white rounded' id='htmlclr'>
				<div className='row no-gutters'>
					<div className='col-md-12'>
						<div className='row'>
							<div className='col-md-12'>
								<div className='d-flex justify-content-end'>
									<ul className='ul-horizontal tab-menus'>
										<li>
											<a href='/#' className='btn btn-primary btn-sm' data-toggle='modal' id='addloadbroker' data-target='.addLoadModal' onClick={() => { this.addloadFun(); }}>Add Load</a>
											{/* <a href='#' className='btn btn-primary btn-sm' disabled >Add Load</a> */}
										</li>
										{/* <li>
											<a href='#' id='reqcancelbtnbrkr' onClick={(e, val) => { this.handleTabClicked(e, val); }} name='cancelbtn' className='btn_carrier_move btn btn-sm btn-primary'>Cancel</a>
											<a href='#' id='notyetdispatchedbrkr' onClick={this.handleGetSelectedData} name='cancelbtn' className='btn_carrier_move btn btn-sm btn-primary'>Cancel</a>
										</li> */}
										<li>
											{/* <Link className='btn_carrier_move btn btn-sm btn-primary' to='cancelledLoads'> Cancelled</Link> */}
										</li>
									</ul>
								</div>
							</div>
						</div>
						<MaterialUITabs tabsData={
							[{ component: 'Unassigned', tabID: 'requested-tab', refData: '#requested', methodName: (e, data, index) => { this.tabChangefrquickdispatch(STATUS.LOAD.REQUESTED,0) } },
							{ component: 'Assets Assigned', tabID: 'notyetdispatched-tab', refData: '#notyetdispatched', methodName: (e, data, index) => { this.tabChange(STATUS.LOAD.DELIVERY_CONFIRMED,1) } },
							{ component: 'Dispatched & Enroute', tabID: 'dispatched-tab', refData: '#dispatched', methodName: (e, data, index) => { this.tabChange(STATUS.LOAD.DISPATCHEDENROUTE,2) } },
							{ component: 'Delivered', tabID: 'delivered-tab', refData: '#delivered', methodName: (e, data, index) => { this.tabChange(STATUS.LOAD.DELIVERED,3) } },
							{ component: 'Cancelled', tabID: 'cancelled-tab', refData: '#cancelled', methodName: (e, data, index) => { this.tabChange(STATUS.LOAD.CANCELLED,4) } }
							]}
							getIndex={this.getIndex}
							activeTab={'#requested'}
							state={this.state}
						/>
						<div className='tab-content' id='myTabContent'>
							{this.state.tabIndex === 0 && (
								<Box>
									<QuickDispatch editloadvalues={(e, val) => { this.setLoadEditValues(e, val) }} setClick={click => this.indicationfrLoadlist = click} loadreq={loadreqlist} indicationhtml={indicationhtml} tabClicked={(e, val) => { this.handleTabClicked(e, val) }} currentStatus={this.currentStatus} loadCancel={this.cancelloadpopup} />
								</Box>
							)}
							{this.state.tabIndex === 1 && (
								<Box>
									<ToolkitProvider
										keyField='id'
										data={notyetdispatched}
										columns={notyetdispatchedcolumns}
										search
									>
										{
											props => (
												<div>
													<SearchBar {...props.searchProps} />
													<BootstrapTable ref={n => this.node = n}
														{...props.baseProps} pagination={paginationFactory(options)} noDataIndication={indicationhtml} hover expandRow={expandRow2}
														bordered={false}
													/>
												</div>
											)
										}
									</ToolkitProvider>
								</Box>
							)}
							{this.state.tabIndex === 2 && (
								<Box>
									<TableDataComponent tableData={dispatched} columnData={accptdcolumns} noDataIndication={indicationhtml} expandRow={expandRow2} />
								</Box>
							)}
							{this.state.tabIndex === 3 && (
								<Box>
									<ToolkitProvider
										keyField='id'
										data={delivered}
										columns={deliverdcolumns}
										search
									>
										{
											props => (
												<div>
													<SearchBar {...props.searchProps} />
													<BootstrapTable
														{...props.baseProps} pagination={paginationFactory(options)} noDataIndication={indicationhtml} hover expandRow={expandRow2}
														bordered={false}
													/>
												</div>
											)
										}
									</ToolkitProvider>
								</Box>
							)}
							{this.state.tabIndex === 4 && (
								<Box>
									<ToolkitProvider
										keyField='id'
										data={cancelledLoadsData}
										columns={cancelledcolumns}
										search
									>
										{
											props => (
												<div>
													<SearchBar {...props.searchProps} />
													<BootstrapTable
														{...props.baseProps} pagination={paginationFactory(options)} noDataIndication={indicationhtml} hover expandRow={expandRow2}
														bordered={false}
													/>
												</div>
											)
										}
									</ToolkitProvider>
								</Box>
							)}
							{/* <div className='tab-pane fade' id='notyetdispatched' role='tabpanel' aria-labelledby='notyetdispatched-tab'>
								<div className='filter-css float-right'><img src={!loading ? filterImg : ''} alt='Not Found' /></div>
								<ToolkitProvider
									keyField='id'
									data={notyetdispatched}
									columns={notyetdispatchedcolumns}
									search
								>
									{
										props => (
											<div>
												<SearchBar {...props.searchProps} />
												<BootstrapTable ref={n => this.node = n}
													{...props.baseProps} pagination={paginationFactory(options)} noDataIndication={indicationhtml} hover expandRow={expandRow2}
													bordered={false}
												/>
											</div>
										)
									}
								</ToolkitProvider>
							</div>

							<div className='tab-pane fade' id='dispatched' role='tabpanel' aria-labelledby='dispatched-tab'>
								<div className='filter-css float-right'><img src={loading === false ? filterImg : ''} alt='Not Found' /></div>
								<TableDataComponent tableData={dispatched} columnData={accptdcolumns} noDataIndication={indicationhtml} expandRow={expandRow2} />
							</div>
							<div className='tab-pane fade' id='delivered' role='tabpanel' aria-labelledby='delivered-tab'>
								<div className='filter-css float-right'><img src={loading === false ? filterImg : ''} alt='Not Found' /></div>
								<ToolkitProvider
									keyField='id'
									data={delivered}
									columns={deliverdcolumns}
									search
								>
									{
										props => (
											<div>
												<SearchBar {...props.searchProps} />
												<BootstrapTable
													{...props.baseProps} pagination={paginationFactory(options)} noDataIndication={indicationhtml} hover expandRow={expandRow2}
													bordered={false}
												/>
											</div>
										)
									}
								</ToolkitProvider>
							</div>

							<div className='tab-pane fade' id='cancelled' role='tabpanel' aria-labelledby='cancelled-tab'>
								<ToolkitProvider
									keyField='id'
									data={cancelledLoadsData}
									columns={cancelledcolumns}
									search
								>
									{
										props => (
											<div>
												<SearchBar {...props.searchProps} />
												<BootstrapTable
													{...props.baseProps} pagination={paginationFactory(options)} noDataIndication={indicationhtml} hover expandRow={expandRow2}
													bordered={false}
												/>
											</div>
										)
									}
								</ToolkitProvider>
							</div> */}
						</div>
					</div>
				</div>
				<CancelLoadConfirmModal loadTab={this.state} />
				<RestoreLoadPopup loadTab={this.state} />
				<Modal show={this.state.showModal} onHide={this.close} dialogClassName='modal-90w modal-map'>
					<Modal.Header closeButton>
						<Modal.Title>Truck Location</Modal.Title>
					</Modal.Header>
					<Modal.Body >
						<div id='map' className='map-container'></div>
					</Modal.Body>
				</Modal>
			</article>
		)
	}
}
const mapStateToProps = state => {
	return {
		data: state.loadReducer.data,
		LoadList: state.loadReducer.data,
		companyList: state.loadReducer.companyList,
		editvaluesview: state.loadReducer.editvaluesview,
		loading: state.loadReducer.loading,
		error: state.loadReducer.error,
		changeloadstatusupdateflag: state.loadReducer.changeloadstatusupdateflag,
		restoredloadflag: state.loadReducer.restoredloadflag,
		restoreData: state.loadReducer.restoreData,
		quickdispatchloads: state.loadReducer.data,
		vehicleData: state.loadReducer.vehicleData
	}
}
export default connect(mapStateToProps)(LoadList);