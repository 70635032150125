
import React,{Component} from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { profileedit,profileview } from '../../actions/userActions';
import { renderField,renderSelect } from '../../helpers/FormControls';
import { getcitybystate,getzipbycity } from '../../actions/loadActions';
import normalizePhone from '../../helpers/normalizePhone';
import {toastr} from 'react-redux-toastr'
import { validate } from './validation';

function submit(values,params) {   
    const frmdata = {};    
     frmdata.companyName= (values.companyName) ? (values.companyName) : ("");
     frmdata.companyEmail= (values.companyEmail) ? (values.companyEmail) : ("");
     frmdata.currentAddress1= (values.currentAddress1) ? (values.currentAddress1) : ("");
     frmdata.currentAddress2= (values.currentAddress1) ? (values.currentAddress1) : ("");
     frmdata.currentAddress3= (values.currentAddress1) ? (values.currentAddress1) : ("");
     frmdata.currentCityId= (values.currentCityId) ? (values.currentCityId) : ("");
     frmdata.currentStateCode= (values.currentStateCode) ? (values.currentStateCode) : ("");
     frmdata.currentZipId= (values.currentZipId) ? (values.currentZipId) : ("");
     frmdata.currentCountryCode= (values.currentCountryCode) ? (values.currentCountryCode) : ("");
     frmdata.websiteAddress= (values.websiteAddress) ? (values.websiteAddress) : ("");
     frmdata.phoneCountryCode= "";
     frmdata.phoneNumber= (values.phoneNumber) ? (values.phoneNumber) : ("");
     frmdata.altPhoneCountryCode= "";
     frmdata.altPhoneNumber= (values.altPhoneNumber) ? (values.altPhoneNumber) : ("");
     frmdata.faxCountryCode= "";
     frmdata.faxNumber= (values.faxNumber) ? (values.faxNumber) : ("");
     frmdata.subscriptionPlanId= (values.subscriptionPlanId) ? (values.subscriptionPlanId) : ("");
     frmdata.specialityId= (values.specialityId) ? (values.specialityId) : ("");
     frmdata.noOfTrucks= (values.noOfTrucks) ? (values.noOfTrucks) : ("");
     frmdata.instructions= (values.instructions) ? (values.instructions) : ("");
     frmdata.firstName= "";
     frmdata.lastName= "";       
     this.props.dispatch(profileedit(frmdata)); 
  }
const renderTextArea = ({input, label,meta: { touched, error, warning }}) => (
    <div>
        <label>{label}</label>
            <textarea {...input} className="form-control level2" ></textarea>
            {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
);
class carrierProfilEdit extends Component
{
    stateOnchange=(event)=>
    {
        this.props.dispatch(getcitybystate(event.target.value));
    }
    cityOnchange=(event)=>
    {
        this.props.dispatch(getzipbycity(event.target.value));
    } 

    handleClick=()=>
    {
        this.props.initialize(this.props.initialValues);
    }
    
    // componentDidMount()
    // {
        
    //     if(this.props.data.Company)
    //     {
    //         if(this.props.data.Company.currentCityId!==null)
    //         {
    //             this.props.dispatch(getcitybystate(this.props.data.Company.currentCityId));
    //         }

    //         if(this.props.data.Company.currentZipId!==null)
    //         {
    //             this.props.dispatch(getzipbycity(this.props.data.Company.currentZipId));
    //         }
    //     }
    //     this.props.dispatch(profileview());
    // }

    componentDidUpdate()
    {
        if(this.props.data.status==="CREATED")
        {              
            toastr.success('Success', 'Profile has been Successfully Updated!!');
            document.getElementById("prflbtnmodL").click();
            this.props.dispatch(profileview());
        }
    }


    render(){  
        //console.log(this.props,"commonlists");
        if(this.props.data.status === 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}
        const { handleSubmit, pristine, reset, submitting } = this.props
      
        return(  
            <form className="tg-forms" onSubmit={handleSubmit(submit.bind(this))}>           
            <div  data-backdrop="static" data-keyboard="false" className="modal fade carrierprofiledetailsview"  role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">  
                      
                <div className="modal-header">
                   <h5 className="modal-title doc-color" id="exampleModalLabel">EDIT PROFILE</h5>
                    <button type="button" className="close" data-dismiss="modal" id="prflbtnmodL" onClick={this.handleClick} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                   </div>
               <div className="modal-body" id="favourite-loads-container">
               <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab">  
                        <div className="row">                 
                            <div className="col col-md-12">
                                <article className="table-data truck-data shadow bg-white rounded">
                                    <div className="row">
                                        <div className="col col-md-3">
                                           <div className="form-group">
                                            <Field type="text" name="specialityId" data={this.props.specialitytypelist.data} component={renderSelect} label="Speciality"/>                                
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="companyName" controlClass="requiredfield" component={renderField} label="Carrier Name"/>
                                            </div>
                                            <div className="form-group">
                                              <Field type="text" name="currentAddress1" component={renderTextArea} label="Current Address"/>                          
                                            </div>
                                            {/* <div className="form-group">
                                            <Field type="text" name="CarrierLogin" component={renderField} className="form-control" label="Carrier Login" />
                                            </div> */}
                                        </div>
                                        <div className="col col-md-3">
                                            <div className="form-group">
                                            <Field type="text" name="companyEmail" component={renderField} controlClass="requiredfield"  label="Email"/> 
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" className="form-control" component={renderField} normalize={normalizePhone} controlClass="requiredfield" name="phoneNumber" label="Primary Phone" />                                            
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" className="form-control" component={renderField} normalize={normalizePhone} name="altPhoneNumber" label="Secondary Phone" />                                                                        
                                            </div>
                                        </div>
                                        <div className="col col-md-3">
                                            <div className="form-group">
                                            <Field type="text" name="currentStateCode"  onChange={this.stateOnchange} controlClass="requiredfield" component={renderSelect} label="State" data={this.props.state.data}/>
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="faxNumber" component={renderField} label="Fax"/>                               
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="websiteAddress" component={renderField} label="Website"/>                                
                                            </div>
                                        </div>  
                                        <div className="col col-md-3">
                                            <div className="form-group">
                                            <Field type="text" name="currentCityId" onChange={this.cityOnchange} component={renderSelect} data={this.props.CityVal} keyColumn='cityId' keyValue='cityName' label="City" />                                                                                                   
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="currentZipId" keyColumn='zipId' keyValue='zipValue' data={this.props.ZipVal} component={renderSelect} label="Zip"/>                        
                                            </div>
                                            <div className="form-group">                                            
                                            <Field type="text" name="currentCountryCode" component={renderSelect} data={this.props.country.data} label="Country"/>                               
                                            </div>
                                        </div>                             
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                              <Field type="text" name="noOfTrucks" component={renderField} label="No. of Trucks"/>                          
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                            <Field type="text" name="subscriptionPlanId" component={renderSelect}  label="Plan Selected"/>                             
                                            </div>
                                        </div>
                                     </div> 
                                    {/* <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                              <Field type="text" name="currentAddress1" component={renderTextArea} label="Current Address"/>                          
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                            <Field type="text" name="permanentaddress" component={renderTextArea} label="Permanent Address"/>                             
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                            <Field type="text" name="Eldproviders" component={renderField} label="Eldprovider(s)"/>                                
                                            </div>
                                        </div>
                                     </div> */}
                                     <div className="row">
                                        <div className="col-md-12">
                                            <Field type="text" name="instructions" component={renderTextArea} label="Description"/> 
                                        </div>
                                     </div>
                                </article>
                                <div className="row">
										<div className="col col-md-12">
											<div className="form-group form-group-btns">
												<button type="submit" className="btn btn-primary btn-sm">Save</button>&nbsp;&nbsp;
												<button type="button" disabled={pristine || submitting} onClick={reset} className="btn btn-primary btn-sm">Reset</button>
											</div>
										</div>
									</div>
                                 </div>
                             </div>
                         </div>                   
                    </div>
                  </div>   
                </div>
            </div>
        </div>   
        </form>     
        )
       
    }
}

const PageOneForm = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    })(carrierProfilEdit)

  const mapStateToProps = (state,ownProps) => { 
    return{
        CityVal:state.loadReducer.CityVal,
        ZipVal:state.loadReducer.ZipVal,
        data: state.userReducer.data,
        form: 'carrierProfilEdit',
        initialValues: ownProps.modalvalues,
        validate,      
        enableReinitialize: true 
    }    
  }
  
  const PageOneFormContainer = connect(mapStateToProps)(PageOneForm)
    
  export default PageOneFormContainer