import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import { getzipbycityService, getcitybystateService, shippertypecompanylistforlanetable, addNewLoad, loadService, shipperLoadService, loadServicefrcancelledloads, loadServicefrquickloads, saveBrokerQuickDispatch, tenderChangeLoadStatusService, cancelledbyloadservice, loadServiceRestoreloads, editLoad, createDeliveryService, fuelAdvanceService } from '../services/loadService';
import * as types from '../actions';

function* getcityByStateSage(payload) {
        try {
                const response = yield call(getcitybystateService, payload);
                yield put({ type: types.GET_CITY_BY_STATE_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.GET_CITY_BY_STATE_ERROR, error })
        }
}

function* getZipByCitySaga(payload) {
        try {
                const response = yield call(getzipbycityService, payload);
                yield put({ type: types.GET_ZIP_BY_CITY_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.GET_ZIP_BY_CITY_ERROR, error })
        }
}

function* loadlistrestored(payload) {
        try {
                const response = yield call(loadServiceRestoreloads, payload);
                yield put({ type: types.GET_LOAD_DATA_RESTORE_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.GET_LOAD_DATA_RESTORE_ERROR, error: e.message });
        }
}
function* editLoadData(payload) {
        try {
                const response = yield call(editLoad, payload);
                yield put({ type: types.POST_NEW_LOAD_DATA_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.POST_NEW_LOAD_DATA_ERROR, error: e.message });
        }
}

function* getshipperandlanedtails(payload) {
        try {
                const response = yield call(shippertypecompanylistforlanetable, payload);
                yield put({ type: types.SHIPPER_AND_LANE_DETAILS_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.SHIPPER_AND_LANE_DETAILS_ERROR, error })
        }
}

function* fetchData(payload) {
        try {
                const response = yield call(loadService, payload);
                yield put({ type: types.GET_LOAD_DATA_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.GET_LOAD_DATA_ERROR, error: e.message });
        }
}
function* fetchShipperLoadData(payload) {
        try {
                const response = yield call(shipperLoadService, payload);
                yield put({ type: types.GET_SHIPPER_LOAD_DATA_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.GET_SHIPPER_LOAD_DATA_ERROR, error: e.message });
        }
}

function* loadlistcancelled(payload) {
        try {
                const response = yield call(loadServicefrcancelledloads, payload);
                yield put({ type: types.GET_LOAD_DATA_CANCEL_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.GET_LOAD_DATA_CANCEL_ERROR, error: e.message });
        }
}

function* loadlistquickdispatch(payload) {
        try {
                const response = yield call(loadServicefrquickloads, payload);
                yield put({ type: types.GET_LOAD_DATA_QUICKDISPATCH_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.GET_LOAD_DATA_QUICKDISPATCH_ERROR, error: e.message });
        }
}


function* newLoadData(payload) {
        try {
                const response = yield call(addNewLoad, payload);
                yield put({ type: types.POST_NEW_LOAD_DATA_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.POST_NEW_LOAD_DATA_ERROR, error: e.message });
        }
}

function* brokerQuickDispatchAction(payload) {
        try {
                const response = yield call(saveBrokerQuickDispatch, payload);
                yield put({ type: types.SAVE_BROKER_QUICK_DISPATCH_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.SAVE_BROKER_QUICK_DISPATCH_ERROR, error })
        }
}

function* tenderChangeLoadStatus(payload) {
        try {
                const response = yield call(tenderChangeLoadStatusService, payload);
                yield put({ type: types.TENDER_CHANGE_LOAD_STATUS_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.TENDER_CHANGE_LOAD_STATUS_ERROR, error: e.message });
        }
}

function* cancelledloadsbyloadid(payload) {
        try {
                const response = yield call(cancelledbyloadservice, payload);
                yield put({ type: types.CANCELLED_LOADS_BYLOADID_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.CANCELLED_LOADS_BYLOADID_ERROR, error: e.message });
        }
}

function* createDelivery(payload) {
        try {
                const response = yield call(createDeliveryService, payload);
                yield put({ type: types.POST_NEW_DELIVERY_DATA_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.POST_NEW_DELIVERY_DATA_ERROR, error: e.message });
        }
}
function* createFuelAdvance(payload) {
        try {
                const response = yield call(fuelAdvanceService, payload);
                yield put({ type: types.POST_NEW_FUEL_ADVANCE_DATA_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.POST_NEW_FUEL_ADVANCE_DATA_ERROR, error: e.message });
        }
}
export function* ChangeLoadStatus() {
        yield takeEvery(types.GET_CITY_BY_STATE, getcityByStateSage);
        yield takeEvery(types.GET_ZIP_BY_CITY, getZipByCitySaga);
        yield takeEvery(types.SHIPPER_AND_LANE_DETAILS, getshipperandlanedtails);
        yield takeEvery(types.SAVE_BROKER_QUICK_DISPATCH, brokerQuickDispatchAction);
        yield takeEvery(types.TENDER_CHANGE_LOAD_STATUS, tenderChangeLoadStatus);
        yield takeEvery(types.CANCELLED_LOADS_BYLOADID, cancelledloadsbyloadid);
}

export function* loadSaga() {
        // Allows concurrent fetches of users
        yield takeEvery(types.GET_LOAD_DATA, fetchData);
        yield takeEvery(types.GET_SHIPPER_LOAD_DATA, fetchShipperLoadData);
        yield takeEvery(types.GET_LOAD_DATA_CANCEL, loadlistcancelled);
        yield takeEvery(types.GET_LOAD_DATA_QUICKDISPATCH, loadlistquickdispatch);
        yield takeLatest(types.POST_NEW_LOAD_DATA, newLoadData);
        yield takeEvery(types.GET_LOAD_DATA_RESTORE, loadlistrestored);
        yield takeLatest(types.POST_EDIT_LOAD_DATA, editLoadData);
        yield takeLatest(types.POST_NEW_DELIVERY_DATA, createDelivery);
        yield takeLatest(types.POST_NEW_FUEL_ADVANCE_DATA, createFuelAdvance);
        // Does not allow concurrent fetches of users
        // yield takeLatest(LOAD_USERS_LOADING, fetchUser);
}

