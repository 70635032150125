import { put, call, takeEvery } from 'redux-saga/effects';
import { loginUserService,frgtpwdService,savenewpwdService } from '../services/authService';
import * as types from '../actions'

export function* loginSaga(payload) {
  	try {
		const response = yield call(loginUserService, payload);
		if(response) {
            var data=response;
            if(data) {
                var menuList = Object.keys(data).map(function(key) {
                    if(key==='Components')
                        return data[key];
                    else return null;
                });
                localStorage.setItem('userMenu', menuList);
            }
        }
		yield put({type: types.LOGIN_USER_SUCCESS, response});
  	} 
  	catch(error) {
    	yield put({ type: types.LOGIN_USER_ERROR, error })
  	}
}

export function* logoutSaga() {
    try {
        let redirecturl = localStorage.getItem('redirecturl');
        localStorage.clear();
        if(redirecturl){
            localStorage.setItem('redirecturl', redirecturl);
        }
        
        yield put({type: types.LOGOUT_SUCCESS});
    } 
    catch(error) {
        yield put({ type: types.LOGOUT_ERROR, error })
    }
}

function* frgtpwd(payload) { 
    try {   
            const response = yield call( frgtpwdService, payload );   
            yield put({type: types.MAIL_SEND_FRGT_PWD_SUCCESS, data: response });
    } catch (error) {
            yield put({ type: types.MAIL_SEND_FRGT_PWD_ERROR, error })
    }
} 

function* savenewpwd(payload) { 
    try {   
            const response = yield call( savenewpwdService, payload );  
            yield put({type: types.SAVE_NEW_PWD_SUCCESS, data:response });
    } catch (error) {
            yield put({ type: types.SAVE_NEW_PWD_ERROR, error })
    }
}

export function* forgotpwdsaga() {
    yield takeEvery(types.MAIL_SEND_FRGT_PWD, frgtpwd);
    yield takeEvery(types.SAVE_NEW_PWD, savenewpwd);
}
