import * as types from '../actions';

const initialState = {
    data: [],
    loading: false,
    //pagination:[],
    error: ''
};

export default function(state = initialState, action) {
    switch(action.type) {
        case types.EDIT_CARRIER_DATA:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.EDIT_CARRIER_DATA_SUCCESS:
            return { 
                ...state,
                data: 'ok',
                loading: false,
            };
        case types.EDIT_CARRIER_DATA_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};