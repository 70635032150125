import { authHeader } from '../helpers/authHeader';
import { checkUnauthorized } from '../helpers/appUtil';
import { DeleteCookie } from '../helpers/Cookies';

require('dotenv').config();
export const PreferredCompanyListByTypeService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyresource/preferred_companylistbytype?companytypeid=' + request.payload.companytypeid
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return { preferredCompanyList: responseData };
        })
        .catch(error => {
            return {};
        });
}
export const PreferredCompanyListService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyresource/preferred_companylist';
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return { preferredCompanyList: responseData };
        })
        .catch(error => {
            return {};
        });
}
export const ChangePreferenceService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyresource/company/ChangeCompanyPreference?companyId=' + request.payload.companyId;
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const InviteCompanyService = (request) => {
    //templateTypeId
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyresource/company/invitecompany?companyId=' + request.companyId + "&templateId=" + request.templateId;
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "POST",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}

export const getInviteTemplatetypeService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'emailtemplateresource/templatetype/' + request.payload.typename + '?companyId=' + request.payload.companyId;
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const VerifyCompanyService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'eldprovidersresource/eldproviders/save_eld_data_verification_info';
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(request),
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}

export const EldOnboardedService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'eldprovidersresource/eldproviders/move_to_onboarded?invitedId=' + request.invitedId + '&eldProviderId=' + request.eldProviderId + '&templateId=' + request.templateId;
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "POST",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const GetInvitedCompanyByTypeService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'eldprovidersresource/eldproviders/invited_companylist_by_type?companytypeid=' + request.companytypeid;
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const GetInvitedCompanyService = () => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'eldprovidersresource/eldproviders/invited_companylist';
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
//new API call For search carriers
export const carrierSearchServiceNew = (request) => {
    let queryParams;
    if ('carrierName' in request.payload) {
        queryParams = 'carrierName:fuzzy=' + encodeURIComponent(request.payload.carrierName)
    }
    else {
        queryParams = 'codeType=' + encodeURIComponent(request.payload.codeType) + '&codeValue=' + encodeURIComponent(request.payload.codeValue)
    }
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_SEARCH_API_URL + 'carriers/search?' + queryParams;
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            if (responseData.length > 0) {
                // responseData["message"] = responseData.message;
            }
            else {
                // responseData["message"] = responseData.message;
            }
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const carrierSearchService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyresource/preferred_companylistbytype?companytypeid=' + request.payload.companytypeid + '&searchFlag=' + request.payload.SearchFlag + '&dOTNumber=' + request.payload.dotNumber + '&mcnumber=' + request.payload.mcNumber + '&companyname=' + request.payload.carrierName;
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            if (responseData.companyList.length > 0) {
                responseData["message"] = "searchlist Found";
            }
            else {
                responseData["message"] = "No searchlist Found";
            }
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const carrierAddService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyresource/company/carrieradd'
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(request.payload),
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const isOnboarded = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyresource/company/is_onboarded';
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const companyNameListService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL + 'companyresource/getoptedincarrierlist'
    //const COMPANY_LIST_API_ENDPOINT = BASE_URL+'companyresource/geofencecompanylistbytype?companytypeid='+request.payload.companytypeid 
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const geofenceCompanyNameListService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyresource/geofencecompanylistbytype?companytypeid=' + request.payload.companytypeid
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const geofenceCarrierCompanyNameListService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyresource/preferredcarriers?companytypeid=' + request.payload.companytypeid
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const geofenceRadiusListService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'commonresource/geofenceradius'
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader(),
        //body:JSON.stringify(request.payload),
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const geofenceMailTimeService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'commonresource/geofencemailnotifytime'
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader(),
        //body:JSON.stringify(request.payload),
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const geofenceRadiusStatusService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyresource/visualgeofence/geofenceradiusupdate'
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(request.payload),
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const carrierBulkUploadService = (request) => {
    // let formElement = document.querySelector("#companyProf");
    const formData = new FormData();
    // const fileField = document.querySelector('input[id="carrierbulkupload"]');
    let authToken = localStorage.getItem('authToken');
    formData.append('file', request.payload.bulkuploadfilename);
    const LOADS_BULK_UPLOAD_STATUS_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyupload/bulk';
    return fetch(LOADS_BULK_UPLOAD_STATUS_API_ENDPOINT, {
        method: 'POST',
        body: formData,
        "mimeType": "multipart/form-data",
        headers: {
            'Authorization': authToken
        }
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
};
export const carrierListForFactorService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyresource/onboardedcompanylistbytype?companytypeid=' + request.companytypeid
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const companyVerifyService = (request) => {
    const UPDATE_TRUCK_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyresource/company/verify'
    return fetch(UPDATE_TRUCK_API_ENDPOINT, {
        method: "PUT",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const getPdfDownloadLink = (request) => {
    const UPDATE_TRUCK_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'publicresource/download/cob_pdf/?carrierInvitationId=' + request.payload.carrierInvitationId;
    return fetch(UPDATE_TRUCK_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const verificationcheckService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'eldprovidersresource/eldproviders/getelddatasharinginfo?carrierInvitedId=' + request.carrierInvitedId
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const getCompanyProfileService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyprofileresource/profile/get?companyId=' + request.payload.companyId
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const saveCompanyProfileService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyprofileresource/profile/add'
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(request.payload),
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const uploadProfileLogoService = (request) => {
    let authToken = localStorage.getItem('authToken');
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyprofileresource/uploadProfileLogo'
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "POST",
        "mimeType": "multipart/form-data",
        headers: {
            'Authorization': authToken
        },
        body: request.payload,
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const uploadProfileBannerService = (request) => {
    let authToken = localStorage.getItem('authToken');
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyprofileresource/uploadProfileBanner'
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "POST",
        "mimeType": "multipart/form-data",
        headers: {
            'Authorization': authToken
        },
        body: request.payload,
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const uploadProfileFaviconService = (request) => {
    let authToken = localStorage.getItem('authToken');
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyprofileresource/uploadProfileFavicon'
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "POST",
        "mimeType": "multipart/form-data",
        headers: {
            'Authorization': authToken
        },
        body: request.payload,
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const getVerificationStatusService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'verificationstatus/get'
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const getCompanyProfilePublicService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'publicresource/profile/getbycode'
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const getDynamicDocumentURL = (url) => {
    return fetch(url, {
        method: "GET"
    }).then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const onboardedCompanyListbyTypeService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyresource/onboardedcompanylistbytype/?companytypeid=' + request.payload.companyTypeId
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const onboardedCompanyListbyCompanyService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyresource/onboardedcompanylist/?companytypeid=' + request.payload.companyTypeId
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const getOnboardPdfDownloadLink = (request) => {
    const UPDATE_TRUCK_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'publicresource/download/cob_onboarded_pdf/?companyMiscId=' + request.payload.companyMiscId;
    return fetch(UPDATE_TRUCK_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const carrierListForBrokerService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyresource/getcarrierlist';
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const dashboardCarrierListService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyresource/onboardedcompanylistbytype?companytypeid=' + request.companytypeid;
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const updateCompanyStatusService = (request) => {
    const COMPANY_AUTH_ELD_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyresource/update/changestatus?companyId=' + request.companyId;
    return fetch(COMPANY_AUTH_ELD_ENDPOINT, {
        method: "POST",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const getOnboardedCarrierService = (request) => {
    const UPDATE_TRUCK_API_ENDPOINT = process.env.REACT_APP_BASE_URL + 'companyresource/get/onboardedcarriers';
    return fetch(UPDATE_TRUCK_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const getInvitedCarrierService = () => {
    const INVITE_TRUCK_API_ENDPOINT = process.env.REACT_APP_CARRIER_API_URL + 'carriers?status=INVITED,EXPIRED,VERIFICATION_FAILED,REINVITED'
    return fetch(INVITE_TRUCK_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
//Post Api call for invite carrier
export const inviteNewCarrierService = (payload, type) => {
    let queryParams = `codeType=${type.codeType}&codeValue=${type.codeValue}`;
    const BASE_URL = process.env.REACT_APP_SEARCH_API_URL;
    const INVITE_CARRIER_API_ENDPOINT = BASE_URL + 'carriers/invite?' + queryParams;
    return fetch(INVITE_CARRIER_API_ENDPOINT, {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(payload),
    })
        .then((response) => response.json())
        .then((responseData) => {
            // checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const getMyCarrierService = () => {
    const MY_CARRIER_TRUCK_API_ENDPOINT = process.env.REACT_APP_CARRIER_API_URL + 'carriers?status=ONBOARDED&carrierName:fuzzy='
    return fetch(MY_CARRIER_TRUCK_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const getCarrierInvitationService = (request) => {
    const CARRIER_INVITATION_API_ENDPOINT = process.env.REACT_APP_CARRIER_API_URL + 'carriers/invitation/' + request.payload
    return fetch(CARRIER_INVITATION_API_ENDPOINT, {
        method: "GET",
        // headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return error;
        });
}//new API call For verifying credentials and signing LOA 
export const VerifyingCredentialsAndSignLOAService = (request) => {
    console.log('request', request)
    let prepareData = {
        carrierInfo: { carrierId: request.payload.invitationData.invitationData.carrierInfo.carrierId },
        eldInfo: request.payload.preparedELDData
    }
    console.log('prepareData', prepareData, request.payload.invitationData)
    const VERIFY_CREDENTIALS_AND_SIGN_LOA_API_ENDPOINT = process.env.REACT_APP_CARRIER_API_URL + 'carriers/onboard/' + request.payload.invitationData.invitationData.invitationInfo.invitationToken
    return fetch(VERIFY_CREDENTIALS_AND_SIGN_LOA_API_ENDPOINT, {
        method: "PUT",
        headers: authHeader(),
        body: JSON.stringify(prepareData)
    })
        .then((response) => response.json())
        .then((responseData) => {
            if (responseData.status === "OK") {
                responseData["status"] = "OK";
                responseData["message"] = responseData.message;
            }
            else if (responseData.status === "UNAUTHORIZED") {
                responseData["status"] = "UNAUTHORIZED";
                responseData["message"] = responseData.message;
            }
            else if (responseData.status === "TOO_MANY_REQUESTS") {
                responseData["status"] = "TOO_MANY_REQUESTS";
                responseData["message"] = responseData.message;
            }
            return responseData;
        })
        .catch(error => {
            return {};
        });
}

//API call for completing carrier signup
export const completeSignUPCarriersService = (request) => {
    console.log('request', request.payload)
    let prepareData = {
        carrierInfo: { carrierId: request.payload.invitationData.invitationData.carrierInfo.carrierId },
        contactInfo: request.payload.invitationData.invitationData.contactInfo,
        eldInfo: request.payload.verifiedELDData
    }
    console.log('request', prepareData)
    const SIGNUP_CARRIER_API_ENDPOINT = process.env.REACT_APP_CARRIER_API_URL + 'carriers/signup/' + request.payload.invitationData.invitationData.invitationInfo.invitationToken
    return fetch(SIGNUP_CARRIER_API_ENDPOINT, {
        method: "PUT",
        headers: authHeader(),
        body: JSON.stringify(prepareData)

    })
        .then((response) => response.json())
        .then((responseData) => {
            if (responseData.status === "OK") {
                // responseData["status"] = "SignedUP";
                responseData["status"] = "OK";
                responseData["code"] = "200";
                responseData["message"] = responseData.message;
            }
            else {
                // responseData["status"] = "NotSIgnedUP";
                responseData["status"] = responseData.status;
                responseData["code"] = (responseData.status === "TOO_MANY_REQUESTS") ? "429" : (responseData.status === "UNAUTHORIZED") ? "401" : (responseData.status === "NOT_FOUND") ? "404" : (responseData.status === "BAD_REQUEST") ? "400" : "500";
                responseData["message"] = responseData.message;
            }
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
//API call for TC WIDGET  carrier signup
export const completeTCWidgetSignUPCarriersService = (request) => {
    console.log('request', request.payload)
    let prepareData = {
        carrierInfo: { carrierId: request.payload.invitationData.invitationData.carrierInfo.carrierId },
        contactInfo: request.payload.invitationData.invitationData.contactInfo,
        eldInfo: request.payload.verifiedELDData
    }
    console.log('request', prepareData)
    const SIGNUP_CARRIER_API_ENDPOINT = process.env.REACT_APP_TC_WIDGET_URL + 'carriers/signup/' + request.payload.invitationData.invitationData.invitationInfo.invitationToken
    return fetch(SIGNUP_CARRIER_API_ENDPOINT, {
        method: "PUT",
        headers: authHeader(),
        body: JSON.stringify(prepareData)
    })
        .then((response) => response.json())
        .then((responseData) => {
            if (responseData.status === "OK") {
                // responseData["status"] = "SignedUP";
                responseData["status"] = "OK";
                responseData["code"] = "200";
                responseData["message"] = responseData.message;
            }
            else {
                // responseData["status"] = "NotSIgnedUP";
                responseData["status"] = responseData.status;
                responseData["code"] = (responseData.status === "TOO_MANY_REQUESTS") ? "429" : (responseData.status === "UNAUTHORIZED") ? "401" : (responseData.status === "NOT_FOUND") ? "404" : (responseData.status === "BAD_REQUEST") ? "400" : "500";
                responseData["message"] = responseData.message;
            }
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const tcWidgetCarrierInvitationService = (request) => {
    let nameParam;
    if (request.payload.firstName !== undefined && request.payload.lastName !== undefined) {
        nameParam = '&firstName=' + request.payload.firstName + '&lastName=' + request.payload.lastName
    }
    else if (request.payload.firstName !== undefined) {
        nameParam = '&firstName=' + request.payload.firstName
    }
    else if (request.payload.lastName !== undefined) {
        nameParam = '&lastName=' + request.payload.lastName
    }
    else {
        nameParam = ''
    }
    const CARRIER_INVITATION_API_ENDPOINT = process.env.REACT_APP_TC_WIDGET_URL + 'carriers/invitation?apiKey=' + request.payload.apiKey + '&codeType=' + request.payload.codeType + '&codeValue=' + request.payload.codeValue + '&email=' + encodeURIComponent(request.payload.email) + nameParam
    return fetch(CARRIER_INVITATION_API_ENDPOINT, {
        method: "PUT",
        // headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            // return error;
        });
}
//new API call For verifying TC WIdget ELD credentials and signing LOA 
export const tcWidgetVerifyingCredentialsAndSignLOAService = (request) => {
    console.log('before prepareData', request)
    let prepareData = {
        carrierInfo: { carrierId: request.payload.invitationData.invitationData.carrierInfo.carrierId },
        eldInfo: request.payload.preparedELDData
    }
    console.log('prepareData', prepareData, request.payload.invitationData)
    const VERIFY_CREDENTIALS_AND_SIGN_LOA_API_ENDPOINT = process.env.REACT_APP_TC_WIDGET_URL + 'carriers/onboard/' + request.payload.invitationData.invitationData.invitationInfo.invitationToken
    return fetch(VERIFY_CREDENTIALS_AND_SIGN_LOA_API_ENDPOINT, {
        method: "PUT",
        headers: authHeader(),
        body: JSON.stringify(prepareData)
    })
        .then((response) => response.json())
        .then((responseData) => {
            if (responseData.status === "OK") {
                responseData["status"] = "OK";
                // responseData["message"] = responseData.message;
            }
            else if (responseData.status === "UNAUTHORIZED") {
                responseData["status"] = "UNAUTHORIZED";
                // responseData["message"] = responseData.message;
            }
            else if (responseData.status === "TOO_MANY_REQUESTS") {
                responseData["status"] = "TOO_MANY_REQUESTS";
                // responseData["message"] = responseData.message;
            }
            return responseData;
        })
        .catch(error => {
            return {};
        });
}

// API call for OAuth credentials verification and signing LOA
export const oAuthVerifyingCredentialsAndSignLOAService = (request) => {
    let prepareData = {
        invitationToken: request.payload.invitationData.invitationData.invitationInfo.invitationToken,
        code: request.payload.invitationData.oAuthCode,
        companyId: request.payload.invitationData.invitationData.invitationInfo.invitingCompanyId,
        eldName: request.payload.eldDataArr[0].eldVendor,
        eldDataSharing: request.payload.invitationData.checkedData[0].eldDataSharing
    }
    const OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA_API_ENDPOINT = process.env.REACT_APP_CARRIER_API_URL + 'carriers/onboard/oauth'
    return fetch(OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA_API_ENDPOINT,{
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(prepareData)
    })
    .then((response) => response.json())
    .then((responseData) => {
        if(responseData.status=== "OK")
        {
            DeleteCookie('eldIndex', true)
            DeleteCookie('eldVendor', true)
        
            responseData["status"]="OK";      
            responseData["message"]= responseData.message;      
        }
        else if(responseData.status==="UNAUTHORIZED"){
            responseData["status"]="UNAUTHORIZED";      
            responseData["message"]=responseData.message;    
        }
        else if(responseData.status==="TOO_MANY_REQUESTS"){
            responseData["status"]="TOO_MANY_REQUESTS";      
            responseData["message"]=responseData.message;    
        }
        else if(responseData.status==="INTERNAL_SERVER_ERROR"){
            responseData["status"]="INTERNAL_SERVER_ERROR";      
            responseData["message"]=responseData.message;    
        }
        return responseData;
    })
    .catch(error => {
        console.log('ERROR DISPATCH')
        console.log(error)
        return {};
    });
}

// API call for OAuth credentials verification and signing LOA in Widget
export const oAuthVerifyingCredentialsAndSignLOAInWidgetService = (request) => {
    let prepareData = {
        invitationToken: request.payload.invitationData.invitationData.invitationInfo.invitationToken,
        code: request.payload.invitationData.oAuthCode,
        companyId: request.payload.invitationData.invitationData.invitationInfo.invitingCompanyId,
        eldName: request.payload.eldDataArr[0].eldVendor,
        eldDataSharing: request.payload.invitationData.checkedData[0].eldDataSharing
    }
    // TODO Change REACT_APP_CARRIER_API_URL to REACT_APP_TC_WIDGET_URL
    const OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA_WIDGET_API_ENDPOINT = process.env.REACT_APP_CARRIER_API_URL + 'carriers/onboard/oauth'
    return fetch(OAUTH_VERIFY_CREDENTIALS_AND_SIGN_LOA_WIDGET_API_ENDPOINT,{
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(prepareData)
    })
    .then((response) => response.json())
    .then((responseData) => {
        if(responseData.status=== "OK")
        {
            responseData["status"]="OK";      
            responseData["message"]= responseData.message;      
        }
        else if(responseData.status==="UNAUTHORIZED"){
            responseData["status"]="UNAUTHORIZED";      
            responseData["message"]=responseData.message;    
        }
        else if(responseData.status==="TOO_MANY_REQUESTS"){
            responseData["status"]="TOO_MANY_REQUESTS";      
            responseData["message"]=responseData.message;    
        }
        return responseData;
    })
    .catch(error => {
        return {};
    });
}
export const getSweepstakesCarrierInvitationService = (request) => {
    const CARRIER_INVITATION_API_ENDPOINT = process.env.REACT_APP_TC_WIDGET_URL + 'sweepstakes/invitation/' + request.payload
    return fetch(CARRIER_INVITATION_API_ENDPOINT, {
        method: "GET",
        headers: { 'Invitation-Origin': window.location.href }
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return error;
        });
}
export const getColorCOdeService = (request) => {
    const COMPANY_LIST_API_ENDPOINT = process.env.REACT_APP_TC_WIDGET_URL + 'user/company/profile?apiKey=' + request
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET"
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
// Factoring Api calls
//Confirm Delivery API calls
export const getActiveDeliveriesService = (request) => {
    const ACTIVE_DELIVERY_ENDPOINT = process.env.REACT_APP_LOAD_API_URL + 'factor/invoices?type=deliveryConfirmation&status=' + request.payload.staus;
    return fetch(ACTIVE_DELIVERY_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const getPastDeliveriesService = (request) => {
    const PAST_DELIVERY_ENDPOINT = process.env.REACT_APP_LOAD_API_URL + 'factor/invoices?type=deliveryConfirmation&status=' + request.payload.staus;
    return fetch(PAST_DELIVERY_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
// Fuel Advance  Api calls
export const getActiveFuelAdvanceService = (request) => {
    const ACTIVE_FUEL_ADVANCE_ENDPOINT = process.env.REACT_APP_LOAD_API_URL + 'factor/invoices?type=fuelAdvance&status=' + request.payload.staus;
    return fetch(ACTIVE_FUEL_ADVANCE_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const getPastFuelAdvanceService = (request) => {
    const PAST_FUEL_ADVANCE_ENDPOINT = process.env.REACT_APP_LOAD_API_URL + 'factor/invoices?type=fuelAdvance&status=' + request.payload.staus;
    return fetch(PAST_FUEL_ADVANCE_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            return {};
        });
}

export const updateFactorService = (request) => {
    const UPDATE_FACTOR_API_ENDPOINT = process.env.REACT_APP_LOAD_API_URL + 'factor/reprocess/' + request.payload
    return fetch(UPDATE_FACTOR_API_ENDPOINT, {
        method: "PUT",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}