export const validate = values => {    
    const errors = {}
    if (!values.companyName) {
        errors.companyName = 'Name can not be left blank.!'
    } 
    // else if (values.companyName.length < 3) {
    //     errors.companyName = 'Must be 3 characters or greater.!'
    // }
    if (!values.firstName) {
        errors.firstName = 'Name can not be left blank.!'
    } 
    // else if (values.firstName.length > 15) {
    //     errors.firstName = 'Must be 15 characters or less.!'
    // } else if (values.firstName.length < 3) {
    //     errors.firstName = 'Must be 3 characters or greater.!'
    // }
    if (!values.currentStateCode) {
        errors.currentStateCode = 'State can not be left blank.!'
    } 
    if (!values.companyEmail) {
        errors.companyEmail = 'Email can not be left blank.!'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.companyEmail)) {
        errors.companyEmail = 'Make sure that you entered valid Email address.!'
    }      
    if (!values.phoneNumber) {
        errors.phoneNumber = 'Mobile No. can not be left blank.!'
    }    
    return errors
}
