import * as types from '../actions';

const initialState = {
    data: [],
    export :[],
    exportFlg:false,
    goFlg:false,
    eldAssets : [],
    loading: false,    
    error: ''
};
export default function(state = initialState, action) {
    switch(action.type) {        
        case types.GET_DASHBOARD_DATA:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.GET_DASHBOARD_DATA_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.GET_DASHBOARD_DATA_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.GET_DASHBOARD_DATA_FACTOR:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.GET_DASHBOARD_DATA_FACTOR_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.GET_DASHBOARD_DATA_FACTOR_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
            case types.GET_DASHBOARD_DATA_FACTOR_MUTIPLE_ELD:
                return { 
                    ...state,
                    loading: true,
                    error:''
                };
            case types.GET_DASHBOARD_DATA_FACTOR_MUTIPLE_ELD_SUCCESS:
                return { 
                    ...state,
                    data: action.data,
                    loading: false,
                };
            case types.GET_DASHBOARD_DATA_FACTOR_MUTIPLE_ELD_ERROR:
                return { 
                    ...state,
                    loading: false,
                    error: action.error
                };
        case types.GET_DASHBOARD_DATA_BROKER:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.GET_DASHBOARD_DATA_BROKER_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.GET_DASHBOARD_DATA_BROKER_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
            case types.GET_DASHBOARD_DATA_BROKER_MULTIPLE_ELD:
                return { 
                    ...state,
                    loading: true,
                    error:''
                };
            case types.GET_DASHBOARD_DATA_BROKER_MULTIPLE_ELD_SUCCESS:
                return { 
                    ...state,
                    data: action.data,
                    loading: false,
                };
            case types.GET_DASHBOARD_DATA_BROKER_MULTIPLE_ELD_ERROR:
                return { 
                    ...state,
                    loading: false,
                    error: action.error
                };
        case types.GET_TRACKING_LIST:
            return { 
                ...state,
                exportFlg: false,
                error:''
            };
        case types.GET_TRACKING_LIST_SUCCESS:
            return { 
                ...state,
                export: action.exportData,
                exportFlg: action.responseData.exportFlg,
                goFlg: action.responseData.goFlg
            };
        case types.GET_TRACKING_LIST_ERROR:
            return { 
                ...state,
                exportFlg: false,
                goFlg: false,
                error: action.error
            };
        case types.GET_ELD_ASSEST_LIST:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.GET_ELD_ASSEST_LIST_SUCCESS:
            return { 
                ...state,
                eldAssets: action.dashboard,
                loading: false,
            };
        case types.GET_ELD_ASSEST_LIST_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.SET_GOFLAG:
            return { 
                ...state,
                goFlg: false,
                exportFlg: false
            };              
        default:
            return state;
    }
};