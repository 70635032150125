import React, { Component, Fragment } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { history } from './helpers/history';
import PrivateRoutes from './routes/PrivateRoutesOld';
import { getCompanyProfilePublicService } from './services/companyService';
import Redirected from './components/pages/Redirected';
import AppLogin from './components/pages/AppLogin';
import QuickLoad from './components/pages/QuickLoad';
import { authHeader } from '../src/helpers/authHeader';
import Forgetpwd from './components/pages/Forgetpwd';
import Resetpwd from './components/pages/Resetpwd';
import CarrierOnboardReinvite from './components/pages/CarrierOnboardReinvite';
import '../src/public/css/map_popup.css';
import carrierOnboardMaterialUI from './components/pages/carrierOnboardMaterialUI';
import AuthorizeCarrierCopy from './components/common/AuthorizeCarrierCopy';
import TCWidgetComponent from './components/common/TCWidgetComponent';
import TCWidgetAuthorizeCarrier from './components/common/TCWidgetAuthorizeCarrier';
import Sweepstakes from './components/common/Sweepstakes';
import FAQCarriers from './components/common/FAQCarriers';
import WidgetTermsAndConditionsScreen from './components/common/WidgetTermsAndConditionsScreen';
import TermsAndConditionsScreenNew from './components/common/TermsAndConditionsScreenNew';
import ExternalOnboard from './components/pages/ExternalOnboard';
import CarrierInstallation from './components/pages/CarrierInstallation';
require('dotenv').config();

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isReady: false
        }
    }
    componentDidMount = () => {
        getCompanyProfilePublicService().then((response) => {
            if (response && response.companyProfile) {
                document.documentElement.style.setProperty('--main-color', response.companyProfile.colourCode);
                // similar to previous proposal but adds a random parameter to avoid caching
                let oldFavicon = document.getElementById('favicon')
                var link = document.createElement('link')
                link.id = 'favicon';
                link.type = 'image/x-icon'
                link.rel = 'icon';
                link.href = response.companyProfile.favicon + '?=' + Math.random();
                if (oldFavicon) {
                    document.head.removeChild(oldFavicon);
                }
                document.head.appendChild(link);
            }
        });
        let app_title = process.env.REACT_APP_TITLE;
        document.title = app_title;
        if (!this.state.isReady) {
            if (history.location.pathname === '/quickload') {
                this.setState({
                    isReady: true
                });
            } else if (history.location.pathname === '/cob') {
                this.setState({
                    isReady: true
                });
            } else if (history.location.pathname === '/cob/direct') {
                this.setState({
                    isReady: true
                });
            }else if (history.location.pathname === '/cob/install') {
                this.setState({
                    isReady: true
                });
            }
            else if (history.location.pathname === '/faq-tc-carriers') {
                this.setState({
                    isReady: true
                });
            }
            else if (history.location.pathname === '/tcwidget') {
                this.setState({
                    isReady: true
                });
            } else if (history.location.pathname === '/sweepstakes') {
                this.setState({
                    isReady: true
                });
            } else if (history.location.pathname === '/cob_reinvite') {
                this.setState({
                    isReady: true
                });
            } else if (history.location.pathname === '/forgotpwd') {
                this.setState({
                    isReady: true
                });
            } else if (history.location.pathname === '/resetpassword') {
                this.setState({
                    isReady: true
                });
            } else if (history.location.pathname === '/redirected') {
                this.setState({
                    isReady: true
                });
            }
            else {
                let authToken = localStorage.getItem('authToken');
                if (authToken) {
                    this.checkAuthToken();
                }
                else {
                    if (!(history.location.pathname === '/login' || history.location.pathname === '/' || history.location.pathname === '/quickload' || history.location.pathname === '/cob' || history.location.pathname === '/forgotpwd' || history.location.pathname === '/resetpassword' || history.location.pathname === '/authCarrier')) {
                        history.push('/login');
                    }
                    this.setState({
                        isReady: true
                    });
                }
            }
        }
    }

    checkAuthToken = () => {
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        const AUTHCHECK_ENDPOINT = BASE_URL + 'commonresource/checkauthtoken';
        fetch(AUTHCHECK_ENDPOINT, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => response.json())
            .then(response => {
                if (response.status) {
                    if (response.status === 'valid') {
                        if (history.location.pathname === '/login' || history.location.pathname === '/') {
                            // let userType = localStorage.getItem('userType');
                            history.push('/pages/dashboard');
                        }
                    }
                    else if (!(history.location.pathname === '/login' || history.location.pathname === '/')) {
                        history.push('/login');
                        localStorage.clear();
                    }
                }
                else if (!(history.location.pathname === '/login' || history.location.pathname === '/')) {
                    history.push('/login');
                    localStorage.clear();
                }
                this.setState({
                    isReady: true
                });
            })
            .catch(error => {
                if (!(history.location.pathname === '/login' || history.location.pathname === '/')) {
                    history.push('/login');
                }
                this.setState({
                    isReady: true
                });
            });
    }

    render() {
        if (this.state.isReady) {
            return (
                <Router history={history}>
                    <Switch>
                        <Route exact path='/redirected' component={Redirected} />
                        <Route exact path='/login' component={AppLogin} />
                        <Route exact path='/quickload' component={QuickLoad} />
                        <Route exact path='/forgotpwd' component={Forgetpwd} />
                        <Route exact path='/resetpassword' component={Resetpwd} />
                        <Route exact path='/cob' component={carrierOnboardMaterialUI} />
                        <Route exact path='/cob/direct' component={ExternalOnboard} />
                        <Route exact path='/cob/install' component={CarrierInstallation} />
                        <Route exact path='/cob_reinvite' component={CarrierOnboardReinvite} />
                        {/* <Route exact path='/eld' component={EldProviders} />                                
                                <Route exact path='/thankyou' component={ThankYouPage} /> */}
                        <Route exact path='/tcwidget' component={TCWidgetComponent} />
                        <Route path={'/authCarrier'} component={AuthorizeCarrierCopy} />
                        <Route path={'/terms-and-conditons'} component={TermsAndConditionsScreenNew} />
                        <Route path={'/terms-and-conditons-widget'} component={WidgetTermsAndConditionsScreen} />
                        {/* <Route path = {'/authCarriercopy'} component = {AuthorizeCarrierCopy}/> */}
                        <Route path={'/tcWidgetAuthCarrier'} component={TCWidgetAuthorizeCarrier} />
                        <Route path={'/sweepstakes'} component={Sweepstakes} />
                        <Route exact path={'/faq-tc-carriers'} component={FAQCarriers} />
                        <Route path={'/pages'} component={PrivateRoutes} />
                        <Route exact path='' component={AppLogin} />
                    </Switch>
                </Router>
            );
        }
        else {
            return <Fragment></Fragment>
        }
    }
}
export default App;