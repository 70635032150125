import React, { Component } from 'react';
class ReinviteThankYou extends Component{
    handleClick = () =>{
        //window.location = '/login'
        window.close('','_parent','');
    }
    render(){
        return(
            <section>
            <div className="container">
                <div className="row thank-you">
                    <div className="col-md-12">
                        <div className="contant-page">
                            <img src={this.props.companyProfile.logo} className="logo-thankyou" alt='Not Found'/>
                            <h6 className="heading-thankyou">Carrier reinvited Successfully!</h6>
                            {/* <p>Your credentials are safe and will not be shared. </p>
                            <p>
                                You will receive a verification email from an IntelliTrans administrator to enable your
                                account.
                            </p> */}
                            <div className="thankyou-btn">
                                <button type="button" onClick={this.handleClick} className="btn gradient-button-2">Done</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </section>
        )
    }
}

export default ReinviteThankYou