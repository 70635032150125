import React,{Component} from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { profileview } from '../../actions/userActions';
import { renderField } from '../../helpers/FormControls';
import BrokerProfilEdit from './brokerProfilEdit';
import { getcitybystate,getzipbycity } from '../../actions/loadActions';

class brokerprofileview extends Component
{
    state={
        modaldata:[]
     }

      carrierprofile=()=>
      {
          if(this.props.data.Company.currentCityId!==null)
          {
              this.props.dispatch(getcitybystate(this.props.data.Company.currentStateCode));
          }
  
          if(this.props.data.Company.currentZipId!==null)
          {
              this.props.dispatch(getzipbycity(this.props.data.Company.currentCityId));
          }
          this.setState({ modaldata: this.props.data.Company });
      }
  
      componentDidMount()
      {
          this.props.dispatch(profileview());
      }
   
    render(){
        document.title="Company Profile";  
        if(this.props.data.status === 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}
        this.props.initialize(this.props.data.Company); 
        // const { handleSubmit } = this.props;
        return(   
            <div className="table-data border">
                <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="truckinfo-tab" data-toggle="tab" href="#truckinfo" role="tab" aria-controls="truckinfo" aria-selected="true">Company Profile</a>
                    </li>      
                    <div className="flex-left-auto">
                        <ul className="ul-horizontal tab-menus">                        
                            <li>
                              <a href="/#" onClick={() => {this.carrierprofile()}} data-toggle="modal" data-target=".brokerprofiledetailsview" className="btn_carrier_move btn btn-sm btn-primary">Edit</a>
                            </li>
                        </ul>
                    </div>                           
                </ul>
                <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab">  
                        <div className="row">                 
                            <div className="col col-md-12">
                                <form className="tg-forms"> 
                                <article className="table-data truck-data shadow bg-white rounded">
                                    <div className="row">
                                        <div className="col col-md-6">
                                            <div className="form-group">
                                            <Field type="text" name="firstName" component={renderField} controlClass="requiredfield"  label="Name"/>                                
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="companyEmail" component={renderField} controlClass="requiredfield"  label="Email"/>
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="specialityTypeName" component={renderField} className="form-control" controlClass="requiredfield"  label="Speciality" />
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="currentAddress1" component={renderField} className="form-control" label="Address" />
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" className="form-control" component={renderField} controlClass="requiredfield" name="phoneNumber" label="Primary Phone" />
                                            </div>
                                        </div>  
                                        <div className="col col-md-6">
                                            <div className="form-group">
                                            <Field type="text" name="currentCityName" component={renderField} label="City"/>                                
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="currentStateName" component={renderField} label="State"/>
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="currentZipValue" component={renderField} className="form-control" label="Zip" />
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="websiteAddress" component={renderField} label="Website"/>
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="companyName" component={renderField} className="form-control" label="Company" />
                                            </div>
                                        </div>                                                           
                                    </div>
                                </article>
                                </form>
                            </div>
                        </div>
                  </div>                   
               </div>
               <BrokerProfilEdit modalvalues={this.state.modaldata} country={this.props.country} CityVal={this.props.CityVal} ZipVal={this.props.ZipVal} state={this.props.statelist} specialitytypelist={this.props.specialitytypelist}/>
        </div>   
     
    
        )
    }
}

const PageOneForm = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    })(brokerprofileview)

  const mapStateToProps = (state,ownProps) => { 
    return{
        data: state.userReducer.data,
        CityVal:state.loadReducer.CityVal,
        ZipVal:state.loadReducer.ZipVal,
        form: 'profiledetailsview',
    }    
  }
  
  const PageOneFormContainer = connect(mapStateToProps)(PageOneForm)
    
  export default PageOneFormContainer
