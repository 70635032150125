import React from 'react';
class Delayed extends React.Component {
    constructor(props) {
        console.log('delayed component render')
        super(props);
        console.log(this.props.data )
    }
    render() {
        return <article className="table-data truck-data shadow bg-white rounded">
        <div className="row">
            <div className="col-md-12">
                <div className="text-center">
               {this.props.data}.Redirecting to <a href="url">{ process.env.REACT_APP_REDIRECT_URL}</a> 
                </div>
            </div>
            <div className="text-center col-md-12 lds-hourglass">
                </div>
        </div>
    </article>
    }
}
export default Delayed;