import React from 'react';
import { TabsBar, TableDataComponent } from 'UI-Component';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { activeDeliveries, companyNameListAction, pastDeliveries, UpdateFactors } from '../../actions/companyActions';
import { connect } from 'react-redux';
import CreateDeliveryModal from './CreateDeliveryModal';
import { toastr } from 'react-redux-toastr';
// import ReduxFormEdit from '../../helpers/ReduxFormEdit';
import MaterialUITabs from '../uicomponents/DataTableGridMUIX';
import { Box } from '@mui/material';
const delivery_confirm_columns = [
    {
        text: 'Invoice',
        dataField: 'Invoice_Id',
        sort: true,
        headerStyle: { width: '80px' }
    },
    {
        text: 'Carrier Name',
        dataField: 'Carrier_Name',
        sort: true,
        headerStyle: { width: '175px' }
    },
    {
        text: 'Asset No',
        dataField: 'Asset_No',
        sort: true,
        headerStyle: { width: '95px' }
    },
    {
        text: 'Pickup Location',
        dataField: 'Pickup_Location',
        headerStyle: { width: '175px' }
    },
    {
        text: 'Pickup Location Status',
        dataField: 'PU_LOC_Status',
        sort: true,
        headerStyle: { width: '110px' }
    },
    {
        text: 'Scheduled Pickup Time',
        dataField: 'Sced_PU_Time',
        headerStyle: { width: '120px' }
    },
    {
        text: 'Pickup Time Status',
        dataField: 'PU_Time_Status',
        sort: true,
        headerStyle: { width: '110px' }
    },
    {
        text: 'Delivery Location',
        dataField: 'Delivery_Location',
        headerStyle: { width: '175px' }
    },
    {
        text: 'Delivery Location Status',
        dataField: 'DL_LOC_Status',
        sort: true,
        headerStyle: { width: '120px' }
    },
    {
        text: 'Scheduled Delivery Time',
        dataField: 'Schec_Delivery_Time',
        headerStyle: { width: '120px' }
    },
    {
        text: 'Delivery Time Status',
        dataField: 'Delivery_status',
        sort: true,
        headerStyle: { width: '120px' }
    },
    {
        text: 'Action',
        dataField: 'Refresh_Action',
        headerStyle: { width: '50px' }
    }
];
class DeliveryConfirmed extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isMenuOpen: false, tabIndex: 0 }
        this.getIndex = this.getIndex.bind(this)
        this.toggleMenu = this.toggleMenu.bind(this)
    }
    componentDidMount = () => {
        this.getActiveDeliveries(0);
        this.props.dispatch(companyNameListAction());
    }
    componentDidUpdate = (prevProps) => {
        console.log(this.props )
        if (this.props.postDelivereydata !== prevProps.postDelivereydata) {
            if (this.props.postDelivereydata?.status === 'OK') {
                document.getElementById('addDeliveryModalpopup').click()
                toastr.success('Success', this.props.postDelivereydata.message);
                this.getActiveDeliveries(0);
            }
            else if (this.props.postDelivereydata?.status === 'BAD_REQUEST') {
                toastr.error('Error', this.props.postDelivereydata.validationErrors[0]);
            }
        }
         if (this.props.updatedFactorData !== prevProps.updatedFactorData) {
            console.log(this.props.updatedFactorData )
            if (this.props.updatedFactorData?.status === 'OK') {
                toastr.success('Success', this.props.updatedFactorData.message);
            }
            else if (this.props.updatedFactorData?.status === 'BAD_REQUEST') {
                toastr.error('Error', this.props.updatedFactorData.validationErrors[0]);
            }
            else{
                toastr.error('Error', this.props.updatedFactorData.message);
            }
        }
    }
    updtateFactor = invoiceID => {
        console.log('invoiceID',invoiceID)
        this.props.dispatch(UpdateFactors(invoiceID))
    }
    getActiveDeliveries = (newTabIndex) => {
        this.setState({ tabIndex: newTabIndex })
        let payload = { staus: 'active' }
        this.props.dispatch(activeDeliveries(payload))
        sessionStorage.setItem('isActiveTab', 'active');
    }
    getPastDeliveries = (newTabIndex) => {
        this.setState({ tabIndex: newTabIndex })
        let payload = { staus: 'past' }
        this.props.dispatch(pastDeliveries(payload))
        sessionStorage.setItem('isActiveTab', 'past');
    }
    getIndex(index, selectedId) {
        this.setState({ activeId: selectedId })
    }
    active_Delivery_Data() {
        let activeDeliveryConfirmedData = [];
        this.props.ActiveDeliveryConfirmedData.map((row, index) => {
            activeDeliveryConfirmedData.push({
                id: row.invoiceNumber,
                Invoice_Id: row.invoiceNumber,
                Carrier_Name: row.carrierInfo?.carrierName,
                Asset_No: row.vehicleInfo?.assetNumber,
                Pickup_Location: row.pickupInfo?.location,
                PU_LOC_Status: <div key={index} data-tip data-for={'pulocstatustooltip' + row.invoiceNumber}>{row.pickupInfo.locationStatus === true ? <span className='matched'>Matched</span> : <span className='unmatched'>Unmatched</span>}
                    <ReactTooltip id={'pulocstatustooltip' + row.invoiceNumber} >
                        <p>Closest Timestamp: {row.closestPickupMatchInfo.closestDate ? moment(row.closestPickupMatchInfo.closestDate).format('MM-DD-YYYY HH:mm A') : ''}</p>
                        <p>Closest Miles From Time Point: {row.closestPickupMatchInfo?.milesFromPoint}</p>
                        <p>Closest Latitude: {row.closestPickupMatchInfo?.latitude}</p>
                        <p>Closest Longitude: {row.closestPickupMatchInfo?.longitude}</p>
                    </ReactTooltip>
                </div>,
                Sced_PU_Time: moment(row.pickupInfo?.pickupTime).format('MM-DD-YYYY HH:mm A'),
                PU_Time_Status: <div key={index} data-tip data-for={'putimestatustooltip' + row.invoiceNumber}>{row.pickupInfo.pickupTimeStatus === true ? <span className='matched'>Matched</span> : <span className='unmatched'>Unmatched</span>}
                    <ReactTooltip id={'putimestatustooltip' + row.invoiceNumber} >
                        <p>Closest Timestamp: {row.closestPickupMatchInfo.closestTimeDate ? moment(row.closestPickupMatchInfo.closestTimeDate).format('MM-DD-YYYY HH:mm A') : ''}</p>
                        <p>Closest Miles From Time Point: {row.closestPickupMatchInfo?.milesFromClosetTimePoint}</p>
                        <p>Closest Latitude: {row.closestPickupMatchInfo?.latitudeClosestTime}</p>
                        <p>Closest Longitude: {row.closestPickupMatchInfo?.longitudeClosestTime}</p>
                    </ReactTooltip>
                </div>,
                Delivery_Location: row.dropInfo?.location,
                DL_LOC_Status: <div key={index} data-tip data-for={'duloctooltip' + row.invoiceNumber}>{row.dropInfo.locationStatus === true ? <span className='matched'>Matched</span> : <span className='unmatched'>Unmatched</span>}
                    <ReactTooltip id={'duloctooltip' + row.invoiceNumber} >
                        <p>Closest Timestamp: {row.closestDropMatchInfo.closestDate ? moment(row.closestDropMatchInfo.closestDate).format('MM-DD-YYYY HH:mm A') : ''}</p>
                        <p>Closest Miles From Time Point: {row.closestDropMatchInfo?.milesFromPoint}</p>
                        <p>Closest Latitude: {row.closestDropMatchInfo?.latitude}</p>
                        <p>Closest Longitude: {row.closestDropMatchInfo?.longitude}</p>
                    </ReactTooltip>
                </div>,
                Schec_Delivery_Time: moment(row.dropInfo?.dropTime).format('MM-DD-YYYY HH:mm A'),
                Delivery_status: <div key={index} data-tip data-for={'dutimestamptooltip' + row.invoiceNumber}>{row.dropInfo.dropTimeStatus === true ? <span className='matched'>Matched</span> : <span className='unmatched'>Unmatched</span>}
                    <ReactTooltip id={'dutimestamptooltip' + row.invoiceNumber} >
                        <p>Closest Timestamp: {row.closestDropMatchInfo.closestTimeDate ? moment(row.closestDropMatchInfo.closestTimeDate).format('MM-DD-YYYY HH:mm A') : ''}</p>
                        <p>Closest Miles From Time Point: {row.closestDropMatchInfo?.milesFromClosetTimePoint}</p>
                        <p>Closest Latitude: {row.closestDropMatchInfo?.latitudeClosestTime}</p>
                        <p>Closest Longitude: {row.closestDropMatchInfo?.longitudeClosestTime}</p>
                    </ReactTooltip>
                </div>,
                Refresh_Action:<button onClick={(e) => {this.updtateFactor(row.invoiceNumber)}} id='refreshBtn' className='btn btn-primary btn-sm' >
                <span className='refresh'>Update</span>
            </button>
            });
            return activeDeliveryConfirmedData;
        });
        return activeDeliveryConfirmedData;
    }
    past_Delivery_Data() {
        let pastDeliveryData = [];
        this.props.PastDeliveryConfirmedData.map((row, index) => {
            pastDeliveryData.push({
                id: row.invoiceNumber,
                Invoice_Id: row.invoiceNumber,
                Carrier_Name: row.carrierInfo?.carrierName,
                Asset_No: row.vehicleInfo?.assetNumber,
                Pickup_Location: row.pickupInfo?.location,
                PU_LOC_Status: <div key={index} data-tip data-for={'pulocstatustooltip' + row.invoiceNumber}>{row.pickupInfo.locationStatus === true ? <span className='matched'>Matched</span> : <span className='unmatched'>Unmatched</span>}
                    <ReactTooltip id={'pulocstatustooltip' + row.invoiceNumber} >
                        <p>Closest Timestamp: {row.closestPickupMatchInfo.closestDate ? moment(row.closestPickupMatchInfo.closestDate).format('MM-DD-YYYY HH:mm A') : ''}</p>
                        <p>Closest Miles From Time Point: {row.closestPickupMatchInfo?.milesFromPoint}</p>
                        <p>Closest Latitude: {row.closestPickupMatchInfo?.latitude}</p>
                        <p>Closest Longitude: {row.closestPickupMatchInfo?.longitude}</p>
                    </ReactTooltip>
                </div>,
                Sced_PU_Time: moment(row.pickupInfo?.pickupTime).format('MM-DD-YYYY HH:mm A'),
                PU_Time_Status: <div key={index} data-tip data-for={'putimestatustooltip' + row.invoiceNumber}>{row.pickupInfo.pickupTimeStatus === true ? <span className='matched'>Matched</span> : <span className='unmatched'>Unmatched</span>}
                    <ReactTooltip id={'putimestatustooltip' + row.invoiceNumber} >
                        <p>Closest Timestamp: {row.closestPickupMatchInfo.closestTimeDate ? moment(row.closestPickupMatchInfo.closestTimeDate).format('MM-DD-YYYY HH:mm A') : ''}</p>
                        <p>Closest Miles From Time Point: {row.closestPickupMatchInfo?.milesFromClosetTimePoint}</p>
                        <p>Closest Latitude: {row.closestPickupMatchInfo?.latitudeClosestTime}</p>
                        <p>Closest Longitude: {row.closestPickupMatchInfo?.longitudeClosestTime}</p>
                    </ReactTooltip>
                </div>,
                Delivery_Location: row.dropInfo?.location,
                DL_LOC_Status: <div key={index} data-tip data-for={'duloctooltip' + row.invoiceNumber}>{row.dropInfo.locationStatus === true ? <span className='matched'>Matched</span> : <span className='unmatched'>Unmatched</span>}
                    <ReactTooltip id={'duloctooltip' + row.invoiceNumber} >
                        <p>Closest Timestamp: {row.closestDropMatchInfo.closestDate ? moment(row.closestDropMatchInfo.closestDate).format('MM-DD-YYYY HH:mm A') : ''}</p>
                        <p>Closest Miles From Time Point: {row.closestDropMatchInfo?.milesFromPoint}</p>
                        <p>Closest Latitude: {row.closestDropMatchInfo?.latitude}</p>
                        <p>Closest Longitude: {row.closestDropMatchInfo?.longitude}</p>
                    </ReactTooltip>
                </div>,
                Schec_Delivery_Time: moment(row.dropInfo?.dropTime).format('MM-DD-YYYY HH:mm A'),
                Delivery_status: <div key={index} data-tip data-for={'dutimestamptooltip' + row.invoiceNumber}>{row.dropInfo.dropTimeStatus === true ? <span className='matched'>Matched</span> : <span className='unmatched'>Unmatched</span>}
                    <ReactTooltip id={'dutimestamptooltip' + row.invoiceNumber} >
                        <p>Closest Timestamp: {row.closestDropMatchInfo.closestTimeDate ? moment(row.closestDropMatchInfo.closestTimeDate).format('MM-DD-YYYY HH:mm A') : ''}</p>
                        <p>Closest Miles From Time Point: {row.closestDropMatchInfo?.milesFromClosetTimePoint}</p>
                        <p>Closest Latitude: {row.closestDropMatchInfo?.latitudeClosestTime}</p>
                        <p>Closest Longitude: {row.closestDropMatchInfo?.longitudeClosestTime}</p>
                    </ReactTooltip>
                </div>,
                Refresh_Action:<button onClick={(e) => {this.updtateFactor(row.invoiceNumber)}} id='refreshBtn' className='btn btn-primary btn-sm' >
                <span className='refresh'>Update</span>
            </button>
            });
        return pastDeliveryData;
        });
        return pastDeliveryData;
    }
    toggleMenu() {
        this.setState({ isMenuOpen: !this.state.isMenuOpen })
    }
    render() {
        let indicationhtml = '';
        if (this.props.loading) {
            indicationhtml =
                <div className='spinner-border' role='status'>
                    <div className='row react-bs-table-no-data'></div>
                </div>
        }
        else {
            indicationhtml = 'No Data Found';
        }
        return (
            <article className='table-data truck-data shadow bg-white rounded' id='htmlclr'>
                <div className='row no-gutters'>
                    <h5 className="modal-title doc-color">Delivery Confirmations</h5>
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className='col-md-12 tab-btn'>
                                <div className='d-flex justify-content-end'>
                                    <ul className='ul-horizontal tab-menus'>
                                        <a href='/#' className='btn btn-primary btn-sm' data-toggle='modal' data-backdrop='false' id='createDeliveryID' data-target='.createDeliveryModal' >Create Delivery</a>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <MaterialUITabs tabsData={
                            [{ component: 'Active', tabID: 'active-tab', refData: '#active', methodName: () => { this.getActiveDeliveries(0) } },
                            { component: 'Past', tabID: 'past-tab', refData: '#past', methodName: () => { this.getPastDeliveries(1) } }
                            ]}
                            getIndex={this.getIndex}
                            activeTab={'#active'}
                            state={this.state}
                        />
                        <div className='tab-content' id='myTabContent'>
                        <Box >
                                {this.state.tabIndex === 0 && (
                                    <Box>
                                <TableDataComponent tableData={this.active_Delivery_Data()} columnData={delivery_confirm_columns} noDataIndication={indicationhtml} />
                                    </Box>
                                )}
                                {this.state.tabIndex === 1 && (
                                    <Box>
                                <TableDataComponent tableData={this.past_Delivery_Data()} columnData={delivery_confirm_columns} noDataIndication={indicationhtml} />
                                    </Box>
                                )}
                            </Box>
                        </div>
                    </div>
                    <CreateDeliveryModal state={this.props.statelist} citylist={this.props.citylist} carriernamelist={this.props.carriernamelist} />
                </div>
            </article>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        loading: state.companyReducer.loading,
        ActiveDeliveryConfirmedData: state.companyReducer.activeDeliveries,
        PastDeliveryConfirmedData: state.companyReducer.pastDeliveries,
        updatedFactorData: state.companyReducer.updatedData,
        postDelivereydata: state.loadReducer.postDelivereydata,
        postFuelAdvData: state.loadReducer.postFuelAdvData
    }
}
export default connect(mapStateToProps)(DeliveryConfirmed);