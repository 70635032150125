import { put, call, takeEvery } from 'redux-saga/effects';
import {newELDPostService } from '../services/assetService';
import * as types from '../actions';
    function* newELDPost(payload) { 
        try {   
                const response = yield call( newELDPostService, payload );
                yield put({type: types.POST_NEW_ELD_DATA_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.POST_NEW_ELD_DATA_ERROR, error })
        }
    }

export function* assetSaga() {
    yield takeEvery(types.POST_NEW_ELD_DATA, newELDPost);
}