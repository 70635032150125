import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginUserAction } from '../../actions/authActions';
import { toastr } from 'react-redux-toastr';
import {getCompanyProfilePublicService} from '../../services/companyService';
import {appBaseUrl,getBaseUrl} from '../../helpers/appUtil';

import $ from 'jquery';
require('dotenv').config();

class AppLogin extends Component{
	constructor(props){
		super(props);

		this.state = {
			fields: {},
			errors: {
				error:''
			},
			companyProfile:{
				companyProfileId:null,
                companyId:null,
                colourCode:null,
                phoneNumber:null,
                logo:null,
                email:null,
				banner:null,
				favicon:null
			}
		}
	}
	componentDidMount() {
		// let urlparam = this.props.location.search.substring(1);
		var baseUrl = window.location.origin;
		var host = window.location.host;
		// stackoverflow.com
		host = baseUrl.replace(/\/$/, '');
		host = baseUrl.replace('truckergig.com', '');
		host = baseUrl.replace('truckercloud.com', '');
		host = baseUrl.replace(/\./g, '');
		let theme = process.env.REACT_APP_BASE_URL + 'publicresource/getbycode/css?originUrl='+getBaseUrl();
		$('<link/>', {rel: 'stylesheet', href: theme}).appendTo('head');
		// let app_title = baseUrl;
		// app_title = appBaseUrl() + ' - Login';
		document.title = appBaseUrl() + 'Login';
		let msg = localStorage.getItem('logmsg');
		if(msg){
			toastr.info('Oops', msg);
		}		
		localStorage.clear();
		getCompanyProfilePublicService().then((response)=>{
			if(response && response.companyProfile)
			{
				document.documentElement.style.setProperty('--main-color', response.companyProfile.colourCode);
				this.setState({
					...this.state,
					companyProfile:response.companyProfile
				});
			}
		});
	}
	changeHandler=(e)=>{
		this.setState({
			[e.target.id]:e.target.value,
		});
	}
	handleSubmit = (e) =>{
		e.preventDefault();
	    let UserName = e.target.UserName.value;
		let Password = e.target.Password.value;
		const data = {
	      	UserName, Password
	    };
		this.props.dispatch(loginUserAction(data));
	}
	render(){
		if(this.state.companyProfile.favicon){
			let link = document.querySelector("link[rel~='icon']");			
			link.href = this.state.companyProfile.favicon;
		}
		var data=this.props.data;
		var errors= [];
		var menuIcon=['dashboard.svg','reports.svg','factors.svg','shippers.svg','shippers.svg','carriers.svg','brokers.svg']
		if(data) {
			// var menuList = Object.keys(data).map(function(key) {
			// 	if(key=='Components')
			// 		return data[key];
			// 	else return null;
			// });
			var menuList=[];
			if(data.hasOwnProperty('Components')){
				menuList=data.Components;
			}
			if(data.status !== 'BAD_REQUEST') {
				if (this.props.userAuthenticated === true ) {
					localStorage.setItem('userMenu', menuList);
					localStorage.setItem('menuIcon', menuIcon);
					localStorage.setItem('companyId', data.CompanyId);
					localStorage.setItem('userName', data.UserName);
					localStorage.setItem('authToken', data.AuthToken);
					localStorage.setItem('Company_Name', data.Company_Name);	
					localStorage.setItem('Password', data.Password);				
					localStorage.setItem('IsLoadDriverShow', data.IsLoadDriverShow);	
					localStorage.setItem('userType', data.UserType.toLowerCase());
					localStorage.setItem('mapboxKey',data.MAPBOX_API_KEY);
					localStorage.setItem('UserTypeId',data.UserTypeId);
					localStorage.setItem('UserId',data.UserId);
					localStorage.setItem('Company_Profile',JSON.stringify(data.Company_Profile));
					localStorage.setItem('MAPBOX_ACCOUNT', data.MAPBOX_ACCOUNT);
					localStorage.setItem('GOOGLE_MAP_KEY', data.GOOGLE_MAP_KEY);
					localStorage.setItem('SUBSCRIPTION_PLAN_ID', data.SUBSCRIPTION_PLAN_ID);
					if(data.UserType.toLowerCase() === 'broker')
					{
					  window.location = '/pages/dashboard';
					}else if(data.UserType.toLowerCase() === 'carrier')
					{
					  window.location = '/pages/opt_in-out';
					}
					else{
						window.location = '/pages/dashboard';
					}			
				}
			}
			else
			{
				errors['error']=data.message
				//errors['error']='Invalid username / password'
			}
		}
			
		return(	
			<section className=''>
				<div className='container-fluid'>
					<div className='row main-content text-center login'>
						<div className='col-md-5 col-xs-12 col-sm-12 login_form'>
							<div className='col-lg-12 login-logo'>
								<img  src={`${this.state.companyProfile.logo}?${new Date().getTime()}`} alt='Not Found'/>
							</div>
							<div className='col-lg-11 text-center'>
								<form name='' onSubmit={ this.handleSubmit }>
									<div className='col-lg-12 form-group'>
										<input type='text' name='UserName' id='UserName' className='form-control logintextbox' placeholder='Username'   />
									</div>
									<div className='col-lg-12 form-group'>
										<input type='password' name='Password' id='Password' className='form-control loginpwdbox' placeholder='Password'  />
										<span style={{color: 'red'}}>{errors['error']}</span>
									</div>
									<div className='col-lg-12 form-group'>
										<input type='submit' className='btn gradient-button gradient-button-1' value='login'/>
									</div>
								</form>
							</div>
						</div>
						<div className='col-md-7 text-center'>
							<div className='login-banner'>
								<img src={`${this.state.companyProfile.banner}?${new Date().getTime()}`}  alt='Not Found'/>
							</div>
						</div>
					</div>				   
				</div>
			</section>
		)
	}
}
// const mapStateToProps = (response) => ({response});
const mapStateToProps = state => ({
    data: state.authReducer.data,
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    userAuthenticated: state.authReducer.userAuthenticated
});

export default connect(mapStateToProps)(AppLogin);
//export default AppLogin