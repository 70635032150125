import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Loader from '../common/loader';
import { toastr } from 'react-redux-toastr';
import {appBaseUrl} from '../../helpers/appUtil';
import {getRolesAndAccessService, saveRoleAccessService} from '../../services/userRoleService';
import $ from 'jquery';
class RoleAccess extends Component{
	constructor(props){
        super(props);
        this.state={
            isReady:false,
            rowSel:-1,
            companyId:localStorage.getItem('companyId'),
            roleId:'',            
            roles:[],
            roleAccess:[],
            saveFlag:false
        }
    }

    componentDidMount = () => {
        document.title = appBaseUrl() + "Role Access";
        getRolesAndAccessService({companyId:this.state.companyId}).then((response) =>{
            let roleId='';            
            let roles=[];
            let roleAccess=[];
            
            if(response && response.roleList && response.roleList.length>0){
                roleId = response.roleList[0].roleId;
                roles = response.roleList.map((row, index) =>{
                    return {
                        roleId:row.roleId,
                        roleName:row.roleName
                    }
                });
            }
            if(response && response.roleAccessList && response.roleAccessList.length>0){                                
                roleAccess = response.roleAccessList.map((row, index) =>{                    
                    return {
                        roleAccessId:row.roleAccessId,
                        roleId:row.roleId,
                        menuId:row.menuId,
                        companyId:row.companyId,
                        addAccess:row.addAccess,
                        editAccess:row.editAccess,
                        deleteAccess:row.deleteAccess,
                        viewAccess:row.viewAccess,
                        menuName:((row.menu && row.menu.menuName) ? row.menu.menuName : ''),
                        selOption:false
                    }
                });
            }
            this.setState({
                isReady:true,               
                roleId:roleId,
                roles:roles,
                roleAccess:roleAccess
            });
        });
    }
    saveRoleAccess = (e, index) => {
        e.preventDefault();
        let add = $("#chkAdd"+index).prop( "checked");
        let edit = $("#chkEdit"+index).prop( "checked");
        let del = $("#chkDel"+index).prop( "checked");
        let view = $("#chkView"+index).prop( "checked");
        let param={
            roleAccessId:this.state.roleAccess[index].roleAccessId,
            roleId:this.state.roleAccess[index].roleId,
            menuId:this.state.roleAccess[index].menuId,
            companyId:this.state.roleAccess[index].companyId,
            addAccess:(add?1:0),
            editAccess:(edit?1:0),
            deleteAccess:(del?1:0),
            viewAccess:(view?1:0),
        }
        saveRoleAccessService(param).then((response)=>{            
            if(response && response.status){
                let roleAccess = this.state.roleAccess;
                roleAccess[index].selOption=false;
                roleAccess[index].addAccess=(add?1:0);
                roleAccess[index].editAccess=(edit?1:0);
                roleAccess[index].deleteAccess=(del?1:0);
                roleAccess[index].viewAccess=(view?1:0);
                this.setState({
                    ...this.state,
                    roleAccess:roleAccess,
                    saveFlag:false
                });
                toastr.success('Updated!', 'Role access updated successfully');
            } else {
                this.setState({
                    ...this.state,
                    saveFlag:false
                });
                toastr.error('Oops!', 'Unable to update role access');
            }
        });
        this.setState({
            ...this.state,
            rowSel:-1,
            saveFlag:true            
        });
    }
    cancelRoleAccess = (e, index) => {
        e.preventDefault();        
        let roleAccess = this.state.roleAccess;
        roleAccess[index].selOption=false;
        this.setState({
            ...this.state,
            rowSel:-1,
            roleAccess:roleAccess
        });
        
    }
    selOptionChange = (e, index) => {                
        let roleAccess = this.state.roleAccess;
        let userRole = roleAccess[index];
        userRole.selOption=true;
        roleAccess[index]=userRole;
        if(this.state.rowSel>=0){
            let userRoleSel = roleAccess[this.state.rowSel];
            userRoleSel.selOption=false;
            roleAccess[this.state.rowSel]=userRoleSel;
        }
        this.setState({
            ...this.state,
            rowSel:index,
            roleAccess:roleAccess
        });
    }
    loadTableData = () =>{
        return this.state.roleAccess.map((row, index) =>{            
            if(row.selOption){               
                return {
                    id:row.roleAccessId,
                    sel_Option:<input key={'radioRoleSel'+index} name="selRole" type="radio" checked onChange={(e) =>{this.selOptionChange(e,index)}} disabled={this.state.saveFlag}/>,
                    menuName:row.menuName,                    
                    add:<input key={'chkAdd'+index} id={'chkAdd'+index} type="checkbox" defaultChecked={row.addAccess===1}/>,
                    edit:<input key={'chkEdit'+index} id={'chkEdit'+index} type="checkbox" defaultChecked={row.editAccess===1}/>,
                    delete:<input key={'chkDel'+index} id={'chkDel'+index} type="checkbox" defaultChecked={row.deleteAccess===1}/>,
                    view:<input key={'chkView'+index} id={'chkView'+index} type="checkbox" defaultChecked={row.viewAccess===1}/>,
                    mode:<span>
                    <a href="/#" key={'chkSave'+index} onClick={(e) =>{this.saveRoleAccess(e, index)}} className="icofont-check-circled icofont-2x" > </a> &nbsp;
                    <a href="/#" key={'chkCancel'+index} onClick={(e) =>{this.cancelRoleAccess(e, index)}} className="icofont-close-circled icofont-2x" > </a></span>
                }
            } else {
                return {
                    id:row.roleAccessId,
                    sel_Option:<input key={'radioRoleSel'+index} name="selRole" type="radio" onChange={(e) =>{this.selOptionChange(e,index)}} disabled={this.state.saveFlag} />,
                    menuName:row.menuName,
                    add:(row.addAccess===1?<i className="icofont-check-alt icofont-2x" />:''),
                    edit:(row.editAccess===1?<i className="icofont-check-alt icofont-2x" />:''),
                    delete:(row.deleteAccess===1?<i className="icofont-check-alt icofont-2x" />:''),
                    view:(row.viewAccess===1?<i className="icofont-check-alt icofont-2x" />:''),
                    mode:''
                }
            }
        });
    }
    loadTableColumns = () => {
        return [	
            {
                text:'',
                dataField:'sel_Option',
                sort:false
            },
            {
                text:'Menu',
                dataField:'menuName',
                sort:true
            },            
            {
                text:'Add',
                dataField:'add',
                sort:false
            },
            {
                text:'Edit',
                dataField:'edit',
                sort:false
            },
            {
                text:'Delete',
                dataField:'delete',
                sort:false
            },
            {
                text:'View',
                dataField:'view',
                sort:false
            },
            {
                text:'Action',
                dataField:'mode',
                sort:false
            },
        ];
    }

    

    set_role = (e) => {
        this.setState({
            ...this.state,
            isReady:false,
            roleId:e.target.value
        });
        getRolesAndAccessService({companyId:this.state.companyId,roleId:e.target.value}).then((response) =>{
            
            let roleAccess=[];           
            
            if(response && response.roleAccessList && response.roleAccessList.length>0){                
                roleAccess = response.roleAccessList.map((row, index) =>{
                    return {
                        roleAccessId:row.roleAccessId,
                        roleId:row.roleId,
                        menuId:row.menuId,
                        companyId:row.companyId,
                        addAccess:row.addAccess,
                        editAccess:row.editAccess,
                        deleteAccess:row.deleteAccess,
                        viewAccess:row.viewAccess,
                        menuName:((row.menu && row.menu.menuName) ? row.menu.menuName : ''),
                        selOption:false
                    }
                });
            }
            this.setState({
                isReady:true,  
                roleAccess:roleAccess
            });
        });

        
    }

    renderRoles = () =>{
        let roles = this.state.roles.map((row, index) =>{
            return <option key={index} value={row.roleId}>{row.roleName}</option>
        });
        return <Fragment>
            <div className="col-1">Roles</div>
            <div className="col-2">
                <select className="form-control" value={this.state.roleId} onChange={(e) =>{this.set_role(e)}} disabled={this.state.saveFlag}>
                    {roles}
                </select>
            </div>
        </Fragment>
    }
    render = () =>{
        const { SearchBar } = Search;

        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                Showing { from } to { to } of { size } Results
            </span>
        );
		  
        const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal
        };
        if(this.state.isReady){
            return(
                <article className="table-data truck-data shadow bg-white rounded">
                    <div className="row">
                        <div className="col">
                            <h3>Role Access</h3>
                        </div>
                    </div>
                    <p>&nbsp;</p>
                    <div className="row">
                        <div className="col">
                            <ToolkitProvider
                                keyField="id"
                                data={ this.loadTableData() }
                                columns={ this.loadTableColumns() }
                                search
                            >
                                {
                                props => (
                                    <div>
                                        <div className="row">                                            
                                            {this.renderRoles()}
                                            
                                            <div className="col">
                                                <SearchBar { ...props.searchProps } />
                                            </div>
                                        </div>
                                        	
                                        <BootstrapTable bootstrap4 
                                            { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication='No data found'  hover 
                                            bordered={ false } />
                                    </div>
                                )
                                }
                            </ToolkitProvider>
                        </div>
                    </div>
                </article>
            );
        }
        return(<Loader />);
    }    
    
}
export default RoleAccess;