import React from 'react'
import { Tabs as MuiTabs } from '@mui/material'
import Tab from '@mui/material/Tab'
import { styled } from '@mui/material/styles'
import { Container } from '../../muiComponents'

import styles from './tab.css'

const MuiTab = styled((props) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  fontWeight: 700,
  width: 'auto',
  fontSize:'14px',
  minHeight: '45px',
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  marginRight: theme.spacing(1),
}))

const Tabs = (props) => {
  const { tabData, value, handleChange, iconPosition = 'end' } = props
  return (
    <Container sx={{ position: 'relative' }}>
      <MuiTabs value={value} onChange={(e, val) => handleChange(e, val)}>
        {tabData?.map((item, index) => (
          <MuiTab
            key={`key-${index}`}
            icon={
              item.badgeColor && (
                <Container
                  className={styles.badge}
                  sx={{ background: item.badgeColor }}
                >
                  12
                </Container>
              )
            }
            value={index}
            label={item.label}
            iconPosition={iconPosition}
          />
        ))}
      </MuiTabs>
    </Container>
  )
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

export { Tabs, TabPanel }
