import { GenerateOAuthCSRF } from "./GenerateOAuthCSRF";
import RegexValidators from "../../RegexValidators";
import DefaultPermissions from "../DefaultPermissions";
import { SetCookie } from "../../Cookies";

const OAuthRedirectLogic = (oAuth, eldInfo, isCfinsMotive) => {
    if (!oAuth.clientId || oAuth.clientId === undefined || oAuth.clientId.trim() === "" || !eldInfo)
        return null;

    var urlHasScopes = false
    var responseType = ""
    var oAuthURL = ""
    var eldName = eldInfo.eldVendor
    var scopeSeparator = " "
    var urlScope = "="
    var redirectURI = oAuth.redirectUri
    const csrf = GenerateOAuthCSRF(eldInfo.eldVendorId, oAuth.companyId, oAuth.clientId)

    SetCookie('oauthCsrfKey', csrf, 0.1, true)

    switch (eldName) {
        case "Motive":
            urlHasScopes = true
            responseType = "code"
            oAuthURL = "https://api.gomotive.com/oauth/authorize?client_id="+oAuth.clientId+"&redirect_uri="+redirectURI+"&response_type="+responseType+"&scope"
            break;
        case "Samsara":
            urlHasScopes = false
            responseType = "code"
            oAuthURL = "https://api.samsara.com/oauth2/authorize?client_id="+oAuth.clientId+"&redirect_uri="+redirectURI+"&state="+csrf+"&response_type="+responseType
            break;
        default:
            return null;
    }

    if (urlHasScopes === true) {
        const authorizationData = (eldInfo.eldDataSharing.authenticationData 
                    && eldInfo.eldDataSharing.authenticationData === true 
                    && eldInfo.eldDataSharing.authenticationDataEnabled 
                    && eldInfo.eldDataSharing.authenticationDataEnabled === true);
        const locationData = (eldInfo.eldDataSharing.locationData 
                    && eldInfo.eldDataSharing.locationData === true 
                    && eldInfo.eldDataSharing.locationDataEnabled 
                    && eldInfo.eldDataSharing.locationDataEnabled === true);
        const vehicleData = (eldInfo.eldDataSharing.vehicleData
                    && eldInfo.eldDataSharing.vehicleData === true
                    && eldInfo.eldDataSharing.vehicleDataEnabled
                    && eldInfo.eldDataSharing.vehicleDataEnabled === true);
        const driverData = (eldInfo.eldDataSharing.driverData
                    && eldInfo.eldDataSharing.driverData === true
                    && eldInfo.eldDataSharing.driverDataEnabled
                    && eldInfo.eldDataSharing.driverDataEnabled === true);
        const hoursOfServiceData = (eldInfo.eldDataSharing.hoursOfServiceData
                    && eldInfo.eldDataSharing.hoursOfServiceData === true
                    && eldInfo.eldDataSharing.hoursOfServiceDataEnabled
                    && eldInfo.eldDataSharing.hoursOfServiceDataEnabled === true);

        if ((DefaultPermissions.authorization === true || authorizationData === true) 
            && oAuth.scopes.authorization) {
            urlScope += scopeSeparator + oAuth.scopes.authorization
        }
        if ((DefaultPermissions.location === true || locationData === true)
            && oAuth.scopes.location) {
            urlScope += scopeSeparator + oAuth.scopes.location
        }
        if ((DefaultPermissions.vehicle === true || vehicleData === true) 
            && oAuth.scopes.vehicle) {
            urlScope += scopeSeparator + oAuth.scopes.vehicle
        }
        if ((DefaultPermissions.driver === true || driverData === true) 
            && oAuth.scopes.driver) {
            urlScope += scopeSeparator + oAuth.scopes.driver
        }
        if ((DefaultPermissions.hos === true || hoursOfServiceData === true)
            && oAuth.scopes.hos) {
            urlScope += scopeSeparator + oAuth.scopes.hos
        }
        if ((DefaultPermissions.engine === true || (
                eldInfo.eldDataSharing.engineData 
                && eldInfo.eldDataSharing.engineData === true 
                && eldInfo.eldDataSharing.engineDataEnabled 
                && eldInfo.eldDataSharing.engineDataEnabled === true)
            )
            && oAuth.scopes.engine) {
            urlScope += scopeSeparator + oAuth.scopes.engine
        }
        if ((DefaultPermissions.dvir === true || (
                eldInfo.eldDataSharing.dvirData 
                && eldInfo.eldDataSharing.dvirData === true 
                && eldInfo.eldDataSharing.dvirDataEnabled 
                && eldInfo.eldDataSharing.dvirDataEnabled === true)
            )
            && oAuth.scopes.safety) {
            urlScope += scopeSeparator + oAuth.scopes.safety
        }
        oAuthURL = oAuthURL + urlScope.replaceAll('='+scopeSeparator,'=')
    }

    if (!RegexValidators('url', oAuthURL)) {
        return null;
    }
    
    return oAuthURL
}

export default OAuthRedirectLogic