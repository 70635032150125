import React, { Component,Suspense } from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { tcWidgetCarrierInvitation } from '../../actions/companyActions';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import MiniDrawerELD from '../../muiComponents/Sidebar/ELDSidebar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline'
import { Container, Card, TypoGraph } from '../../muiComponents'
import ELDVerificationModal from '../../helpers/ELDVerificationModalMaterialUI';
import Icon from '../../muiComponents/icon'
import noPhoto from '../../no-photo-available.png'
import { history } from '../../helpers/history';
import { withRouter } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { getBaseUrl, getUrlParams } from '../../helpers/appUtil';
import $ from 'jquery';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import FAQModal from '../../helpers/FAQModal';
import NewEld from '../../helpers/NewEld';
import { getColorCOdeService, getDynamicDocumentURL } from '../../services/companyService';
import * as ReactBootstrap from 'react-bootstrap';
import { checkSplChar } from '../../helpers/searchValidation';
import MenuIcon from '@mui/icons-material/Menu';
import { Button } from '@mui/material';
import EldCardList from '../onboarding/EldCardList';
import { GetCookie } from '../../helpers/Cookies';
require('dotenv').config();

var localFilteredData;

class TCWidgetComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedData: [],
            invitationData: {},
            error: '',
            radio2: false,
            radio3: true,
            email: '',
            codeType: 'DOT',
            codeValue: '',
            mcNumber: '',
            dotNumber: '',
            mcNumberError: '',
            dotNumberError: '',
            isDisableOnboard: true,
            isDataUpdated: false,
            mainModalOpen: false,
            newEldOpen: false,
            drawerOpen: false,
            preselectedModalScreen: 0,
            returnFromOAuth: false
        }
        this.checkedArray = []
        this.temp = []
        this.carrierInviteReponse = {};
        this.preChecked = [];
        this.setELDName = this.setELDName.bind(this)
        this.selectedFilterData = this.selectedFilterData.bind(this)
        this.redirectVerifyELD = this.redirectVerifyELD.bind(this)
        this.diableCheck = this.diableCheck.bind(this)
        this.handleMainModalClose = this.handleMainModalClose.bind(this)
        this.handleELDModalClose = this.handleELDModalClose.bind(this)
    }
    componentDidMount = () => {
        // show widget on mount component
        // zE('webWidget', 'show')
        if (JSON.parse(sessionStorage.getItem('hasLocalData'))) {
            let param = { scenario: JSON.parse(sessionStorage.getItem('scenario')), codeType: JSON.parse(sessionStorage.getItem('codeType')), codeValue: JSON.parse(sessionStorage.getItem('codeValue')), apiKey: JSON.parse(sessionStorage.getItem('apiKey')), email: JSON.parse(sessionStorage.getItem('email')) }
            this.callAPIDirectly(param)
        }
        let theme = process.env.REACT_APP_BASE_URL + 'publicresource/getbycode/css?originUrl=' + getBaseUrl();
        $('<link/>', { rel: 'stylesheet', href: theme }).appendTo('head');
        if (this.props.location.search) {
            let urlparam = getUrlParams(this.props.location.search);
            getColorCOdeService(urlparam.apiKey).then((response) => {
                if (response) {
                    document.documentElement.style.setProperty('--main-color', response.colourCode);
                    this.setState({ logo: response.logo })
                }
            });
            if (urlparam.apiKey && urlparam.codeType && urlparam.codeValue && urlparam.email) {
                if (urlparam.codeType === 'DOT') {
                    this.setState({ scenario: 1, showModal: true, codeType: urlparam.codeType, codeValue: urlparam.codeValue, apiKey: urlparam.apiKey, radio2: false, radio3: true, dotNumber: urlparam.codeValue, email: urlparam.email })
                    this.callAPIDirectly(urlparam)
                }
                else {
                    this.setState({ scenario: 1, showModal: true, codeType: urlparam.codeType, codeValue: urlparam.codeValue, apiKey: urlparam.apiKey, radio2: true, radio3: false, mcNumber: urlparam.codeValue, email: urlparam.email })
                    this.callAPIDirectly(urlparam)
                }
            } else if (urlparam.apiKey && urlparam.codeType && urlparam.codeValue) {
                if (urlparam.codeType === 'DOT') {
                    this.setState({ scenario: 2, showModal: true, codeType: urlparam.codeType, codeValue: urlparam.codeValue, apiKey: urlparam.apiKey, radio2: false, radio3: true, dotNumber: urlparam.codeValue })
                }
                else {
                    this.setState({ scenario: 2, showModal: true, codeType: urlparam.codeType, codeValue: urlparam.codeValue, apiKey: urlparam.apiKey, radio2: true, radio3: false, mcNumber: urlparam.codeValue })
                }
            } else if (urlparam.apiKey && urlparam.email) {
                this.setState({ scenario: 3, showModal: true, apiKey: urlparam.apiKey, email: urlparam.email })
            }
            else if (urlparam.apiKey) {
                this.setState({ scenario: 4, showModal: true, apiKey: urlparam.apiKey })
            }
            console.log('links',urlparam.success , urlparam.failure)
            if (urlparam.success && urlparam.failure) {
                sessionStorage.setItem('successRedirection', urlparam.success);
                sessionStorage.setItem('failureRedirection', urlparam.failure);
            }
            else {
                sessionStorage.removeItem('successRedirection', urlparam.success);
                sessionStorage.removeItem('failureRedirection', urlparam.failure);
            }
        }
        else {
            this.props.history.push('/login');
        }
        if (Object.keys(this.props.carrierInvitationRes).length > 0) {
            this.setState({
                invitationData: this.props.carrierInvitationRes
            });
            this.carrierInviteReponse = this.props.carrierInvitationRes;
        }
    }
    async callTCURL(eldName) {
        let url = `${process.env.REACT_APP_CARRIER_API_URL}carriers/terms-and-conditions/${this.state.invitationToken}/${eldName}`
        let finalLink;
        await getDynamicDocumentURL(url).then((response) => {
            finalLink = response.url;
        });
        return await encodeURI(finalLink)
    }
    componentWillReceiveProps(prevProps, nextProps) {
        if (prevProps !== nextProps) {
            if (prevProps.carrierInvitationRes.hasOwnProperty('message')) {
                this.setState({ error: prevProps.carrierInvitationRes.message })
                return
            }
            this.carrierInviteReponse = prevProps.carrierInvitationRes;
            localFilteredData = prevProps.carrierInvitationRes;
            this.setState({
                invitationData: prevProps.carrierInvitationRes,
                invitationToken: this.carrierInviteReponse.invitationInfo.invitationToken,
                isDataUpdated: true
            });
            this.preChecked = this.carrierInviteReponse.eldInfo.filter(val => val.overallStatus === 'VERIFIED')
            if (sessionStorage.getItem('hasLocalData')) {
                this.checkedArray = JSON.parse(sessionStorage.getItem('localData'))
                this.checkedArray.forEach(element => {
                    this.temp.push(element)
                });
                this.preChecked.forEach(element => {
                    this.temp.push(element)
                });
                this.temp = this.removeDuplicate(this.temp)
                this.setState({ checkedData: this.temp })
            }
            else {
                this.checkedArray = this.carrierInviteReponse.eldInfo.filter(val => val.overallStatus === 'VERIFIED')
            }
            this.setState({
                checkedData: this.checkedArray,
                prevcheckedData: this.checkedArray
            })
            if (this.props?.history?.location?.state?.dummyData?.checkedData.length > 0) {
                this.checkedArray = this.props?.history?.location?.state?.dummyData?.checkedData
                this.setState({
                    checkedData: this.props?.history?.location?.state?.dummyData?.checkedData,
                    prevcheckedData: this.props?.history?.location?.state?.dummyData?.checkedData
                })
            }
            this.setStateForOAuthReturn(this.carrierInviteReponse.eldInfo)
        }
    }
    setStateForOAuthReturn(eldInfo) {
        let urlparam = getUrlParams(this.props.location.search);
        if (urlparam.code) {    // OAuth parameter
            var oauthELD;
            let eldIndex = GetCookie('eldIndex', true)
            if (eldIndex !== null && eldIndex !== undefined && eldIndex >= 0) {
                this.setState({ 
                    returnFromOAuth: true,
                    preselectedModalScreen: 3,
                    oAuthCode: decodeURIComponent(urlparam.code)
                })
                eldInfo.every((eld, index) => {
                    if (eld.oAuth && index === eldIndex) {
                        oauthELD = eld
                        return false
                    }
                    return true
                })
            }
            if (oauthELD) {
                this.redirectVerifyELD(oauthELD, eldIndex)
            }
            else {
                this.setState({ error: 'We couldn\'t find the ELD with OAuth' })
            }
        }
    }
    addDefaultSrc(ev) {
        ev.target.src = noPhoto
    }
    async redirectVerifyELD(state, eldIndex) {
        if (this.state.returnFromOAuth)
            this.setState({ mainModalOpen: true, checkedData: [state] })
        else 
            await this.setState({ mainModalOpen: true, checkedData: [state] })

        for (let index = 0; index < this.state.checkedData.length; index++) {
            const element = this.state.checkedData[index];
            if (element !== undefined) {
                element.tcURL = await this.callTCURL(element.eldVendor)
                for (let index2 = 0; index2 < element.eldMetadataParams.length; index2++) {
                    const element2 = element.eldMetadataParams[index2];
                    element2.isShowValidation = false;
                }
            }
        }
    }
    setELDName(eld_name, eld_id, eldVendor) {
        let eldDetails = { eld_name: eld_name, eld_id: eld_id, eldVendor: eldVendor };
        this.setState({ eldDetails: eldDetails })
    }
    selectedFilterData(data) {
        for (let i = 0; i < this.state.checkedData.length; i++) {
            if (this.state.checkedData[i] && this.state.checkedData[i].eldProviderId === data) {
                return true
            }
        }
    }
    componentDidUpdate() {
        if (!this.state.mainModalOpen && this.state.checkedData.length > 0) {
            this.setState({ checkedData: [] })
            if (this.state.prevcheckedData.length > 4) {
                toastr.info('Info', 'Only 5 ELD can be selected');
            }
        }
    }
    async onInputchange(event) {
        console.log('state.email', this.state.email)
        await this.setState({
            [event.target.name]: event.target.value,
            isDisableOnboard: false
        });
        console.log('after email', this.state.email)
        if (this.state.email === '' || this.state.codeValue === '') {
            await this.setState({
                isDisableOnboard: true
            });
        }
        else {
            this.setState({
                isDisableOnboard: false
            })
        }
    }
    applyChanges() {
        //Validation
        if (this.state.radio2) {
            if (!checkSplChar.mcregex.test(this.state.mcNumber)) {
                this.setState({ mcNumberError: 'MC Number should be numeric' })
                return;
            }
            if (this.state.mcNumber.length < 1) {
                this.setState({ mcNumberError: 'MC Number Should be atleast 5 character' })
                return;
            }
        }
        else if (this.state.radio3) {
            if (!checkSplChar.dotregex.test(this.state.dotNumber)) {
                this.setState({ dotNumberError: 'DOT Number should be numeric' })
                return;
            }
            if (this.state.dotNumber.length < 1) {
                this.setState({ dotNumberError: 'DOT Number Should be atleast 5 character' })
                return;
            }
        }
        if (this.checkEmail(this.state.email) && this.checkCodeType(this.state.codeType) && this.checkCodeValue(this.state.codeValue)) {
            let param = { apiKey: this.state.apiKey, codeType: this.state.codeType, codeValue: this.state.codeValue, email: this.state.email, firstName: this.state.firstName, lastName: this.state.lastName }
            this.setState({ showModal: false })
            this.carrierInvitation(param);
        }
    }
    toLower(str) {
        return str = str.toLowerCase();
    }
    carrierInvitation = (urlparam) => {
        this.props.dispatch(tcWidgetCarrierInvitation(urlparam))
    }
    callAPIDirectly(urlparam) {
        if (this.checkEmail(urlparam.email) && this.checkCodeType(urlparam.codeType) && this.checkCodeValue(urlparam.codeValue)) {
            let param = { apiKey: urlparam.apiKey, codeType: urlparam.codeType, codeValue: urlparam.codeValue, email: urlparam.email }
            this.setState({ showModal: false })
            this.carrierInvitation(param);
        }
    }
    displayCompanies() {
        let eldData = []
        const eldListLength = this.state.invitationData.eldInfo.length;
        // let path = 'https://carrierapi.truckercloud.com/api/v1/elds/logo/'
        let path = process.env.REACT_APP_CARRIER_API_URL + 'elds/logo/'
        for (let index = 0; index < eldListLength; index++) {
            eldData.push({
                id: index,
                website_Name:
                    <span key={index} className='website_name'>
                        {
                            this.state.invitationData.eldInfo[index].eldVendor === 'LbTechnology' ?
                                <a href={'https://lbtechnologyinc.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>lbtechnologyinc.com</a>
                                : this.state.invitationData.eldInfo[index].eldVendor === 'OmnitracsVLV' ?
                                    <a href={'https://www.omnitracs.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>omnitracs.com</a>
                                    : this.state.invitationData.eldInfo[index].eldVendor === 'ThreeMD' ?
                                        <a href={'https://3mdinc.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>3mdinc.com</a>
                                        : this.state.invitationData.eldInfo[index].eldVendor === 'EldMandateHOS' ?
                                            <a href={'https://www.eldmandate.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>eldmandate.com</a>
                                            : this.state.invitationData.eldInfo[index].eldVendor === 'SkyBits' ?
                                                <a href={'https://sky-bits.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>sky-bits.com</a>
                                                : this.state.invitationData.eldInfo[index].eldVendor === 'Geotab-ATT' ?
                                                    <a href={'https://www.geotab.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>geotab.com</a>
                                                    : this.state.invitationData.eldInfo[index].eldVendor === 'RandMcNallyDriverTech' ?
                                                        <a href={'https://www.randmcnally.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>randmcnally.com</a>
                                                        : this.state.invitationData.eldInfo[index].eldVendor === 'Motive' ?
                                                            <a href={'https://gomotive.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>gomotive.com</a>
                                                            : this.state.invitationData.eldInfo[index].eldVendor === 'VerizonConnectReveal' ?
                                                                <a href={'https://www.verizonconnect.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>verizonconnect.com</a>
                                                                : this.state.invitationData.eldInfo[index].eldVendor === 'EldMandatePlus' ?
                                                                    <a href={'https://www.eldmandate.biz/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>eldmandate.biz</a>
                                                                    : this.state.invitationData.eldInfo[index].eldVendor === 'ISAAC' ?
                                                                        <a href={'https://www.isaacinstruments.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>isaacinstruments.com</a>
                                                                        : this.state.invitationData.eldInfo[index].eldVendor === 'Pedigree' ?
                                                                            <a href={'https://pedigreetechnologies.com/'} target='_blank' rel='noopener noreferrer' className='website_anchor'>pedigreetechnologies.com</a>
                                                                            : <a href={`https://www.${this.state.invitationData.eldInfo[index].eldVendor}.com`} target='_blank' rel='noopener noreferrer' className='website_anchor'>{this.toLower(this.state.invitationData.eldInfo[index].eldVendor)}.com</a>
                        }
                    </span>,
                faq_credentials: <div data-toggle='modal' data-target='#faqModal' onClick={() => { this.setELDName(this.state.invitationData.eldInfo[index].eldVendorDisplayName, this.state.invitationData.eldInfo[index].eldVendorId, this.state.invitationData.eldInfo[index].eldVendor) }}><a href="javascript:;">{this.state.invitationData.eldInfo[index]?.eldVendorDisplayName} FAQ <Icon name={'link'} width={20} height={20} /></a></div>,
                eld_name: <div>{this.state.invitationData.eldInfo[index]?.eldVendorDisplayName}</div>,
                eld_brand:
                    <div
                        data-tip data-for='tool-tip-max-eld'
                        style={this.state.invitationData.eldInfo[index]?.overallStatus === 'VERIFIED' || (!this.diableCheck(this.state.invitationData.eldInfo[index]?.eldProviderId) && this.state.checkedData.length > 4) ? { opacity: '.3' } : { opacity: '1', cursor: 'pointer' }}
                        key={this.state.invitationData.eldInfo[index]?.eldProviderId}
                        aria-hidden='true' data-toggle='modal' data-backdrop='dismiss' data-target='#eldVerificationModal'
                        disabled={this.state.invitationData.eldInfo[index].overallStatus === 'VERIFIED' || this.selectedFilterData(this.state.invitationData.eldInfo[index]?.eldProviderId)}
                        onClick={this.state.invitationData.eldInfo[index].overallStatus === 'VERIFIED' || this.selectedFilterData(this.state.invitationData.eldInfo[index]?.eldProviderId) ? (e) => e.preventDefault() : () => { this.redirectVerifyELD(this.state.invitationData.eldInfo[index], index) }}
                    >
                        <img src={path + this.state.invitationData.eldInfo[index]?.eldVendor} className='eldImage' onError={this.addDefaultSrc} alt='Not Found' />
                        {
                            this.state.prevcheckedData.length > 4 ?
                                <ReactTooltip id='tool-tip-max-eld' style={{ zIndex: '1200' }}>
                                    You have already verified 5 ELDs.please click on Done button to complete onboarding.
                                </ReactTooltip> : null
                        }
                    </div>
            })
        }
        return eldData
    }
    carrierCodes() {
        let carrierCode = [];
        carrierCode.push(
            this.state.invitationData.carrierInfo.carrierCodes.map((val, index) => {
                return (
                    <h5 className='list-cont-data' key={index}><span id='iconCOB'>#</span> {val.codeType}-{val.codeValue}</h5>
                )
            })
        )
        return carrierCode
    }
    preventCheck(e, index) {
        if (this.state.invitationData.eldInfo[index]?.overallStatus === 'VERIFIED') {
            e.target.checked = true
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            return false;
        }
        e.target.checked = false
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        return false;
    }
    previousCheck(data) {
        for (let i = 0; i < this.props?.history?.location?.state?.dummyData?.checkedData.length; i++) {
            if (this.props?.history?.location?.state?.dummyData?.checkedData[i].eldProviderId === data) {
                return true
            }
        }
    }
    diableCheck(data) {
        for (let i = 0; i < this.state.checkedData.length; i++) {
            if (this.state.checkedData[i].eldProviderId === data) {
                return true
            }
        }
    }
    searchELDs(e) {
        let code = (e.keyCode ? e.keyCode : e.which);
        const input = e.target.value.toLowerCase();
        if (input.length === 0 || code === 27) {
            this.setState(prevState => ({
                invitationData: {
                    ...prevState.invitationData,
                    eldInfo: localFilteredData.eldInfo
                }
            }))
        }
        else {
            const filteredArray = localFilteredData.eldInfo.filter(obj => {
                return obj.eldVendor.toLowerCase().includes(input);
            });
            this.setState(prevState => ({
                invitationData: {
                    ...prevState.invitationData,
                    eldInfo: filteredArray
                }
            }))
        }
    }
    async onChangeValue(event) {
        if (event.target.value) {
            this.setState({
                searchValue: false,
                mcNumberError: '',
                dotNumberError: '',
            })
        } else {
            this.setState({
                searchValue: true,
            })
        }
        //enabling or disabling fields based on radio selected along with emptying the old values 
        if (event.target.id === 'mcnumberid') {
            await this.setState({
                radio2: true,
                radio3: false,
                mcNumber: event.target.value,
                dotNumber: '',
                codeType: 'MC',
                codeValue: event.target.value,
                dotNumberError: '',
                searchText: this.state.codeType + '# ' + event.target.value
            });
        }
        else if (event.target.id === 'dotnumberid') {
            await this.setState({
                radio2: false,
                radio3: true,
                mcNumber: '',
                dotNumber: event.target.value,
                codeType: 'DOT',
                mcNumberError: '',
                codeValue: event.target.value,
                searchText: this.state.codeType + '# ' + event.target.value.trim()
            });
        }
        console.log(this.state.email, this.state.codeValue)
        if (this.state.codeValue === '' || this.state.email === '') {
            console.log('empty')
            this.setState({
                isDisableOnboard: true
            })
        }
        else {
            this.setState({
                isDisableOnboard: false
            })
        }
    }
    checkEmail(input) {
        const re = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(input.trim())) {
            this.setState({ emailError: '' })
            return true;

        } else {
            this.setState({ emailError: 'Please provide a valid  carrier email.' })
            return false;
        }
    }
    checkCodeType(input) {
        if (input !== '') {
            return true;
        } else {
            this.setState({ codeTypeError: 'Please provide a valid code type.' })
            return false;
        }
    }
    checkCodeValue(input) {
        if (input.trim() !== '') {
            return true;
        } else {
            this.setState({ codeValueError: 'Please provide a valid code value' })
            return false;
        }
    }
    removeDuplicate(data) {
        const unique = [];
        data.map(x => unique.filter(a => a.eldProviderId === x.eldProviderId).length > 0 ? null : unique.push(x));
        return unique;
    }
    handleChange = (e, data, index) => {
        if (this.state.invitationData.eldInfo[index]?.overallStatus === 'VERIFIED' || (!this.diableCheck(this.state.invitationData.eldInfo[index]?.eldProviderId) && this.state.checkedData.length > 4)) {
            this.preventCheck(e, index);
            return
        }
        if (e.target.checked) {
            this.checkedArray.push(data)
        }
        if (e.target.checked === false) {
            let index = this.checkedArray.map(function (x) { return x.eldProviderId; }).indexOf(data.eldProviderId)
            this.checkedArray.splice(index, 1);
        }
        this.checkedArray = this.removeDuplicate(this.checkedArray)
        this.setState({
            checkedData: this.checkedArray
        })
    }
    async authCarrier() {
        for (let index = 0; index < this.state.checkedData.length; index++) {
            const element = this.state.checkedData[index];
            element.tcURL = await this.callTCURL(element.eldVendor)
            for (let index = 0; index < element.eldMetadataParams.length; index++) {
                const element2 = element.eldMetadataParams[index];
                element2.isShowValidation = false;
            }
        }
        sessionStorage.setItem('hasLocalData', JSON.stringify(true))
        sessionStorage.setItem('localData', JSON.stringify(this.state.checkedData))
        sessionStorage.setItem('codeType', JSON.stringify(this.state.codeType))
        sessionStorage.setItem('codeValue', JSON.stringify(this.state.codeValue))
        sessionStorage.setItem('email', JSON.stringify(this.state.email))
        sessionStorage.setItem('scenario', JSON.stringify(this.state.scenario))
        sessionStorage.setItem('apiKey', JSON.stringify(this.state.apiKey))
        // history.push('/tcWidgetAuthCarrier', this.state);
        history.push('/terms-and-conditons-widget', this.state);
    }

    handleMainModalOpen = async () => {
        await this.setState({ ...this.state, mainModalOpen: true })
    }
    handleMainModalClose = async () => {
        await this.setState({ ...this.state, mainModalOpen: false })
    }
    handleELDModalOpen = async () => {
        await this.setState({ ...this.state, newEldOpen: true })
    }
    handleELDModalClose = async () => {
        await this.setState({ ...this.state, newEldOpen: false })
    }
    toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({ ...this.state, drawerOpen: open });
    };

    render() {
        if (this.state.error && this.state.error.length > 0) {
            return (
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <Box
                        component="main"
                        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
                    >
                        <Container>
                            <Container className='headerContainer'>
                                <Container>
                                </Container>
                            </Container>
                            <Container>
                                <TypoGraph variant="h3" mb={0} sx={{ fontSize: '20px', fontWeight: '400', textAlign:'center' }}>
                                    We're sorry, there is an error with your invitation: {this.state.error}
                                </TypoGraph>
                                <TypoGraph variant="h3" mb={0} sx={{ fontSize: '16px', fontWeight: '400', textAlign:'center', marginTop: '1rem' }}>
                                    Please contact our support team at support@truckercloud.com
                                </TypoGraph>
                            </Container>
                        </Container>
                    </Box>
                </Box>
            )
        }
        else {
            var Modal = ReactBootstrap.Modal;

            if (Object.keys(this.state.invitationData).length > 0) {
                return (
                    <Box sx={{ display: 'flex' }}>
                        <CssBaseline />
                        <MiniDrawerELD 
                            passingData={this.state.logo} 
                            state={this.state} 
                            open={this.state.drawerOpen} 
                            toggleDrawer={this.toggleDrawer}
                        />
                        <Box 
                            component="main" 
                            sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
                        >
                            <Container>
                                <Container className='headerContainer'>
                                    <Container>
                                        <Button 
                                            className="btn btn-secondary" 
                                            aria-label="drawer" 
                                            sx={{ 
                                                display: { xs: 'block', md: 'none' }, 
                                                marginRight:'1rem', 
                                                border: '1px solid darkgrey',
                                                width: '1rem'
                                            }} 
                                            onClick={this.toggleDrawer(!this.state.drawerOpen)}
                                        >
                                            <MenuIcon />
                                        </Button>
                                    </Container>
                                    <Container className='headerAction'>
                                        <div className='input-container1'>
                                            <button type="button" className="btn btn-primary btn-sm newELDBTN" onClick={this.handleELDModalOpen}>Request New ELD</button>
                                            {
                                                this.state.prevcheckedData.length > 4 ?
                                                <a href='/#' className='btn btn-primary1 btn-sm authbtn2' data-toggle='modal'>Done</a> : null
                                            }
                                        </div>
                                    </Container>
                                </Container>
                                <Container>
                                    <TypoGraph variant="h1" mb={0} sx={{ fontSize: '24px', fontWeight: '700' }}>
                                        Select your ELD to Onboard
                                    </TypoGraph>
                                </Container>
                                <Box sx={{ width: '100%', margin: '1rem 0rem' }}>
                                    <EldCardList
                                        companies={this.state.invitationData.eldInfo}
                                        path={process.env.REACT_APP_CARRIER_API_URL + 'elds/logo/'}
                                        checkedData={this.state.checkedData}
                                        filterData={this.selectedFilterData}
                                        redirectVerifyELD={this.redirectVerifyELD}
                                        setELDName={this.setELDName}
                                        diableCheck={this.diableCheck}
                                        onboardedELDs={this.state.checkedData.length}
                                        previouslyOnboardedELDs={this.state.prevcheckedData.length}
                                    />
                                    
                                    <FAQModal state={this.state.eldDetails} />
                                    <NewEld state={this.state} close={this.handleELDModalClose} />
                                    {
                                        this.state.checkedData.length > 0 && this.state.prevcheckedData.length < 5 ?
                                        <ELDVerificationModal 
                                            state={this.state} 
                                            handleClose={this.handleMainModalClose} 
                                            preselectedScreen={this.state.preselectedModalScreen}
                                            dynamicImage={this.state.returnFromOAuth ? "verifying" : null}
                                            returnFromOAuth={this.state.returnFromOAuth}
                                            origin="widget"
                                        /> 
                                        : null
                                    }
                                </Box>
                            </Container>
                        </Box>
                    </Box>
                );
            }
            else if (!this.state.carrierDoesnotExist && this.state.error.length > 1) {
                return (
                    <Suspense fallback={<div>Loading...</div>}>
                        <article className='table-data truck-data shadow bg-white rounded'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='text-center'>
                                        <h4>Invitation Token expired. Please contact admin@truckercloud.com for further assistance</h4>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </Suspense>
                )
            }
            // Scenario 1: Email prefilled
            else if (this.state.scenario === 1) {
                return (
                    <Suspense fallback={<div>Loading...</div>}>
                        <Modal show={this.state.showModal} >
                            <Modal.Body className='modal-body-css'>
                                <div className='img text-center'>
                                    <img src={this.state.logo} className='widgetLogoCss' alt='Not Found' />
                                    <div className='tc-link-text'>Onboard in less than <span className='tc-link'>60 seconds!</span>
                                    </div>
                                    <div>True logistic visibility</div><br />
                                </div>
                                <div className='modal-body table-data'>
                                    <div className='tab-content' id='myTabContent'>
                                        <div className='tab-pane fade show active' id='carrierinfo' role='tabpanel' aria-labelledby='carrierinfo-tab'>
                                            <form id='new_carrier' >
                                                <div className='row responsive-row'>
                                                    <div className='col form-group'>
                                                        <input type='radio' id='mcnumberid' value={this.state.mcNumber || ''} name='searchGroup' checked={this.state.radio2} />
                                                        <input id='mcnumberid' className={!this.state.radio2 ? 'labelCssForSearchWidget form-control' : 'labelCssForSearchWidget form-control requiredfield'} autoComplete='off' type='text' placeholder='MC # ' disabled={this.state.radio2} value={this.state.mcNumber || ''} />
                                                        <span className='shadow-input'>{this.state.mcNumberError}</span>
                                                    </div>
                                                    <div className='col form-group'>
                                                        <input type='radio' value={this.state.dotNumber || ''} id='dotnumberid' name='searchGroup' checked={this.state.radio3} />
                                                        <input id='dotnumberid' type='text' placeholder='DOT #' autoComplete='off' className={!this.state.radio3 ? 'labelCssForSearchWidget form-control' : 'labelCssForSearchWidget form-control requiredfield'} disabled={this.state.radio3} value={this.state.dotNumber || ''} />
                                                        <span className='shadow-input'>{this.state.dotNumberError}</span>
                                                    </div>
                                                </div>
                                                <div className='row responsive-row'>
                                                    <div className='col form-group'>
                                                        <label>Carrier Email</label>
                                                        <input type='email' className='form-control requiredfield json-col' name='email' value={this.state.email} disabled />
                                                        <span className='shadow-input'>{this.state.emailError}</span>
                                                    </div>
                                                    <div className='col form-group' >
                                                        <label>First Name</label>
                                                        <input type='text' className='form-control  json-col' name='firstName' value={this.state.firstName} onChange={this.onInputchange.bind(this)} />
                                                    </div>
                                                    <div className='col form-group'>
                                                        <label>Last Name</label>
                                                        <input type='text' className='form-control  json-col' name='lastName' value={this.state.lastName} onChange={this.onInputchange.bind(this)} />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col form-group'>
                                                        <div className='shadow-input'>{this.state.carrierDoesnotExist}</div>
                                                    </div>
                                                </div>
                                                <br />
                                            </form>
                                            <div className='row'>
                                                <div className='col col-md-12'>
                                                    <div className='form-group-btns'>
                                                        <button type='button' onClick={this.applyChanges.bind(this)} className='btn btn-primary btn-sm onboard-btn' disabled={this.state.isDisableOnboard}>Onboard</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </Suspense>
                )
            }
            else if (this.state.scenario === 2) {
                return (
                    <Suspense fallback={<div>Loading...</div>}>
                        <Modal show={this.state.showModal} >
                            <Modal.Body className='modal-body-css'>
                                <div className='img text-center'>
                                    <img src={this.state.logo} className='widgetLogoCss' alt='Not Found' />
                                    <div className='tc-link-text'>Onboard in less than <span className='tc-link'>60 seconds!</span>
                                    </div>
                                    <div>True logistic visibility</div><br />
                                </div>
                                <div className='modal-body table-data'>
                                    <div className='tab-content' id='myTabContent'>
                                        <div className='tab-pane fade show active' id='carrierinfo' role='tabpanel' aria-labelledby='carrierinfo-tab'>
                                            <form id='new_carrier' >
                                                <div className='row responsive-row'>
                                                    <div className='col form-group'>
                                                        <input type='radio' id='mcnumberid' value={this.state.mcNumber || ''} name='searchGroup' checked={this.state.radio2} />
                                                        <input id='mcnumberid' className={!this.state.radio2 ? 'labelCssForSearchWidget form-control' : 'labelCssForSearchWidget form-control requiredfield'} autoComplete='off' type='text' placeholder='MC # ' disabled={this.state.radio2} value={this.state.mcNumber || ''} />
                                                        <span className='shadow-input'>{this.state.mcNumberError}</span>
                                                    </div>
                                                    <div className='col form-group'>
                                                        <input type='radio' value={this.state.dotNumber || ''} id='dotnumberid' name='searchGroup' checked={this.state.radio3} />
                                                        <input id='dotnumberid' type='text' placeholder='DOT #' autoComplete='off' className={!this.state.radio3 ? 'labelCssForSearchWidget form-control' : 'labelCssForSearchWidget form-control requiredfield'} disabled={this.state.radio3} value={this.state.dotNumber || ''} />
                                                        <span className='shadow-input'>{this.state.dotNumberError}</span>
                                                    </div>
                                                </div>
                                                <div className='row responsive-row'>
                                                    <div className='col form-group'>
                                                        <label>Carrier Email</label>
                                                        <input type='email' className='form-control requiredfield json-col' name='email' value={this.state.email} onChange={this.onInputchange.bind(this)} />
                                                        <span className='shadow-input'>{this.state.emailError}</span>
                                                    </div>
                                                    <div className='col form-group' >
                                                        <label>First Name</label>
                                                        <input type='text' className='form-control  json-col' name='firstName' value={this.state.firstName} onChange={this.onInputchange.bind(this)} />
                                                    </div>
                                                    <div className='col form-group'>
                                                        <label>Last Name</label>
                                                        <input type='text' className='form-control  json-col' name='lastName' value={this.state.lastName} onChange={this.onInputchange.bind(this)} />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col form-group'>
                                                        <div className='shadow-input'>{this.state.carrierDoesnotExist}</div>
                                                    </div>
                                                </div>
                                                <br />
                                            </form>
                                            <div className='row'>
                                                <div className='col col-md-12'>
                                                    <div className='form-group-btns'>
                                                        <button type='button' onClick={this.applyChanges.bind(this)} className='btn btn-primary btn-sm onboard-btn' disabled={this.state.isDisableOnboard}>Onboard</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </Suspense>
                )
            }
            else if (this.state.scenario === 3) {
                return (
                    <Suspense fallback={<div>Loading...</div>}>
                        <Modal show={this.state.showModal} >
                            <Modal.Body className='modal-body-css'>
                                <div className='img text-center'>
                                    <img src={this.state.logo} className='widgetLogoCss' alt='Not Found' />
                                    <div className='tc-link-text'>Onboard in less than <span className='tc-link'>60 seconds!</span>
                                    </div>
                                    <div>True logistic visibility</div><br />
                                </div>
                                <div className='modal-body table-data'>
                                    <div className='tab-content' id='myTabContent'>
                                        <div className='tab-pane fade show active' id='carrierinfo' role='tabpanel' aria-labelledby='carrierinfo-tab'>
                                            <form id='new_carrier' >
                                                <div className='row responsive-row' onChange={(e) => { this.onChangeValue(e) }}>
                                                    <div className='col form-group'>
                                                        <input type='radio' id='mcnumberid' value={this.state.mcNumber || ''} name='searchGroup' checked={this.state.radio2} />
                                                        <input id='mcnumberid' className={!this.state.radio2 ? 'labelCssForSearchWidget form-control' : 'labelCssForSearchWidget form-control requiredfield'} autoComplete='off' type='text' placeholder='MC # ' disabled={!this.state.radio2} value={this.state.mcNumber || ''} />
                                                        <span className='shadow-input'>{this.state.mcNumberError}</span>
                                                    </div>
                                                    <div className='col form-group'>
                                                        <input type='radio' value={this.state.dotNumber || ''} id='dotnumberid' name='searchGroup' checked={this.state.radio3} />
                                                        <input id='dotnumberid' type='text' placeholder='DOT #' autoComplete='off' className={!this.state.radio3 ? 'labelCssForSearchWidget form-control' : 'labelCssForSearchWidget form-control requiredfield'} disabled={!this.state.radio3} value={this.state.dotNumber || ''} />
                                                        <span className='shadow-input'>{this.state.dotNumberError}</span>
                                                    </div>
                                                </div>
                                                <div className='row responsive-row'>
                                                    <div className='col form-group'>
                                                        <label>Carrier Email</label>
                                                        <input type='email' className='form-control requiredfield json-col' name='email' value={this.state.email} disabled />
                                                        <span className='shadow-input'>{this.state.emailError}</span>
                                                    </div>
                                                    <div className='col form-group' >
                                                        <label>First Name</label>
                                                        <input type='text' className='form-control  json-col' name='firstName' value={this.state.firstName} onChange={this.onInputchange.bind(this)} />
                                                    </div>
                                                    <div className='col form-group'>
                                                        <label>Last Name</label>
                                                        <input type='text' className='form-control  json-col' name='lastName' value={this.state.lastName} onChange={this.onInputchange.bind(this)} />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col form-group'>
                                                        <div className='shadow-input'>{this.state.carrierDoesnotExist}</div>
                                                    </div>
                                                </div>
                                                <br />
                                            </form>
                                            <div className='row'>
                                                <div className='col col-md-12'>
                                                    <div className='form-group-btns'>
                                                        <button type='button' onClick={this.applyChanges.bind(this)} className='btn btn-primary btn-sm onboard-btn' disabled={this.state.isDisableOnboard}>Onboard</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </Suspense>
                )
            }
            else if (this.state.scenario === 4) {
                return (
                    <Suspense fallback={<div>Loading...</div>}>
                    <Modal show={this.state.showModal} >
                        <Modal.Body className='modal-body-css'>
                            <div className='img text-center'>
                                <img src={this.state.logo} className='widgetLogoCss' alt='Not Found' />
                                <div className='tc-link-text'>Onboard in less than <span className='tc-link'>60 seconds!</span>
                                </div>
                                <div>True logistic visibility</div><br />
                            </div>
                            <div className='modal-body table-data'>
                                <div className='tab-content' id='myTabContent'>
                                    <div className='tab-pane fade show active' id='carrierinfo' role='tabpanel' aria-labelledby='carrierinfo-tab'>
                                        <form id='new_carrier' >
                                            <div className='row responsive-row' onChange={(e) => { this.onChangeValue(e) }}>
                                                <div className='col form-group'>
                                                    <input type='radio' id='mcnumberid' value={this.state.mcNumber || ''} name='searchGroup' checked={this.state.radio2} />
                                                    <input id='mcnumberid' className={!this.state.radio2 ? 'labelCssForSearchWidget form-control' : 'labelCssForSearchWidget form-control requiredfield'} autoComplete='off' type='text' placeholder='MC # ' disabled={!this.state.radio2} value={this.state.mcNumber || ''} />
                                                    <span className='shadow-input'>{this.state.mcNumberError}</span>
                                                </div>
                                                <div className='col form-group'>
                                                    <input type='radio' value={this.state.dotNumber || ''} id='dotnumberid' name='searchGroup' checked={this.state.radio3} />
                                                    <input id='dotnumberid' type='text' placeholder='DOT #' autoComplete='off' className={!this.state.radio3 ? 'labelCssForSearchWidget form-control' : 'labelCssForSearchWidget form-control requiredfield'} disabled={!this.state.radio3} value={this.state.dotNumber || ''} />
                                                    <span className='shadow-input'>{this.state.dotNumberError}</span>
                                                </div>
                                            </div>
                                            <div className='modal-row2'>
                                                <div className='col form-group'>
                                                    <label>Carrier Email</label>
                                                    <input type='email' className='form-control requiredfield json-col' name='email' value={this.state.email} onChange={this.onInputchange.bind(this)} />
                                                    <span className='shadow-input'>{this.state.emailError}</span>
                                                </div>
                                                <div className='col form-group'>
                                                    <label>First Name</label>
                                                    <input type='text' className='form-control  json-col' name='firstName' value={this.state.firstName} onChange={this.onInputchange.bind(this)} />
                                                </div>
                                                <div className='col form-group'>
                                                    <label>Last Name</label>
                                                    <input type='text' className='form-control  json-col' name='lastName' value={this.state.lastName} onChange={this.onInputchange.bind(this)} />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col form-group'>
                                                    <div className='shadow-input'>{this.state.carrierDoesnotExist}</div>
                                                </div>
                                            </div>
                                            <br />
                                        </form>
                                        <div className='row'>
                                            <div className='col col-md-12'>
                                                <div className='form-group-btns'>
                                                    <button type='button' onClick={this.applyChanges.bind(this)} className='btn btn-primary btn-sm onboard-btn' disabled={this.state.isDisableOnboard}>Onboard</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    </Suspense>
                )
            }
            else {
                return (
                    <div className='text-center'>
                        <div className='spinner-border' role='status'>
                            <span className='sr-only'>Loading...</span>
                        </div>
                    </div>
                );
            }
        }
    }
}

const mapStateToProps = (state) => {
    let carrierInvitationList = {};
    if (state.companyReducer.carrierInvitation) {
        carrierInvitationList = state.companyReducer.carrierInvitation;
    }
    return {
        carrierInvitationRes: carrierInvitationList
    };
}

export default connect(mapStateToProps)(withRouter(TCWidgetComponent))
