import React, { Component, Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import { toastr } from 'react-redux-toastr';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Loader from './loader';
import NewUser from './NewUser';
import { appBaseUrl } from '../../helpers/appUtil';
import { TableDataComponent } from 'UI-Component';
import { userlistservice, getUserRoles, updateUserStatus } from '../../services/userService';
require('dotenv').config();
class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageReady: false,
            newUserPopup: false,
            userList: [],
            userData: null,
            title: '',
            userRoles: [],
            userPopupMode: 'add'
        }
    }
    componentDidMount = () => {
        document.title = appBaseUrl() + 'Users';
        this.getUserList();
        let param = {
            payload: {
                companyId: localStorage.getItem('companyId')
            }
        }
        getUserRoles(param).then((response) => {
            let roles = [];
            if (response.roleList) {
                roles = response.roleList;
            }
            this.setState({
                ...this.state,
                userRoles: roles
            });
        });
    }
    getUserList = () => {
        let param = {
            payload: {
                usertype: localStorage.getItem('userType')
            }
        }
        userlistservice(param).then((response) => {
            let users = [];
            if (response && response.Users) {
                users = response.Users.map((data, index) => {
                    return {
                        firstName: data.firstName,
                        lastName: data.lastName,
                        emailId: data.emailId,
                        Zip: data.Zip,
                        phoneNumber: data.phoneNumber,
                        State: data.State,
                        City: data.City,
                        currentAddress1: data.currentAddress1,
                        userTypeId: data.userTypeId,
                        companyId: data.companyId,
                        currentCountryId: data.currentCountryId,
                        roleId: data.roleId,
                        userId: data.userId,
                        isDeleted: data.isDeleted,
                        templateTypeId: data.templateTypeId
                    }
                });
            }
            this.setState({
                ...this.state,
                userList: users,
                pageReady: true,
                newUserPopup: false,
                userData: null,
                title: ''
            });
        });
    }
    getUserColumns = () => {
        return (
            [
                {
                    text: 'First Name',
                    dataField: 'firstName',
                    sort: true
                },
                {
                    text: 'Last Name',
                    dataField: 'lastName',
                    sort: true
                },
                {
                    text: 'Email',
                    dataField: 'XemailId',
                    sort: true
                },
                {
                    text: 'Mobile Number',
                    dataField: 'XphoneNumber',
                    sort: true
                },
                {
                    text: '',
                    dataField: 'actions',
                    sort: true
                }
            ]
        );
    }
    editUser = (e, data) => {
        this.setState(
            {
                ...this.state,
                userData: data,
                newUserPopup: true,
                title: 'Update User',
                userPopupMode: 'edit'
            });
    }
    changeUserStatus = (e, index) => {
        let param = {
            payload: {
                userId: this.state.userList[index].userId
            }
        }
        updateUserStatus(param).then((response) => {
            if (response.status && response.status === 'OK') {
                toastr.success('Success', 'User status changed successfully');
                let userInfo = this.state.userList[index];
                let userList = this.state.userList;
                userInfo.isDeleted = userInfo.isDeleted ? 0 : 1;
                userList[index] = userInfo;
                this.setState({
                    ...this.state,
                    userList: userList
                });
            } else {
                toastr.error('Oops!', 'Unable to change user status');
            }
        });
    }
    getAction = (data, index) => {
        if (localStorage.getItem('UserId') === data.userId) {
            return (
                <button className='btn icofont-pencil icofonts' onClick={(e) => { this.editUser(e, data) }}></button>
            );
        } else {
            return (
                <Fragment>
                    <button className='btn icofont-pencil icofonts' onClick={(e) => { this.editUser(e, data) }}></button>
                    <button className={`btn ${data.isDeleted ? 'icofont-close-circled' : 'icofont-check-circled'} icofonts`} onClick={(e) => { this.changeUserStatus(e, index) }} title={data.isDeleted ? 'Inactive' : 'Active'}></button>
                </Fragment>
            );
        }
    }
    getUserData = () => {
        let userData = [];
        userData = this.state.userList.map((data, index) => {
            return {
                firstName: data.firstName,
                lastName: data.lastName,
                emailId: data.emailId,
                Zip: data.Zip,
                phoneNumber: data.phoneNumber,
                State: data.State,
                City: data.City,
                currentAddress1: data.currentAddress1,
                userTypeId: data.userTypeId,
                companyId: data.companyId,
                currentCountryId: data.currentCountryId,
                roleId: data.roleId,
                userId: data.userId,
                isDeleted: data.isDeleted,
                templateTypeId: data.templateTypeId,
                XemailId: <span>
                    <i data-tip data-for={'user_email' + index} className='icofont-email icofonts'></i>
                    <ReactTooltip id={'user_email' + index} >
                        <p>Email : {data.emailId}</p>
                    </ReactTooltip>
                </span>,
                XphoneNumber: <span>
                    <i data-tip data-for={'user_phone' + index} className='icofont-phone-circle icofonts'></i>
                    <ReactTooltip id={'user_phone' + index} >
                        <p>Phone : {data.phoneNumber}</p>
                    </ReactTooltip>
                </span>,
                actions: <Fragment>
                    {this.getAction(data, index)}
                </Fragment>
            }
        });
        return userData;
    }
    loadPage = () => {
        if (this.state.pageReady) {
            return (
                <div>
                    <div className='btn-export float-right d-inline-block pr-2'><button className='btn btn-primary' onClick={(e) => { this.openNewUser() }} >New User</button></div>
                    <TableDataComponent tableData={this.getUserData()} columnData={this.getUserColumns()} noDataIndication={'No data found'} />
                </div>
            );
        } else {
            return (<Loader />);
        }
    }
    closeNewUser = (flag) => {
        if (flag) {
            this.getUserList();
        } else {
            this.setState(
                {
                    ...this.state,
                    newUserPopup: false,
                    userData: null,
                    title: '',
                    userPopupMode: 'add'
                }
            );
        }
    }
    openNewUser = () => {
        this.setState(
            {
                ...this.state,
                newUserPopup: true,
                userData: null,
                title: 'Add New User',
                userPopupMode: 'add'
            }
        );
    }
    render = () => {
        return (
            <article className='table-data truck-data shadow bg-white rounded'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h2>Users</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        {this.loadPage()}
                    </div>
                </div>
                <NewUser userPopupMode={this.state.userPopupMode} newUserPopup={this.state.newUserPopup} closeNewUser={(flag) => { this.closeNewUser(flag) }} statelist={this.props.statelist} userData={this.state.userData} userRoles={this.state.userRoles} title={this.state.title} />
            </article>
        );
    }
}
export default Users