export function authHeader() {

    // return authorization header with token
    let authToken = localStorage.getItem('authToken');

    if (authToken) {
        return { 
            'Content-Type': 'application/json',
            'Authorization': authToken 
        };
    } 
    else {
        return {
            'Content-Type': 'application/json'
        };
    }

    // if (user && user.token) {
    //     return { 'Authorization': 'Bearer ' + user.token };
    // } 
    // else {
    //     return {};
    // }
}