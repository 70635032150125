export const SetCookie = (cname, cvalue, exhours, useLocalStorage) => {
    if (useLocalStorage === true) {
        localStorage.setItem(cname, JSON.stringify(cvalue));
    }
    else {
        const d = new Date();
        d.setTime(d.getTime() + (exhours * 60 * 60 * 1000));
        let expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
};

export const GetCookie = (cname, useLocalStorage) => {
    if (useLocalStorage === true) {
        let value = localStorage.getItem(cname);
        if (value === undefined || value === null || value === "") {
            return null;
        }
        value = JSON.parse(value);
        return value;
    }
    else {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
};

export const DeleteCookie = (name, useLocalStorage) => {
    if (useLocalStorage === true) {
        if (localStorage.getItem(name) !== null) {
            localStorage.removeItem(name);
            return true;
        }
        else {
            return false;
        }
    }
    else {
        if (GetCookie(name)) {
            document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            return true;
        }
        return false;
    }
};