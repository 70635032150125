import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Loader from './loader';
import { emailtemplatelistservice, emailtemplatetypelist, verifiedemailaccountlist } from '../../services/emailTemplateService';
import EditMailTemplate from '../widgets/EditMailTemplate';
import { appBaseUrl } from '../../helpers/appUtil';
import { TableDataComponent } from 'UI-Component';
import moment from 'moment';
require('dotenv').config();

class EmailTemplateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageReady: false,
            popup: false,
            templateList: [],
            templateTypeList: [],
            emailAccountList: [],
            title: '',
            templateData: null,
            hasEmailAccounts: true
        }
    }
    componentDidMount = () => {
        document.title = appBaseUrl() + 'Email Templates';
        this.getTemplateList();
        emailtemplatetypelist().then((response) => {
            let templatesTypes = [];
            if (response && response.data) {
                templatesTypes = response.data.map((data, index) => {
                    return {
                        templateTypeId: data.carrierEmailTemplateTypeId,
                        templateName: data.carrierEmailTemplateTypeName,
                        defaultContent: data.defaultContent
                    }
                });
            }
            this.setState({
                ...this.state,
                templateTypeList: templatesTypes,
                pageReady: true
            });
        });
        verifiedemailaccountlist().then((response) => {
            let accountList = [];
            if (response) {
                accountList = response.map((data, index) => {
                    return {
                        accountID: data.carrierEmailAccountSettingId,
                        senderEmail: data.senderEmail
                    }
                });
            }
            let hasEmailAccounts = accountList.length > 0;
            this.setState({
                ...this.state,
                emailAccountList: accountList,
                pageReady: true,
                hasEmailAccounts: hasEmailAccounts
            });
        });
    }
    getTemplateList = () => {
        let param = {
            payload: {
                id: '',
                usertype: localStorage.getItem('userType')
            }
        }
        emailtemplatelistservice(param).then((response) => {
            let templates = [];
            let carrierEmailTemplateTypeId = null;
            let carrierEmailTemplateTypeName = null;
            let carrierEmailAccountSettingId = null;
            let accountName = null;
            let createdTime = null;
            if (response && response.data) {
                templates = response.data.map((data, index) => {
                    carrierEmailTemplateTypeId = null;
                    carrierEmailTemplateTypeName = null;
                    carrierEmailAccountSettingId = null;
                    accountName = null;
                    createdTime = null;
                    if (data.carrierEmailTemplateType) {
                        carrierEmailTemplateTypeId = data.carrierEmailTemplateType.carrierEmailTemplateTypeId;
                        carrierEmailTemplateTypeName = data.carrierEmailTemplateType.carrierEmailTemplateTypeName;
                    }
                    if (data.carrierEmailAccountSetting) {
                        carrierEmailAccountSettingId = data.carrierEmailAccountSetting.carrierEmailAccountSettingId;
                        accountName = data.carrierEmailAccountSetting.accountName;
                    }
                    if (data.createdTime !== '' && data.createdTime != null) {
                        createdTime = moment(data.createdTime).format('MM-DD-YYYY hh:mm a');
                    }
                    return {
                        emailTemplateName: data.emailTemplateName,
                        emailTemplateContent: data.emailTemplateContent,
                        carrierEmailTemplateTypeId: carrierEmailTemplateTypeId,
                        carrierEmailTemplateTypeName: carrierEmailTemplateTypeName,
                        carrierEmailTemplateId: data.carrierEmailTemplateId,
                        carrierEmailAccountSettingId: carrierEmailAccountSettingId,
                        accountName: accountName,
                        createdTime: createdTime
                    }
                });
            }
            this.setState({
                ...this.state,
                templateList: templates,
                pageReady: true,
                popup: false,
                templateData: null,
                title: ''
            });
        });
    }
    getEmailTemplateColumns = () => {
        return (
            [
                {
                    text: 'Template Name',
                    dataField: 'emailTemplateName',
                    sort: true
                },
                {
                    text: 'Template Type Name',
                    dataField: 'carrierEmailTemplateTypeName',
                    sort: true
                },
                {
                    text: 'Email Account',
                    dataField: 'accountName',
                    sort: true
                },
                {
                    text: 'Actions',
                    dataField: 'actions',
                    sort: true
                }
            ]
        );
    }
    editTemplate = (e, data) => {
        this.setState(
            {
                ...this.state,
                templateData: data,
                popup: true,
                title: 'Update Template'
            });
    }
    getEmailTemplateData = () => {
        let templateData = [];
        templateData = this.state.templateList.map((data, index) => {
            return {
                emailTemplateName: data.emailTemplateName,
                emailTemplateContent: data.emailTemplateContent,
                carrierEmailTemplateTypeId: data.carrierEmailTemplateTypeId,
                carrierEmailTemplateTypeName: data.carrierEmailTemplateTypeName,
                carrierEmailTemplateId: data.carrierEmailTemplateId,
                carrierEmailAccountSettingId: data.carrierEmailAccountSettingId,
                accountName: data.accountName,
                createdTime: data.createdTime,
                actions: <Fragment>
                    <button className='btn icofont-pencil icofonts' onClick={(e) => { this.editTemplate(e, data) }}></button>
                </Fragment>
            }
        });
        return templateData;
    }
    loadPage = () => {
        if (this.state.pageReady) {
            return (
                <div>
                    {(!this.state.hasEmailAccounts) &&
                        <p className='page-error-text'>Please <NavLink exact={true} to={'/pages/email_accounts'}>add email accounts</NavLink> to add email templates</p>
                    }
                    <div className='btn-export float-right d-inline-block pr-2'><button className='btn btn-primary' onClick={(e) => { this.openPopup() }} >New Template</button></div>
                    <TableDataComponent tableData={this.getEmailTemplateData()} columnData={this.getEmailTemplateColumns()} noDataIndication={'No data found'} />
                </div>
            );
        } else {
            return (<Loader />);
        }
    }
    closePopup = (flag) => {
        if (flag) {
            this.getTemplateList()
        } else {
            this.setState(
                {
                    ...this.state,
                    popup: false,
                    templateData: null,
                    title: ''
                }
            );
        }
    }
    openPopup = () => {
        this.setState(
            {
                ...this.state,
                popup: true,
                templateData: null,
                title: 'Add new template'
            }
        );
    }
    render = () => {
        return (
            <article className='table-data truck-data shadow bg-white rounded'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h2>Email Templates</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        {this.loadPage()}
                    </div>
                </div>
                <EditMailTemplate popup={this.state.popup} closePopup={(flag) => { this.closePopup(flag) }} templateTypes={this.state.templateTypeList} accountList={this.state.emailAccountList} templateData={this.state.templateData} />
                <div className='d-none'>
                    <Editor apiKey='t21f9uu8xkoyas4d800dojz0q68xajd2gcs62n8ocskunmxe' />
                </div>
            </article>
        );
    }
}
export default EmailTemplateList
