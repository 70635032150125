import { put, call, takeEvery } from 'redux-saga/effects';
import { dashboardService,trackingListService, eldAssetListService,factordashboardService, brokerdashboardService ,factordashboardMutipleEldService,brokerMultipleElddashboardService} from '../services/dashboardService';
import * as types from '../actions';

// export function* dashboardSaga() {
//      try {
//      const response = yield call(dashboardService);
//      yield put({type: types.GET_DASHBOARD_DATA_SUCCESS, response});
//      } 
//      catch(error) {
//      yield put({ type: types.GET_DASHBOARD_DATA_ERROR, error })
//      }
// }

// async function fetchAsync(func) {
//     const response = await func();
//     if (response.ok) {
//         return await response.json();
//     }
//     throw new Error("Unexpected error!!!");
// }

function* fetchData(payload) {
    try {
        const response = yield call(dashboardService, payload);
        //console.log(response);
        yield put({type: types.GET_DASHBOARD_DATA_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.GET_DASHBOARD_DATA_ERROR, error: e.message});
    }
}

function* factorfetchData(payload) {
    try {
        const response = yield call(factordashboardService, payload);
        //console.log(response);
        yield put({type: types.GET_DASHBOARD_DATA_FACTOR_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.GET_DASHBOARD_DATA_FACTOR_ERROR, error: e.message});
    }
}
function* factorMutipleEldfetchData(payload) {
    try {
        const response = yield call(factordashboardMutipleEldService, payload);
        //console.log(response);
        yield put({type: types.GET_DASHBOARD_DATA_FACTOR_MUTIPLE_ELD_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.GET_DASHBOARD_DATA_FACTOR_MUTIPLE_ELD_ERROR, error: e.message});
    }
}


function* brokerfetchData(payload) {
    try {
        const response = yield call(brokerdashboardService, payload);
        //console.log(response);
        yield put({type: types.GET_DASHBOARD_DATA_BROKER_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.GET_DASHBOARD_DATA_BROKER_ERROR, error: e.message});
    }
}

function* brokerMultipleEldfetchData(payload) {
    try {
        const response = yield call(brokerMultipleElddashboardService, payload);
        console.log(response);
        yield put({type: types.GET_DASHBOARD_DATA_BROKER_MULTIPLE_ELD_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.GET_DASHBOARD_DATA_BROKER_MULTIPLE_ELD_ERROR, error: e.message});
    }
}

export function* dashboardSaga(payload) {
    // Allows concurrent fetches of users
    yield takeEvery(types.GET_DASHBOARD_DATA, fetchData);
    yield takeEvery(types.GET_DASHBOARD_DATA_FACTOR, factorfetchData);
    yield takeEvery(types.GET_DASHBOARD_DATA_BROKER, brokerfetchData);
    yield takeEvery(types.GET_DASHBOARD_DATA_FACTOR_MUTIPLE_ELD, factorMutipleEldfetchData);
    yield takeEvery(types.GET_DASHBOARD_DATA_BROKER_MULTIPLE_ELD, brokerMultipleEldfetchData);

    // Does not allow concurrent fetches of users
    // yield takeLatest(LOAD_USERS_LOADING, fetchUser);
}
// async function fetchAsyncWithPayload(func,payload) {
//     const response = await func(payload);
//     if (response.ok) {
//         return await response.json();
//     }
//     throw new Error("Unexpected error!!!");
// }
// function* fetchTrackingData(payload) {
//     try {
//         const response = yield fetchAsyncWithPayload(trackingListService,payload);
//         yield put({type: types.GET_TRACKING_LIST_SUCCESS, exportData: response.Date_List});
//     } catch (e) {
//         yield put({type: types.GET_TRACKING_LIST_ERROR, error: e.message});
//     }
// }
// export function* trackingListSaga() {
//     // Allows concurrent fetches of users
//     yield takeEvery(types.GET_TRACKING_LIST, fetchTrackingData);

//     // Does not allow concurrent fetches of users
//     // yield takeLatest(LOAD_USERS_LOADING, fetchUser);
// }

// async function fetchAsyncWithPayload(func,payload) {
//     const response = await func(payload);
//     if (response.ok) {
//         return await response.json();
//     }
//     throw new Error("Unexpected error!!!");
// }

function* fetchTrackingData(payload) {
    try {    
        const response = yield call(trackingListService,payload);
        yield put({type: types.GET_TRACKING_LIST_SUCCESS, exportData: response.Date_List, responseData: payload.payload});
    } catch (e) {
        yield put({type: types.GET_TRACKING_LIST_ERROR, error: e.message});
    }
}
export function* trackingListSaga() {
    // Allows concurrent fetches of users
    yield takeEvery(types.GET_TRACKING_LIST, fetchTrackingData);

    // Does not allow concurrent fetches of users
    // yield takeLatest(LOAD_USERS_LOADING, fetchUser);
}
// Get Eld providers Assets
function* fetchEldAssets(payload) {
    try {
        const response = yield call(eldAssetListService,payload);
        yield put({type: types.GET_ELD_ASSEST_LIST_SUCCESS, dashboard: response});
    } catch (e) {
        yield put({type: types.GET_ELD_ASSEST_LIST_ERROR, error: e.message});
    }
}
export function* eldAssetsListSaga() {
    // Allows concurrent fetches of users
    yield takeEvery(types.GET_ELD_ASSEST_LIST, fetchEldAssets);
}
