import React, { Component } from 'react';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { invitedCompanyList, myCarrierList } from '../../actions/companyActions';
import CarrierSearch from './CarrierSearch';
import moment from 'moment';
import { getPdfDownloadLink, getOnboardPdfDownloadLink } from '../../services/companyService';
import { history } from '../../helpers/history';
import CustomModal from '../../helpers/CustomModal';
import { TableDataComponent } from 'UI-Component';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Box } from '@mui/material';
import MaterialUITabs from '../uicomponents/DataTableGridMUIX';
require('dotenv').config();
const { SearchBar } = Search;
class BrokersCarrierList extends Component {
	constructor(props) {
		super(props);
		this.getIndex = this.getIndex.bind(this)
		this.state = {
			hasInviteTemplates: true,
			tabIndex: 0
		}
	}
	getIndex(index, selectedId) {
		this.setState({ activeId: selectedId })
	}
	componentDidMount = () => {
		let userType = localStorage.getItem('userType');
		this.setState({ userType: userType })
		if ('isActiveTab' in sessionStorage) {
			this.setState({
				activeId: sessionStorage.getItem('isActiveTab'),
				tabIndex: sessionStorage.getItem('isActiveTab')
			})
		} else {
			this.setState({ activeId: 'mycarriers', tabIndex: 'mycarriers' })
			sessionStorage.setItem('isActiveTab', 'mycarriers');
		}
		switch (sessionStorage.getItem('isActiveTab')) {
			case 'mycarriers':
				this.getMyCarrier(0);
				break;
			case 'invited':
				this.invitedAPICall(1);
				break;
			default:
				break;
		}
	}
	getInvitedCarrier = (newTabIndex) => {
		this.setState({ tabIndex: newTabIndex })
		this.props.dispatch(invitedCompanyList())
		sessionStorage.setItem('isActiveTab', 'invited');
	}
	getMyCarrier = (newTabIndex) => {
		this.setState({ tabIndex: newTabIndex })
		this.props.dispatch(myCarrierList())
		sessionStorage.setItem('isActiveTab', 'mycarriers');
	}
	refreshCarrier = () => {
		this.getMyCarrier();
	}
	componentWillUnmount() {
		sessionStorage.removeItem('isActiveTab');
	}
	loadTabs = () => {
		return (
			<>
				<div className='flex-left-auto inviteBtn tab-btn'>
					<ul className='ul-horizontal tab-menus'>
						<li>
							<a href='/#' className='btn btn-primary btn-sm' data-toggle='modal' data-backdrop='false' data-target='#modal1' style={{ marginBottom: '1rem' }}>Invite Carriers</a>
						</li>
					</ul>
				</div>
				<MaterialUITabs tabsData={
					[{ component: 'MY Carriers', tabID: 'mycarriers-tab', refData: 'mycarriers', methodName: (e, data, index) => { this.getMyCarrier(e, data, index) } },
					{ component: 'Invited Carriers', tabID: 'invited-tab', refData: 'invited', methodName: (e, data, index) => { this.getInvitedCarrier(e, data, index) } },
					]}
					getIndex={this.getIndex}
					activeTab={this.state.activeId}
					state={this.state}
				/>
			</>
		);
	}
	newCarrier() {
		history.push('/COBNew');
	}
	reInvitecarrier = (rowdata) => {
		this.setState(
			{
				passingData: rowdata
			}
		);
	}
	downloadPdf = (e, Carrier_Invitation_Id) => {
		e.preventDefault();
		let param = {
			payload: {
				carrierInvitationId: Carrier_Invitation_Id
			}
		}
		getPdfDownloadLink(param).then((response) => {
			if (response && response.status && response.status === 1) {
				window.open(response.message);
			} else {
				toastr.error('Error', 'Unable to find the document');
			}
		});
	}
	downloadOnboardedPdf = (e, Company_Misc_Id) => {
		e.preventDefault();
		let param = {
			payload: {
				companyMiscId: Company_Misc_Id
			}
		}
		getOnboardPdfDownloadLink(param).then((response) => {
			if (response && response.status && response.status === 1) {
				window.open(response.message);
			} else {
				toastr.error('Error', 'Unable to find the document');
			}
		});
	}
	downloadLOAPdf = (e, LOA) => {
		e.preventDefault();
		switch (LOA) {
			case 'empty': return
			case null: return
			case undefined: return
			case '': {
				return
			}
			default: {
				window.open(LOA);
			}
		}
	}
	prepareDataToBeselected() {
		let preparedData = []
		const onboardLength = this.props.onBoardedCarrierList.length;
		let counter = 0;
		for (let i = 0; i < onboardLength; i++) {
			if (this.props.onBoardedCarrierList[i].statusInfo.enrollmentAuthorizedByCarrier === false) {
				for (let index = 0; index < this.props.onBoardedCarrierList[i].eldInfo.length; index++) {
					counter++;
					preparedData.push(counter)
				}
			} else {
				counter++;
			}
		}
		return preparedData;
	}
	showOnboardCarrier() {
		let mycarriers = []
		const onboardLength = this.props.onBoardedCarrierList.length;
		for (let i = 0; i < onboardLength; i++) {
			this.props.onBoardedCarrierList[i].eldInfo.map((row, index) => {
				let stringLoadDate = '';
				if (this.props.onBoardedCarrierList[i].statusInfo?.onboarded !== '' && this.props.onBoardedCarrierList[i].statusInfo?.onboarded !== null) {
					stringLoadDate = moment(this.props.onBoardedCarrierList[i].statusInfo?.onboarded).format('MM-DD-YYYY');
				}
				mycarriers.push({
					Company_Name: this.props.onBoardedCarrierList[i].carrierInfo.carrierName,
					TC_ID: this.props.onBoardedCarrierList[i].carrierInfo.carrierId,
					Carrier_legal_Name: this.props.onBoardedCarrierList[i].carrierInfo.carrierLegalName,
					Opt_Status: this.props.onBoardedCarrierList[i].statusInfo.enrollmentAuthorizedByCarrier ? 'Enabled' : 'Disabled',
					Data_Sharing: <span key={index}>
						{this.props.onBoardedCarrierList[i].eldInfo[index].eldDataSharing?.authenticationData ? <i id='iconPermissionTrue' className='icofont-lock' data-tip data-for='authenticationData'></i> : <i id='iconPermissionFalse' className='icofont-lock' data-tip data-for='authenticationData'></i>}
						{this.props.onBoardedCarrierList[i].eldInfo[index].eldDataSharing?.vehicleData ? <i id='iconPermissionTrue' className='icofont-truck-alt' data-tip data-for='vehicleData'></i> : <i id='iconPermissionFalse' className='icofont-truck-alt' data-tip data-for='vehicleData'></i>}
						{this.props.onBoardedCarrierList[i].eldInfo[index].eldDataSharing?.locationData ? <i id='iconPermissionTrue' className='icofont-location-pin' data-tip data-for='locationData'></i> : <i id='iconPermissionFalse' className='icofont-location-pin' data-tip data-for='locationData'></i>}
						{this.props.onBoardedCarrierList[i].eldInfo[index].eldDataSharing?.driverData ? <i id='iconPermissionTrue' className='icofont-user-alt-3' data-tip data-for='driverData'></i> : <i id='iconPermissionFalse' className='icofont-user-alt-3' data-tip data-for='driverData'></i>}
						{this.props.onBoardedCarrierList[i].eldInfo[index].eldDataSharing?.hoursOfServiceData ? <i id='iconPermissionTrue' className='icofont-ui-clock' data-tip data-for='hoursOfServiceData'></i> : <i id='iconPermissionFalse' className='icofont-ui-clock' data-tip data-for='hoursOfServiceData'></i>}<br />
						{this.props.onBoardedCarrierList[i].eldInfo[index].eldDataSharing?.engineData ? <i id='iconPermissionTrue' className='icofont-spanner' data-tip data-for='engineData'></i> : <i id='iconPermissionFalse' className='icofont-spanner' data-tip data-for='engineData'></i>}
						{this.props.onBoardedCarrierList[i].eldInfo[index].eldDataSharing?.dvirData ? <i id='iconPermissionTrue' className='icofont-file-file' data-tip data-for='dvirData'></i> : <i id='iconPermissionFalse' className='icofont-file-file' data-tip data-for='dvirData'></i>}
						<button onClick={(e) => { this.downloadLOAPdf(e, this.props.onBoardedCarrierList[i].eldInfo[index].letterOfAuthorizationUrl); }} className='btn cobpdf' title='Download PDF' disabled={!this.props.onBoardedCarrierList[i].statusInfo.enrollmentAuthorizedByCarrier}>
							<i className='icofont-file-pdf' data-tip data-for='loa'></i>
						</button>
						{/* <span> */}
						{this.props.onBoardedCarrierList[i].eldInfo[index].eldLetterOfAuthorizationUrl ?
							<button onClick={(e) => { this.downloadLOAPdf(e, this.props.onBoardedCarrierList[i].eldInfo[index].eldLetterOfAuthorizationUrl); }} className='btn cobpdf' title='Download PDF' disabled={!this.props.onBoardedCarrierList[i].statusInfo.enrollmentAuthorizedByCarrier}>
								<i className='icofont-file-pdf' data-tip data-for='eld-loa'></i>
							</button> : null}
						{/* </span> */}
						<ReactTooltip id='authenticationData'>
							Authentication Data
						</ReactTooltip>
						<ReactTooltip id='vehicleData'>
							Vehicle Data
						</ReactTooltip>
						<ReactTooltip id='locationData'>
							Location Data
						</ReactTooltip>
						<ReactTooltip id='driverData'>
							Driver Data
						</ReactTooltip>
						<ReactTooltip id='hoursOfServiceData'>
							Hours Of Service Data
						</ReactTooltip>
						<ReactTooltip id='engineData'>
							Engine Data
						</ReactTooltip>
						<ReactTooltip id='dvirData'>
							DVIR Data
						</ReactTooltip>
						<ReactTooltip id='loa'>
							TC-LOA
						</ReactTooltip>
						<ReactTooltip id='eld-loa'>
							ELD-LOA
						</ReactTooltip>
					</span>,
					Phone_Number: <span>
						{this.props.onBoardedCarrierList[i].contactInfo?.phoneNumber}
					</span>,
					No_Of_Trucks: this.props.onBoardedCarrierList[i].eldInfo[index].numberOfTrucks,
					ELD_Provider_Name: this.props.onBoardedCarrierList[i]?.eldInfo[index].eldVendorDisplayName,
					MC: this.props.onBoardedCarrierList[i].carrierInfo.carrierCodes.map(carrierCodes =>
						'codeType' in carrierCodes && carrierCodes.codeType === 'MC' ? carrierCodes.codeValue : ''
					),
					Dot: this.props.onBoardedCarrierList[i].carrierInfo.carrierCodes.map(carrierCodes =>
						'codeType' in carrierCodes && carrierCodes.codeType === 'DOT' ? carrierCodes.codeValue : ''
					),
					SCAC: this.props.onBoardedCarrierList[i].carrierInfo.carrierCodes.map(carrierCodes =>
						'codeType' in carrierCodes && carrierCodes.codeType === 'SCAC' ? carrierCodes.codeValue : ''
					),
					UUID: this.props.onBoardedCarrierList[i].carrierInfo.carrierCodes.map(carrierCodes =>
						'codeType' in carrierCodes && carrierCodes.codeType === 'UUID' ? carrierCodes.codeValue : ''
					),
					eMail: this.props.onBoardedCarrierList[i].contactInfo?.email,
					Date_Onboarded: stringLoadDate,
					name: this.props.onBoardedCarrierList[i].contactInfo.hasOwnProperty('firstName') && this.props.onBoardedCarrierList[i].contactInfo.hasOwnProperty('lastName') ? (this.props.onBoardedCarrierList[i].contactInfo?.firstName === null ? '' : this.props.onBoardedCarrierList[i].contactInfo?.firstName) + ' ' + (this.props.onBoardedCarrierList[i].contactInfo?.lastName === null ? '' : this.props.onBoardedCarrierList[i].contactInfo?.lastName) : null
				})
				return mycarriers;
			})
		}
		return mycarriers
	}
	showInviteCarrier() {
		let invited = [];
		this.props.invitedCarrierList.map((row, index) => {
			let stringLoadDate = '';
			if (row.Expired_On !== '' && row.Expired_On !== null) {
				stringLoadDate = moment(row.Expired_On).format('MM-DD-YYYY');
			}
			invited.push({
				id: row.Carrier_Invitation_Id,
				Invitation_ID: row.statusInfo.invitationId,
				Company_Id: row.carrierInfo.carrierId,
				Company_Name: row.carrierInfo.carrierName,
				Carrier_legal_Name: row.carrierInfo.carrierLegalName,
				Country_Code: row.Country_Code,
				State_Name: row.contactInfo.city === null || '' ? row.contactInfo.state : row.contactInfo.city + ',' + ' ' + row.contactInfo.state,
				Current_Zip: row.contactInfo.zipCode,
				MC: row.carrierInfo.carrierCodes.map(carrierCodes =>
					'codeType' in carrierCodes && carrierCodes.codeType === 'MC' ? carrierCodes.codeValue : ''
				),
				Dot: row.carrierInfo.carrierCodes.map(carrierCodes =>
					'codeType' in carrierCodes && carrierCodes.codeType === 'DOT' ? carrierCodes.codeValue : ''
				),
				SCAC: row.carrierInfo.carrierCodes.map(carrierCodes =>
					'codeType' in carrierCodes && carrierCodes.codeType === 'SCAC' ? carrierCodes.codeValue : ''
				),
				UUID: row.carrierInfo.carrierCodes.map(carrierCodes =>
					'codeType' in carrierCodes && carrierCodes.codeType === 'UUID' ? carrierCodes.codeValue : ''
				),
				Company_Email: row.contactInfo.email,
				Name: row.contactInfo.hasOwnProperty('firstName') && row.contactInfo.hasOwnProperty('lastName') ? (row.contactInfo.firstName === null ? '' : row.contactInfo.firstName) + ' ' + (row.contactInfo.lastName === null ? '' : row.contactInfo.lastName) : null,
				Status: <span>
					{row.statusInfo.status === 'INVITED' ?
						<button data-tip data-for='invitedcounter' onClick={(e) => { this.reInvitecarrier(row) }} id='statusInvited' data-toggle='modal' data-target='#customModal' data-backdrop='true' className='btn btn-primary btn-sm' >
							<span className='invite'>{row.statusInfo.status}</span>
							<span className='re-invite'>Re-Invite
								<ReactTooltip id='invitedcounter'>
									Reinvited {row.statusInfo.reinviteCounter} times
								</ReactTooltip>
							</span>
						</button>
						: row.statusInfo.status === 'EXPIRED' ?
							<button onClick={(e) => { this.reInvitecarrier(row) }} id='statusInvited' data-toggle='modal' data-target='#customModal' data-backdrop='true' className='btn btn-primary btn-sm' >
								<span className='invite'>{row.statusInfo.status}</span>
								<span className='re-invite'>Re-Invite</span>
							</button>
							: row.statusInfo.status === 'VERIFICATION_FAILED' ?
								<button id='statusVerificationFailed' className='btn btn-primary btn-sm' >VERIFICATION FAILED</button>
								: row.statusInfo.status === 'REINVITED' ?
									<button data-tip data-for='reinvitedcounter' onClick={(e) => { this.reInvitecarrier(row) }} id='statusInvited' data-toggle='modal' data-target='#customModal' data-backdrop='true' className='btn btn-primary btn-sm' >
										<span className='invite'>{row.statusInfo.status}</span>
										<span className='re-invite'>Re-Invite
											<ReactTooltip id='reinvitedcounter'>
												Reinvited {row.statusInfo.reinviteCounter} times
											</ReactTooltip>
										</span>
									</button>
									: null}</span>,
				expiry_date: stringLoadDate,
				Invited: row.statusInfo.hasOwnProperty('invited') ? moment(row.statusInfo.invited).format('MM-DD-YYYY') : null,
				Expired: row.statusInfo.hasOwnProperty('expired') ? moment(row.statusInfo.expired).format('MM-DD-YYYY') : null,
				ELD_Provider_Name: row.statusInfo.status === 'INVITED' || row.statusInfo.status === 'EXPIRED' ? 'Unknown' : row.eldInfo.length > 0 ? row.eldInfo[0].eldVendorDisplayName : 'Unknown'
			});
		return invited
		});
		return invited
	}
	invitedAPICall() {
		this.setState({ tabIndex: sessionStorage.getItem('isActiveTab') })
		this.getInvitedCarrier(1)
		sessionStorage.setItem('isActiveTab', 'invited');
	}
	rowClasses = (row, rowIndex) => {
		let classes = null;
		let disabledRows = []
		if (row.Opt_Status === 'Disabled') {
			disabledRows.push(rowIndex + 1)
		}
		if (disabledRows.includes(rowIndex + 1)) {
			classes = "custom-row-class";
		}
		return classes;
	};
	render() {
		let carrier_nodata = '';
		if (this.props.loading) {
			carrier_nodata =
				<div className='spinner-border' role='status'>
					<div className='row react-bs-table-no-data'></div>
				</div>
		}
		else {
			carrier_nodata = 'No Data Found';
		}
		const myCarColumns = [
			{
				text: 'TC Carrier ID',
				dataField: 'TC_ID',
				headerStyle: { width: '120px' }
			},
			{
				text: 'Carrier Name',
				dataField: 'Company_Name',
				sort: true,
				headerStyle: { width: '150px' }
			},
			{
				text: 'Carrier Legal Name',
				dataField: 'Carrier_legal_Name',
				sort: true,
				headerStyle: { width: '220px' }
			},
			{
				text: 'Name',
				dataField: 'name',
				sort: true,
				headerStyle: { width: '130px' }
			},
			{
				text: ' Carrier Email',
				dataField: 'eMail',
				headerStyle: { width: '150px' },
				sort: true
			},
			{
				text: 'DOT #',
				dataField: 'Dot',
				sort: true,
				headerStyle: { width: '80px' }
			},
			{
				text: 'MC #',
				dataField: 'MC',
				headerStyle: { width: '70px' }
			},
			{
				text: 'UUID #',
				dataField: 'UUID',
				headerStyle: { width: '80px' }
			},
			{
				text: 'SCAC #',
				dataField: 'SCAC',
				headerStyle: { width: '90px' }
			},
			{
				text: 'ELD Provider',
				dataField: 'ELD_Provider_Name',
				headerStyle: { width: '120px' },
				sort: true
			},
			{
				text: 'Trucks',
				dataField: 'No_Of_Trucks',
				sort: true,
				headerStyle: { width: '90px' }
			},
			{
				text: 'Date Onboarded',
				dataField: 'Date_Onboarded',
				headerStyle: { width: '190px' },
				sort: true
			},
			{
				text: 'Data Sharing',
				dataField: 'Data_Sharing',
				headerStyle: { width: '100px', paddingLeft: '15px' }
			},
			{
				text: 'Is Preferred Company',
				dataField: 'isPreferredCompany',
				hidden: true
			}
		];
		const invitedColumns = [
			{
				text: 'Invitation ID',
				dataField: 'Invitation_ID',
				sort: true,
				headerStyle: { width: '150px' }
			},
			{
				text: 'Carrier Name',
				dataField: 'Company_Name',
				sort: true,
				headerStyle: { width: '150px' }
			},
			{
				text: 'Carrier Legal Name',
				dataField: 'Carrier_legal_Name',
				sort: true,
				headerStyle: { width: '220px' }
			},
			{
				text: 'Name',
				dataField: 'Name',
				sort: true,
				headerStyle: { width: '150px' }
			},
			{
				text: 'Carrier Email',
				dataField: 'Company_Email',
				sort: true,
				headerStyle: { width: '150px' }
			},
			{
				text: 'DOT #',
				dataField: 'Dot',
				sort: true,
				headerStyle: { width: '80px' }
			},
			{
				text: 'MC #',
				dataField: 'MC',
				headerStyle: { width: '70px' }
			},
			{
				text: 'UUID #',
				dataField: 'UUID',
				headerStyle: { width: '80px' }
			},
			{
				text: 'SCAC #',
				dataField: 'SCAC',
				headerStyle: { width: '90px' }
			},
			{
				text: 'Invited On',
				dataField: 'Invited',
				sort: true,
				headerStyle: { width: '150px' }
			},
			{
				text: ' Expires On',
				dataField: 'Expired',
				sort: true,
				headerStyle: { width: '150px' }
			},
			{
				text: 'ELD Provider',
				dataField: 'ELD_Provider_Name',
				headerStyle: { width: '150px' },
				sort: true
			},
			{
				text: 'Status',
				dataField: 'Status',
				sort: true,
				headerStyle: { width: '100px' }
			}
		];
		const customTotal = (from, to, size) => (
			<span className='react-bootstrap-table-pagination-total'>
				Showing {from} to {to} of {size} Results
			</span>
		);
		const options = {
			paginationSize: 4,
			pageStartIndex: 1,
			firstPageText: 'First',
			prePageText: 'Back',
			nextPageText: 'Next',
			lastPageText: 'Last',
			nextPageTitle: 'First page',
			prePageTitle: 'Pre page',
			firstPageTitle: 'Next page',
			lastPageTitle: 'Last page',
			showTotal: true,
			paginationTotalRenderer: customTotal,
		};
		return (
			<article className='table-data truck-data shadow bg-white rounded'>
				<div className='row no-gutters'>
					<div className='col-md-12'>
						{this.loadTabs()}
						<div className='tab-content' id='myTabContent'>
								<Box sx={{ padding: 2 }}>
									{this.state.tabIndex === 0 && (
										<Box>
												<ToolkitProvider
													keyField='id'
													data={this.showOnboardCarrier()}
													columns={myCarColumns}
													search
												>
													{
														props => (
															<div>
																<SearchBar {...props.searchProps} />
																<BootstrapTable bootstrap4
																	{...props.baseProps} pagination={paginationFactory(options)}
																	noDataIndication={carrier_nodata} hover bordered={false}
																	expandRow={this.expandRow ? this.expandRow : {}}
																	rowClasses={this.prepareDataToBeselected() && (this.state.userType === 'broker' || this.state.userType === 'carrier' || this.state.userType === 'techpartner') ? this.rowClasses : ''} />
															</div>
														)
													}
												</ToolkitProvider>
										</Box>
									)}
									{this.state.tabIndex === 1 && (
										<Box>
											<TableDataComponent tableData={this.showInviteCarrier()} columnData={invitedColumns} noDataIndication={carrier_nodata} />
										</Box>
									)}
								</Box>
							<div>
								<CarrierSearch />
								<CustomModal data={this.state.passingData} />
							</div>
						</div>
					</div>
				</div>
			</article>
		)
	}
}
const mapStateToProps = (state) => {
	return {
		loading: state.companyReducer.loading,
		invitedCarrierList: state.companyReducer.invitedCarriers,
		onBoardedCarrierList: state.companyReducer.myCarriers
	}
}
export default connect(mapStateToProps)(BrokersCarrierList)