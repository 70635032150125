import React, { Component, Fragment } from 'react';
class ReinviteHome extends Component{
    submitInvitation = (e) => {

        this.props.onAllow(true);
           
    }
    render = () => {
        return(
            <Fragment>
                {/* <header className="header-section">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <div className="row carrier">
                                <div className="col-sm-2">
                                    <a className="navbar-brand" href="#">
                                        <img src={this.props.companyProfile.logo} />
                                    </a>
                                </div>
                            </div>
                        </nav>
                    </div>
                </header> */}
                <article>
                    <div className="container">
                        <div className="row thank-you">
                            <div className="col-md-12">
                                <div className="contant-page">
                                    <img src={this.props.companyProfile.logo} alt='Not Found'/>
                                    <hr />
                                    <strong>The following ELD authorization is failed. You can reinvite the carrier </strong>
                                    <hr />
                                        <p><b>Carrier name :</b> {this.props.cobInvitationData.carrier_name}</p>
                                        <p><b>Eld name :</b> {this.props.cobInvitationData.eldName}</p>
                                        <p><b>Invited on :</b> {this.props.cobInvitationData.invitedDate}</p>
                                        <p><b>Reason :</b> {this.props.cobInvitationData.reason}</p>
                                    
                                    {/* <p>
                                        Carrier name : Frieghtco <br />
                                        Eld name : Omnitracs<br />
                                        Invited on : 11-11-2020 <br />
                                        Reason : Eld credentials wrong
                                    </p> */}
                                    <div className="thankyou-btn">
                                        <button type="button" onClick={(e) => { this.submitInvitation(e) }} className="btn gradient-button-2">Reinvite</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </Fragment>
        );
    }
}

export default ReinviteHome;