import * as types from '../actions';
const initialState = {
    data: [],
    loading: false,
    error: '',
    userAuthenticated:false,
    forgetpwdflag:false,
    savepwdflag:false
};

export default function(state = initialState, action) {
    switch(action.type) {
        case types.LOGIN_USER:
            return { 
                ...state,
                loading: true,
                error:'',
                userAuthenticated:false
            };
        case types.LOGIN_USER_SUCCESS:
            return { 
                ...state,
                data: action.response,
                loading: false,
                userAuthenticated:true
            };
        case types.LOGIN_USER_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error,
                userAuthenticated:false
            };
        case types.LOGOUT_USER:
            return { 
                ...state,
                loading: true,
                error: 'logged-out',
                userAuthenticated:false
            }; 
        case types.LOGIN_USER_SUCCESS:
            return { 
                ...state,
                loading: false,
                error: 'logged-out',
                userAuthenticated:false
            };   
        case types.MAIL_SEND_FRGT_PWD:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.MAIL_SEND_FRGT_PWD_SUCCESS:
            return { 
                ...state,
                data: action.data,
                forgetpwdflag:true,
                loading: false,
            };
        case types.MAIL_SEND_FRGT_PWD_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.FORGET_PWD_FLAG:
            return {
                ...state,
                forgetpwdflag:false
            };
        case types.SAVE_NEW_PWD:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.SAVE_NEW_PWD_SUCCESS:
            return { 
                ...state,
                data: action.data,
                savepwdflag:true,
                loading: false,
            };
        case types.SAVE_NEW_PWD_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.FORGET_PWD_FLAG:
            return {
                ...state,
                savepwdflag:false
            };
        default:
            return state;
    }
};