export const validate = values => {
    // var oldpwdval  = localStorage.getItem('Password')
      const errors = {}    
        if (!values.currentPassword) {
           errors.currentPassword = 'CurrentPassword can not be left blank.!'
        } 
        if (!values.password) {
            errors.password = 'Password can not be left blank.!'
        }
        if (!values.retypePassword) {
            errors.retypePassword = 'RetypePassword can not be left blank.!'
        }
        if (values.retypePassword!==values.password) {
            errors.retypePassword = 'Please Enter a valid password!'
        }    
      return errors
    }