import React from 'react';
import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';

const drawerWidth = 350;

const SidePanel = (props) => {
    const theme = useTheme();
    const isMobileView = !useMediaQuery(theme.breakpoints.up('md'));

    if (isMobileView) {
        return (
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    }
                }}
                variant="temporary"
                anchor="left" 
                open={props.open} 
                onClose={props.toggleDrawer(false)}
                ModalProps={{
                    keepMounted: true
                }}
            >
                {props.children}
            </Drawer>
        );
    }
    else {
        return (
            <Drawer
                sx={{
                    zIndex: 500,
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left" 
                open={true}
                ModalProps={{
                    keepMounted: true
                }}
            >
                {props.children}
            </Drawer>
        );
    }
}

export default SidePanel;