import React from 'react';
class SearchCarrierComponent extends React.Component {
    render() {
        return (
            <>
                <span className='icofont-info-circle' ></span>
                &ensp;MC , DOT and Name searches both Trucker Cloud and FMCSA for information. SCAC searches only Trucker Cloud.
                <article className='table-data truck-data shadow bg-white rounded' style={{ display: 'block' }}>
                    <form >
                        <div onChange={(e) => { this.props.onChange(e) }} className='row'>
                            <div className='col'>
                                <input type='radio' defaultValue={this.props.passedData.carriername || ''} id='carriernameid' defaultChecked={this.props.passedData.radio1} name='searchGroup' />
                                <input placeholder='Carrier Name ' id='carriernameid' autoComplete='off' type='text' className='labelCssForSearch' disabled={!this.props.passedData.radio1} defaultValue={this.props.passedData.carriername || ''} />
                                <span className='shadow-input cname-input'>{this.props.passedData.carrierNameError}</span>
                            </div>
                            <div className='col'>
                                <input type='radio' id='mcnumberid' defaultValue={this.props.passedData.mcNumber || ''} name='searchGroup' defaultChecked={this.props.passedData.radio2} />
                                <input id='mcnumberid' className='labelCssForSearch' autoComplete='off' type='text' placeholder='MC # ' disabled={!this.props.passedData.radio2} defaultValue={this.props.passedData.mcNumber || ''} />
                                <span className='shadow-input'>{this.props.passedData.mcNumberError}</span>
                            </div>
                            <div className='col'>
                                <input type='radio' defaultValue={this.props.passedData.dotNumber || ''} id='dotnumberid' name='searchGroup' defaultChecked={this.props.passedData.radio3} />
                                <input id='dotnumberid' type='text' placeholder='DOT #' autoComplete='off' className='labelCssForSearch' disabled={!this.props.passedData.radio3} defaultValue={this.props.passedData.dotNumber || ''} />
                                <span className='shadow-input'>{this.props.passedData.dotNumberError}</span>
                            </div>
                            <div className='col'>
                                <input type='radio' id='scacnumberid' name='searchGroup' disabled />
                                <input id='scacnumberid' type='text' placeholder='SCAC #' className='labelCssForSearch' disabled />
                            </div>
                            <div className='col'>
                                <input type='radio' id='uuidId' name='searchGroup' defaultValue={this.props.passedData.uuId || ''} defaultChecked={this.props.passedData.radio4}/>
                                <input id='uuidId' type='text' placeholder='UUID #' className='labelCssForSearch' autoComplete='off' disabled={!this.props.passedData.radio4} defaultValue={this.props.passedData.uuId || ''} />
                                <span className='shadow-input'>{this.props.passedData.uuIdError}</span>
                            </div>
                            <div className='col-lg-1'>
                                <button type='button' disabled={this.props.passedData.searchValue} defaultValue='searchbtn' className='btn btn-primary' onClick={(e) => { this.props.searchClick(e); }} >Search</button>
                            </div>
                        </div>
                    </form>
                </article>
            </>
        )
    }
}
export default SearchCarrierComponent;