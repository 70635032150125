import { authHeader } from '../helpers/authHeader';
import {checkUnauthorized} from '../helpers/appUtil';
require('dotenv').config();


export const userlistservice = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const USER_DATA_API_ENDPOINT = BASE_URL+'userresource/user/userlist?usertype='+request.payload.usertype+'&companyid='+localStorage.getItem('companyId');
    
    return fetch(USER_DATA_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};

export const addNewUser = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const ADD_USER_API_ENDPOINT = BASE_URL+'userresource/user/add';

    return fetch(ADD_USER_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);         
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}
export const changepasswordService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const CHANGE_PASSWORD_API = BASE_URL+'userresource/user/passwordreset?oldpassword='+request.payload.oldpassword+'&newpassword='+request.payload.newpassword;

    return fetch(CHANGE_PASSWORD_API,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);          
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const driveraddService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const DRIVER_ADD_API = BASE_URL+'driver/add'
    return fetch(DRIVER_ADD_API,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);          
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const drivereditService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const DRIVER_ADD_API = BASE_URL+'driver/update'
    return fetch(DRIVER_ADD_API,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);          
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const driverdeleteService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const DRIVER_ADD_API = BASE_URL+'userresource/user/deleted?userid='+request.payload
    return fetch(DRIVER_ADD_API,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);           
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}



export const profileviewService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const USER_DATA_API_ENDPOINT = BASE_URL+'companyresource/company/profileview'
    return fetch(USER_DATA_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message); 
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};

export const profileeditService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const PROFILEEDIT = BASE_URL+'companyresource/company/update'
    return fetch(PROFILEEDIT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => { 
        checkUnauthorized(responseData.status, responseData.message);           
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const shipperListService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const SHIPPERLIST = BASE_URL+'companyresource/company/shipperlist'
    return fetch(SHIPPERLIST,{
        method: "GET",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);          
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const shipperAddService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const SHIPPERADD = BASE_URL+'companyresource/company/shipperadd'
    return fetch(SHIPPERADD,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);          
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const getDriversService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const SHIPPERADD = BASE_URL+'userresource/driver/userlist'
    return fetch(SHIPPERADD,{
        method: "GET",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {   
        checkUnauthorized(responseData.status, responseData.message);         
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const inviteDriverService = (request) => {
    //console.log(request);
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const SHIPPERADD = BASE_URL+'userresource/driver/invite_driver?userId='+request.userId;
    return fetch(SHIPPERADD,{
        method: "POST",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);           
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const getUserRoles = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const SHIPPERADD = BASE_URL+'roleresource/role/get?companyId='+request.payload.companyId
    return fetch(SHIPPERADD,{
        method: "GET",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {   
        checkUnauthorized(responseData.status, responseData.message);         
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const updateUserStatus = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const SHIPPERADD = BASE_URL+'userresource/user/changestatus?userid='+request.payload.userId
    return fetch(SHIPPERADD,{
        method: "POST",        
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);          
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}





