import { authHeader } from '../helpers/authHeader';
import {checkUnauthorized} from '../helpers/appUtil';

require('dotenv').config();
export const dashboardService = (request) => {    
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    let LOGIN_API_ENDPOINT = BASE_URL+'assetsresource/trackinglist';
    if(request.payload){
        if(request.payload.carrierCompanyId){
            LOGIN_API_ENDPOINT = LOGIN_API_ENDPOINT + '?carrierCompanyId='+request.payload.carrierCompanyId;
        }
        else if(request.payload.eldProviderId){
            LOGIN_API_ENDPOINT = LOGIN_API_ENDPOINT + '?eldProviderId='+request.payload.eldProviderId;
        }
        //console.log(request.payload);
    }

    // return fetch(LOGIN_API_ENDPOINT)
    // .then(response => {
    //     ////console.log("service-" + response.json());
    //     return response.json();
    // })
    // .then(json => {
    //     return json;
    // });
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }) 
    .then((response) => response.json())
    .then((responseData) => {       
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};

export const factordashboardService = (request) => {  
    const BASE_URL= process.env.REACT_APP_BASE_URL;  
    let LOGIN_API_ENDPOINT = BASE_URL+'assetsresource/factortrackinglist';
    if(request.payload){
        if(request.payload.carrierCompanyId && request.payload.carriercompanyIdFlag){
            LOGIN_API_ENDPOINT = LOGIN_API_ENDPOINT + '?carrierCompanyId='+request.payload.carrierCompanyId;
        }
        else if(request.payload.eldProviderId){
            LOGIN_API_ENDPOINT = LOGIN_API_ENDPOINT + '?eldProviderId='+request.payload.eldProviderId+'&carrierCompanyId='+request.payload.carrierCompanyId;
        }
        //console.log(request.payload);
    }
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }) 
    .then((response) => response.json())
    .then((responseData) => {       
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};

export const factordashboardMutipleEldService = (request) => {  
    const BASE_URL= process.env.REACT_APP_BASE_URL;  
    let LOGIN_API_ENDPOINT = BASE_URL+'assetsresource/factortrackinglist?carrierCompanyId='+request.payload.carrierCompanyId +'&eldProviderId='+request.payload.eldProviderId;

    
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }) 
    .then((response) => response.json())
    .then((responseData) => {       
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};
export const brokerdashboardService = (request) => {  
    const BASE_URL= process.env.REACT_APP_BASE_URL;  
    let LOGIN_API_ENDPOINT = BASE_URL+'assetsresource/brokertrackinglist';
    if(request.payload){
        if(request.payload.eldProviderId){
            LOGIN_API_ENDPOINT = LOGIN_API_ENDPOINT + '?eldProviderId='+request.payload.eldProviderId+'&carrierCompanyId='+request.payload.carrierCompanyId;
        }
        else{
            LOGIN_API_ENDPOINT = LOGIN_API_ENDPOINT + '?carrierCompanyId='+request.payload.carrierCompanyId;            
        }
        //console.log(request.payload);
    }
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }) 
    .then((response) => response.json())
    .then((responseData) => {       
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};

export const brokerMultipleElddashboardService = (request) => {  
    const BASE_URL= process.env.REACT_APP_BASE_URL;  
    let LOGIN_API_ENDPOINT = BASE_URL+'assetsresource/brokertrackinglist?carrierCompanyId='+request.payload.carrierCompanyId +'&eldProviderId='+request.payload.eldProviderId;
  
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }) 
    .then((response) => response.json())
    .then((responseData) => {       
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};
//tracking list
// export const trackingListService = (request) => {
//     const BASE_URL= process.env.REACT_APP_BASE_URL;
//     const TRACKING_LIST_END_POINT = BASE_URL+'trackingresource/datelist?fromDate='+request.payload.fromDate+'&toDate='+request.payload.toDate+'&truckNo='+request.payload.truckNo;
//     return fetch(TRACKING_LIST_END_POINT, {
//         method: 'GET',
//         headers: authHeader()
//    });
// };
export const trackingListService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const TRACKING_LIST_END_POINT = BASE_URL+'trackingresource/datelist';
    return fetch(TRACKING_LIST_END_POINT, {
        method: 'POST',
        body:JSON.stringify(request.payload),
        headers: authHeader()
   })
   .then((response) => response.json())
   .then((responseData) => {       
        checkUnauthorized(responseData.status, responseData.message);     
       return responseData;
   })
   .catch(error => {
       console.warn(error);
       return {};
   });
};
//eld Assets List
export const eldAssetListService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const ELD_ASSEST_END_POINT = BASE_URL+'assetsresource/trackinglist?eldProviderId='+request.payload;
    return fetch(ELD_ASSEST_END_POINT, {
        method: 'GET',
        headers: authHeader()
   })
   .then((response) => response.json())
   .then((responseData) => {       
        checkUnauthorized(responseData.status, responseData.message);     
       return responseData;
   })
   .catch(error => {
       console.warn(error);
       return {};
   });
};