import React,{Component} from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { profileview } from '../../actions/userActions';
import BrokerProfilEdit from './brokerProfilEdit';
import { getcitybystate,getzipbycity } from '../../actions/loadActions';
import { companyVerifyService } from '../../services/companyService';
import {toastr} from 'react-redux-toastr';
import { Editor } from '@tinymce/tinymce-react';

class editemailtemplate extends Component
{
      state={
        modaldata:[],
      GeofenceRad:[
       {id:"5",radiusVal:"5 Miles"},
       {id:"10",radiusVal:"10 Miles"},
       {id:"15",radiusVal:"15 Miles"},
       {id:"20",radiusVal:"20 Miles"}
      ],
      myArray: ["1:00", "2:00", "3:00"],
        verifyResponse : ""
     }
      carrierprofile=()=>
      {
          if(this.props.data.Company.currentCityId!==null)
          {
              //console.log(this.props.data.Company);
              this.props.dispatch(getcitybystate(this.props.data.Company.currentStateCode));
          }
          if(this.props.data.Company.currentZipId!==null)
          {
              this.props.dispatch(getzipbycity(this.props.data.Company.currentCityId));
          }
          this.setState({ modaldata: this.props.data.Company });
      }
  
      componentDidMount()
      {
          this.props.dispatch(profileview());
      }

      verifyThisProfile =()=>
      {
        companyVerifyService().then((response) => {            
            if(response){                
                this.setState({
                    ...this.state,
                    verifyResponse:response
                });
            }
        });
      }

      componentDidUpdate = () =>{  
        if(this.state.verifyResponse){
        if(this.state.verifyResponse.message==="Company Verified Successfully"){  
			toastr.success('Success', 'Company Verified Successfully!');
        }}
    }
   
     handleEditorChange = (e) => {
    // //console.log(
    //   'Content was updated:',
    //   e.target.getContent()
    // );
  }
    render(){  
        this.props.initialize(this.props.data.Company); 
        // const { handleSubmit } = this.props;
        let myarray = this.state.GeofenceRad ;        
        return(   
            <div className="table-data border">
                <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="truckinfo-tab" data-toggle="tab" href="#truckinfo" role="tab" aria-controls="truckinfo" aria-selected="true">Edit Email Template</a>
                    </li>      
                    <div className="flex-left-auto">
                        <ul className="ul-horizontal tab-menus">   
                            <li>
                            </li>                      
                        </ul>
                    </div>                           
                </ul>
                <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab">  
                        <div className="row">                 
                            <div className="col col-md-12">
                                <form className="tg-forms"> 
                                <article className="table-data truck-data shadow bg-white rounded">
                                    <div className="row">
                                          <Editor apiKey='t21f9uu8xkoyas4d800dojz0q68xajd2gcs62n8ocskunmxe'                                         
                                          init={{ selector: 'textarea#myTextArea',                                         
                                          paste_data_images: true,
                                          automatic_uploads: false,
                                          file_picker_types: 'image',
                                          relative_urls : false,
                                          remove_script_host : false,
                                          menubar:false,
                                          statusbar: false,
                                          height: 500, 
                                          toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | insertsitelink',
                                           setup: editor => {
                                             editor.ui.registry.addMenuButton('insertsitelink', {
                                                  text: "Web URL",
                                                  icon: "link",
                                                  fetch: function (callback) {
                                                    var items = [];                                                    
                                                    var myListItems = myarray ;
                                                   function iterate(row, index) {
                                                    items.push({
                                                        type: 'menuitem',
                                                        text: row['radiusVal'],
                                                        onAction: function(){
                                                          editor.insertContent('<a href="'+row['id']+'">'+row['radiusVal'] +'</a>');
                                                        }
                                                      })
                                                      }
                                                    myListItems.forEach(iterate);
                                                    callback(items);
                                                    },
                                                  onAction: function (_) {
                                                    //console.log("Cloning...")
                                                    editor.insertContent('&nbsp;<strong>Cloning...</strong>&nbsp;');
                                                  },
                                                });
                                           }
                                         }}
                                           onChange={this.handleEditorChange}
                                             />                                    
                                    </div>
                                </article>
                                </form>
                            </div>
                        </div>
                  </div>                   
               </div>
               <BrokerProfilEdit modalvalues={this.state.modaldata} country={this.props.country} CityVal={this.props.CityVal} ZipVal={this.props.ZipVal} state={this.props.statelist} specialitytypelist={this.props.specialitytypelist}/>
        </div>   
        )
    }
}

const PageOneForm = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    })(editemailtemplate)

  const mapStateToProps = (state,ownProps) => { 
    return{
        data: state.userReducer.data,
        CityVal:state.loadReducer.CityVal,
        ZipVal:state.loadReducer.ZipVal,
        form: 'profiledetailsview',
    }    
  }
  
  const PageOneFormContainer = connect(mapStateToProps)(PageOneForm)
    
  export default PageOneFormContainer