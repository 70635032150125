import React, { Component } from 'react';
import BrokersCarrierList from './CarrierList';
import {appBaseUrl} from '../../helpers/appUtil';

class CarrierOnBoard extends Component{
	state={
	}
	componentDidMount() {
		document.title = appBaseUrl() + 'Carriers';
	}

	render(){		
		return(
				<BrokersCarrierList history={this.props.history} companyTypeId={7} statelist={this.props.statelist} country={this.props.country} specialitytypelist={this.props.specialitytypelist} eldproviderlist={this.props.eldproviderlist}/>
		)
	}
}

export default CarrierOnBoard