import React from 'react'
import Isvg from 'react-inlinesvg'
import IconButton from '@mui/material/IconButton'
import styles from './icon.css'

export const Icon  = (props) => {
  const {
    keyName = '',
    title,
    name,
    url,
    boxClassName,
    iconClassName,
    hover,
    height,
    width,
    size = 20,
    color,
    iconButton,
    type = 'icons',
    onClick = () => {},
    alt = 'vector_images',
    children,
    reference,
    imageId,
  } = props
  const node = (isIconButton) => {
    try {
      return type === 'icons' ? (
        <span
          key={keyName}
          title={title}
          className={`${styles.iconContainer} ${hover ? styles.pointer : ''}
              ${boxClassName}`}
          onClick={(!isIconButton && onClick) || undefined}
        >
          <Isvg
            className={`${iconClassName} ${styles.iconSvg}`}
            src={require(`../../assets/${type}/${name}.svg`)}
            width={width || size}
            height={height || size}
            // preProcessor={(code) => code.replace(/fill=".*?"/g, `fill=${color}`)}
            // preProcessor={(code) =>
            //   code.replace(color ? /fill=".*?"/g : '', `fill='${color}'`)
            // }
            stroke={color}
          />
        </span>
      ) : (
        <div className={boxClassName}>
          <img
            id={imageId}
            src={url ? url : require(`../../assets/${type}/${name}.png`)}
            alt={alt}
            ref={reference}
          />
        </div>
      )
    } catch (e) {
      const err = `Icon Error: ${!name ? 'name is missing in prop' : e.message}`
      return <span title={err}>icon error</span>
    }
  }

  const isIconButton = Boolean(children || iconButton)

  return (
    <>
      {isIconButton ? (
        <IconButton onClick={onClick} className={boxClassName} key={keyName}>
          {children ? children : node(isIconButton)}
        </IconButton>
      ) : (
        node()
      )}
    </>
  )
}

export default Icon
