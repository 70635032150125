import * as React from 'react'
import MuiContainer from '@mui/material/Container'

const Container = (props) => {
  const {
    className,
    disableGutters = true,
    maxWidth = false,
    children,
    sx = {},
    innerHtml,
  } = props
  return (
    <MuiContainer
      className={`${className}`}
      sx={{ display: 'grid', ...sx }}
      disableGutters={disableGutters}
      maxWidth={maxWidth}
      dangerouslySetInnerHTML={innerHtml}
    >
      {children}
    </MuiContainer>
  )
}

export default Container
