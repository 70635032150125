import * as React from 'react'
import Typography from '@mui/material/Typography'

const TypoGraph = (props) => {
  const {
    children,
    variant = 'body1',
    sx,
    align = 'left',
    gutterBottom = true,
    paragraph = true,
    classes,
    mb = 0,
    color,
    fontSize='1.2rem',
    link = false,
    content,
    className,
    component = 'div',
    onClick = () => {},
  } = props
  
  let typoSx = { ...sx }

  if (link) {
    typoSx = { ...typoSx, cursor: 'pointer' }
  }

  if (color) {
    typoSx = { ...typoSx, color: color }
  }

  return (
    <Typography
      sx={typoSx}
      className={className}
      variant={variant}
      classes={classes}
      align={align}
      gutterBottom={gutterBottom}
      paragraph={paragraph}
      component={component}
      onClick={onClick}
      mb={mb}
      fontSize={fontSize}
    >
      {children || content}
    </Typography>
  )
}

export default TypoGraph
