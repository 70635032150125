import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import logo from '../../truckercloud-logo.png';
import { Col, Image, Row } from 'react-bootstrap';
import SimpleLoadingSpinner from '../common/SimpleLoadingSpinner';
import { getBaseUrl, getUrlParams } from '../../helpers/appUtil';
import { DeleteCookie, GetCookie } from '../../helpers/Cookies';

const ExternalOnboard = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [resultMessage, setResultMessage] = useState(null);
    const [apiCallFinished, setApiCallFinished] = useState(false);

    const marketplaceOnboardingSeparator = '~';
    const invitationCookieName = 'invitationkey';
    const csrfCookieName = 'oauthCsrfKey';
    const invitationKey = GetCookie(invitationCookieName, true) ?? '';
    const csrf = GetCookie(csrfCookieName, true) ?? '';
    let isDirectOnboarding = false;
    let isValidCSRF = true;
    let urlParams;
    let code;
    let scope;
    let state;
    let identifier;
    let identifierParts;
    let errorMessage;
    let domainUrl = getBaseUrl();
    
    const hasValidUrlParams = (urlParams) => {
        return urlParams.identifier && 
            urlParams.identifier.length > 0 && 
            urlParams.identifier.includes(marketplaceOnboardingSeparator) &&
            urlParams.code &&
            urlParams.code.length > 0 &&
            (!urlParams.error || 
            urlParams.error.length === 0);
    }

    const isValidRedirectCookie = (invitationToken) => {
        return invitationToken && 
            invitationToken.length > 0 && 
            invitationToken !== undefined && 
            invitationToken !== '';
    }

    if (props.location.search.length > 0) {
        let url = props.location.search;
        // -------------- Fix: Motive --------------
        // The url is not being parsed correctly when the url contains a question mark (bug from Motive side)
        if (url.includes("?identifier=") === true && url.includes("?code=") === true) {
            url = url.replace("?code=", "&code=");
        }
        // -----------------------------------------
        urlParams = getUrlParams(url);
        if (hasValidUrlParams(urlParams) === true)
        {
            code = urlParams.code;
            scope = urlParams.scope ?? '';
            state = urlParams.state ?? '';

            scope = decodeURIComponent(scope)
            state = decodeURIComponent(state)

            if (state !== "" && state.length > 0) {
                if (state !== csrf) {
                    isValidCSRF = false;
                    console.log('Invalid CSRF state');
                }
            }

            identifier = urlParams.identifier;
            if (isValidCSRF === true) {
                if (isValidRedirectCookie(invitationKey) === true) {
                    DeleteCookie(invitationCookieName, true);
                    window.location.href = window.location.protocol + '//' + domainUrl + '/cob?invitationkey=' + invitationKey + '&code=' + code;
                }
                else {
                    identifierParts = identifier.split(marketplaceOnboardingSeparator);
                    isDirectOnboarding = true;
                }
            }
        }
        else {
            errorMessage = urlParams.error_description ?? "";
            console.log('Invalid identifier');
        }
    }
    else {
        console.log('No params found');
    }

    const callOnboardingEndpoint = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                companyId: identifierParts[1],
                code: urlParams.code,
                eldName: identifierParts[0]
            })
        };

        return fetch(process.env.REACT_APP_CARRIER_API_URL + 'carriers/onboard/oauth/', requestOptions)
                .then((response) => response.json())
                .then(data => {
                    setApiCallFinished(true);
                    setIsLoading(false);
                    if (data.status === 'OK') {
                        setResultMessage({
                            'message': 'The onboarding was successfull! You can close this browser tab now.',
                            'color': 'green'
                        });
                    }
                    else {
                        setResultMessage({
                            'message': 'Error: ' + data.message,
                            'color': 'red'
                        });
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setApiCallFinished(true);
                    setIsLoading(false);
                    setResultMessage({
                        'message': 'It seems the installation process is broken. Please contact support@truckercloud.com for assistance.',
                        'color': 'red'
                    });
                })
    }

    useEffect(() => {
        if (apiCallFinished === false && isDirectOnboarding === true) {
            if (hasValidUrlParams(urlParams) && (!invitationKey || invitationKey === undefined)) {
                callOnboardingEndpoint();
            }
            else {
                console.log('Invalid params or invitation key')
                setIsLoading(false);
                setResultMessage({
                    'message': 'It seems the installation process is broken. Please contact support@truckercloud.com for assistance.',
                    'color': 'red'
                });
            }
        }
    }, [isDirectOnboarding, identifierParts, urlParams, apiCallFinished]);

    // CSRF Validation
    if (state !== '' && state !== undefined && isValidCSRF === false) {
        return (
            <div style={{ overflow: 'hidden' }}>
                <Row className="mt-5">
                    <Col className="text-center">
                        <Image src={logo} alt="Company Logo" fluid style={{ maxWidth: '30vmax' }} />

                        <Row className="mt-5">
                            <Col>
                                <h3 style={{ fontSize: '3.5vmin' }}>Sorry! We cannot validate this onboarding.</h3>
                                <h3 style={{ fontSize: '3vmin' }}>We weren't able to confirm the security parameters of the current operation.</h3>
                                <h5 style={{ fontSize: '2.5vmin'}} className="mt-5">Contact support@truckercloud.com if the issue persists.</h5>
                                <p className="text-muted mt-3">CSRF token mismatch</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }

    // Error Message
    if (errorMessage !== '' && errorMessage !== undefined) {
        return (
            <div style={{ overflow: 'hidden' }}>
                <Row className="mt-5">
                    <Col className="text-center">
                        <Image src={logo} alt="Company Logo" fluid style={{ maxWidth: '30vmax' }} />

                        <Row className="mt-5">
                            <Col>
                                <h3 style={{ fontSize: '3.5vmin' }}>Sorry! We couldn't validate your onboarding.</h3>
                                <h3 style={{ fontSize: '3vmin' }}>An error has ocurred in the process.</h3>
                                <h5 style={{ fontSize: '2.5vmin'}} className="mt-5">Contact support@truckercloud.com if the issue persists.</h5>
                                <p className="text-muted mt-3">ELD error response</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }

    if (isDirectOnboarding === true) {
        if (!identifierParts || identifierParts === undefined || identifierParts.length !== 2) {
            return (
                <div style={{ overflow: 'hidden' }}>
                    <Row className="mt-5">
                        <Col className="text-center">
                            <Image src={logo} alt="Company Logo" fluid style={{ maxWidth: '30vmax' }} />

                            <Row className="mt-5">
                                <Col>
                                    <h3 style={{ fontSize: '3.5vmin' }}>Sorry! There is a communication issue</h3>
                                    <h3 style={{ fontSize: '3vmin' }}>The communication between our platform and the marketplace seems to be broken. Please try again.</h3>
                                    <h5 style={{ fontSize: '2.5vmin'}} className="mt-5">Contact support@truckercloud.com if the issue persists.</h5>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            );
        }
        else {
            return (
                <div style={{ overflow: 'hidden' }}>
                    <Row className="mt-5">
                        <Col className="text-center">
                            <Image src={logo} alt="Company Logo" fluid style={{ maxWidth: '30vmax' }} />

                            <Row className="mt-5">
                                <Col>
                                    <h3 style={{ fontSize: '3.5vmin' }}>Thank you for installing our application!</h3>
                                    <h3 style={{ fontSize: '3vmin' }}>We will finish the process for you, please wait.</h3>
                                    <div className="mt-5">
                                        {isLoading ? <SimpleLoadingSpinner /> : <h3 style={{ fontSize: '2.5vmin', color: resultMessage.color }}>{resultMessage.message}</h3>}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            );
        }
    }
    else {
        if (isValidRedirectCookie(invitationKey) === true) {
            return (
                <div style={{ overflow: 'hidden' }}>
                    <Row className="mt-5">
                        <Col className="text-center">
                            <Image src={logo} alt="Company Logo" fluid style={{ maxWidth: '30vmax' }} />

                            <Row className="mt-5">
                                <Col>
                                    <h3 style={{ fontSize: '3.5vmin' }}>You are being redirected back to TruckerCloud onboarding.</h3>
                                    <h3 style={{ fontSize: '3vmin' }}>Please wait...</h3>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            );
        }
        else {
            return (
                <div style={{ overflow: 'hidden' }}>
                    <Row className="mt-5">
                        <Col className="text-center">
                            <Image src={logo} alt="Company Logo" fluid style={{ maxWidth: '30vmax' }} />

                            <Row className="mt-5">
                                <Col>
                                    <h3 style={{ fontSize: '3.5vmin' }}>Sorry! There is a communication issue</h3>
                                    <h3 style={{ fontSize: '3vmin' }}>The communication between our platform and the marketplace seems to be broken. Please try again.</h3>
                                    <h5 style={{ fontSize: '2.5vmin'}} className="mt-5">Contact support@truckercloud.com if the issue persists.</h5>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            );
        }
    }
};

export default withRouter(ExternalOnboard);