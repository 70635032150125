import React, { Component, Fragment } from 'react';
import ReinviteHome from '../pages/COBReinviteHome';
import ReinviteThankYou from '../pages/reinviteThankYou';
import './carrieronboard.css';
import $ from 'jquery';
import 'jquery-validation';
import {getCompanyProfilePublicService} from '../../services/companyService';
import {invitationKeyCheckService, saveReinvitationService} from '../../services/carrierService';
import { toastr } from 'react-redux-toastr';
import {appBaseUrl,getBaseUrl} from '../../helpers/appUtil';
require('dotenv').config();

class CarrierOnboardReinvite extends Component{
    constructor(props)
    {
        super(props);

        this.state={
            isReady:false,
            page:1,
            companyProfile:{
				companyProfileId:null,
                companyId:null,
                colourCode:null,
                phoneNumber:null,
                logo:null,
                email:null,
                banner:null
			},
            cobInvitationData:{
                carrierId:'',
                verificationKey:'',
                carrier_name:'',
                eldName:'',
                invitedDate:'',
                reason:'',
                templateId:'',
                userId:'',
                carrierEldAuthenticationId:''
            }
        }
    }
    getUrlParams = () => {
        let urlparam = this.props.location.search.substring(1);
        urlparam = JSON.parse('{"' + decodeURI(urlparam).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
        return urlparam;
    }

    invalidError = () => {
        toastr.error('Invalid', 'Verification key not valid');
        setTimeout(
            function() {                                    
                this.props.history.push('/login');
            }
            .bind(this),
            1000
        );
    }
    componentDidMount = () => {
		let theme = process.env.REACT_APP_BASE_URL + 'publicresource/getbycode/css?originUrl='+getBaseUrl();
        $('<link/>', {rel: 'stylesheet', href: theme}).appendTo('head');
        if(this.props.location.search){
            let urlparam = this.getUrlParams();
            if(urlparam.invitationkey){
                document.title = appBaseUrl() + 'System';
                const params1={
                    verificationKey:urlparam.invitationkey
                 }
                 
                invitationKeyCheckService(params1).then( (response) => {
                    if(response.ReInviteData){
                        if(response.ReInviteData.carrierId){
                            this.setState({
                                isReady:true,
                                cobInvitationData:{
                                    carrierId:response.ReInviteData.carrierId,
                                    verificationKey:urlparam.invitationkey,
                                    carrier_name:response.ReInviteData.carrierName,
                                    eldName:response.ReInviteData.eldName,
                                    invitedDate:response.ReInviteData.invitedDate,
                                    reason:response.ReInviteData.reason,
                                    templateId:response.ReInviteData.templateId,
                                    userId:response.ReInviteData.userId,
                                    carrierEldAuthenticationId:response.ReInviteData.carrierEldAuthenticationId
                                }
                            });
                            this.getCompanyProfile();
                        }
                        else{
                            this.invalidError();
                        }
                    }
                    else{
                        this.invalidError();
                    }
                });
            }
            else{
                this.props.history.push('/login');
            }
        }
        else{
            this.props.history.push('/login');
        }
    }
    getCompanyProfile = () => {
        getCompanyProfilePublicService().then((response)=>{
            if(response && response.companyProfile)
			{
				this.setState({
					...this.state,
					companyProfile:response.companyProfile
				});
			}
		});
    }
    onAllow = (cobInvitehomeData) => {
        let param={
            companyId:this.state.cobInvitationData.carrierId,
            userId:this.state.cobInvitationData.userId,
            templateId:this.state.cobInvitationData.templateId,
            carrierEldAuthenticationId:this.state.cobInvitationData.carrierEldAuthenticationId
        }
        saveReinvitationService(param).then((response) => {
            if(response.status){
                if(response.status===1){
                    toastr.success(response.result);
                    this.setState({
                        ...this.state,
                        page:2
                    });                    
                } else {
                    toastr.error('Error',response.result);
                }
            } else {
                toastr.error('Error',response.result);
            }
        });
    }
    reinviteCOBHome = (cprops) => {
        if(this.state.page===1){
            return(<ReinviteHome cobInvitationData={cprops} onAllow={this.onAllow} companyProfile={this.state.companyProfile} />);
        }
        else if(this.state.page===2){
            return(<ReinviteThankYou companyProfile={this.state.companyProfile} />);
        }
        else{
            return(
                <Fragment></Fragment>
            );
        }
    }
    render = () => {
        return(
            <Fragment>
                {this.reinviteCOBHome(this.state.cobInvitationData)}
            </Fragment>
        );
    }
}

export default CarrierOnboardReinvite;