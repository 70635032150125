const components = {	
	optInOptOut: {
		component: 'optInOptOut',
        url: '/opt_in-out',
        title: 'OptInOptOut',
        icon: 'menu',
        module: 1
	},
	carrierprofileview: {
        component: 'carrierprofileview',
        url: '/profileView',
        title: 'View Trailer',
        icon: 'menu',
        module: 1
	},  
};

const CarrierRoutes = {
	carrier: {
		routes: [
			components.optInOptOut,
			components.carrierprofileview
		]
	}
};

export { CarrierRoutes };