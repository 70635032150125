import * as types from './index';

export const loginUserAction = (user) => {
  	return {
    	type: types.LOGIN_USER,
    	user
  	}
};

export const logoutUserAction = () => {
  	return {
    	type: types.LOGOUT_USER
  	}
};

export const MailSendForgetPwd = (payload) => {
	return {
	  type: types.MAIL_SEND_FRGT_PWD,
	  payload: payload
	}
};

export const SaveNewPwd = (payload) => {
	return {
	  type: types.SAVE_NEW_PWD,
	  payload: payload
	}
};

export const ForgetPwdFlag= (payload) => {
	return {
	  type: types.FORGET_PWD_FLAG
	}
};

export const SavePwdFlag= (payload) => {
	return {
	  type: types.FORGET_PWD_FLAG
	}
};


