import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import TrucksList from './TrucksList';
class Trucks extends Component{	
	componentDidMount() {
		document.title = 'Trucks';
	}
	render(){
	    if(this.props.data.status === 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}	
		return(	
                  <Fragment>
					  <TrucksList truck={this.props.trucktypelist} state={this.props.statelist} fueltypelist={this.props.fueltypelist} eld={this.props.eldproviderlist}/>	
                  </Fragment>
		)	
	}
}

const mapStateToProps = state => {
	return {
		data: state.assetReducer.data,
		error: state.assetReducer.error,
	}
}
export default connect(mapStateToProps)(Trucks)
