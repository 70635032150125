import { authHeader } from '../helpers/authHeader';
import { checkUnauthorized } from '../helpers/appUtil';
require('dotenv').config();

export const getcitybystateService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const GET_CITY_API_ENDPOINT = BASE_URL + 'commonresource/getcitybystate?stateId=' + request.payload;
    return fetch(GET_CITY_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}

export const getzipbycityService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const GET_ZIP_API_ENDPOINT = BASE_URL + 'commonresource/getzipbycity?cityId=' + request.payload;
    return fetch(GET_ZIP_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}

//Individual vehicle tracking for broker
export const getVehicleTrackingService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const VEHICLE_TRACKING_API_ENDPOINT = BASE_URL + 'trackingresource/loadvehicletracking?loadId=' + request;
    return fetch(VEHICLE_TRACKING_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}

export const shippertypecompanylistforlanetable = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL + 'loadresource/load/shippertypecompanylistforlanetable'
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}

// export const loadService = (request) => {    
//     const BASE_URL= process.env.REACT_APP_BASE_URL;
//     const LOAD_DATA_API_ENDPOINT = BASE_URL+'load/list'
//     return fetch(LOAD_DATA_API_ENDPOINT,{
//         method: "POST",
//         body:JSON.stringify(request.payload),
//         headers: authHeader()
//     })
//     .then((response) => response.json())
//     .then((responseData) => { 
//         checkUnauthorized(responseData.status, responseData.message);            
//         return responseData;
//     })
//     .catch(error => {
//         return {};
//     });
// }
export const loadService = (request) => {
    let endURL;
    switch (request.payload.loadStatusId) {
        case 8:
            endURL = 'status=ASSIGNED'
            break;
        case 13:
            endURL = 'status=DISPATCHED'
            break;
        case 10:
            endURL = 'status=DELIVERED'
            break;
        case 14:
            endURL = 'status=CANCELLED'
            break;
        default:
            break;
    }
    const BASE_URL = process.env.REACT_APP_LOAD_API_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL + 'loads?' + endURL;
    return fetch(LOAD_DATA_API_ENDPOINT, {
        method: "GET",
        // body:JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}

export const shipperLoadService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL + 'load/shipperlist'
    return fetch(LOAD_DATA_API_ENDPOINT, {
        method: "POST",
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}

export const loadServicefrcancelledloads = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL + 'load/list'
    return fetch(LOAD_DATA_API_ENDPOINT, {
        method: "POST",
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return { cancelledloadlist: responseData };
        })
        .catch(error => {
            return {};
        });
}

// export const loadServicefrquickloads = (request) => {
//     const BASE_URL= process.env.REACT_APP_BASE_URL;
//     const LOAD_DATA_API_ENDPOINT = BASE_URL+'load/list'
//     return fetch(LOAD_DATA_API_ENDPOINT,{
//         method: "POST",
//         body:JSON.stringify(request.payload),
//         headers: authHeader()
//     })
//     .then((response) => response.json())
//     .then((responseData) => {   
//         checkUnauthorized(responseData.status, responseData.message);   
//         return {quickloadlists:responseData};   
//     })
//     .catch(error => {
//         return {};
//     });
// }
export const loadServicefrquickloads = (request) => {
    const BASE_URL = process.env.REACT_APP_LOAD_API_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL + 'loads?status=CREATED'
    return fetch(LOAD_DATA_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return { quickloadlists: responseData };
        })
        .catch(error => {
            return {};
        });
}

// export const addNewLoad = (request) => {
//     const BASE_URL= process.env.REACT_APP_BASE_URL;
//     const LOAD_DATA_API_ENDPOINT = BASE_URL + 'loaddata/add';

//     return fetch(LOAD_DATA_API_ENDPOINT,{
//         method: "POST",
//         body:JSON.stringify(request.payload),
//         headers: authHeader()
//     })
//     .then((response) => response.json())
//     .then((responseData) => {  
//         checkUnauthorized(responseData.status, responseData.message);           
//         return responseData;
//     })
//     .catch(error => {
//         return {};
//     });
// }


export const addNewLoad = (request) => {
    // const BASE_URL= process.env.REACT_APP_BASE_URL;
    // const LOAD_DATA_API_ENDPOINT = BASE_URL + 'loaddata/add';
    const BASE_URL = process.env.REACT_APP_LOAD_API_URL;

    const LOAD_DATA_API_ENDPOINT = BASE_URL + 'loads';

    return fetch(LOAD_DATA_API_ENDPOINT, {
        method: "POST",
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}

export const saveBrokerQuickDispatch = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL + 'quickdispatch/broker';

    return fetch(LOAD_DATA_API_ENDPOINT, {
        method: "POST",
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}

export const tenderChangeLoadStatusService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL + 'loadstatus/set/' + request.payload.statusval
    return fetch(LOAD_DATA_API_ENDPOINT, {
        method: "POST",
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}

// export const cancelledbyloadservice = (request) => {
//     const BASE_URL= process.env.REACT_APP_BASE_URL;
//     const GET_API_ENDPOINT = BASE_URL+'loadresource/load/cancelledbyloadid'
//     return fetch(GET_API_ENDPOINT,{
//         method: "POST",
//         body:JSON.stringify(request.payload),
//         headers: authHeader()
//     })
//     .then((response) => response.json())
//     .then((responseData) => {  
//         checkUnauthorized(responseData.status, responseData.message);           
//         return responseData;
//     })
//     .catch(error => {
//         return {};
//     });
// }
export const cancelledbyloadservice = (request) => {
    const BASE_URL = process.env.REACT_APP_LOAD_API_URL;
    const GET_API_ENDPOINT = BASE_URL + 'loads/' + request.payload;
    return fetch(GET_API_ENDPOINT, {
        method: "PATCH",
        body: JSON.stringify({
            status: "CANCELLED"
        }),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}

export const getEldByCariierService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const GET_ELD_API_ENDPOINT = BASE_URL + 'eldprovidersresource/eldproviders/onboardedlist?companyId=' + request.payload;
    return fetch(GET_ELD_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const editLoad = (request) => {
    const BASE_URL = process.env.REACT_APP_LOAD_API_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL + 'loads/' + request.payload.loadId;
    return fetch(LOAD_DATA_API_ENDPOINT, {
        method: "PATCH",
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const loadServiceRestoreloads = (request) => {
    const BASE_URL = process.env.REACT_APP_LOAD_API_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL + 'loads/' + request.payload.loadId + '/restore';
    return fetch(LOAD_DATA_API_ENDPOINT, {
        method: "PUT",
        // body:JSON.stringify({"status": "CANCELLED"}),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return { cancelledloadlist: responseData };
        })
        .catch(error => {
            return {};
        });
}
export const getTrucksByELDSService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const GET_TRUCK_NO_API_ENDPOINT = BASE_URL + 'assetsresource/carrierassetlist?eldProviderId=' + request.eldProviderId + '&carrierCompanyId=' + request.companyId;
    return fetch(GET_TRUCK_NO_API_ENDPOINT, {
        method: "GET",
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}

export const createDeliveryService = (request) => {
    const CRREATE_DELIVERY_API_ENDPOINT = process.env.REACT_APP_LOAD_API_URL + 'factor';
    return fetch(CRREATE_DELIVERY_API_ENDPOINT, {
        method: "POST",
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const fuelAdvanceService = (request) => {
    const CRREATE_DELIVERY_API_ENDPOINT = process.env.REACT_APP_LOAD_API_URL + 'factor';
    return fetch(CRREATE_DELIVERY_API_ENDPOINT, {
        method: "POST",
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
