import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { borkerQuickDispatchAction, loadAction, loadActionfrquickdispatch, canceledloadflag, LoadAddFlag, deletePropsData } from '../../../actions/loadActions';
import { STATUS } from '../../../helpers/status';
import { companyNameListAction } from '../../../actions/companyActions';
import $ from 'jquery';
import Lanehistory from './lanehistory';
import moment from 'moment';
import cancel from '../../../cancel.png';
import edit from '../../../edit.png';
import filterImg from '../../../filterImg.png';

class QuickDispatch extends Component {
    constructor(props) {
        super(props);
        this.requested = [];
        this.laneDetails = [];
        this.indicationhtml = '';
        this.loadReqFlag = false;
    }
    state = { loadRequested: [], carriers: [] };
    editLoadFun = async (e, row) => {
        await this.setState({ loadValues: row })
        this.props.editloadvalues(e, row)
        $('#LoadDateVal_input').val('');
    }
    lanehistory(row) {
        this.setState({
            laneDetails: row.laneDetails
        })
    }
    componentDidMount = () => {
        this.props.setClick(this.indicationfrLoadlist);
        const params = {
            ...STATUS.LOAD.REQUESTED,
        }
        this.props.dispatch(loadActionfrquickdispatch(params));
        this.indicationfrLoadlist(true);
        const params1 = {
            companytypeid: 3,
        }
        this.props.dispatch(companyNameListAction(params1));
    }

    selectLoad = (e, index) => {
        var loadRequested = this.state.loadRequested;
        loadRequested[index].loadSel = e.target.checked;
        this.setState({
            ...this.state,
            loadRequested: loadRequested
        });
        this.props.tabClicked(index, this.state.loadRequested);
    }
    componentDidUpdate = (prevprops, prevState) => {
        console.log('this.props.data.quickloadlists',this.props.data.quickloadlists)
        if (this.props.data !== prevprops.quickdispatchloads) {
            if (this.state.loadRequested.length === 0) {
                if (Array.isArray(this.props.data.quickloadlists)) {
                    var loadRequested = this.props.data.quickloadlists.map(
                        row => {
                            return ({
                                loadSel: false,
                                loadId: row.loadId,
                                loadNumber: row.loadNumber,
                                loadDate: moment(row.loadDate).format('MM-DD-YYYY'),
                                shipperName: row.shipperInfo?.name,
                                assetNumber: row.vehicleInfo?.assetNumber,
                                customerName: row.customerName,
                                carrierName: row.carrierInfo?.name,
                                contactNumber: row.contactNumber,
                                originCity: row.origin,
                                destinationCity: row.destination,
                                loadPoints: row.loadPoints,
                                originCityName: row.orginCityName,
                                destinationCityName: row.destinationCityName,
                                originState: row.orginStateName,
                                destinationState: row.destinationStateName,
                                orginStateCode: row.orginStateCode,
                                destinationStateCode: row.destinationStateCode,
                                orginZipValue: row.orginZipValue,
                                destinationZipValue: row.destinationZipValue,
                                customerLoadNumber: row.customerLoadNumber,
                                orderNumber: row.orderNumber,
                                bolNumber: row.bolNumber,
                                rate: row.rate,
                                weight: row.weight,
                                distance: row.distance,
                                numberOfPickup: row.numberOfPickup,
                                numberOfDropoff: row.numberOfDropoff,
                                instruction: row.instruction,
                                BrokerNetAmount: row.tenderInfo?.totalBrokerNetAmount,
                                tenderInfo: row.tenderInfo,
                                shipperInfo: row.shipperInfo,
                                carrierInfo: row.carrierInfo,
                                vehicleInfo: row.vehicleInfo
                            });
                        }
                    );

                    if (loadRequested.length > 0) {
                        this.indicationfrLoadlist(true);
                        this.setState({
                            ...this.state,
                            loadRequested: loadRequested
                        });
                    }
                    else if (this.indicationhtml !== 'No Data Found') {
                        this.indicationfrLoadlist(false);
                    }
                }
            }
            else {
                if (this.props.dispatchData.quickloadlists === undefined) {
                }
                else {
                    this.indicationfrLoadlist(true);
                    this.setState({
                        ...this.state,
                        loadRequested: this.props.dispatchData.quickloadlists
                    });
                }
            }

        }

        if (Array.isArray(this.props.companyList.companyList)) {
            if (this.state.carriers.length === 0) {
                var carriers = this.props.companyList.companyList.map(
                    (row, index) => {
                        return ({
                            id: row.Company_Id,
                            company_Name: row.Company_Name,
                            selCarrier: false,
                            selIndex: '',
                            current_City: row.City_Name,
                            company_Email: row.Company_Email,
                            state_Name: row.State_Name,
                            current_Zip: row.Current_Zip,
                            phone_Number: row.Phone_Number,
                            isPreferredCompany: row.isPreferredCompany
                        });
                    });

                if (carriers.length > 0) {
                    this.setState({
                        ...this.state,
                        carriers: carriers
                    });
                }
            }
        }
        //Add or Edit Load starts
        if (this.props.loaddataaddflag === true) {
            if (this.props.data.status === 'CREATED') {
                toastr.success('Success', 'Load has been Successfully Created!');
                this.props.dispatch(LoadAddFlag());
                const params = {
                    ...STATUS.LOAD.REQUESTED
                }
                this.props.dispatch(loadActionfrquickdispatch(params));
                this.indicationfrLoadlist(true);
                this.setState({
                    ...this.state,
                    loadRequested: []
                })
                document.getElementById('addmodalpopup').click()
            }
            else if (this.props.data.status === 'OK') {
                toastr.success('Success', this.props.data.message);
                this.props.dispatch(LoadAddFlag());
                // const params = {
                //     ...STATUS.LOAD.REQUESTED
                // }
                // this.props.dispatch(loadActionfrquickdispatch(params));
                // this.indicationfrLoadlist(true);
                // this.setState({
                //     ...this.state,
                //     loadRequested: []
                // })
                if (this.props.currentStatus.loadStatusId === 1) {
                    const params = {
                        ...STATUS.LOAD.REQUESTED
                    }
                    this.props.dispatch(loadActionfrquickdispatch(params));
                    this.indicationfrLoadlist(true);
                    this.setState({
                        ...this.state,
                        loadRequested: []
                    })
                }
                else {
                    const params = {
                        ...STATUS.LOAD.DELIVERY_CONFIRMED
                    }
                    this.props.dispatch(loadAction(params));
                    this.setState({
                        ...this.state,
                        loadRequested: []
                    })
                }
                document.getElementById('editmodalpopup').click()
            }
            else if (this.props.data.status === 'BAD_REQUEST' || this.props.data.status === 'INTERNAL_SERVER_ERROR') {
                toastr.error('Error', this.props.data?.validationErrors ? this.props.data.validationErrors[0] : this.props.data.message);
                this.props.dispatch(deletePropsData());
            }
        }
        //Add or Edit Load ends

        if (this.props.canceledloadflag === true) {
            if (this.props.data.message === 'load updated') {
                toastr.success('Success', 'Load has been cancelled sucessfully!');
                this.props.dispatch(canceledloadflag());
                if (this.props.currentStatus.loadStatusId === 1) {
                    const params = {
                        ...STATUS.LOAD.REQUESTED
                    }
                    this.props.dispatch(loadActionfrquickdispatch(params));
                    this.indicationfrLoadlist(true);
                    this.setState({
                        ...this.state,
                        loadRequested: []
                    })
                    localStorage.setItem('cancelLoadIds', 0);
                }
                else {
                    const params = {
                        ...STATUS.LOAD.DELIVERY_CONFIRMED
                    }
                    this.props.dispatch(loadAction(params));
                    this.setState({
                        ...this.state,
                        loadRequested: []
                    })
                }
            }
        }
    }
    cancelloadpopupMethodCall(loadid, loadNo) {
        const params = {
            loadId: loadid,
            loadNo: loadNo
        }
        this.setState({ loaddata: params })
    }

    assignLoad = (row, index) => {
        this.indicationfrLoadlist(true);
        var carrierVal = '';
        var a = document.querySelector('#CarrierVal' + [index]);
        if (a.value === 0) {
            toastr.info('Info', 'Please choose a carrier!');
            return;
        }
        else {
            carrierVal = a.value;
        }
        this.props.dispatch(borkerQuickDispatchAction({
            loads: [row.id],
            carrier: carrierVal
        }));
    }

    radiobutton = (e, index) => {
        var cells = document.getElementsByClassName('assignbtn');
        for (var i = 0; i < cells.length; i++) {
            cells[i].disabled = true;
        }
        if (e.target.value === 'on') {
            const idval = 'truckval' + index;
            document.getElementById(idval).disabled = false;
        }
    }

    indicationfrLoadlist = (flag) => {
        if (flag) {
            this.indicationhtml =
                <div className='spinner-border' role='status'>
                    <div className='row react-bs-table-no-data'></div>
                </div>
        }
        else {
            this.indicationhtml = 'No Data Found'
        }
    }

    render() {
        var indicationhtml;
        if (this.props.data.status === 'UNAUTHORIZED') {
            localStorage.clear();
            window.location = '/login'
            return <p></p>
        }
        var rowData = [];
        var carrierRowData = [];
        if (this.props.loading) {
             indicationhtml =
                <div className='spinner-border' role='status'>
                    <div className='row react-bs-table-no-data'></div>
                </div>
        }
        else {
            if (this.state.loadRequested.length > 0) {
                var rowData = this.state.loadRequested.map(
                    (row, index) => {
                        return ({
                            // loadSelInput:<input type='radio' onChange={(e) => this.selectLoad(e,index)} defaultChecked={row.loadSel} />,
                            id: row.loadId,
                            loadNumber: row.loadNumber,
                            loadDate: moment(row.loadDate).format('MM-DD-YYYY'),
                            shipperName: row.shipperName,
                            customerName: row.customerName,
                            carrierName: row.carrierName,
                            consigneeName: row.consigneeName,
                            consigneeAddress: row.consigneeAddress,
                            weight: row.weight,
                            commodity: row.commodity,
                            equipmentType: row.equipmentType,
                            loadPoints: row.loadPoints,
                            basecharge: row.basecharge,
                            fulecharge: row.fulecharge,
                            accessiorialcharge: row.accessiorialcharge,
                            totalrevenue: row.totalrevenue,
                            loadedmiles: row.loadedmiles,
                            emptymiles: row.emptymiles,
                            totalmiles: row.totalmiles,
                            driverpay: row.driverpay,
                            tenderprice: row.tenderprice,
                            tenderamount: row.tenderBrokerNetAmount,
                            tenderBrokerNetAmount: row.tenderBrokerNetAmount,
                            tenderCarrierPrice: row.tenderCarrierPrice,
                            tenderBrokerGross: row.tenderBrokerGross,
                            tenderBaseCharge: row.tenderBaseCharge,
                            tenderFuelCharge: row.tenderFuelCharge,
                            rate: row.rate,
                            laneDetails: row.laneDetails,
                            cityname: row.originCityName,
                            dname: row.destinationCityName,
                            originState: row.orginStateName,
                            destinationState: row.destinationStateName,
                            originState: row.originState,
                            destinationState: row.destinationState,
                            // pickUpLocation:pickUpLocation,
                            // DeliveryLocation:DeliveryLocation,
                            assetNumber: row.assetNumber,
                            pickUpdate: row.loadDate,
                            contactNumber: row.contactNumber,
                            selBtn: <span>
                                {/* <img src={edit}  disabled/> */}
                                <img src={edit} alt='Not Found' data-toggle='modal' id='editloadbroker' data-target='.editLoadModal' onClick={(e) => { this.editLoadFun(e, row); }} /> &ensp;
                                <img src={cancel} alt='Not Found' data-toggle='modal' id='cancelConfirmload' data-target='#cancelConfirmloadModal' onClick={(e) => this.selectLoad(e, index)} /></span>,
                            clkicon: <i data-toggle='modal' data-target='.lanehistorymodal' id='lanehistoryclass' onClick={() => { this.lanehistory(row); }} className='icofont-clock-time'></i>
                        });
                    }
                );
            }
            else {
                var rowData = [];
                 indicationhtml = 'No Data Found';
            }
        }
        const expandRow2 = {
            renderer: row => (
                console.log(row),
                <article className='table-data truck-data shadow bg-white rounded'>
                    <div className='row no-gutters'>
                        <div className='col-md-12'>
                            <ul className='nav nav-tabs' id='myTrucks' role='tablist'>
                                <li className='nav-item'>
                                    <a className='nav-link active' id='pickup-tab' data-toggle='tab' href={`#pickup_${row.id}`} role='tab' aria-controls='pickup' aria-selected='true' >
                                        pickup Details
                                    </a>
                                </li>
                                <li className='nav-item'>
                                    <a className='nav-link' id='delivery-tab' data-toggle='tab' href={`#delivery_${row.id}`} role='tab' aria-controls='delivery' aria-selected='false' >
                                        Delivery Details
                                    </a>
                                </li>
                            </ul>
                            <div className='tab-content' id='myTabContent'>
                                <div className='tab-pane fade show active' id={`pickup_${row.id}`} role='tabpanel' aria-labelledby='pickup-tab'>
                                    <table className='table table-sm'>
                                        <thead>
                                            <tr>
                                                <th scope='col'>Address</th>
                                                <th scope='col'>Contact</th>
                                                {/* <th scope='col'>Origin</th>
                                                            <th scope='col'>Destination</th> */}
                                                {/* <th scope='col'>Pickup Date / Time</th> */}
                                                <th scope='col'>Earliest Time</th>
                                                <th scope='col'>Latest Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                row.loadPoints.length ? (row.loadPoints.map(function (doc, index) {
                                                    if (doc.typeId === 2) {
                                                        var contactDetails = '';
                                                        let contactNumber = '';
                                                        if (doc.contactNumber !== null) {
                                                            contactNumber = doc.contactNumber
                                                        }
                                                        if (doc.city !== null && doc.state !== null && doc.zipCode !== null) {
                                                            contactDetails = doc.city + ', ' + doc.state + ', ' + doc.zipCode
                                                        }
                                                        else if (doc.city !== null && doc.state !== null) {
                                                            contactDetails = doc.city + ', ' + doc.state
                                                        }
                                                        else if (doc.city !== null && doc.zipCode !== null) {
                                                            contactDetails = doc.city + ', ' + doc.zipCode
                                                        }
                                                        else if (doc.city !== null) {
                                                            contactDetails = doc.city
                                                        }
                                                        else if (doc.state !== null) {
                                                            contactDetails = doc.state
                                                        }
                                                        else if (doc.zipCode !== null) {
                                                            contactDetails = doc.zipCode
                                                        }
                                                        return (
                                                            <tr key={index}>
                                                                <td>{contactDetails}</td>
                                                                <td>{contactNumber}</td>
                                                                {/* <td>{row.pickUpdate}</td> */}
                                                                <td>{moment(doc.earliestTime).format('MM-DD-YYYY')}</td>
                                                                <td>{moment(doc.latestTime).format('MM-DD-YYYY')}</td>
                                                            </tr>
                                                        )
                                                    }
                                                })) : (<tr><td className='text-center' colspan='4'>No Record Found</td></tr>)
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='tab-pane fade' id={`delivery_${row.id}`} role='tabpanel' aria-labelledby='delivery-tab'>
                                    <table className='table table-sm'>
                                        <thead>
                                            <tr>
                                                <th scope='col'>Address</th>
                                                <th scope='col'>Contact</th>
                                                <th scope='col'>Earliest Time</th>
                                                <th scope='col'>Latest Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                row.loadPoints.length ? (row.loadPoints.map(function (doc, index) {
                                                    if (doc.typeId === 3) {
                                                        var contactDetails = '';
                                                        let contactNumber = '';
                                                        if (doc.contactNumber !== null) {
                                                            contactNumber = doc.contactNumber
                                                        }
                                                        if (doc.city !== null && doc.state !== null && doc.zipCode !== null) {
                                                            contactDetails = doc.city + ', ' + doc.state + ', ' + doc.zipCode
                                                        }
                                                        else if (doc.city !== null && doc.state !== null) {
                                                            contactDetails = doc.city + ', ' + doc.state
                                                        }
                                                        else if (doc.city !== null && doc.zipCode !== null) {
                                                            contactDetails = doc.city + ', ' + doc.zipCode
                                                        }
                                                        else if (doc.city !== null) {
                                                            contactDetails = doc.city
                                                        }
                                                        else if (doc.state !== null) {
                                                            contactDetails = doc.state
                                                        }
                                                        else if (doc.zipCode !== null) {
                                                            contactDetails = doc.zipCode
                                                        }
                                                        return (
                                                            <tr key={index}>
                                                                <td>{contactDetails}</td>
                                                                <td>{contactNumber}</td>
                                                                <td>{moment(doc.earliestTime).format('MM-DD-YYYY')}</td>
                                                                <td>{moment(doc.latestTime).format('MM-DD-YYYY')}</td>
                                                            </tr>
                                                        )
                                                    }
                                                }
                                                )) : (<tr><td className='text-center' colspan='4'>No Record Found</td></tr>)
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            ),
            showExpandColumn: true,
            expandByColumnOnly: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                    return (
                        <i className='icofont-minus-circle'></i>
                    );
                }
                return (
                    <i className='icofont-plus-circle'></i>
                );
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (
                        <i className='icofont-minus-circle'></i>
                    );
                }
                return (
                    <i className='icofont-plus-circle'></i>
                );
            }
        };

        const { SearchBar, ClearSearchButton } = Search;
        const columns = [
            {
                dataField: 'loadSelInput',
                text: ''
            },
            {
                text: 'Load #',
                dataField: 'loadNumber',
                sort: true
            },
            {
                text: 'Date',
                dataField: 'loadDate',
                sort: true
            },
            {
                text: 'Shipper',
                dataField: 'shipperName',
                sort: true
            },
            {
                text: 'Carrier Name',
                dataField: 'carrierName',
                sort: true,
            },
            {
                dataField: 'selBtn',
                text: 'Action'
            }
        ];

        const customTotal = (from, to, size) => (
            <span className='react-bootstrap-table-pagination-total'>
                Showing {from} to {to} of {size} Results
            </span>
        );

        const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            sizePerPage: 10,
            firstPageText: '<<<',
            prePageText: '<<',
            nextPageText: '>>',
            lastPageText: '>>>',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal
        };

         carrierRowData = [];
        if (this.props.loading) {
             indicationhtml =
                <div className='spinner-border' role='status'>
                    <div className='row react-bs-table-no-data'></div>
                </div>
        }
        else {
            if (this.state.carriers.length > 0) {
                 carrierRowData = this.state.carriers.map(
                    (row, index) => {
                        if (row.isPreferredCompany === 1)
                            return ({
                                carrierSelInput: <input type='radio' name='radionbtn' id={'truckid' + index} onClick={(e) => this.radiobutton(e, index)} />,
                                selBtn: <button className='btn btn-primary assignbtn' id={'truckval' + index} onClick={(e) => this.assignLoad(row)} >Assign</button>,
                                id: row.id,
                                company_Name: row.company_Name,
                                current_City: row.current_City,
                                company_Email: row.company_Email,
                                state_Name: row.state_Name,
                                current_Zip: row.current_Zip,
                                phone_Number: row.phone_Number,
                                isPreferredCompany: row.isPreferredCompany
                            });
                    });
            } else {
                 carrierRowData = [];
                 indicationhtml = 'No Data Found';
            }
        }
        return (
            <article className='table-data truck-data shadow bg-white rounded'>
                <div className='row no-gutters'>
                    <div className='col-md-12'>
                        <div className='tab-content' id='myTabContent'>
                            <div className='tab-pane fade show active' id='availLoads' role='tabpanel' aria-labelledby='availLoads'>
                                <div className='filter-css float-right'><img src={!this.props.loading ? filterImg : ''} alt='Not Found'/></div>
                                <ToolkitProvider
                                    keyField='id'
                                    data={rowData}
                                    columns={columns}
                                    search
                                >
                                    {
                                        props => (
                                            <div>
                                                <SearchBar {...props.searchProps} />
                                                <ClearSearchButton className='clearValueDisplay' {...props.searchProps} />
                                                <BootstrapTable
                                                    {...props.baseProps} pagination={paginationFactory(options)}
                                                    noDataIndication={this.props.indicationhtml} hover expandRow={expandRow2}
                                                    bordered={false}
                                                />
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <Lanehistory laneValues={this.state.laneDetails} />
            </article>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.loadReducer.data,
        dispatchData: state.loadReducer.data,
        loadEditorAddData: state.loadReducer.editdata,
        DriverList: state.loadReducer.DriverList,
        companyList: state.companyReducer.preferredCompanyList,
        quickdispatchbrokerflag: state.loadReducer.quickdispatchbrokerflag,
        quickDispatch: state.loadReducer.QuickDispatch,
        canceledloadflag: state.loadReducer.canceledloadflag,
        quickdispatchloads: state.loadReducer.data,
        loaddataaddflag: state.loadReducer.loaddataaddflag,
        // brokerTenderFlag: state.loadReducer.brokerTenderFlag,
        loading: state.loadReducer.loading,
        companyNameListValue: state.companyReducer.companyNamelist,
        bulkuploadval: state.loadReducer.loads,
        type: state.loadReducer.type,
        bulkuploadflag: state.loadReducer.bulkuploadflag
    }
}
export default connect(mapStateToProps)(QuickDispatch);