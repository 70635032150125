import React, { Component } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Modal from 'react-bootstrap/Modal';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import {appBaseUrl} from '../../helpers/appUtil';
import {latsTrackingByAssetIdService} from '../../services/assetService';
import {formatLocation, getMapboxKey} from '../../helpers/appUtil';
import moment from 'moment';
import './optinout.css';
import {optInOutAssetsService, saveOptInOutService} from '../../services/assetService';
require('dotenv').config();

class optInOptOut extends Component{
	constructor(props){
        super(props);
        this.state={
            assetList :[],
            elds:[],
            showMap:false,
            mapData:{},
            activeEld:0
        }
        this.map=null;
        this.noDataIndication='';
    }
    componentDidMount() {
        document.title = appBaseUrl() + "Opt In/Out";
        mapboxgl.accessToken = getMapboxKey();
        //mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
        this.loadAssets();        
    }
    loadAssets = () => {
        this.noDataIndication=<div className="spinner-border" role="status">
        <div className="row react-bs-table-no-data"></div>
        </div>;
        this.setState({ ...this.state});
        const params={				
			assettype: 'truck',		
        }
        optInOutAssetsService(params).then( (response) => {
            if(response.AssetList){
                this.setAssetList(response.AssetList);
            }
            //console.log(response);
        });
    }
    setAssetList = (assets) => {
        let eldArray = [];
        let eldList = [];
        for(var i=0; i<assets.length; i++){
            let ELD_Provider_Id = assets[i].ELD_Provider_Id;            
            if(ELD_Provider_Id){
                if(eldArray.indexOf(ELD_Provider_Id)<0){
                    eldArray.push(ELD_Provider_Id);
                    eldList.push({
                        ELD_Provider_Id:assets[i].ELD_Provider_Id,
                        ELD_Provider_Name:assets[i].ELD_Provider_Name
                    });
                }
            }
        }
        this.noDataIndication='No Data Found';
        this.setState({
            ...this.state,
            assetList:assets,
            elds:eldList,
            activeEld:eldArray[0]
        });
    }
    selectOptInOut = (e, index, flag) => {
        let params={
            assetid:this.state.assetList[index].Asset_Id,
            inout_flag:flag
        }
        saveOptInOutService(params).then( (response) => {
            if(response.status){
                let assetList = [...this.state.assetList];
                assetList[index].OptInOut=flag;
                this.setState({
                    ...this.state,
                    assetList:assetList
                });
            }
            else{
                toastr.error('Error', "Unable to save the status");
            }
        } );
    }
    selectEldTab = (e, tab) => {        
        this.setState({
            ...this.state,
            activeEld:tab
        });
    }
    loadTabs = () => {

        let tabs = this.state.elds.map( (row, index) =>{            
            return(
                <li className="nav-item" key={index}>
                    <a className={row.ELD_Provider_Id===this.state.activeEld ? "nav-link active" : "nav-link"} onClick={(e) => {this.selectEldTab(e, row.ELD_Provider_Id)}} data-toggle="tab" href="/#" role="tab" aria-controls="carriers" aria-selected="true">{row.ELD_Provider_Name}</a>
                </li>
            );
        });
        return(
            <ul className="nav nav-tabs" role="tablist">
                {tabs}
            </ul>
        );
    }
    openMap = (e, row) => {
		//latsTrackingByAssetIdService assetId
		if(row.Asset_Id){
			let param = {
				assetId:row.Asset_Id
			}
			latsTrackingByAssetIdService(param).then((response) => {
				if(response.TrackingData){
					row={
						Latitude:response.TrackingData.Latitude,
						Longitude:response.TrackingData.Longitude,
						Asset_Id:1,
						Asset_Number:(row.Asset_Number?row.Asset_Number:''),
						Location:(response.TrackingData.Location?response.TrackingData.Location:''),
						Speed:(response.TrackingData.Speed?response.TrackingData.Speed:''),
						UTC_Date_Time:(response.TrackingData.UTC_Date_Time?response.TrackingData.UTC_Date_Time:'')
					}
					var Latitude = parseFloat(row.Latitude);
					var Longitude = parseFloat(row.Longitude);
					if(isNaN(Latitude) || isNaN(Longitude))
					{
						toastr.info('Info', 'Location not valid!');
						return;
					}
					else if(Latitude>90||Longitude<-90)
					{
						toastr.error('Error', 'Invalid Latitude Longitude');
						return;
					}
					this.setState({
						...this.state,
						showMap:true
					});
			
					setTimeout(() => this.loadMap(row), 1000);
				}
				else{
					toastr.info('Info', 'Tracking data not found');
					return;
				}
			});
		}
		else{
			toastr.info('Info', 'Vehicle not assigned');
			return;
		}
	}
    loadMap = (row) => {
        var el = document.querySelector("#map-canvas");
        if(el === null)
        {
            setTimeout(() => this.loadMap(row), 1000);
            return;
        }
        this.showRouteMap(row.Asset_Id.toString(),row.Asset_Number.toString(),row.Location.toString(),row.Latitude.toString(),row.Longitude.toString(),row.Speed.toString(), row.UTC_Date_Time.toString());
	}

	showRouteMap(id,truckNo,location,Latitude,longitude,Speed,utctime){
		var el = document.querySelector("#map-canvas");
		if(el == null)
		{
			setTimeout(
				function() {
					this.showRouteMap(id,truckNo,location,Latitude,longitude,Speed,utctime);
				}
				.bind(this),
				1000
			);
			return;
		}
		if(el.clientHeight < 1)
		{
            var body = document.body,
            html = document.documentElement;
            var height = Math.min( body.scrollHeight, body.offsetHeight, 
            html.clientHeight, html.scrollHeight, html.offsetHeight );
            height -= 150;
            el.style.height = height+"px";
        }
        this.setState({
            ...this.state,
            mapData:[
                { 
                    "id":id,
                    "truckNo":truckNo,
                    "latitude":Latitude,
                    "longitude":longitude,
                    "speed":Speed,
                    "address":location,
                    "location":location,
                    "date":utctime
                }
            ]
        });
       // if(this.props.data.length>0){
            var features = this.state.mapData.map(data => {
                return(
                {
                    "type":"Feature",
                    "properties":{
                        "id":data.id,                        
                        "latitude":data.latitude,
                        "longitude":data.longitude,
                        "speed":data.speed,                        
                        "location":data.location,
                        "date":data.date,
                        "truckNo":data.truckNo,
                    },
                    "geometry":{
                        "type":"Point",
                        "coordinates":[
                            data.longitude,                            
                            data.latitude
                            
                        ]
                    }
                 }
                )
			}) 
		let Lat = 45;
		let Lon = -90;
		if(this.state.mapData.length>0){
			Lat = parseFloat(this.state.mapData[0].latitude);
			Lon = parseFloat(this.state.mapData[0].longitude);
		}			
		if(isNaN(Lat) || isNaN(Lon)){
			Lat = 45;
			Lon = -90;
		}
		const map = new mapboxgl.Map({
			container : 'map-canvas', // container id
			style : 'mapbox://styles/mapbox/streets-v11', //stylesheet location
            center: [Lon, Lat], // starting position
            zoom: 13 // st arting zoom
        });
        var geojsonData = {
            "type":"FeatureCollection",
            "features": features
        }
        map.on('load', function() {
            // Add a new source from our GeoJSON data and set the
            // 'cluster' option to true.
            map.addSource("earthquakes", {
                type: "geojson",
                // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
                // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
                data: geojsonData,
                cluster: true,
                clusterMaxZoom: 14, // Max zoom to cluster points on
                clusterRadius: 40 // Radius of each cluster when clustering points (defaults to 50)
            });    
            map.loadImage('https://maps.google.com/mapfiles/ms/icons/red.png', function(error, image) {
                if (error) throw error;
                map.addImage('cat', image);
            });       
            map.addLayer({
                "id": "unclustered-points",
                "type": "symbol",
               "source": "earthquakes",
                "layout": {
                        "icon-image": "cat",
                        "icon-size": 1
                },
                "properties":{
                    iconAllowOverlap :false
                }
            });
            // Display the earthquake data in three layers, each filtered to a range of
            // count values. Each range gets a different fill color.
            var layers = [
                [20, '#f28cb1'],
                [10, '#f1f075'],
                [0, '#51bbd6']
            ];    
            layers.forEach(function (layer, i) {
                map.addLayer({
                    "id": "cluster-" + i,
                    "type": "circle",
                    "source": "earthquakes",
                    "paint": {
                        "circle-color": layer[1],
                        "circle-radius": 18
                    },
                    "filter": i === 0 ?
                        [">=", "point_count", layer[0]] :
                        ["all",
                            [">=", "point_count", layer[0]],
                            ["<", "point_count", layers[i - 1][0]]]
                });
            });   
    // Add a layer for the clusters' count labels
            map.addLayer({
                "id": "cluster-count",
                "type": "symbol",
                "source": "earthquakes",
                "layout": {
                    "text-field": "{point_count}",
                    "text-font": [
                        "DIN Offc Pro Medium",
                        "Arial Unicode MS Bold"
                    ],
                    "text-size": 12
                },
                'paint': {
                    'text-color': 'black'
                }
            });
        });
		
		var popup = new mapboxgl.Popup({
			closeButton: false,
			closeOnClick: false
			});
			 
			map.on('mouseenter', 'unclustered-points', function(e) {
				// Change the cursor style as a UI indicator.
				map.getCanvas().style.cursor = 'pointer';
				var coordinates = e.features[0].geometry.coordinates.slice();
                var description = e.features[0].properties;  
				// Ensure that if the map is zoomed out such that multiple
				// copies of the feature are visible, the popup appears
				// over the copy being pointed to.
				while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
					coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
				}         
				// Populate the popup and set it's coordinates
                // based on the feature found.
                var popupDate="";
                if(description.date!=null)
                {
                    popupDate=moment(description.date).format('MM-DD-YYYY T: h:mm a');
                }
                else
                {
                    popupDate= "";
				}
				let speed = parseFloat(description.speed);
				if(isNaN(speed))
				{
					speed ='';
				}
				else{
					speed = speed.toFixed(2) + " mph";
				}
				//console.log(speed);

				let location = formatLocation(description.location);				
				popup
				.setLngLat(coordinates) 
				.setHTML('<span className="map-popup-label">Truck No. : </span><span className="map-popup-label">'+description.truckNo+'</span></br><span className="map-popup-label1"> Date : </span><span className="map-popup-label">'+popupDate+'</span></br><span className="map-popup-label1">Speed : </span><span className="map-popup-label">'+speed+'</span></br><span className="map-popup-label2">Location : </span><span className="map-popup-label">'+location+'</span></br>')
				.addTo(map);
			});         
			map.on('mouseleave', 'unclustered-points', function() {
				map.getCanvas().style.cursor = '';
				popup.remove();
			});

       
			map.on('mouseleave', 'unclustered-points1', function() {
				map.getCanvas().style.cursor = '';
				popup.remove();
			});

	}
	closeMap = () => {                
        this.setState({
            ...this.state,
            showMap:false
        });
    }

    render = () => {            

            var data=[];
            		
            if(!this.props.loading){                
                data = this.state.assetList.map(
                    (row, index) =>{
                        if(row.ELD_Provider_Id === this.state.activeEld){
                            return({
                                id:row.Asset_Id,
                                Asset_Number:row.Asset_Number,
                                Model:row.Model,
                                Make:row.Make,
                                ELD_Provider_Name:row.ELD_Provider_Name,
                                Location:row.Location,
                                Latitude:row.Latitude,
                                Longitude:row.Longitude,
                                odometer:row.Odometer,
                                OptInOut:row.OptInOut,
                                driver:<i data-toggle="modal" id="lanehistoryclass" className="icofont-boy"></i>,
                                hos:<i data-toggle="modal" id="lanehistoryclass" className="icofont-clock-time"></i>,
                                documents:<i data-toggle="modal" id="lanehistoryclass" className="icofont-law-document"></i>,
                                locationIcon:<a  href='/#' className="icon_truck_location btn pc-mailer-tooltip" onClick={(e) => this.openMap(e,row)} ><img src={"/images/mapicon.png"} alt='Loading...'/></a>,
                                optinout:<div><label>OPT In &nbsp; <input onChange={(e) => {this.selectOptInOut(e,index,1);}} name={"opt_"+row.Asset_Id} type="radio" checked={row.OptInOut === 1}></input>&nbsp;
                                        </label><label>OPT Out &nbsp; <input onChange={(e) => {this.selectOptInOut(e,index,0);}} name={"opt_"+row.Asset_Id} checked={row.OptInOut === 0} type="radio"></input></label></div>
                            });
                        }
                    }
                );                
            }
            const { SearchBar } = Search;
            const columns=[	
                {
                    text:'Truck #',
                    dataField:'Asset_Number',
                    sort:true
                },
                {
                    text:'Make',
                    dataField:'Make',
                    sort:true
                },
                {
                    text:'Model',
                    dataField:'Model',
                    sort:true
                },
                // {
                //     text:'Odometer',
                //     dataField:'odometer',
                //     sort:true
                // },
                {
                    text:'ELD Provider',
                    dataField:'ELD_Provider_Name',
                    sort:true
                },
                {
                    text:'Location',
                    dataField:'locationIcon',
                    sort:false
                },
                // {
                //     text:'Driver',
                //     dataField:'driver',
                //     sort:false
                // },                
                // {
                //     text:'Hos',
                //     dataField:'hos',
                //     sort:false
                // },                
                // {
                //     text:'Documents',
                //     dataField:'documents',
                //     sort:false
                // },
                {
                    text:'OPT In/OPT Out',
                    dataField:'optinout',
                    sort:false
                }

             ]
             const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                  Showing { from } to { to } of { size } Results
                </span>
              );
              
              const options = {
                paginationSize: 4,
                pageStartIndex: 1,		
                firstPageText: 'First',
                prePageText: 'Back',
                nextPageText: 'Next',
                lastPageText: 'Last',
                nextPageTitle: 'First page',
                prePageTitle: 'Pre page',
                firstPageTitle: 'Next page',
                lastPageTitle: 'Last page',
                showTotal: true,
                paginationTotalRenderer: customTotal			
              };


            return(<article className="table-data truck-data shadow bg-white rounded">
            <div className="row">
                <div className="col">
                    <h3>Opt IN/OUT</h3>
                </div>
            </div>
            {/* <p>&nbsp;</p>
            <div className="row">
                <div className="col">
                    {this.loadTabs()}
                </div>
            </div> */}
            <div className="row no-gutters">
               <div className="col-md-12">                  
                  <div className="tab-content" id="myTabContent">
                     <div className="tab-pane fade show active" id="mytruck" role="tabpanel" aria-labelledby="mytruck-tab">                         
                        <ToolkitProvider
                           keyField="id"
                           data={ data }
                           columns={ columns }
                           search
                           >
                           {
                           props => (
                           <div>
                              <SearchBar { ...props.searchProps } />
                              <BootstrapTable  ref={ n =>
                              this.node = n } 
                              { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={this.noDataIndication}  hover 
                              bordered={false}
                              />
                           </div>
                           )
                           }
                        </ToolkitProvider>
                     </div>
                  </div>
               </div>
            </div>
            <Modal show={this.state.showMap} onHide={this.closeMap} dialogClassName="modal-90w modal-map">
				<Modal.Header closeButton>
					<Modal.Title>Vehicle Location</Modal.Title>
				</Modal.Header>
				<Modal.Body >
					<div id="map-canvas" className="map-container"></div>
				</Modal.Body>								
			</Modal>
         </article>);
    }
}

const mapStateToProps = state => {
	return {   
        loading: state.loadReducer.loading,
    }
}
export default connect(mapStateToProps)(optInOptOut)